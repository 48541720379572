import React, { useCallback, useEffect, useState } from "react";

import { getCategory, removeCategory } from "./categoryTable.service";

import { EditCategory } from "..";
import { ConfirmationAlert, UpdatedPagination } from "../../../../components";

import "./style.scss";
import { editCategoryPermission } from "../../../../components/NewsFullView/permission.controller";
import { useUser } from "../../../../dataService/contexts";
import { getDictionaryData } from "../../../Dictionary/dictionary.service";

const CategoryTable = () => {
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { state: userState } = useUser();
  const { user } = userState || {};
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [total, setTotal] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const initialParams = {
    size: 40,
    page: 0,
  };
  const [params, setParams] = useState(initialParams);

  const initialState = {
    category: [],
    total: 0,
    page: 0,
  };
  const [state, setState] = useState(initialState);
  const handleParams = (key, value) => {
    if (key === "page") {
      // wrapperRef.current.scrollIntoView();
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams(value);
    }
  };
  const handleState = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const fetchCategory = () => {
    getCategory(params, (status, data) => {
      if (status === "success") {
        const { categories, total, page } = data;
        handleState("category", categories);
        handleState("total", total);
        handleState("page", page);
      }
    });
  };

  const handleOpenAlert = () => setShowAlert(true);

  const handleCloseAlert = () => setShowAlert(false);
  const handleDelete = (id) => {
    removeCategory(id, (status, data) => {
      if (status === "success") {
        handleCloseAlert();
        setState((prev) => {
          return {
            tags: prev.videos.filter((item) => item.id !== id),
            total: prev.total - 1,
          };
        });
      }
    });
  };

  const handleSelectedCategory = (category) => {
    setSelectedCategory(category);
  };
  const removeSelectedCategory = () => setSelectedCategory(null);

  useEffect(() => {
    fetchCategory();
  }, [params]);
  return (
    <div className="text-silver">
      <table className="w-100 table--dictionary ">
        <thead>
          <tr
            className="text-light font-s-n font-w-bolder text-uppercase "
            style={{ letterSpacing: "1px" }}
          >
            <th>SL</th>
            <th>Category</th>
            <th>Color</th>

            <th>Description</th>

            {editCategoryPermission(user) && (
              <th>
                <div className="d-flex justify-content-end">ACTIONS</div>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {state.category.length > 0 &&
            state.category.map((category, index) => (
              <tr key={index}>
                <td className="my-2">
                  <div className="text-white font-w-bold text-nowrap">
                    #{" "}
                    {state.page === 0 ? index + 1 : index + 1 + 40 * state.page}
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {category.categoryName}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  <div
                    style={{
                      height: "1.5rem",
                      width: "40px",
                      background: `${category.color}`,
                    }}
                  ></div>
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {category.description}
                </td>
                {editCategoryPermission(user) && (
                  <td className="text-white font-s-n font-w-bolder">
                    <div className="d-flex justify-content-end gap-4">
                      <button
                        className="btn btn-md  bg-warning text-white font-w-bolder px-4 font-s-md"
                        onClick={() => handleSelectedCategory(category)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-danger btn btn-md text-white px-4 font-w-bolder font-s-md"
                        onClick={() => {
                          handleOpenAlert();
                          setSelectedCategoryId(category.id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      {selectedCategory ? (
        <EditCategory
          selectedCategory={selectedCategory}
          removeSelectedCategory={removeSelectedCategory}
        />
      ) : (
        <></>
      )}

      <div className="mt-3">
        <UpdatedPagination
          itemsPerPage={40}
          total={state.total}
          action={handleParams}
        />
      </div>

      <ConfirmationAlert
        show={showAlert}
        handleConfirm={handleDelete}
        id={selectedCategoryId}
        handleShow={handleCloseAlert}
        contentType={"category"}
      />
    </div>
  );
};

export default CategoryTable;
