import React from "react";

const AnalystReportsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8.60846H22"
        stroke="#808191"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 16.6085H8"
        stroke="#808191"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 16.6085H14.5"
        stroke="#808191"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.44 3.60846H17.55C21.11 3.60846 22 4.48846 22 7.99846V16.2085C22 19.7185 21.11 20.5985 17.56 20.5985H6.44C2.89 20.6085 2 19.7285 2 16.2185V7.99846C2 4.48846 2.89 3.60846 6.44 3.60846Z"
        stroke="#808191"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AnalystReportsIcon;
