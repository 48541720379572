import React from "react";

import moment from "moment";
import { markingColors } from "../../../../const/markingColors";

const TagWiseTable = ({ report }) => {
  return report.length ? (
    <table className="p-4 w-100 table--topicManagement ">
      <thead>
        <tr className="text-light font-s-n font-w-bolder text-uppercase ">
          <th className="text-nowrap">
            <div className="pe-3">SOURCE</div>
          </th>
          <th className="text-nowrap">
            <div className="pe-3">Tags</div>
          </th>
          <th className="text-nowrap">
            <div className="pe-3">Category</div>
          </th>
          <th className="text-nowrap">
            <div className="pe-3">NEWS START TIME</div>
          </th>
          <th className="text-nowrap">
            <div className="pe-3">NEWS MARKED</div>
          </th>
        </tr>
      </thead>
      {report.length ? (
        <tbody className="rounded-5 ">
          {report.map((item) => {
            return (
              <tr>
                <td
                  className="text-white font-s-n font-w-bolder"
                  style={{ width: "25%" }}
                >
                  {item.sourceName || "..."}
                </td>
                <td
                  className="text-light text-nowrap font-s-n font-w-bolder"
                  style={{ width: "15%" }}
                >
                  {item.tags && Array.isArray(item.tags) && item.tags.length
                    ? item.tags.map((i) => i.word).toString()
                    : item.tag}
                </td>
                <td
                  className="text-light text-nowrap font-s-n font-w-bolder"
                  style={{ width: "15%" }}
                >
                  {item.categoryTypes &&
                  Array.isArray(item.categoryTypes) &&
                  item.categoryTypes.length
                    ? item.categoryTypes.map((i) => i.categoryName).toString()
                    : item.categoryTypes}
                </td>
                <td
                  className="text-light text-nowrap font-s-n font-w-bolder"
                  style={{ width: "15%" }}
                >
                  {moment(item.newsTimeAt, "yyyy-MM-DD hh:mm:ss").format(
                    "hh:mm, DD MMM yy"
                  )}
                </td>
                <td
                  className="font-s-n font-w-bolder"
                  style={{
                    color: item.sentiment
                      ? markingColors[item.sentiment]
                      : "white",
                  }}
                >
                  {item.highlightedContent}
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <></>
      )}
    </table>
  ) : (
    <></>
  );
};

export default TagWiseTable;
