import React from "react";
import { Dropdown, Form } from "react-bootstrap";

import { useUser } from "./../../dataService/contexts";

import "./style.scss";

import FilterIcon from "./../../assets/filter.png";
import LoadMoreIcon from "../../assets/loadMore";

const CheckboxMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      reload,
      loadingState,
      removeAllFilters,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={`${className} CheckboxMenu`}
        aria-labelledby={labeledBy}
      >
        <div className="news-source-filter__content">
          <ul
            className="list-unstyled flex-shrink mb-4"
            style={{ overflow: "auto" }}
          >
            {children}
          </ul>
          <div className="">
            <button
              type="button"
              className="btn btn-sm rounded-3 px-4 py-2 mb-2 btn-primary"
              onClick={() => reload("FILTER")}
              disabled={loadingState ? "true" : ""}
            >
              <div className="text-nowrap py-1 font-w-bold">
                {loadingState ? <LoadMoreIcon/> : "Update Result"}
              </div>
            </button>
          </div>

          <div
            className="my-3"
            style={{
              minHeight: "1px",
              width: "100%",
              background: "rgba(228, 228, 228, 0.1)",
            }}
          ></div>

          <div className="pb-0">
            <button
              type="button"
              className="btn btn-sm rounded-3 p-0 text-light"
              onClick={() => removeAllFilters()}
            >
              <div className="text-nowrap font-w-bolder font-s-md">
                Reset all filters
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
);

const CheckDropdownItem = React.forwardRef(
  ({ children, id, checked, onChange }, ref) => {
    return (
      <Form.Group
        ref={ref}
        className="text-white dropdown-item mb-0 p-0 mb-4 news-source-filter__menu-item-inner"
        controlId={id}
      >
        <Form.Check
          type="checkbox"
          label={children}
          checked={checked}
          onChange={onChange && onChange.bind(onChange, id)}
        />
      </Form.Group>
    );
  }
);

const NewsSourceFilter = ({
  items,
  toggleFilter,
  types,
  toggleFilterType,
  reload,
  loadingState,
  removeAllFilters,
}) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const newsChannelHasPermission = (filterItem) =>
    user &&
    user.availableNewsPapers.length > 0 &&
    user.availableNewsPapers.filter((i) => i.code === filterItem.code).length >
      0;

  const tvChannelHasPermission = (filterItem) =>
    user &&
    user.availableTvChannels.length > 0 &&
    user.availableTvChannels.filter((i) => i.code === filterItem.code).length >
      0;

  const handlePermission = (filterItem) => {
    if (filterItem.type === 1) return newsChannelHasPermission(filterItem);
    else if (filterItem.type === 2) return tvChannelHasPermission(filterItem);
    else return false;
  };

  return (
    <Dropdown className="news-source-filter">
      <Dropdown.Toggle
        id="dropdown-basic"
        className="news-source-filter__button--toggle"
      >
        <div className="d-flex gap-2 align-items-center news-source-filter__button--toggle-inner">
          <img
            src={FilterIcon}
            alt="filter"
            style={{
              height: "1rem",
              width: "1rem",
            }}
          />
          <div className="text-white font-s-md font-w-bold">Filter</div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        removeAllFilters={removeAllFilters}
        reload={reload}
        loadingState={loadingState}
        as={CheckboxMenu}
        className="news-source-filter__menu text-white"
      >
        {types && (
          <div>
            <div className="text-white font-w-bold font-s-md mb-4">Type</div>
            {types.map(
              (i) =>
                i.id !== "0" && (
                  <Dropdown.Item
                    key={i.id}
                    as={CheckDropdownItem}
                    id={i.id}
                    checked={i.checked}
                    onChange={toggleFilterType}
                    className="news-source-filter__menu-item text-white"
                  >
                    {i.label}
                  </Dropdown.Item>
                )
            )}
          </div>
        )}

        <div>
          <div className="text-white font-w-bold font-s-md mb-4">Names</div>
          {items.map((i) => {
            return (
              <Dropdown.Item
                key={i.code}
                as={CheckDropdownItem}
                id={i.code}
                ariaLabel={i.type}
                checked={i.checked}
                onChange={toggleFilter}
                className="news-source-filter__menu-item"
              >
                {i.name}
              </Dropdown.Item>
            );
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NewsSourceFilter;
