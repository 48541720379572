import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import {
  setPageName,
  useNewsSources,
  usePageName,
  useUser,
} from "../../dataService/contexts";

import { getLiveNewsStream } from "../Dashboard/components/LiveNewsStream/liveNewsStream.service";

import { getBySourceCode, getBySourceType } from "../../const/newsSources";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source, newsSources).icon}
        style={{
          height: "3.5rem",
          width: "3.5rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};
const StreamList = ({ data, activeChannel, action }) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const permittedChannelList = (channels) => {
    const channelCodes =
      !!user && !!user.availableTvChannels && user.availableTvChannels?.length
        ? user.availableTvChannels.map((tc) => tc.code)
        : [];

    if (channelCodes.length) {
      const permittedChannels = channels.filter((c) =>
        channelCodes.includes(`${c.code}`)
      );

      return permittedChannels;
    } else return getBySourceType(2, newsSources);
  };

  const channelList = permittedChannelList(data);

  return channelList.length > 0 ? (
    <div
      className=""
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill,  minmax(200px, 1fr))",
        gridAutoRows: "auto",
        gridGap: ".5rem",
      }}
    >
      {channelList.map((channel) => (
        <div
          key={channel.id}
          className="p-2 flex-grow-1"
          style={
            channel.code === activeChannel
              ? {
                  background: "rgba(53, 93, 255, 0.2)",
                  borderRadius: ".75rem",
                  border: "1px solid rgba(255, 255, 255, 0.5)",
                  cursor: "pointer",
                }
              : {
                  background: "#242731",
                  borderRadius: ".75rem",
                  cursor: "pointer",
                }
          }
          onClick={() => action(channel.code, channel.url)}
        >
          <div>
            <div className="d-flex flex-row gap-3 py-2">
              <NewsSourceIcon source={channel.code} newsSources={newsSources} />
              <div className="text-white font-s-md font-w-bold d-flex align-self-center ">
                {channel.name}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};
const Index = () => {
  const { state: userState } = useUser();
  const { user } = userState || {};
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const { dispatch: pageNameDispatch } = usePageName();

  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss A"));

  const [activeChannel, setActiveChannel] = useState("");
  const [activeChannelLink, setActiveChannelLink] = useState("");

  const handleActiveChannel = (channel, link) => {
    setActiveChannel(channel);
    setActiveChannelLink(link);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss A DD-MM-YYYY"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (
      newsSources &&
      newsSources.length &&
      user &&
      user.availableTvChannels.length
    ) {
      const channel = newsSources.filter(
        (item) => item.code === user.availableTvChannels[0].code
      );
      handleActiveChannel(channel[0].code, channel[0].url);
    } else if (newsSources && newsSources.length) {
      handleActiveChannel(
        getBySourceType(2, newsSources)[0].code,
        getBySourceType(2, newsSources)[0].url
      );
    }
  }, [newsSources, user]);

  useEffect(() => {
    setPageName(pageNameDispatch, "Live Video Stream");
  }, []);

  return (
    <>
      <div className="d-flex flex-column p-1 gap-4">
        <div className="d-flex flex-column bg-black__light rounded-4 p-4 shadow">
          <div className="rounded-4 overflow-hidden bg-dark">
            <ReactPlayer
              url={activeChannelLink}
              playing={true}
              controls={true}
              loop={true}
              muted={true}
              playsinline={true}
              height="30rem"
              width="100%"
              autoplay={true}
            />
          </div>

          {newsSources.map((channel) => (
            <div>
              {channel.code === activeChannel && (
                <div className="mt-3 d-flex flex-row gap-3">
                  <NewsSourceIcon
                    source={channel.code}
                    newsSources={newsSources}
                  />
                  <div className="d-flex flex-column d-flex align-self-center">
                    {channel.name && (
                      <div className="text-white font-s-md font-w-bold ">
                        {channel.name}
                      </div>
                    )}
                    <div className="text-light">{currentTime}</div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="text-white font-s-lg font-w-bolder">
          Live TV Channel List
        </div>
        <StreamList
          data={newsSources}
          action={handleActiveChannel}
          activeChannel={activeChannel}
        />
      </div>
    </>
  );
};

export default Index;
