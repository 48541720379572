import React from "react";

const ScrollBackIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0987 10L11.0001 11.3521L11.9015 10L11.0001 8.64792L10.0987 10Z"
        fill="#D6D6D6"
      />
      <path
        d="M10.3992 7.74654L9.49778 9.09861L9.15725 8.58782C8.18356 7.12728 6.54434 6.25 4.78899 6.25H2.81072L4.53039 7.96967C4.82328 8.26256 4.82328 8.73744 4.53039 9.03033C4.2375 9.32322 3.76262 9.32322 3.46973 9.03033L0.46973 6.03033C0.176838 5.73744 0.176838 5.26256 0.46973 4.96967L3.46973 1.96967C3.76262 1.67678 4.2375 1.67678 4.53039 1.96967C4.82328 2.26256 4.82328 2.73744 4.53039 3.03033L2.81072 4.75H4.78899C7.04217 4.75 9.14652 5.87424 10.3992 7.74654Z"
        fill="#D6D6D6"
      />
      <path
        d="M12.5024 10.9014L11.601 12.2535C12.8537 14.1258 14.958 15.25 17.2112 15.25H19.0001C19.4143 15.25 19.7501 14.9142 19.7501 14.5C19.7501 14.0858 19.4143 13.75 19.0001 13.75H17.2112C15.4558 13.75 13.8166 12.8727 12.8429 11.4122L12.5024 10.9014Z"
        fill="#D6D6D6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.53039 18.0303C4.2375 18.3232 3.76262 18.3232 3.46973 18.0303L0.46973 15.0303C0.176838 14.7374 0.176838 14.2626 0.46973 13.9697L3.46973 10.9697C3.76262 10.6768 4.2375 10.6768 4.53039 10.9697C4.82328 11.2626 4.82328 11.7374 4.53039 12.0303L2.81072 13.75H4.78899C6.54434 13.75 8.18356 12.8727 9.15725 11.4122L11.5949 7.75577C12.8467 5.87793 14.9543 4.75 17.2112 4.75H19.0001C19.4143 4.75 19.7501 5.08579 19.7501 5.5C19.7501 5.91421 19.4143 6.25 19.0001 6.25H17.2112C15.4558 6.25 13.8166 7.12728 12.8429 8.58782L10.4053 12.2442C9.15343 14.1221 7.04587 15.25 4.78899 15.25H2.81072L4.53039 16.9697C4.82328 17.2626 4.82328 17.7374 4.53039 18.0303Z"
        fill="#D6D6D6"
      />
    </svg>
  );
};

export default ScrollBackIcon;
