import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React from "react";

import { getDateTime_12T2D3M2Y, getTime_12T } from "../../const/dateFormat";
import { getBySourceCode } from "../../const/newsSources";

import { Notification } from "../../assets/Navbar";

import { useNewsSources } from "../../dataService/contexts";
import "./styles.scss";

const NotificationDropdown = ({
  unseen,
  currentNotifications,
  handleVideoPopup,
  handleLoadMoreNotifications,
  loadMoreNotifications,
}) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild className="IconButton">
          <button className={`iconButton  btn`} aria-label="Customise options">
            <div className="d-flex flex-column ">
              {unseen !== 0 && (
                <div className="w-100 d-flex justify-content-end">
                  <div
                    className="bg-danger font-s-sm font-w-bold text-white d-flex justify-content-center "
                    style={{
                      height: "1.2rem",
                      width: "1.2rem",
                      borderRadius: "100%",
                      position: "absolute",
                    }}
                  >
                    {unseen}
                  </div>
                </div>
              )}

              <Notification />
            </div>
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="DropdownMenuContent text-white"
            sideOffset={0}
          >
            <div className="d-flex flex-column p-4">
              <div>
                <DropdownMenu.Item
                  className="DropdownMenuItem text-white font-w-bold"
                  style={{ fontSize: "1.2rem" }}
                >
                  Recent Notifications
                </DropdownMenu.Item>
              </div>
              <div className="d-flex flex-column">
                {currentNotifications.length > 0 &&
                  currentNotifications.map((notification) => {
                    const channel = getBySourceCode(
                      notification.data.tvChannel,
                      newsSources
                    );
                    return (
                      <DropdownMenu.Item
                        className={`DropdownMenuItem text-white py-4`}
                        style={{
                          background: `${notification.seen ? "" : "#373737"}`,
                        }}
                      >
                        <div className={`d-flex flex-row gap-4 `}>
                          <div>
                            <img
                              alt="newspaper-logo"
                              src={channel.icon}
                              style={{
                                height: "3rem",
                                width: "3rem",
                              }}
                            />
                          </div>

                          <div className="d-flex flex-column justify-content-center gap-1">
                            <span className=" font-s-n font-w-bolder">
                              {channel.name}
                            </span>
                            <div>{`${getTime_12T(
                              channel.searchStart
                            )} - ${getDateTime_12T2D3M2Y(
                              channel.searchEnd
                            )}`}</div>
                            <div className=" text-silver d-flex flex-row gap-3 font-s-sm">
                              Video Retrived
                              <button
                                className="text-primary"
                                onClick={() => handleVideoPopup(notification)}
                              >
                                View Clip
                              </button>
                            </div>
                          </div>
                        </div>
                      </DropdownMenu.Item>
                    );
                  })}
              </div>
              <button
                className="mt-4 btn btn-sm bg-primary font-w-bolder text-white"
                onClick={() => handleLoadMoreNotifications()}
                disabled={loadMoreNotifications ? "true" : ""}
              >
                Load More
              </button>
            </div>
            <DropdownMenu.Arrow className="DropdownMenuArrow text-white" />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default NotificationDropdown;
