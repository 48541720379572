import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  DateTime,
  LoadingComponent,
  News,
  SourcesDropdown,
} from "./../../components";
import jsonpath from "jsonpath";
import {
  managerReport,
  updateManagerReport,
  removeFromReport,
} from "./managerReport.service";

import { ROUTE } from "../../const";
import { MarkedLine } from "./components";
import { setPageName, usePageName } from "../../dataService/contexts";
import { CategoryOptions, Sentiment } from "../NewspaperReport/components";
import NewspaperSourceDropdown from "../NewspaperReport/components/NewspaperSourceDropdown";
import CategoryDropdown from "../../components/Layouts/components/Navbar/components/SearchVideoCrop/CategoryDropdown";
import { SourceTypeDropdown } from "../index";
import { DateCalender } from "../../components";

const ManagerReport = () => {
  const currentDateAndTime = moment();
  const { dispatch: pageNameDispatch } = usePageName();

  const [negativeReport, setNegativeReport] = useState([]);
  const [positiveReport, setPositiveReport] = useState([]);
  const [desableDownload, setDesableDownload] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const initialParams = {
    date: moment(currentDateAndTime)
      .startOf("day")
      .utc("asia/dhaka")
      .format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(currentDateAndTime)
      .utc("asia/dhaka")
      .format("YYYY-MM-DD HH:mm:ss"),
    contentType: null,
    categoryType: null,
    sentiment: null,
    sourceName: null,
  };

  //params
  const [params, setParams] = useState(initialParams);

  const handleCategory = (key, value) => {
    setParams((prev) => ({
      ...prev,
      ["categoryType"]: value.categoryName,
    }));
  };

  const handleSourceName = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleParams = (key, value) => {
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleSelect = (id, sourceName, newsTimeAt, marked) => {
    setLoadingId(id);
    if (!marked) {
      updateManagerReport(
        {
          reportId: id,
          sourceName,
          newsTimeAt,
          marked: marked,
        },
        (status, data) => {
          if (status === "success") {
            fetchManagerReport(data);
          }
          setLoadingId(false);
        }
      );
    } else {
      setLoadingId(id);
      removeFromReport(id, (status, data) => {
        if (status === "success") {
          fetchManagerReport(data);
        }
        setLoadingId(null);
      });
    }
  };

  const fetchManagerReport = () => {
    setLoading(true);
    managerReport(params, (status, data) => {
      if (status === "success") {
        if (data) {
          if (data.negative) {
            setNegativeReport(data.negative.reports);
          }
          if (data.positive) {
            setPositiveReport(data.positive.reports);
          }
        }
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    setDesableDownload((prev) => [
      ...jsonpath.query(positiveReport, "$..marked"),
      ...jsonpath.query(negativeReport, "$..marked"),
    ]);
  }, [positiveReport, negativeReport]);
  useEffect(() => {
    setPageName(pageNameDispatch, "Manager Report");
  }, []);

  useEffect(() => {
    fetchManagerReport();
  }, [params]);

  return (
    <div className="d-flex flex-column  p-1 gap-2">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between gap-3 flex-wrap flex-row">
          <div>
            <DateTime
              placeholder={"From"}
              date={moment(params.date).format("YYYY-MM-DD, hh:mm A")}
              handleDateWithTime={(e) => {
                handleParams("date", e);
              }}
              hasTime={true}
            />
            <DateTime
              placeholder={"To"}
              date={moment(params.endDate).format("YYYY-MM-DD, hh:mm A")}
              handleDateWithTime={(e) => {
                handleParams("endDate", e);
              }}
              hasTime={true}
            />
          </div>
          <div>
            {desableDownload.includes(true) ? (
              <Link
                to={`/${ROUTE.MANAGERREPORT_PREVIEW}?date=${moment(
                  startDate
                ).format("L")}`}
                className="btn btn-md btn-primary px-5 py-3 font-w-bold font-s-lg rounded-4"
              >
                Preview
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="d-flex flex-row gap-3 flex-wrap">
          <CategoryOptions
            handleCategory={handleCategory}
            seletedCategory={params.categoryType}
          />
          <div style={{ minWidth: "100px" }}>
            <SourcesDropdown
              currentChannel={params.sourceName}
              handleChannel={handleSourceName}
            />
          </div>
          <div style={{ minWidth: "100px" }}>
            <Sentiment
              currentSentiment={params.sentiment}
              handleSentiment={handleParams}
            />
          </div>
          <SourceTypeDropdown
            currentSource={params.contentType}
            handleSource={handleParams}
          />
        </div>
      </div>

      {negativeReport.length ? (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          {negativeReport.map((item) => (
            <News
              news={item.newsContent || {}}
              keywords={
                item.newsContent && item.newsContent.matchedKeywordList
                  ? item.newsContent.matchedKeywordList.split(",")
                  : []
              }
              type=""
              tags=""
              hasViewButton={true}
              bookmarkAble={false}
            >
              {item.highlights && item.highlights.length ? (
                item.highlights.map((highlight) => (
                  <MarkedLine
                    highlight={highlight}
                    news={item}
                    loading={loadingId}
                    handleSelect={handleSelect}
                  />
                ))
              ) : (
                <></>
              )}
            </News>
          ))}
        </div>
      ) : (
        <></>
      )}

      {positiveReport.length ? (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          {positiveReport.map((item) => (
            <News
              news={item.newsContent || {}}
              keywords={
                item.newsContent && item.newsContent.matchedKeywordList
                  ? item.newsContent.matchedKeywordList.split(",")
                  : []
              }
              type=""
              tags=""
              hasViewButton={true}
              bookmarkAble={false}
            >
              {item.highlights && item.highlights.length ? (
                item.highlights.map((highlight) => (
                  <MarkedLine
                    highlight={highlight}
                    news={item}
                    loading={loadingId}
                    handleSelect={handleSelect}
                  />
                ))
              ) : (
                <></>
              )}
            </News>
          ))}
        </div>
      ) : (
        <></>
      )}

      {loading && (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          <LoadingComponent size={1} />
        </div>
      )}
    </div>
  );
};

export default ManagerReport;
