import React, { useState, useEffect } from "react";
import moment from "moment";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import { setPageName, usePageName } from "../../dataService/contexts";
import {
  getTotalCount,
  getNewspaperSourceWise,
  getNewspaperCategoryWise,
  getTVScrollSourceWise,
  getTVScrollCategoryWise,
  getTVScrollCategoryWiseNoSort,
  getNewspaperCategoryWiseNoSort,
  getNewspaperSourceWiseNoSort,
  getTVScrollSourceWiseNoSort,
  getCategoryWiseNoSort,
  getCategoryAggregatedReport,
} from "./analyticalDashboard.service";

import {
  DateRange,
  Stat,
  Pie,
  Line,
  Card,
  LeftArrow,
  RightArrow,
  Bar,
} from "./components";
import StackedBar from "./components/StackedBar";
import SingleBarChart from "./components/SingleBarChart";
import { getSourceReport } from "../ReportOverview/reportOverview.service";
import AggregatedTable from "./components/AggregatedTable";
import aggregatedTable from "./components/AggregatedTable";
import { parseInt } from "lodash";
import ScrollIIcon from "../../assets/scrollIIcon";
import ScrollBackIcon from "../../assets/scrollBackIcon";

const UniqueReportDashboard = () => {
  const { dispatch: pageNameDispatch } = usePageName();

  const currentDateAndTime = moment();
  const initialParams = {
    startDate: moment(currentDateAndTime)
      .subtract(30, "d")
      .utc("asia/dhaka")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(currentDateAndTime)
      .utc("asia/dhaka")
      .format("YYYY-MM-DD HH:mm:ss"),
    activeStatus: 1,
    top: true,
  };

  const [top, setTop] = useState({
    categoryRanking: true,
    newspaperCategoryWiseData: true,
    tvScrollSourceWiseData: true,
    newspaperSourceWiseDataPositive: true,
    newspaperSourceWiseDataNegative: true,
    categoryWiseDataPositive: true,
    categoryWiseDataNegative: true,
    tvScrollCategoryWiseDataPositive: true,
    tvScrollCategoryWiseDataNegative: true,
  });
  const [loading, setLoading] = useState({
    categoryRanking: false,
    newspaperCategoryWiseData: false,
    tvScrollSourceWiseData: false,
    newspaperSourceWiseDataPositive: false,
    newspaperSourceWiseDataNegative: false,
    categoryWiseDataPositive: false,
    categoryWiseDataNegative: false,
    tvScrollCategoryWiseDataPositive: false,
    tvScrollCategoryWiseDataNegative: false,
  });

  const [newspaperCategoryWiseData, setNewspaperCategoryWiseData] = useState(
    []
  );
  const [categoryRanking, setCategoryRanking] = useState([]);
  const [TVScrollCategoryWiseData, setTVScrollCategoryWiseData] = useState([]);

  const [
    newspaperCategoryWiseDataPositive,
    setNewspaperCategoryWiseDataPositive,
  ] = useState([]);
  const [
    newspaperCategoryWiseDataNegative,
    setNewspaperCategoryWiseDataNegative,
  ] = useState([]);

  const [CategoryWiseDataPositive, setCategoryWiseDataPositive] = useState([]);
  const [CategoryWiseDataNegative, setCategoryWiseDataNegative] = useState([]);

  const [
    tvScrollCategoryWiseDataPositive,
    setTVScrollCategoryWiseDataPositive,
  ] = useState([]);
  const [
    tvScrollCategoryWiseDataNegative,
    setTVScrollCategoryWiseDataNegative,
  ] = useState([]);

  const [newspaperAggregated, setNewspaperAggregated] = useState({});
  const [tvAggregated, setTVAggregated] = useState({});

  const elemPrefix = "test";
  const getId = (index) => `${elemPrefix}${index}`;

  const [params, setParams] = useState(initialParams);
  const [totalData, setTotalData] = useState(null);
  const [dailyCount, setDailyCount] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [items] = React.useState(
    Array(20)
      .fill(0)
      .map((_, ind) => ({ id: getId(ind) }))
  );

  const handlePieData = () => {
    const _labels = Object.keys(totalData);
    const _series = _labels.map((item) => totalData[`${item}`]);

    setPieData({ labels: _labels, series: _series });
  };

  const handleLineData = () => {
    const _keys = Object.keys(dailyCount);

    const series = _keys.map((item) => ({
      name: item.toLocaleUpperCase(),
      data: dailyCount[item],
    }));

    setLineData(series);
  };

  const handleActiveStatus = (value) => {
    setParams((prev) => {
      return {
        ...prev,
        activeStatus: value,
      };
    });
  };
  const handlecategoryRanking = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        categoryRanking: top,
      };
    });
  };
  const handleNewspaperCategoryWiseData = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        newspaperCategoryWiseData: top,
      };
    });
  };

  const handleTvScrollSourceWiseData = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        tvScrollSourceWiseData: top,
      };
    });
  };
  const handleNewspaperSourceWiseDataPositive = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        newspaperSourceWiseDataPositive: top,
      };
    });
  };
  const handleNewspaperSourceWiseDataNegative = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        newspaperSourceWiseDataNegative: top,
      };
    });
  };
  const handleCategoryWiseDataPositive = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        categoryWiseDataPositive: top,
      };
    });
  };
  const handleCategoryWiseDataNegative = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        categoryWiseDataNegative: top,
      };
    });
  };

  const handleTvScrollCategoryWiseDataPositive = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        tvScrollCategoryWiseDataPositive: top,
      };
    });
  };
  const handleTvScrollCategoryWiseDataNegative = (top) => {
    setTop((prev) => {
      return {
        ...prev,
        tvScrollCategoryWiseDataNegative: top,
      };
    });
  };
  const handleDateRange = (dateRange) => {
    setParams((prev) => {
      return {
        ...prev,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };
    });
  };

  // useEffect(() => {
  //   setLoading((prev) => {
  //     return {
  //       ...prev,
  //       categoryRanking: true,
  //     };
  //   });
  //
  //   getCategoryWiseNoSort(params, top.categoryRanking, (status, data) => {
  //     if (status === "success") {
  //       const mainData = data.filter(
  //         (item) =>
  //           item.count.dailyCount.negative.some((value) => value !== 0) ||
  //           item.count.dailyCount.positive.some((value) => value !== 0)
  //       );
  //       const categoryAggregated = mainData.map(
  //         (item) => item.count.categoryAggregated
  //       );
  //
  //       const categoryName = categoryAggregated.map(
  //         (item) => item.categoryName
  //       );
  //       const negative = categoryAggregated.map((item) =>
  //         parseInt((item.negative / (item.positive + item.negative)) * 100)
  //       );
  //       const positive = categoryAggregated.map((item) =>
  //         Math.ceil((item.positive / (item.positive + item.negative)) * 100)
  //       );
  //
  //       setCategoryRanking({
  //         series: [
  //           {
  //             name: "Positive",
  //             data: positive,
  //           },
  //           {
  //             name: "Negative",
  //             data: negative,
  //           },
  //         ],
  //         categories: categoryName,
  //       });
  //     }
  //     setLoading((prev) => {
  //       return {
  //         ...prev,
  //         categoryRanking: false,
  //       };
  //     });
  //   });
  // }, [params, top.categoryRanking]);
  //
  useEffect(() => {
    setLoading((prev) => {
      return {
        ...prev,
        newspaperCategoryWiseData: true,
      };
    });
    getNewspaperSourceWiseNoSort(
      params,
      top.newspaperCategoryWiseData,
      (status, data) => {
        if (status === "success") {
          const mainData = data.filter(
            (item) =>
              item.count.dailyCount.negative.some((value) => value !== 0) ||
              item.count.dailyCount.positive.some((value) => value !== 0)
          );

          const aggregated = mainData.map((item) => item.count.aggregated);

          const categoryName = aggregated.map((item) => item.sourceName);
          const negative = aggregated.map((item) =>
            parseInt((item.negative / (item.positive + item.negative)) * 100)
          );

          const positive = aggregated.map((item) =>
            Math.ceil((item.positive / (item.positive + item.negative)) * 100)
          );

          setNewspaperCategoryWiseData({
            series: [
              {
                name: "Positive",
                data: positive !== 0 && negative !== 0 && positive,
              },
              {
                name: "Negative",
                data: positive !== 0 && negative !== 0 && negative,
              },
            ],
            categories: positive !== 0 && negative !== 0 && categoryName,
          });
        }
        setLoading((prev) => {
          return {
            ...prev,
            newspaperCategoryWiseData: false,
          };
        });
      }
    );
  }, [params, top.newspaperCategoryWiseData]);
  //
  // /*===================================================================================================*/
  //
  // useEffect(() => {
  //   setLoading((prev) => {
  //     return {
  //       ...prev,
  //       tvScrollSourceWiseData: true,
  //     };
  //   });
  //   getTVScrollSourceWiseNoSort(
  //     params,
  //     top.tvScrollSourceWiseData,
  //     (status, data) => {
  //       if (status === "success") {
  //         const mainData = data.filter(
  //           (item) =>
  //             item.count.dailyCount.negative.some((value) => value !== 0) ||
  //             item.count.dailyCount.positive.some((value) => value !== 0)
  //         );
  //         const aggregated = mainData.map((item) => item.count.aggregated);
  //
  //         const sourceName = aggregated.map((item) => item.sourceName);
  //         const negative = aggregated.map((item) =>
  //           parseInt((item.negative / (item.positive + item.negative)) * 100)
  //         );
  //         const positive = aggregated.map((item) =>
  //           Math.ceil((item.positive / (item.positive + item.negative)) * 100)
  //         );
  //
  //         setTVScrollCategoryWiseData({
  //           series: [
  //             {
  //               name: "Positive",
  //               data: positive,
  //             },
  //             {
  //               name: "Negative",
  //               data: negative,
  //             },
  //           ],
  //           categories: sourceName,
  //         });
  //       }
  //       setLoading((prev) => {
  //         return {
  //           ...prev,
  //           tvScrollSourceWiseData: false,
  //         };
  //       });
  //     }
  //   );
  // }, [params, top.tvScrollSourceWiseData]);
  // useEffect(() => {
  //   getTotalCount(params, (status, data) => {
  //     if (status === "success") {
  //       setTotalData({
  //         negative: data?.aggregated?.negative || 0,
  //         positive: data?.aggregated?.positive || 0,
  //       });
  //
  //       setDailyCount({
  //         negative: data?.dailyCount?.negative || [],
  //         positive: data?.dailyCount?.positive || [],
  //       });
  //     }
  //   });
  // }, [params]);
  //
  // useEffect(() => {
  //   if (totalData) handlePieData();
  // }, [totalData]);
  //
  // useEffect(() => {
  //   if (dailyCount) handleLineData();
  // }, [dailyCount]);

  /*==================================================================================================*/

  /*===================================================================================================*/

  useEffect(() => {
    setLoading((prev) => {
      return {
        ...prev,
        newspaperSourceWiseDataPositive: true,
      };
    });
    getNewspaperSourceWise(
      params,
      top.newspaperSourceWiseDataPositive,
      1,
      (status, data) => {
        if (status === "success") {
          console.log(data, "tony");
          const mainData = data.filter(
            (item) =>
              item.count.dailyCount.negative.some((value) => value !== 0) ||
              item.count.dailyCount.positive.some((value) => value !== 0)
          );
          const aggregated = mainData.map((item) => item.count.aggregated);

          const sourceName = aggregated.map((item) => item.sourceName);
          const negative = aggregated.map((item) => item.negative);
          const positive = aggregated.map((item) => item.positive);

          setNewspaperCategoryWiseDataPositive({
            series: [
              {
                name: "Positive",
                data: positive,
              },
            ],
            categories: sourceName,
          });
        }
        setLoading((prev) => {
          return {
            ...prev,
            newspaperSourceWiseDataPositive: false,
          };
        });
      }
    );
  }, [params, top.newspaperSourceWiseDataPositive]);
  useEffect(() => {
    setLoading((prev) => {
      return {
        ...prev,
        newspaperSourceWiseDataNegative: true,
      };
    });
    getNewspaperSourceWise(
      params,
      top.newspaperSourceWiseDataNegative,
      2,
      (status, data) => {
        if (status === "success") {
          const mainData = data.filter(
            (item) =>
              item.count.dailyCount.negative.some((value) => value !== 0) ||
              item.count.dailyCount.positive.some((value) => value !== 0)
          );
          const aggregated = mainData.map((item) => item.count.aggregated);

          const sourceName = aggregated.map((item) => item.sourceName);
          const negative = aggregated.map((item) => item.negative);
          const positive = aggregated.map((item) => item.positive);

          setNewspaperCategoryWiseDataNegative({
            series: [
              {
                name: "Negative",
                data: negative,
              },
            ],
            categories: sourceName,
          });
        }
        setLoading((prev) => {
          return {
            ...prev,
            newspaperSourceWiseDataNegative: false,
          };
        });
      }
    );
  }, [params, top.newspaperSourceWiseDataNegative]);

  // useEffect(() => {
  //   setLoading((prev) => {
  //     return {
  //       ...prev,
  //       categoryWiseDataPositive: true,
  //     };
  //   });
  //   getNewspaperCategoryWise(
  //     params,
  //     top.newspaperCategoryWiseData,
  //     1,
  //     (status, data) => {
  //       if (status === "success") {
  //         const mainData = data.filter(
  //           (item) =>
  //             item.count.dailyCount.negative.some((value) => value !== 0) ||
  //             item.count.dailyCount.positive.some((value) => value !== 0)
  //         );
  //         const categoryAggregated = mainData.map(
  //           (item) => item.count.categoryAggregated
  //         );
  //
  //         const categoryName = categoryAggregated.map(
  //           (item) => item.categoryName
  //         );
  //         const negative = categoryAggregated.map((item) => item.negative);
  //         const positive = categoryAggregated.map((item) => item.positive);
  //
  //         setCategoryWiseDataPositive({
  //           series: [
  //             {
  //               name: "Positive",
  //               data: positive,
  //             },
  //           ],
  //           categories: categoryName,
  //         });
  //       }
  //       setLoading((prev) => {
  //         return {
  //           ...prev,
  //           categoryWiseDataPositive: false,
  //         };
  //       });
  //     }
  //   );
  // }, [params, top.categoryWiseDataPositive]);
  // useEffect(() => {
  //   setLoading((prev) => {
  //     return {
  //       ...prev,
  //       categoryWiseDataNegative: true,
  //     };
  //   });
  //   getNewspaperCategoryWise(
  //     params,
  //     top.categoryWiseDataNegative,
  //     2,
  //     (status, data) => {
  //       if (status === "success") {
  //         const mainData = data.filter(
  //           (item) =>
  //             item.count.dailyCount.negative.some((value) => value !== 0) ||
  //             item.count.dailyCount.positive.some((value) => value !== 0)
  //         );
  //         const categoryAggregated = mainData.map(
  //           (item) => item.count.categoryAggregated
  //         );
  //
  //         const categoryName = categoryAggregated.map(
  //           (item) => item.categoryName
  //         );
  //         const negative = categoryAggregated.map((item) => item.negative);
  //         const positive = categoryAggregated.map((item) => item.positive);
  //
  //         setCategoryWiseDataNegative({
  //           series: [
  //             {
  //               name: "Negative",
  //               data: negative,
  //             },
  //           ],
  //           categories: categoryName,
  //         });
  //       }
  //       setLoading((prev) => {
  //         return {
  //           ...prev,
  //           categoryWiseDataNegative: false,
  //         };
  //       });
  //     }
  //   );
  // }, [params, top.categoryWiseDataNegative]);
  // /*==================================================================================================*/
  //
  // useEffect(() => {
  //   setLoading((prev) => {
  //     return {
  //       ...prev,
  //       tvScrollCategoryWiseDataPositive: true,
  //     };
  //   });
  //   getTVScrollSourceWise(
  //     params,
  //     top.tvScrollCategoryWiseDataPositive,
  //     1,
  //     (status, data) => {
  //       if (status === "success") {
  //         const mainData = data.filter(
  //           (item) =>
  //             item.count.dailyCount.negative.some((value) => value !== 0) ||
  //             item.count.dailyCount.positive.some((value) => value !== 0)
  //         );
  //         const aggregated = mainData.map((item) => item.count.aggregated);
  //
  //         const sourceName = aggregated.map((item) => item.sourceName);
  //         const negative = aggregated.map((item) => item.negative);
  //         const positive = aggregated.map((item) => item.positive);
  //
  //         setTVScrollCategoryWiseDataPositive({
  //           series: [
  //             {
  //               name: "Positive",
  //               data: positive,
  //             },
  //           ],
  //           categories: sourceName,
  //         });
  //       }
  //       setLoading((prev) => {
  //         return {
  //           ...prev,
  //           tvScrollCategoryWiseDataPositive: false,
  //         };
  //       });
  //     }
  //   );
  // }, [params, top.tvScrollCategoryWiseDataPositive]);
  // useEffect(() => {
  //   setLoading((prev) => {
  //     return {
  //       ...prev,
  //       tvScrollCategoryWiseDataNegative: true,
  //     };
  //   });
  //   getTVScrollSourceWise(
  //     params,
  //     top.tvScrollCategoryWiseDataNegative,
  //     2,
  //     (status, data) => {
  //       if (status === "success") {
  //         const mainData = data.filter(
  //           (item) =>
  //             item.count.dailyCount.negative.some((value) => value !== 0) ||
  //             item.count.dailyCount.positive.some((value) => value !== 0)
  //         );
  //         const aggregated = mainData.map((item) => item.count.aggregated);
  //
  //         const sourceName = aggregated.map((item) => item.sourceName);
  //         const negative = aggregated.map((item) => item.negative);
  //         const positive = aggregated.map((item) => item.positive);
  //
  //         setTVScrollCategoryWiseDataNegative({
  //           series: [
  //             {
  //               name: "Negative",
  //               data: negative,
  //             },
  //           ],
  //           categories: sourceName,
  //         });
  //       }
  //       setLoading((prev) => {
  //         return {
  //           ...prev,
  //           tvScrollCategoryWiseDataNegative: false,
  //         };
  //       });
  //     }
  //   );
  // }, [params, top.tvScrollCategoryWiseDataNegative]);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //
  // useEffect(() => {
  //   getCategoryAggregatedReport(params, 1, (status, data) => {
  //     if (status === "success") {
  //       setNewspaperAggregated(data);
  //     }
  //   });
  //   getCategoryAggregatedReport(params, 2, (status, data) => {
  //     if (status === "success") {
  //       setTVAggregated(data);
  //     }
  //   });
  // }, [params]);
  // Define breakpoints
  const isMobile = windowSize.width <= 1024;

  useEffect(() => {
    setPageName(pageNameDispatch, "Unique Marked Report");
  }, []);
  return (
    <div className="d-flex flex-column gap-4 overflow-hidden ">
      <div className="text-white">
        {loading.categoryRanking ? "hahahahaha" : ""}
      </div>
      <div>
        <DateRange
          onChange={handleActiveStatus}
          loading={loading}
          params={params}
          onDateChange={handleDateRange}
        />
      </div>
      <div className="d-flex flex-wrap gap-4">
        {totalData ? <Stat totalData={totalData} /> : <></>}
        {pieData ? <Pie pieData={pieData} /> : <></>}
      </div>
      <div className="d-flex flex-column gap-5">
        {categoryRanking ? (
          isMobile ? (
            <ScrollMenu>
              <div className="d-flex flex-column gap-4 ">
                <div className="d-flex flex-row justify-content-between">
                  <div className="text-white font-s-xlg font-w-bold">
                    Unique Marked Report
                  </div>
                  {/*<div className="d-flex flex-row gap-3">*/}
                  {/*  <div className="text-white font-s-lg font-w-bold">*/}
                  {/*    Scroll more*/}
                  {/*  </div>*/}
                  {/*  <ScrollIIcon />*/}
                  {/*</div>*/}
                </div>
                <div
                  className="overflow-auto bar-container rounded-4"
                  style={{ maxWidth: "350px" }}
                >
                  <StackedBar
                    barData={newspaperCategoryWiseData}
                    type={4}
                    params={params}
                    handleParams={handleNewspaperCategoryWiseData}
                    top={top.newspaperCategoryWiseData}
                    loading={loading.newspaperCategoryWiseData}
                  />
                </div>
              </div>
              {/*<div className="d-flex flex-column gap-4 ">*/}
              {/*  <div className="d-flex flex-row justify-content-between">*/}
              {/*    <div className="text-white font-s-xlg font-w-bold">*/}
              {/*      TV Ranking*/}
              {/*    </div>*/}
              {/*    <div className="d-flex flex-row gap-3">*/}
              {/*      <div className="text-white font-s-lg font-w-bold">*/}
              {/*        Scroll more*/}
              {/*      </div>*/}
              {/*      <ScrollIIcon />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <div*/}
              {/*    className="overflow-auto bar-container rounded-4"*/}
              {/*    style={{ maxWidth: "350px" }}*/}
              {/*  >*/}
              {/*    <StackedBar*/}
              {/*      barData={TVScrollCategoryWiseData}*/}
              {/*      type={2}*/}
              {/*      params={params}*/}
              {/*      handleParams={handleTvScrollSourceWiseData}*/}
              {/*      top={top.tvScrollSourceWiseData}*/}
              {/*      loading={loading.tvScrollSourceWiseData}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="d-flex flex-column gap-4 ">*/}
              {/*  <div className="d-flex flex-row justify-content-between">*/}
              {/*    <div className="text-white font-s-xlg font-w-bold">*/}
              {/*      Category Ranking*/}
              {/*    </div>*/}
              {/*    <div className="d-flex flex-row gap-3">*/}
              {/*      <div className="text-white font-s-lg font-w-bold">*/}
              {/*        Scroll more*/}
              {/*      </div>*/}
              {/*      <ScrollBackIcon />*/}
              {/*    </div>*/}
              {/*  </div>*/}

              {/*  <div*/}
              {/*    className="overflow-auto bar-container rounded-4"*/}
              {/*    style={{ maxWidth: "350px" }}*/}
              {/*  >*/}
              {/*    <StackedBar*/}
              {/*      barData={categoryRanking}*/}
              {/*      type={3}*/}
              {/*      params={params}*/}
              {/*      handleParams={handlecategoryRanking}*/}
              {/*      top={top.categoryRanking}*/}
              {/*      loading={loading.categoryRanking}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}
            </ScrollMenu>
          ) : (
            <div className="d-flex flex-row gap-3  w-100">
              <div className="d-flex flex-column gap-4 w-50">
                <div className="text-white font-s-xlg font-w-bold">
                  Unique Marked Report
                </div>
                <StackedBar
                  barData={newspaperCategoryWiseData}
                  type={4}
                  params={params}
                  handleParams={handleNewspaperCategoryWiseData}
                  top={top.newspaperCategoryWiseData}
                  loading={loading.newspaperCategoryWiseData}
                />
              </div>
              {/*<div className="d-flex flex-column gap-4  w-50">*/}
              {/*  <div className="text-white font-s-xlg font-w-bold">*/}
              {/*    TV Ranking*/}
              {/*  </div>*/}
              {/*  <StackedBar*/}
              {/*    barData={TVScrollCategoryWiseData}*/}
              {/*    type={2}*/}
              {/*    params={params}*/}
              {/*    handleParams={handleTvScrollSourceWiseData}*/}
              {/*    top={top.tvScrollSourceWiseData}*/}
              {/*    loading={loading.tvScrollSourceWiseData}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="d-flex flex-column gap-4  w-50">*/}
              {/*  <div className="text-white font-s-xlg font-w-bold">*/}
              {/*    Category Ranking*/}
              {/*  </div>*/}
              {/*  <StackedBar*/}
              {/*    barData={categoryRanking}*/}
              {/*    type={3}*/}
              {/*    params={params}*/}
              {/*    handleParams={handlecategoryRanking}*/}
              {/*    top={top.categoryRanking}*/}
              {/*    loading={loading.categoryRanking}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          )
        ) : (
          <></>
        )}
        {newspaperCategoryWiseDataPositive ? (
          isMobile ? (
            <ScrollMenu>
              <div className="d-flex flex-column gap-4 ">
                <div className="d-flex flex-row justify-content-between">
                  <div className="text-white font-s-xlg font-w-bold">
                    Top Positive Unique Marked Report
                  </div>
                  <div className="d-flex flex-row gap-3">
                    <div className="text-white font-s-lg font-w-bold">
                      Scroll more
                    </div>
                    <ScrollIIcon />
                  </div>
                </div>
                <div
                  className=" bar-container rounded-4"
                  style={{
                    maxWidth: "350px",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                >
                  <SingleBarChart
                    barData={newspaperCategoryWiseDataPositive}
                    type={4}
                    param={params}
                    handleParams={handleNewspaperSourceWiseDataPositive}
                    top={top.newspaperSourceWiseDataPositive}
                    loading={loading.newspaperSourceWiseDataPositive}
                  />
                </div>
              </div>
              <div className="d-flex flex-column gap-4 ">
                <div className="d-flex flex-row justify-content-between">
                  <div className="text-white font-s-xlg font-w-bold">
                    Top Negative Unique Marked Report
                  </div>
                  <div className="d-flex flex-row gap-3">
                    <div className="text-white font-s-lg font-w-bold">
                      Scroll more
                    </div>
                    <ScrollBackIcon />
                  </div>
                </div>
                <div
                  className=" bar-container rounded-4"
                  style={{
                    maxWidth: "350px",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                >
                  <SingleBarChart
                    barData={newspaperCategoryWiseDataNegative}
                    sentiment={true}
                    type={4}
                    param={params}
                    handleParams={handleNewspaperSourceWiseDataNegative}
                    top={top.newspaperSourceWiseDataNegative}
                    loading={loading.newspaperSourceWiseDataNegative}
                  />
                </div>
              </div>
            </ScrollMenu>
          ) : (
            <div className="d-flex flex-row gap-3  w-100">
              <div className="d-flex flex-column gap-4 w-50">
                <div className="text-white font-s-xlg font-w-bold">
                  Top Positive Unique Marked Report
                </div>
                <SingleBarChart
                  barData={newspaperCategoryWiseDataPositive}
                  type={4}
                  param={params}
                  handleParams={handleNewspaperSourceWiseDataPositive}
                  top={top.newspaperSourceWiseDataNegative}
                  loading={loading.newspaperSourceWiseDataPositive}
                />
              </div>
              <div className="d-flex flex-column gap-4  w-50">
                <div className="text-white font-s-xlg font-w-bold">
                  Top Negative Unique Marked Report
                </div>
                <SingleBarChart
                  barData={newspaperCategoryWiseDataNegative}
                  sentiment={true}
                  type={4}
                  param={params}
                  handleParams={handleNewspaperSourceWiseDataNegative}
                  top={top.newspaperSourceWiseDataNegative}
                  loading={loading.newspaperSourceWiseDataNegative}
                />
              </div>
            </div>
          )
        ) : (
          <></>
        )}
        {/*{tvScrollCategoryWiseDataPositive ? (*/}
        {/*  isMobile ? (*/}
        {/*    <ScrollMenu>*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="d-flex flex-row justify-content-between">*/}
        {/*          <div className="text-white font-s-xlg font-w-bold">*/}
        {/*            Top Positive TV*/}
        {/*          </div>*/}
        {/*          <div className="d-flex flex-row gap-3">*/}
        {/*            <div className="text-white font-s-lg font-w-bold">*/}
        {/*              Scroll more*/}
        {/*            </div>*/}
        {/*            <ScrollIIcon />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div*/}
        {/*          className=" bar-container rounded-4"*/}
        {/*          style={{*/}
        {/*            maxWidth: "350px",*/}
        {/*            overflowX: "auto",*/}
        {/*            overflowY: "hidden",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <SingleBarChart*/}
        {/*            barData={tvScrollCategoryWiseDataPositive}*/}
        {/*            type={2}*/}
        {/*            param={params}*/}
        {/*            handleParams={handleTvScrollCategoryWiseDataPositive}*/}
        {/*            top={top.tvScrollCategoryWiseDataPositive}*/}
        {/*            loading={loading.tvScrollCategoryWiseDataPositive}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="d-flex flex-row justify-content-between">*/}
        {/*          <div className="text-white font-s-xlg font-w-bold">*/}
        {/*            Top Negative TV*/}
        {/*          </div>*/}
        {/*          <div className="d-flex flex-row gap-3">*/}
        {/*            <div className="text-white font-s-lg font-w-bold">*/}
        {/*              Scroll more*/}
        {/*            </div>*/}
        {/*            <ScrollBackIcon />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div*/}
        {/*          className=" bar-container rounded-4"*/}
        {/*          style={{*/}
        {/*            maxWidth: "350px",*/}
        {/*            overflowX: "auto",*/}
        {/*            overflowY: "hidden",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <SingleBarChart*/}
        {/*            barData={tvScrollCategoryWiseDataNegative}*/}
        {/*            sentiment={true}*/}
        {/*            type={2}*/}
        {/*            param={params}*/}
        {/*            handleParams={handleTvScrollCategoryWiseDataNegative}*/}
        {/*            top={top.tvScrollCategoryWiseDataNegative}*/}
        {/*            loading={loading.tvScrollCategoryWiseDataNegative}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </ScrollMenu>*/}
        {/*  ) : (*/}
        {/*    <div className="d-flex flex-row gap-3  w-100">*/}
        {/*      <div className="d-flex flex-column gap-4 w-50">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          Top Positive TV*/}
        {/*        </div>*/}

        {/*        <SingleBarChart*/}
        {/*          barData={tvScrollCategoryWiseDataPositive}*/}
        {/*          type={2}*/}
        {/*          param={params}*/}
        {/*          handleParams={handleTvScrollCategoryWiseDataPositive}*/}
        {/*          top={top.tvScrollCategoryWiseDataPositive}*/}
        {/*          loading={loading.tvScrollCategoryWiseDataPositive}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <div className="d-flex flex-column gap-4  w-50">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          Top Negative TV*/}
        {/*        </div>*/}
        {/*        <SingleBarChart*/}
        {/*          barData={tvScrollCategoryWiseDataNegative}*/}
        {/*          sentiment={true}*/}
        {/*          type={2}*/}
        {/*          param={params}*/}
        {/*          handleParams={handleTvScrollCategoryWiseDataNegative}*/}
        {/*          top={top.tvScrollCategoryWiseDataNegative}*/}
        {/*          loading={loading.tvScrollCategoryWiseDataNegative}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}
        {/*{newspaperCategoryWiseDataPositive ? (*/}
        {/*  isMobile ? (*/}
        {/*    <ScrollMenu>*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="d-flex flex-row justify-content-between">*/}
        {/*          <div className="text-white font-s-xlg font-w-bold">*/}
        {/*            Top Positive Category*/}
        {/*          </div>*/}
        {/*          <div className="d-flex flex-row gap-3">*/}
        {/*            <div className="text-white font-s-lg font-w-bold">*/}
        {/*              Scroll more*/}
        {/*            </div>*/}
        {/*            <ScrollIIcon />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div*/}
        {/*          className=" bar-container rounded-4"*/}
        {/*          style={{*/}
        {/*            maxWidth: "350px",*/}
        {/*            overflowX: "auto",*/}
        {/*            overflowY: "hidden",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <SingleBarChart*/}
        {/*            barData={CategoryWiseDataPositive}*/}
        {/*            type={3}*/}
        {/*            param={params}*/}
        {/*            handleParams={handleCategoryWiseDataPositive}*/}
        {/*            top={top.categoryWiseDataPositive}*/}
        {/*            loading={loading.categoryWiseDataPositive}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="d-flex flex-row justify-content-between">*/}
        {/*          <div className="text-white font-s-xlg font-w-bold">*/}
        {/*            Top Negative Category*/}
        {/*          </div>*/}
        {/*          <div className="d-flex flex-row gap-3">*/}
        {/*            <div className="text-white font-s-lg font-w-bold">*/}
        {/*              Scroll more*/}
        {/*            </div>*/}
        {/*            <ScrollBackIcon />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div*/}
        {/*          className=" bar-container rounded-4"*/}
        {/*          style={{*/}
        {/*            maxWidth: "350px",*/}
        {/*            overflowX: "auto",*/}
        {/*            overflowY: "hidden",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <SingleBarChart*/}
        {/*            barData={CategoryWiseDataNegative}*/}
        {/*            sentiment={true}*/}
        {/*            type={3}*/}
        {/*            param={params}*/}
        {/*            handleParams={handleCategoryWiseDataNegative}*/}
        {/*            top={top.categoryWiseDataNegative}*/}
        {/*            loading={loading.categoryWiseDataNegative}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </ScrollMenu>*/}
        {/*  ) : (*/}
        {/*    <div className="d-flex flex-row gap-3  w-100">*/}
        {/*      <div className="d-flex flex-column gap-4 w-50">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          Top Positive Category*/}
        {/*        </div>*/}
        {/*        <SingleBarChart*/}
        {/*          barData={CategoryWiseDataPositive}*/}
        {/*          type={3}*/}
        {/*          param={params}*/}
        {/*          handleParams={handleCategoryWiseDataPositive}*/}
        {/*          top={top.categoryWiseDataPositive}*/}
        {/*          loading={loading.categoryWiseDataPositive}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <div className="d-flex flex-column gap-4  w-50">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          Top Negative Category*/}
        {/*        </div>*/}
        {/*        <SingleBarChart*/}
        {/*          barData={CategoryWiseDataNegative}*/}
        {/*          sentiment={true}*/}
        {/*          type={3}*/}
        {/*          param={params}*/}
        {/*          handleParams={handleCategoryWiseDataNegative}*/}
        {/*          top={top.categoryWiseDataNegative}*/}
        {/*          loading={loading.categoryWiseDataNegative}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}
        {/*{newspaperCategoryWiseDataPositive ? (*/}
        {/*  isMobile ? (*/}
        {/*    <ScrollMenu>*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          Newspaper Stat (Category wise)*/}
        {/*        </div>*/}
        {/*        <AggregatedTable*/}
        {/*          data={newspaperAggregated}*/}
        {/*          aggregated={true}*/}
        {/*          params={params}*/}
        {/*          currentContentType={1}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </ScrollMenu>*/}
        {/*  ) : (*/}
        {/*    <div*/}
        {/*      className="d-flex flex-row gap-3  w-100"*/}
        {/*      style={{ overflow: "auto" }}*/}
        {/*    >*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          Newspaper Stat (Category wise)*/}
        {/*        </div>*/}
        {/*        <AggregatedTable*/}
        {/*          data={newspaperAggregated}*/}
        {/*          aggregated={true}*/}
        {/*          params={params}*/}
        {/*          currentContentType={1}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}

        {/*{newspaperCategoryWiseDataPositive ? (*/}
        {/*  isMobile ? (*/}
        {/*    <ScrollMenu>*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          TV Stat (Category wise)*/}
        {/*        </div>*/}
        {/*        <AggregatedTable*/}
        {/*          data={tvAggregated}*/}
        {/*          aggregated={true}*/}
        {/*          params={params}*/}
        {/*          currentContentType={1}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </ScrollMenu>*/}
        {/*  ) : (*/}
        {/*    <div*/}
        {/*      className="d-flex flex-row gap-3  w-100"*/}
        {/*      style={{ overflow: "auto" }}*/}
        {/*    >*/}
        {/*      <div className="d-flex flex-column gap-4 ">*/}
        {/*        <div className="text-white font-s-xlg font-w-bold">*/}
        {/*          TV Stat (Category wise)*/}
        {/*        </div>*/}
        {/*        <AggregatedTable*/}
        {/*          data={tvAggregated}*/}
        {/*          aggregated={true}*/}
        {/*          params={params}*/}
        {/*          currentContentType={1}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  )*/}
        {/*) : (*/}
        {/*  <></>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default UniqueReportDashboard;
