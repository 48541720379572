import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { updateCroppedVideo } from "../../videoCropList.service";
import TagsDropdown from "../../../../components/Layouts/components/Navbar/components/SearchVideoCrop/TagsDropdown";

const EditCroppedVideo = ({ selectedTitle, removeSelectedTitle }) => {
  const [state, setState] = useState({
    title: selectedTitle.title,
    description: selectedTitle.description,
  });

  const handleState = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: date,
      };
    });
  };
  const updateTitle = (id) => {
    const params = {
      cropTitle: state.title,
    };
    updateCroppedVideo(id, params, (status, data, _params) => {
      if (status === "success") {
        removeSelectedTitle(id, _params);
      }
    });
  };

  return (
    <div>
      <Modal
        show={!!selectedTitle}
        onHide={removeSelectedTitle}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">Edit Title</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4 ">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Title</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Category Name"
                  value={state.title}
                  onChange={(e) => handleState("title", e.target.value)}
                />
              </div>
            </div>
            {/*<div className="d-flex flex-column gap-3 flex-wrap">*/}
            {/*  <div className="text-light font-s-md">Description</div>*/}
            {/*  <div style={{ minWidth: "250px" }}>*/}
            {/*    <input*/}
            {/*      className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"*/}
            {/*      type="text"*/}
            {/*      placeholder="Enter Category Name"*/}
            {/*      value={state.description}*/}
            {/*      onChange={(e) => handleState("description", e.target.value)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<TagsDropdown*/}
            {/*  handleTags={handleState}*/}
            {/*  currentTag={state.cropTags}*/}
            {/*/>*/}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary "
            onClick={() => updateTitle(selectedTitle.id)}
          >
            Edit Title
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditCroppedVideo;
