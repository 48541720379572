import React, { useCallback, useEffect, useState } from "react";

import { setPageName, usePageName, useUser } from "../../dataService/contexts";

import moment from "moment/moment";

import { NoteComponent, ScrollTab, TvSourceDropdown } from "../../components";
import {
  downloadCategoryReport,
  downloadSourceReport,
  getCategoryReport,
  getSourceReport,
} from "../ReportOverview/reportOverview.service";
import {
  ReportListDropdown,
  SearchByDate,
  SourceReportTable,
} from "../ReportOverview/components";
import ContentScrollTab from "../ReportOverview/components/ContentScrollTab";
import CategoryReportTable from "../ReportOverview/components/CategoryReportTable";
import NewspaperReportLoading from "../NewspaperReport/NewspaperReportLoading";
import { Link } from "react-router-dom";
import ViewIcon from "../../assets/viewIcon";
import DeleteIcon from "../../assets/deleteIcon";
import CategoryDropdown from "../../components/Layouts/components/Navbar/components/SearchVideoCrop/CategoryDropdown";
import { CategoryOptions } from "../NewspaperReport/components";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const handleDownload = (date, handleDownloadState, filterBy) => {
  if (filterBy === 1) {
    handleDownloadState(true);
    downloadSourceReport({ ...date }, (status, data) => {
      if (status === "success") {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${moment(date.startDate).format("DD-MM-YYYY")}_to_${moment(
            date.endDate
          ).format("DD-MM-YYYY")}_video_crop_aggregated_report.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      handleDownloadState(false);
    });
  } else {
    downloadCategoryReport({ ...date }, (status, data) => {
      if (status === "success") {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${moment(date.startDate).format("DD-MM-YYYY")}_to_${moment(
            date.endDate
          ).format("DD-MM-YYYY")}_video_crop_aggregated_report.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      handleDownloadState(false);
    });
  }
};

const VideoCropReportOverview = () => {
  const currentDateAndTime = moment();
  const { dispatch: pageNameDispatch } = usePageName();
  const initialValue = {
    reports: null,
    total: 0,
    page: 0,
  };
  const [iconState, setIconState] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    startDate: moment(currentDateAndTime)
      .startOf("day")
      .utc("asia/dhaka")
      .format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(currentDateAndTime)
      .utc("asia/dhaka")
      .format("YYYY-MM-DD HH:mm:ss"),
    contentType: 3,

    categoryName: "",
    sourceName: "",

    aggregated: false,
  });
  const [downloading, setDownloading] = useState(false);
  const handleDownloadState = (state) => {
    setDownloading(state);
  };
  const handleCategory = (key, value) => {
    setParams((prev) => {
      return {
        ...prev,
        ["categoryName"]: value.categoryName,
      };
    });
  };
  const handleSourceName = (key, value) => {
    setParams((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleState = (key, value) => {
    setIconState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const [filterBy, setFilterBy] = useState(1);

  const handleFilterBy = (filter) => {
    setFilterBy(filter);
  };
  const handleDateRange = (dateRange) => {
    setParams((prev) => {
      return {
        ...prev,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };
    });
  };
  const handleParams = (key, value) => {
    // wrapperRef.current.scrollIntoView();
    setParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const fetchBySource = useCallback(() => {
    setLoading(true);
    if (filterBy === 1) {
      getSourceReport(params, (status, data) => {
        if (status === "success") {
          // const { newsSourceMetaData, total, page } = data;

          handleState("reports", data);
        }
        setLoading(false);
      });
    } else {
      getCategoryReport(params, (status, data) => {
        if (status === "success") {
          // const { newsSourceMetaData, total, page } = data;

          handleState("reports", data);
        }
        setLoading(false);
      });
    }
  }, [params, filterBy]);

  useEffect(() => {
    setPageName(pageNameDispatch, "Video Crop Report Overview");
  }, []);
  useEffect(() => {
    fetchBySource();
  }, [filterBy, fetchBySource, params]);

  return (
    <div className="d-flex flex-column  p-1 gap-3">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex mb-3 flex-row justify-content-between ">
          <SearchByDate
            onChange={handleDateRange}
            loading={loading}
            params={params}
          />

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-lg btn-primary font-w-bolder px-4 py-2"
              onClick={() => {
                handleDownload(params, handleDownloadState, filterBy);
              }}
              disabled={downloading}
            >
              Download
            </button>
          </div>
        </div>
        <NoteComponent time={params} />
        <div className="d-flex flex-row  gap-3">
          <div className="d-flex flex-row gap-4">
            <ButtonGroup className="me-2" aria-label="First group">
              <Button
                variant={!params.aggregated ? `light` : `primary`}
                onClick={() => handleParams("aggregated", true)}
              >
                Aggregated Report
              </Button>
              <Button
                variant={params.aggregated ? `light` : `primary`}
                onClick={() => handleParams("aggregated", false)}
              >
                Date Wise report
              </Button>
            </ButtonGroup>

            <ReportListDropdown handleSentiment={handleFilterBy} />
            <CategoryOptions
              handleCategory={handleCategory}
              seletedCategory={params.categoryType}
            />
            <TvSourceDropdown
              currentChannel={params.sourceName}
              handleChannel={handleSourceName}
            />
          </div>
        </div>

        <div className="d-flex flex-column mt-3 gap-4">
          {filterBy === 1 ? (
            iconState.reports && Object.keys(iconState.reports).length ? (
              <SourceReportTable
                data={iconState}
                currentContentType={params.contentType}
                aggregated={params.aggregated}
                params={params}
              />
            ) : (
              <></>
            )
          ) : iconState.reports && Object.keys(iconState.reports).length ? (
            <CategoryReportTable
              data={iconState}
              currentContentType={params.contentType}
              aggregated={params.aggregated}
              params={params}
            />
          ) : (
            <></>
          )}

          {loading && (
            <div style={{ overflow: "auto" }}>
              <table className="table--report w-100">
                <tbody>
                  <NewspaperReportLoading />
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoCropReportOverview;
