import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { News, NewsDetails, LoadingComponent } from "./../../../../components";

import { useUser } from "../../../../dataService/contexts";
import { getLiveTVScroll } from "./liveTVScroll.service";

import ViewAllIcon from "../../../../assets/icons/link.png";

const LiveTVScroll = () => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const [liveTvScroll, setLiveTvScroll] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  /* this functions are for modal open and close , 
  when a news is selected for view "handleShow" function will be fired 
  and set the selected news on state , oposite will happen when "handleClose" will be fired*/
  const handleClose = () => setSelectedNews(null);
  const handleShow = (news) => setSelectedNews(news);

  /*this function is to handle the change of the checkbox "Alerts news only" */
  const handleCheckboxChange = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  /*this function is for fetching the news,
    We are fetching user specific news only. 
    To get the user specific news we need the list of Tv channels where this user has permission.
    We will not fetch any thing until we haven't the user.*/
  const fetchNews = () => {
    if (user) {
      setLoading(true); // when we start fetching data, we want to show a Loding Component here. To that we set the loading state here

      getLiveTVScroll(checked, user, (status, data) => {
        if (status === "success") setLiveTvScroll(data.news);

        setLoading(false); // sucess or fail loading should be off in any condition
      });
    }
  };

  useEffect(() => {
    fetchNews(); //this will call after initial component mount

    const timer = setInterval(() => {
      fetchNews();
    }, 5000 * 60); // this timer will git the fetchNews after every 5 min

    return () => clearTimeout(timer); // here we destroy the timer when component will unmount
  }, [checked, user]);

  return (
    <div className="d-flex flex-column gap-3 mt-5">
      <div className="d-flex flex-row justify-content-between gap-4 flex-wrap ">
        <div className="d-flex gap-4 align-items-center flex-wrap">
          <div className="text-white font-s-lg font-w-bolder">
            Live TV Scroll Alert
          </div>
          <Link
            to={"/tv-scroll-news"}
            target={"_blank"}
            className="px-3 py-2 btn btn-sm p-0 text-white d-flex align-items-center gap-2"
            style={{
              border: "1px solid rgba(228, 228, 228, 0.1)",
              borderRadius: ".5rem",
            }}
          >
            <img 
              src={ViewAllIcon} 
              alt="view all live news"
              style={{
                width: "1rem",
                height: "1rem"
              }}  
            />
            <div className="text-nowrap font-s-md">View All</div>
          </Link>
        </div>
        <div className="d-flex gap-3 align-items-center">
          <input
            className="m-0 form-check-input"
            type="checkbox"
            name="alertedNewsOnly"
            id="alertedNewsOnly"
            checked={checked}
            onChange={handleCheckboxChange}
          />
          <label
            className="text-white font-s-md font-w-bold align-self-center"
            htmlFor="alertedNewsOnly"
          >
            Alerted News Only
          </label>
        </div>
      </div>

      {loading ? (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          <LoadingComponent size={5} />
        </div>
      ) : (
        liveTvScroll.length > 0 && (
          <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
            {liveTvScroll.length > 0 &&
              liveTvScroll.map((news) => (
                <News
                  news={news}
                  action={handleShow}
                  keywords={
                    news.matchedKeywordList
                      ? news.matchedKeywordList.split(",")
                      : []
                  }
                  type=""
                  tags=""
                />
              ))}
          </div>
        )
      )}
      {selectedNews && (
        <NewsDetails
          data={selectedNews}
          keyword={
            selectedNews.matchedKeywordList
              ? selectedNews.matchedKeywordList.split(",")
              : []
          }
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export { LiveTVScroll };
