import React from "react";

import Skeleton from "react-loading-skeleton";

const DailyReportLoading = () => {
  const background = "#4e4d511a";
  const effect = "#3f3f3f";
  return (
    
      
              <tr>
                <td
                  className="text-white font-s-n font-w-bolder"
                  style={{ width: "15%" }}
                >
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
                <td className="" style={{ width: "15%" }}>
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder  ">
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
              </tr>
  
  );
};

export default DailyReportLoading;
