import React, { useEffect } from "react";

import { usePageName, setPageName } from "../../dataService/contexts";

import {
  LiveNewspaper,
  LiveTVScroll,
  LiveNewsStream,
  KeywordsAlert,
  TrendingKeywordsAlert,
} from "./components";

const Dashboard = () => {
  const { dispatch: pageNameDispatch } = usePageName();

  useEffect(()=>{
    setPageName(pageNameDispatch, "Welcome to National news surveillance platform");
  }, []);

  return (
    <div className="d-flex flex-column gap-2 p-1">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(4rem, 1fr))",
        }}
        className="gap-4"
      >
        <div style={{ gridColumn: "span 2" }}>
          <LiveNewspaper />
        </div>
        <div>
          <LiveNewsStream />
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(4rem, 1fr))",
          gridTemplateRows: "100vh",
        }}
        className="gap-4"
      >
        <div
          className=""
          style={{
            gridColumn: "span 2",
            height: "fit-content",
          }}
        >
          <LiveTVScroll />
        </div>
        <div
          className="d-flex flex-column gap-5 mt-5 py-2 justify-content-between "
          style={{
            height: "fit-content",
          }}
        >
          <TrendingKeywordsAlert />
          <KeywordsAlert />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
