import React from "react";
import Select from "react-select";
import "./styles.scss";
const doctype = [
  {
    name: "Word",
    doctype: 2,
  },
  {
    name: "Excel",
    doctype: 1,
  },
];
const CategoryDownloadDropdown = ({ handleDownload }) => {
  const handleSelect = (data) => {
    handleDownload(data.doctype);
  };
  return (
    <Select
      isSearchable={false}
      options={doctype}
      className="category-download-select "
      classNamePrefix="tags-select"
      getOptionLabel={(options) => `${options.name}`}
      getOptionValue={(options) => `${options.doctype}`}
      placeholder="Download"
      onChange={(e) => {
        handleSelect(e);
      }}
    />
  );
};

export default CategoryDownloadDropdown;
