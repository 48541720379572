import React, { useState, useEffect } from "react";
import moment from "moment";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

import { setPageName, usePageName } from "../../dataService/contexts";
import {
  getTotalCount,
  getNewspaperSourceWise,
  getNewspaperCategoryWise,
  getTVScrollSourceWise,
  getTVScrollCategoryWise,
} from "./analyticalDashboard.service";

import {
  DateRange,
  Stat,
  Pie,
  Line,
  Card,
  LeftArrow,
  RightArrow,
  Bar,
} from "./components";

const AnalyticalDashboard = () => {
  const { dispatch: pageNameDispatch } = usePageName();

  const currentDateAndTime = moment();
  const initialParams = {
    startDate: moment(currentDateAndTime)
      .subtract(30, "d")
      .utc("asia/dhaka")
      .format("YYYY-MM-DD"),
    endDate: moment(currentDateAndTime).utc("asia/dhaka").format("YYYY-MM-DD"),
  };

  const elemPrefix = "test";
  const getId = (index) => `${elemPrefix}${index}`;

  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const [dailyCount, setDailyCount] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [items] = React.useState(
    Array(20)
      .fill(0)
      .map((_, ind) => ({ id: getId(ind) }))
  );

  const handlePieData = () => {
    const _labels = Object.keys(totalData);
    const _series = _labels.map((item) => totalData[`${item}`]);

    setPieData({ labels: _labels, series: _series });
  };

  const handleLineData = () => {
    const _keys = Object.keys(dailyCount);

    const series = _keys.map((item) => ({
      name: item.toLocaleUpperCase(),
      data: dailyCount[item],
    }));

    setLineData(series);
  };

  const handleDateRange = (dateRange) => {
    setParams((prev) => {
      return {
        ...prev,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };
    });
  };

  useEffect(() => {
    setPageName(pageNameDispatch, "Analytical Dashboard");
  }, []);

  /*===================================================================================================*/

  useEffect(() => {
    getTotalCount(params, (status, data) => {
      if (status === "success") {
        setTotalData({
          negative: data?.aggregated?.negative || 0,
          positive: data?.aggregated?.positive || 0,
          uncategorized: data?.aggregated?.neutral || 0,
        });

        setDailyCount({
          negative: data?.dailyCount?.negative || [],
          positive: data?.dailyCount?.positive || [],
          uncategorized: data?.dailyCount?.neutral || [],
        });
      }
    });
  }, [params]);

  useEffect(() => {
    if (totalData) handlePieData();
  }, [totalData]);

  useEffect(() => {
    if (dailyCount) handleLineData();
  }, [dailyCount]);

  /*==================================================================================================*/

  const [newspaperSourceWiseData, setNewspaperSourceWiseData] = useState([]);

  useEffect(
    () =>
      getNewspaperSourceWise(params, (status, data) => {
        if (status === "success") {
          setNewspaperSourceWiseData(data);
        }
      }),
    [params]
  );

  /*===================================================================================================*/

  const [newspaperCategoryWiseData, setNewspaperCategoryWiseData] = useState(
    []
  );

  useEffect(
    () =>
      getNewspaperCategoryWise(params, (status, data) => {
        if (status === "success") {
          const categoryAggregated = data.map(
            (item) => item.count.categoryAggregated
          );

          const categoryName = categoryAggregated.map(
            (item) => item.categoryName
          );
          const negative = categoryAggregated.map((item) => item.negative);
          const positive = categoryAggregated.map((item) => item.positive);

          setNewspaperCategoryWiseData({
            series: [
              {
                name: "Positive",
                data: positive,
              },
              {
                name: "Negative",
                data: negative,
              },
            ],
            categories: categoryName,
          });
        }
      }),
    [params]
  );

  /*==================================================================================================*/

  const [TVScrollSourceWiseData, setTVScrollSourceWiseData] = useState([]);

  useEffect(
    () =>
      getTVScrollSourceWise(params, (status, data) => {
        if (status === "success") {
          setTVScrollSourceWiseData(data);
        }
      }),
    [params]
  );

  /*===================================================================================================*/

  const [TVScrollCategoryWiseData, setTVScrollCategoryWiseData] = useState([]);

  useEffect(
    () =>
      getTVScrollCategoryWise(params, (status, data) => {
        if (status === "success") {
          const categoryAggregated = data.map(
            (item) => item.count.categoryAggregated
          );

          const categoryName = categoryAggregated.map(
            (item) => item.categoryName
          );
          const negative = categoryAggregated.map((item) => item.negative);
          const positive = categoryAggregated.map((item) => item.positive);

          setTVScrollCategoryWiseData({
            series: [
              {
                name: "Positive",
                data: positive,
              },
              {
                name: "Negative",
                data: negative,
              },
            ],
            categories: categoryName,
          });
        }
      }),
    [params]
  );

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="text-white font-s-lg font-w-bolder">Overview</div>

        <div>
          <DateRange
            onChange={handleDateRange}
            loading={loading}
            params={params}
          />
        </div>
      </div>

      <div className="d-flex flex-wrap gap-4">
        {totalData ? <Stat totalData={totalData} /> : <></>}
        {pieData ? <Pie pieData={pieData} /> : <></>}
        {lineData ? (
          <Line
            lineData={lineData}
            startDate={params.startDate}
            endDate={params.endDate}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="d-flex flex-column gap-4">
        <div className="text-white font-s-lg font-w-bold">Newspaper</div>
        {newspaperSourceWiseData.length ? (
          <div>
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
              {newspaperSourceWiseData.map((item) => (
                <Card source={item} />
              ))}
            </ScrollMenu>
          </div>
        ) : (
          <></>
        )}

        {newspaperCategoryWiseData ? (
          <Bar barData={newspaperCategoryWiseData} />
        ) : (
          <></>
        )}
      </div>

      <div className="d-flex flex-column gap-4">
        <div className="text-white font-s-lg font-w-bold">TV Scroll</div>
        {TVScrollSourceWiseData.length ? (
          <div>
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
              {TVScrollSourceWiseData.map((item) => (
                <Card source={item} />
              ))}
            </ScrollMenu>
          </div>
        ) : (
          <></>
        )}

        {TVScrollCategoryWiseData ? (
          <Bar barData={TVScrollCategoryWiseData} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AnalyticalDashboard;
