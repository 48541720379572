import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

import { addNotice } from "./addNotice.service";

const AddNotice = ({ open, handleClose }) => {
  const [notices, setNotice] = useState("");
  const [detail, setDetail] = useState("");
  //const [keyword, setKeywords] = useState("");
  const handleNotification = (e) => {
    setNotice(e.target.value);
  };

  const handleDetail = (e) => {
    setDetail(e.target.value);
  };

  // const handleKeywords = (e) => {
  //   setKeywords(e.target.value);
  // };

  const onAddNotification = () => {
    addNotice({ title: notices, detail: detail }, (status, data) => {
      if (status === "success") {
        window.location.reload(true);
      }
    });
    handleClose();
  };
  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">New Notice</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Title</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Notice Title"
                  value={notices}
                  onChange={handleNotification}
                />
              </div>
              <div className="text-light font-s-md">Description</div>
              <div style={{ minWidth: "250px" }}>
                <textarea
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  id="exampleFormControlTextarea1"
                  value={detail}
                  onChange={handleDetail}
                  rows="5"
                />
              </div>
              {/* <div className="text-light font-s-md">Notice Keywords</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Notice Title"
                  value={keyword}
                  onChange={handleKeywords}
                />
              </div> */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary " onClick={onAddNotification}>
            Add Notice
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddNotice;
