import React from "react";
import "typicons.font/src/font/typicons.css";

const Bookmark = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 4.19108 0 2.78661 0.674122 1.77772C0.965956 1.34096 1.34096 0.965956 1.77772 0.674122C2.78661 0 4.19108 0 7 0C9.80892 0 11.2134 0 12.2223 0.674122C12.659 0.965956 13.034 1.34096 13.3259 1.77772C14 2.78661 14 4.19108 14 7V16.1136C14 17.8158 14 18.6668 13.4576 18.946C12.9152 19.2251 12.2226 18.7304 10.8375 17.7411L8.16248 15.8303C7.60114 15.4294 7.32047 15.2289 7 15.2289C6.67953 15.2289 6.39886 15.4294 5.83752 15.8303L3.16248 17.7411C1.77738 18.7304 1.08484 19.2251 0.542418 18.946C0 18.6668 0 17.8158 0 16.1136V7ZM12.5 7V16.1136C12.5 16.5019 12.4993 16.8165 12.4946 17.0748C12.2817 16.9284 12.0253 16.7462 11.7094 16.5205L8.99139 14.579C8.74906 14.4058 8.47616 14.2107 8.21949 14.0681C7.914 13.8983 7.50756 13.7289 7 13.7289C6.49244 13.7289 6.086 13.8983 5.78051 14.0681C5.52383 14.2107 5.25095 14.4058 5.0086 14.5791L2.29062 16.5205C1.97468 16.7462 1.71827 16.9284 1.50538 17.0748C1.50073 16.8165 1.5 16.5019 1.5 16.1136V7C1.5 5.56432 1.50181 4.60047 1.57656 3.86566C1.64876 3.15592 1.7764 2.82798 1.92133 2.61107C2.10372 2.3381 2.3381 2.10372 2.61107 1.92133C2.82798 1.7764 3.15592 1.64876 3.86566 1.57656C4.60047 1.50181 5.56432 1.5 7 1.5C8.43568 1.5 9.39954 1.50181 10.1343 1.57656C10.8441 1.64876 11.172 1.7764 11.3889 1.92133C11.6619 2.10372 11.8963 2.3381 12.0787 2.61107C12.2236 2.82798 12.3512 3.15592 12.4234 3.86566C12.4982 4.60047 12.5 5.56432 12.5 7Z"
        fill="#808191"
      />
    </svg>
  );
};

export default Bookmark;
