import ReactApexChart from "react-apexcharts";

import "./style.scss";

const options = {
  chart: {
    dropShadow: {
      enabled: true,
      color: "#000",
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.1,
    },
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ",",
          headerCategory: "category",
          headerValue: "value",
        },
        svg: { filename: "Analysis" },
        png: { filename: "Analysis" },
      },
      autoSelected: "zoom",
    },
    type: "donut",
  },

  legend: {
    show: true,
    position: "bottom",
    fontSize: "10px",
    labels: {
      colors: "#808191",
      useSeriesColors: false,
    },
    itemMargin: {
      horizontal: 5,
      vertical: 5,
    },

    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: ["#ff7a68", "#3DBAA2", "#ff9f38"],
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
  },

  dataLabels: { enabled: false },
  tooltip: { enabled: true },
  fill: { colors: ["#ff5733", "#3DBAA2", "#ff9f38"] },
  states: {
    hover: { filter: { type: "lighten", value: 0.2 } },
  },

  title: {
    text: "News by Type",
    align: "center",
    margin: 15,
    offsetX: 0,
    offsetY: 0,
    floating: false,
    style: {
      fontSize: "14px",
      fontWeight: "bold",
      fontFamily: undefined,
      color: "white",
    },
  },
  stroke: { width: 0 },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: "70%",
        labels: {},
      },
    },
  },
};

const Pie = (props) => {
  const { pieData } = props;

  return options ? (
    <div className="d-flex align-items-center rounded-4 p-4 pie-container">
      <ReactApexChart
        height="100%"
        options={{
          ...options,
          labels: pieData.labels.map((item) => item.toUpperCase()),
        }}
        series={pieData.series}
        type="donut"
      />
    </div>
  ) : (
    <></>
  );
};

export default Pie;
