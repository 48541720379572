import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

function Arrow({
  children,
  disabled,
  onClick
}) {
  return (
    disabled? <></>: <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none"
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return ( disabled ?
    <></> :
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
        <path d="M0.540498 12.88C0.198282 13.1555 -6.30913e-07 13.5664 -6.11959e-07 14C-5.93005e-07 14.4336 0.198282 14.8445 0.540498 15.12L17.7106 27.72C18.1442 28.0382 18.7243 28.0893 19.209 27.8522C19.6938 27.615 20 27.1303 20 26.6L20 1.40001C20 0.869725 19.6938 0.384957 19.209 0.147809C18.7243 -0.089341 18.1442 -0.038163 17.7106 0.280006L0.540498 12.88Z" fill="white"/>
      </svg>
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
    VisibilityContext
  );

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (disabled ?
    <></> :
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
        <path d="M19.4595 15.12C19.8017 14.8445 20 14.4336 20 14C20 13.5664 19.8017 13.1555 19.4595 12.88L2.28935 0.280007C1.85578 -0.0381612 1.2757 -0.0893396 0.790955 0.147809C0.306206 0.384958 1.86933e-06 0.869726 1.84615e-06 1.40001L7.44626e-07 26.6C7.21447e-07 27.1303 0.306205 27.615 0.790953 27.8522C1.2757 28.0893 1.85578 28.0382 2.28935 27.72L19.4595 15.12Z" fill="white"/>
      </svg>
    </Arrow>
  );
}
