import React from "react";

const ArrowLeftIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="#808191"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2444 1.59436C13.2192 1.50144 11.8968 1.5 10 1.5C8.10316 1.5 6.78082 1.50144 5.75559 1.59436C4.75097 1.68541 4.1798 1.85474 3.75 2.10289C3.06591 2.49784 2.49784 3.06591 2.10289 3.75C1.85474 4.1798 1.68541 4.75097 1.59436 5.75559C1.50144 6.78082 1.5 8.10316 1.5 10C1.5 11.8968 1.50144 13.2192 1.59436 14.2444C1.68541 15.249 1.85474 15.8202 2.10289 16.25C2.49784 16.9341 3.06591 17.5022 3.75 17.8971C4.1798 18.1453 4.75097 18.3146 5.75559 18.4056C6.78082 18.4986 8.10316 18.5 10 18.5C11.8968 18.5 13.2192 18.4986 14.2444 18.4056C15.249 18.3146 15.8202 18.1453 16.25 17.8971C16.9341 17.5022 17.5022 16.9341 17.8971 16.25C18.1453 15.8202 18.3146 15.249 18.4056 14.2444C18.4986 13.2192 18.5 11.8968 18.5 10C18.5 8.10316 18.4986 6.78082 18.4056 5.75559C18.3146 4.75097 18.1453 4.1798 17.8971 3.75C17.5022 3.06591 16.9341 2.49784 16.25 2.10289C15.8202 1.85474 15.249 1.68541 14.2444 1.59436ZM19.1962 17C20 15.6077 20 13.7385 20 10C20 6.26154 20 4.39231 19.1962 3C18.6695 2.08788 17.9121 1.33046 17 0.803848C15.6077 0 13.7385 0 10 0C6.26154 0 4.39231 0 3 0.803848C2.08788 1.33046 1.33046 2.08788 0.803848 3C0 4.39231 0 6.26154 0 10C0 13.7385 0 15.6077 0.803848 17C1.33046 17.9121 2.08788 18.6695 3 19.1962C4.39231 20 6.26154 20 10 20C13.7385 20 15.6077 20 17 19.1962C17.9121 18.6695 18.6695 17.9121 19.1962 17Z"
        fill="#808191"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0303 6.46967C10.3232 6.76256 10.3232 7.23744 10.0303 7.53033L8.31065 9.25001L13.7 9.25001C14.1142 9.25001 14.45 9.5858 14.45 10C14.45 10.4142 14.1142 10.75 13.7 10.75L8.31067 10.75L10.0303 12.4697C10.3232 12.7626 10.3232 13.2374 10.0303 13.5303C9.73744 13.8232 9.26256 13.8232 8.96967 13.5303L5.96967 10.5303C5.67678 10.2374 5.67678 9.76256 5.96967 9.46967L8.96967 6.46967C9.26256 6.17678 9.73744 6.17678 10.0303 6.46967Z"
        fill="#808191"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
