import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

import { updateComment } from "./commentNews.service";

const MarkedNews = ({ item, addComment }) => {
  const [editCommentActive, setEditCommentActive] = useState(false);
  const [comment, setComment] = useState("");

  const handleComment = (comment) => setComment(comment);
  const handleEditCommentActive = () => setEditCommentActive((prev) => !prev);

  const submitComment = (e) => {
    if (e.key === "Enter") {
      updateComment(
        {
          id: item.id,
          comment,
        },
        (status, data) => {
          if (status === "success") {
            setEditCommentActive(false);
            addComment(data.id, data.comment);
          }
        }
      );
    }
  };

  useEffect(() => handleComment(item.comment), [item]);

  return (
    <div
      className="px-4 py-3 rounded-3 d-flex flex-column justify-content-between gap-4"
      style={{
        height: "fit-content",
        backgroundColor: "rgba(228, 228, 228, 0.1)",
      }}
    >
      <div className="d-flex flex-column gap-2 w-100">
        <div className="py-2 d-flex flex-row flex-wrap justify-content-between gap-4">
          <div className="d-flex flex-row gap-4 flex-wrap">
            <div className="d-flex align-items-center gap-2">
              <div className="text-light font-s-sm">Category</div>
              <div className="text-white font-w-bold font-s-n">{item.tag}</div>
            </div>
          </div>

          <div>
            <button
              className="btn btn-sm btn-info text-white p-0 px-3 py-1 d-flex gap-2 rounded-3 align-items-center"
              onClick={() => handleEditCommentActive()}
              type="button"
            >
              <div className="font-w-bolder">
                {item.comment ? "Update Comment" : "Add Comment"}
              </div>
              {/* <CommentIcon /> */}
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between gap-4">
          <div>
            <div
              className="font-w-bolder"
              style={{
                color: item.color,
              }}
            >
              {item.text}
            </div>
          </div>
          <div className="d-flex align-self-center">
            <BsThreeDotsVertical color={"white"} />
          </div>
        </div>
      </div>
      {editCommentActive ? (
        <div style={{ minWidth: "250px" }}>
          <textarea
            className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
            rows="2"
            defaultValue={comment}
            onChange={(e) => handleComment(e.target.value)}
            onKeyPress={(e) => submitComment(e)}
          />
        </div>
      ) : (
        item.comment && (
          <div
            className=" px-2 py-1 rounded "
            style={{
              background: "#25272e",
            }}
          >
            <samp className="font-w-bold text-white font-s-sm">
              {item.comment}
            </samp>
          </div>
        )
      )}
    </div>
  );
};

export default MarkedNews;
