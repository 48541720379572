import apiService from "../../dataService/apiService";
import { getDateTime_GMT } from "../../const/dateFormat";
import { getEnvironment } from "../../const/environment";

export const getNewspaperContent = (
  params,
  user,
  name = [],
  done = () => {}
) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  const { availableNewsPapers } = user || {};
  const { availableTvChannels } = user || {};

  const permittedSource = [...availableNewsPapers, ...availableTvChannels];

  const _name = name?.length
    ? name
        .filter((name) => name.checked)
        .map((name) => name.code)
        .join(",")
    : permittedSource?.length
    ? permittedSource.map((an) => an.code).join(",")
    : "";

  const _params = {
    nextNewsPaperOffset: params.nextNewsPaperOffset,
    nextTvOffset: params.nextTvOffset,
    keys: params.keywords,
    size: params.size,
    start: getDateTime_GMT(params.startDate),
    end: getDateTime_GMT(params.endDate),
    type: params.type.value,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data, { ..._params });
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
      name: _name,
    }
  );
};
