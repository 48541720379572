import { getEnvironment } from "../../../../const/environment";
import apiService from "../../../../dataService/apiService";

export const getTags = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/tag-words/all`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },

    {},
    { ...params }
  );
};

export const removeTag = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/tag-words/delete/${id}`;
  apiService.delete(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};

export const downloadTagsExcel = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/report/tag-words/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const downloadTagsDoc = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/report/tag-words/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
