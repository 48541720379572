const NewspaperIcon = () => {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.663 4.279H18.72V1.337A1.337 1.337 0 0 0 17.384 0H1.337A1.336 1.336 0 0 0 0 1.337v17.651A4.013 4.013 0 0 0 4.012 23h16.046A2.942 2.942 0 0 0 23 20.058V5.616a1.337 1.337 0 0 0-1.337-1.337ZM10.43 18.186H4.012a.802.802 0 1 1 0-1.605h6.418a.802.802 0 0 1 0 1.605Zm4.28-3.21H4.011a.802.802 0 1 1 0-1.604h10.697a.802.802 0 1 1 0 1.605Zm.802-4.546a.812.812 0 0 1-.803.803H4.012a.814.814 0 0 1-.803-.803V5.081a.813.813 0 0 1 .803-.802h10.697a.813.813 0 0 1 .803.802v5.35Zm5.883 9.628a1.337 1.337 0 0 1-2.674 0V5.884h2.674v14.174Z"
                fill="#fff"
                fillOpacity={0.2}
            />
        </svg>
    )
}

const TVIcon = () => {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9998 15.8136V11.2135C22.9998 7.96073 22.9998 6.33425 21.9894 5.32367C21.0403 4.37469 19.5478 4.31693 16.6748 4.31348V22.7134C19.5479 22.7099 21.0404 22.6521 21.9894 21.703C22.9998 20.6926 22.9998 19.0661 22.9998 15.8132V15.8136ZM19.5497 10.0632C20.1849 10.0632 20.6999 10.578 20.6999 11.2132C20.6999 11.8484 20.1849 12.3632 19.5497 12.3632C18.9146 12.3632 18.3998 11.8484 18.3998 11.2132C18.3998 10.578 18.9146 10.0632 19.5497 10.0632ZM19.5497 14.6633C20.1849 14.6633 20.6999 15.1781 20.6999 15.8133C20.6999 16.4484 20.1849 16.9634 19.5497 16.9634C18.9146 16.9634 18.3998 16.4484 18.3998 15.8133C18.3998 15.1781 18.9146 14.6633 19.5497 14.6633Z" fill="white" fill-opacity="0.2"/>
            <path d="M15.6051 1.42425L13.129 4.31312H14.9503V22.7135H6.9002C3.64745 22.7135 2.02097 22.7135 1.01039 21.7029C0 20.6925 0 19.0661 0 15.8131V11.213C0 7.96027 0 6.3338 1.01039 5.32321C2.02097 4.31283 3.64745 4.31283 6.9002 4.31283H9.87148L7.39519 1.42396C7.08522 1.0622 7.1272 0.517714 7.48876 0.207747C7.85052 -0.102418 8.39501 -0.0604447 8.70497 0.301313L11.5003 3.56235L14.2954 0.301313C14.6056 -0.0604447 15.1501 -0.102418 15.5118 0.207747C15.8734 0.517719 15.9154 1.06219 15.6052 1.42396L15.6051 1.42425Z" fill="white" fill-opacity="0.2"/>
        </svg>
    )
}


const getTypeIcon = (type, newsType) => {
    if (Number(type) === 0) { 
        if (newsType === 1) { 
            return NewspaperIcon()
        } else if (newsType === 2) {
            return TVIcon()
        }        
    }

    return null
}


export { getTypeIcon };
