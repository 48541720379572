// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1rem;
}
.status:hover {
  background: #191b20;
  color: #355DFF !important;
  cursor: pointer;
}
.status:focus {
  background: #191b20;
  color: #355DFF !important;
  cursor: pointer;
}
.status--active {
  background: #191b20;
  color: #355DFF !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dictionary/components/KeywordTabs/style.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;EACA,yBAAA;EACA,eAAA;AACJ;AAEE;EACE,mBAAA;EACA,yBAAA;EACA,eAAA;AAAJ;AAEE;EACE,mBAAA;EACA,yBAAA;AAAJ","sourcesContent":[".status{\n  padding: 0.50rem 1rem 0.50rem 1rem;\n  border-radius: 1rem;\n\n  &:hover{\n    background: #191b20;\n    color: #355DFF !important;\n    cursor: pointer;\n  }\n\n  &:focus{\n    background: #191b20;\n    color: #355DFF !important;\n    cursor: pointer;\n  }\n  &--active{\n    background: #191b20;\n    color: #355DFF !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
