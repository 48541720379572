import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";

import moment from "moment";

export const getTotalCount = (params, done = () => { }) => {
    const env = getEnvironment();
    const url = `${env.protocol}://${env.baseUrl}:${env.port}/dashboard-management-service/api/${env.version}/${env.type}/graphs/aggregated`;

    const _params = {
        start: moment(params.startDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        end: moment(params.endDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
    };

    apiService.get(
        url,
        (status, data) => {
            if (status > 199 && status < 300) done("success", data);
            else done("error");
        },
        {},
        {..._params}
    );
}

export const getNewspaperSourceWise = (params, done = () => { }) => {
    const env = getEnvironment();
    const url = `${env.protocol}://${env.baseUrl}:${env.port}/dashboard-management-service/api/${env.version}/${env.type}/graphs/source-wise`;

    const _params = {
        start: moment(params.startDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        end: moment(params.endDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        contentType: 1
    };

    apiService.get(
        url,
        (status, data) => {
            if (status > 199 && status < 300) done("success", data);
            else done("error");
        },
        {},
        {..._params}
    );
}


export const getNewspaperCategoryWise = (params, done = () => { }) => {
    const env = getEnvironment();
    const url = `${env.protocol}://${env.baseUrl}:${env.port}/dashboard-management-service/api/${env.version}/${env.type}/graphs/category-wise`;

    const _params = {
        start: moment(params.startDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        end: moment(params.endDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        contentType: 1
    };

    apiService.get(
        url,
        (status, data) => {
            if (status > 199 && status < 300) done("success", data);
            else done("error");
        },
        {},
        {..._params}
    );
}

export const getTVScrollSourceWise = (params, done = () => { }) => {
    const env = getEnvironment();
    const url = `${env.protocol}://${env.baseUrl}:${env.port}/dashboard-management-service/api/${env.version}/${env.type}/graphs/source-wise`;

    const _params = {
        start: moment(params.startDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        end: moment(params.endDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        contentType: 2
    };

    apiService.get(
        url,
        (status, data) => {
            if (status > 199 && status < 300) done("success", data);
            else done("error");
        },
        {},
        {..._params}
    );
}


export const getTVScrollCategoryWise = (params, done = () => { }) => {
    const env = getEnvironment();
    const url = `${env.protocol}://${env.baseUrl}:${env.port}/dashboard-management-service/api/${env.version}/${env.type}/graphs/category-wise`;

    const _params = {
        start: moment(params.startDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        end: moment(params.endDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
        contentType: 2
    };

    apiService.get(
        url,
        (status, data) => {
            if (status > 199 && status < 300) done("success", data);
            else done("error");
        },
        {},
        {..._params}
    );
}