import { getEnvironment } from "../../../../../../const/environment";
import apiService from "../../../../../../dataService/apiService";

export const updateNewPassword = (body, done = () => {}) => {
  const env = getEnvironment();
  const url = ` http://192.168.160.205:32034/user-management-service/api/v1/private/password/update`;
  apiService.put({ url, data: body }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};
