import React, { useEffect, useState } from "react";

import Select from "react-select";
//
const sentiment = [
  {
    id: 1,
    label: "Newspaper",
    value: 1,
  },
  {
    id: 2,
    label: "TV",
    value: 2,
  },
];
const SourceTypeDropdown = ({ handleSource, currentSource }) => {
  const handleSelect = (data) => {
    handleSource("contentType", data);
  };
  return (
    <Select
      isSearchable={false}
      options={sentiment.map((item) => ({
        id: item.id,
        value: item.value,
        label: item.label,
      }))}
      className="channel-select-containerr"
      classNamePrefix="channel-select"
      getOptionLabel={(options) => `${options.label}`}
      placeholder={`Type`}
      defaultValue={null}
      onChange={(e) => {
        if (e !== null) {
          handleSelect(e.value);
        } else {
          handleSelect("");
        }
      }}
      isClearable
    />
  );
};

export default SourceTypeDropdown;
