import moment from "moment";

const getDate_2D2M2Y = (date) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format("DD-MM-YYYY") : "";
};
const getDate_3W2D3M2Y = (date) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format("ddd, DD MMM YY") : "";
};
const getDate_3W2D3M2Y2h2mA = (date) => {
  const momentDate = moment(date);
  return momentDate.isValid()
    ? momentDate.format("ddd, DD MMM YY, hh:mm A")
    : "";
};

const getDateTime_12T2D3M2Y = (date) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format("hh:mm A, DD MMM YY") : "";
};

const getDateTime_4Y2M2D12T = (date) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format("YYYY-MM-DD, hh:mm A") : "";
};

const getDateTime_4Y2M2D24T = (date) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format("YYYY-MM-DD HH:mm:ss") : "";
};

const getDateTime_GMT = (date) => {
  const momentDate = moment(date);

  return momentDate.isValid() ? momentDate.format() : "";
};

const getTime_12T = (date) => {
  const momentDate = moment(date);

  return momentDate.isValid() ? momentDate.format("hh:mm A") : "";
};

const dateIsValid = (date) => {
  const momentDate = moment(date);

  return momentDate.isValid();
};

export {
  getDate_3W2D3M2Y,
  getDateTime_12T2D3M2Y,
  getDateTime_4Y2M2D12T,
  getDateTime_4Y2M2D24T,
  getDateTime_GMT,
  getTime_12T,
  dateIsValid,
  getDate_3W2D3M2Y2h2mA,
  getDate_2D2M2Y,
};
