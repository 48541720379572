import React, { useState } from "react";
import moment from "moment";

import { DateCalender, DateTime } from "../../../../components";

import LoadMoreIcon from "./../../../../assets/loadMore";
import { dateIsValid } from "../../../../const/dateFormat";

const SearchByDate = ({ onChange, loading, params }) => {
  const [state, setState] = useState({
    startDate: params.startDate,
    endDate: params.endDate,
  });
  const handleState = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: moment(date).utc("asia/dhaka").format("YYYY-MM-DD HH:mm:ss"),
      };
    });
  };

  const handleSubmit = () => {
    onChange(state);
  };

  return (
    <div className="d-flex flex-row gap-4">
      <div style={{ minWidth: "100px" }}>
        <DateTime
          placeholder={"From"}
          date={moment(state.startDate).format("YYYY-MM-DD, hh:mm A")}
          handleDateWithTime={(e) => {
            handleState("startDate", e);
          }}
          hasTime={true}
        />
      </div>
      <div style={{ minWidth: "100px" }}>
        <DateTime
          placeholder={"To"}
          date={moment(state.endDate).format("YYYY-MM-DD, hh:mm A")}
          handleDateWithTime={(e) => {
            handleState("endDate", e);
          }}
          hasTime={true}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary px-4 py-3 rounded-4"
        disabled={
          !dateIsValid(state.startDate) ||
          !dateIsValid(state.endDate) ||
          new Date(state.startDate).getTime() >
            new Date(state.endDate).getTime() ||
          loading
            ? true
            : ""
        }
        onClick={() => handleSubmit()}
      >
        <div className="px-3 font-w-bolder font-s-md">
          {loading ? <LoadMoreIcon /> : "Search"}
        </div>
      </button>
    </div>
  );
};

export default SearchByDate;
