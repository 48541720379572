import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import "./style.scss";

import { postNewKeyWord } from "../../dictionary.service";

const AddNewWord = ({ open, handleClose, handleGetAPi }) => {
  const [keyword, setKeyword] = useState("");
  const [severity, setSeverity] = useState(1);

  const handleSeverity = (e) => {
    setSeverity(e.target.value);
  };
  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };
  const onAddWord = () => {
    postNewKeyWord({ value: keyword, severity: +severity }, (status, data) => {
      if (status === "success") {
        window.location.reload(true);
      }
    });
    handleClose();
  };
  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        className="dictionary-add"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">New Word</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Name</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter keyword"
                  value={keyword}
                  onChange={handleKeyword}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="text-light font-s-md">Severity</div>
              <div className="d-flex flex-row gap-3">
                <div className="form-check d-flex gap-2">
                  <input
                    className="form-check-input p-2 align-self-center"
                    type="radio"
                    name="critical"
                    id="critical"
                    value={3}
                    checked={severity === "3" ? "checked" : ""}
                    onChange={handleSeverity}
                  />
                  <label
                    className="form-check-label text-white bg-danger px-3 py-1 rounded-pill font-s-md font-w-bold"
                    htmlFor="critical"
                  >
                    Critical
                  </label>
                </div>
                <div className="form-check d-flex gap-2">
                  <input
                    className="form-check-input p-2 align-self-center"
                    type="radio"
                    name="neutral"
                    id="neutral"
                    value={2}
                    checked={severity === "2" ? "checked" : ""}
                    onChange={handleSeverity}
                  />
                  <label
                    className="form-check-label text-white bg-warning px-3 py-1 rounded-pill font-s-md font-w-bold align-self-center"
                    htmlFor="neutral"
                  >
                    Neutral
                  </label>
                </div>
                <div className="form-check d-flex gap-2">
                  <input
                    className="form-check-input p-2  align-self-center"
                    type="radio"
                    name="positive"
                    id="positive"
                    value={1}
                    checked={severity === "1" ? "checked" : ""}
                    onChange={handleSeverity}
                  />
                  <label
                    className="form-check-label text-white bg-success px-3 py-1 rounded-pill font-s-md font-w-bold  align-self-center"
                    htmlFor="positive"
                  >
                    Positive
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <div className="text-light font-s-md">Status</div>
              <div className="d-flex flex-row gap-3">
                <div className="form-check d-flex gap-2">
                  <input
                    className="form-check-input p-2 align-self-center"
                    type="radio"
                    name="activeimmediatly"
                    id="activeimmediatly"
                    checked
                  />
                  <label
                    className="form-check-label text-white  px-3 py-1 rounded-pill font-s-md font-w-bold"
                    htmlFor="activeimmediatly"
                  >
                    Active Immediatly
                  </label>
                </div>
                <div className="form-check d-flex gap-2">
                  <input
                    className="form-check-input p-2 align-self-center"
                    type="radio"
                    name="inactive"
                    id="inactive"
                    disabled
                  />
                  <label
                    className="form-check-label text-white   rounded-pill font-s-md font-w-bold align-self-center"
                    htmlFor="inactive"
                  >
                    Inactive
                  </label>
                </div>
              </div>
              <div>{}</div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {" "}
          <button className="btn btn-primary " onClick={onAddWord}>
            Add Word
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddNewWord;
