import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

import { usePageName, useUser, setPageName } from "../../dataService/contexts";
import { getNewspaperNews } from "./newspaperLive.service";

import {
  Pagination,
  UpdatedPagination,
  LoadingComponent,
  NewsCard,
  ScrollTab,
} from "../../components";
import { Actions } from "./components";

import { getDateTime_4Y2M2D12T } from "./../../const/dateFormat";
import { downloadNewspaperNews } from "../NewspaperSearch/newspaperLive.service";

const TVScrollSearch = () => {
  const wrapperRef = useRef(null);

  const { dispatch: pageNameDispatch } = usePageName();

  const { state: userState } = useUser();
  const { user } = userState || {};

  //data state
  const initialState = {
    news: [],
    newsSources: [],
    total: 0,
  };
  const [state, setState] = useState(initialState);
  const handleState = (key, value) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const resetState = () => {
    setState({
      ...initialState,
    });
  };
  const updateCategory = (categoryTypes, contentId) => {
    if (state.news && state.news.length) {
      const index = state.news.findIndex(
        (news) => news.contentId === contentId
      );
      state.news[index].categoryTypes = categoryTypes;
      setState((prev) => ({ ...prev, news: Array(...state.news) }));
    }
  };

  const updateSentiment = (sentiment, contentId) => {
    if (state.news && state.news.length) {
      const index = state.news.findIndex(
        (news) => news.contentId === contentId
      );
      state.news[index].sentiment = sentiment;
      setState((prev) => ({ ...prev, news: Array(...state.news) }));
    }
  };

  const updateTags = (tags, contentId) => {
    if (state.news && state.news.length) {
      const index = state.news.findIndex(
        (news) => news.contentId === contentId
      );
      state.news[index].tags = tags;
      setState((prev) => ({ ...prev, news: Array(...state.news) }));
    }
  };

  const location = useLocation();
  const { state: searchQuery } = location;

  //params for this page
  const currentDateAndTime = moment();
  const endDate = getDateTime_4Y2M2D12T(
    moment(currentDateAndTime).utc("asia/dhaka")
  );
  const prevDateAndTime = getDateTime_4Y2M2D12T(
    moment(currentDateAndTime).startOf("day").utc("asia/dhaka")
  );
  const initialParams = searchQuery
    ? {
        keys: searchQuery.searchText,
        name: searchQuery.sourceNames.length
          ? searchQuery.sourceNames.map((item) => item.code)
          : [],
        type: 2,
        isKeywordSearch: false,
        page: 0,
        startDate: searchQuery.startDate,
        endDate: searchQuery.endDate,
        scrollNumber: null,
      }
    : {
        keys: "",
        name: [],
        type: 2,
        isKeywordSearch: false,
        page: 0,
        startDate: prevDateAndTime,
        endDate: endDate,
        scrollNumber: null,
      };

  const [params, setParams] = useState(initialParams);
  const handleParams = (key, value) => {
    if (key === "isKeywordSearch" || key === "scrollNumber") {
      setParams((prev) => {
        resetState();
        return {
          ...prev,
          page: 0,
          [key]: value,
        };
      });
    } else if (key === "page") {
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  //searchbox params
  const initialSearchBoxParams = searchQuery
    ? {
        searchText: searchQuery.searchText,
        sourceNames: searchQuery.sourceNames,
        startDate: searchQuery.startDate,
        endDate: searchQuery.endDate,
        sourceCategory: searchQuery.sourceCategory,
      }
    : {
        searchText: "",
        sourceNames: [],
        startDate: prevDateAndTime,
        endDate: endDate,
        sourceCategory: true,
      };
  const [searchBoxParams, setSearchBoxParams] = useState({
    ...initialSearchBoxParams,
  });
  const handleSearchBoxParams = (key, value) => {
    setSearchBoxParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearch = () => {
    setParams((prev) => ({
      ...prev,
      page: 0,
      keys: searchBoxParams.searchText,
      startDate: searchBoxParams.startDate,
      endDate: searchBoxParams.endDate,
      name: searchBoxParams.sourceNames.map((source) => source.code),
      sourceCategory: searchBoxParams.sourceCategory,
    }));
  };

  //laoding
  const [loading, setLoading] = useState(false);
  const handleLoading = (loadingState) => {
    setLoading(loadingState);
  };

  // get live data of newspaper
  const fetch = useCallback(() => {
    handleLoading(true);

    getNewspaperNews(user, { ...params }, (status, data) => {
      if (status === "success") {
        const { news, newsPaperNames, total } = data;

        handleState("news", news);
        handleState("newsSources", newsPaperNames);
        handleState("total", total);

        wrapperRef.current.scrollIntoView();
      }
      handleLoading(false);
    });
  }, [params, user]);

  const handleDownload = useCallback(() => {
    handleLoading(true);

    downloadNewspaperNews(user, state.total, { ...params }, (status, data) => {
      if (status === "success") {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${moment(params.startDate).format("DD-MM-YYYY")}_to_${moment(
            params.endDate
          ).format("DD-MM-YYYY")}_tv_channel_report.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  }, [params, user, state]);

  useEffect(() => {
    if (user) fetch();
  }, [user, params, fetch]);

  // Source set
  useEffect(() => {
    setPageName(pageNameDispatch, "TV Scroll Search");
  }, []);

  return user ? (
    <div className="d-flex flex-column gap-3 p-1" ref={wrapperRef}>
      <Actions
        handleSearch={handleSearch}
        sourceType={params.type}
        handleSearchBoxParams={handleSearchBoxParams}
        searchBoxParams={searchBoxParams}
        handleDownload={handleDownload}
      />

      <div className="d-flex flex-row flex-wrap gap-4 justify-content-between">
        <div className="form-check d-flex gap-3 my-3 align-items-center">
          <input
            className="form-check-input"
            type="checkbox"
            value={params.isKeywordSearch}
            id="alerted_news_only"
            checked={params.isKeywordSearch}
            onChange={(e) => {
              handleParams("isKeywordSearch", e.target.checked);
            }}
          />
          <label
            className="form-check-label text-white font-s-md"
            htmlFor="alerted_news_only"
          >
            Alerted News Only
          </label>
        </div>
        <ScrollTab
          handleScroll={handleParams}
          currentScroll={params.scrollNumber}
        />
      </div>

      {state.news.length ? (
        <div className="d-flex flex-column gap-3">
          {state.news.map((item, index) => {
            return (
              <NewsCard
                sr={index + 1 + params.page * 40}
                news={item}
                keywords={
                  item.matchedKeywordList
                    ? item.matchedKeywordList.split(",")
                    : []
                }
                type={params.type}
                tags=""
                name={params.name}
                categoryAction={updateCategory}
                sentimentAction={updateSentiment}
                tagsAction={updateTags}
              />
            );
          })}
        </div>
      ) : (
        <></>
      )}

      {loading && (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          <LoadingComponent size={1} />
        </div>
      )}

      {state.total >= 40 ? (
        // <Pagination
        //   page={params.page}
        //   total={state.total}
        //   action={handleParams}
        // />
        <UpdatedPagination
          itemsPerPage={40}
          total={state.total}
          action={handleParams}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

export default TVScrollSearch;
