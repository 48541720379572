import React from "react";
import Select from "react-select";

import { newsSourceTypes } from "./../../const/newsSourceTypes";

import "./style.scss";

const NewsSourceTypes = ({
  handleCurrentNewsSourceType,
  currentNewsSourceType,
}) => {
  return (
    <Select
      isSearchable={false}
      options={newsSourceTypes}
      className="news-source-types-container  "
      classNamePrefix="news-source-types"
      getOptionLabel={(options) => `${options.label}`}
      placeholder="News Source"
      defaultValue={currentNewsSourceType}
      onChange={(e) => handleCurrentNewsSourceType(e)}
    />
  );
};

export default NewsSourceTypes;
