import { getEnvironment } from "../../const/environment";
import { parseJwt } from "../../utilities";
import apiService from "../../dataService/apiService";

export const getInvalidOtp = (userId, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/otp/invalidate?`;

  const _params = {
    keycloakId: parseJwt(userId)?.sub,
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        if (data) {
          done("success", data);
        } else {
          done("success", data);
        }
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};
