import React from "react";

const ArrowRightIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.75559 18.4056C6.78082 18.4986 8.10316 18.5 10 18.5C11.8968 18.5 13.2192 18.4986 14.2444 18.4056C15.249 18.3146 15.8202 18.1453 16.25 17.8971C16.9341 17.5022 17.5022 16.9341 17.8971 16.25C18.1453 15.8202 18.3146 15.249 18.4056 14.2444C18.4986 13.2192 18.5 11.8968 18.5 10C18.5 8.10316 18.4986 6.78082 18.4056 5.75559C18.3146 4.75097 18.1453 4.1798 17.8971 3.75C17.5022 3.06591 16.9341 2.49784 16.25 2.10289C15.8202 1.85474 15.249 1.68541 14.2444 1.59436C13.2192 1.50144 11.8968 1.5 10 1.5C8.10316 1.5 6.78082 1.50144 5.75559 1.59436C4.75097 1.68541 4.1798 1.85474 3.75 2.10289C3.06591 2.49784 2.49784 3.06591 2.10289 3.75C1.85474 4.1798 1.68541 4.75097 1.59436 5.75559C1.50144 6.78082 1.5 8.10316 1.5 10C1.5 11.8968 1.50144 13.2192 1.59436 14.2444C1.68541 15.249 1.85474 15.8202 2.10289 16.25C2.49784 16.9341 3.06591 17.5022 3.75 17.8971C4.1798 18.1453 4.75097 18.3146 5.75559 18.4056ZM0.803847 3C0 4.3923 0 6.26154 0 10C0 13.7385 0 15.6077 0.803847 17C1.33046 17.9121 2.08788 18.6695 3 19.1962C4.3923 20 6.26154 20 10 20C13.7385 20 15.6077 20 17 19.1962C17.9121 18.6695 18.6695 17.9121 19.1962 17C20 15.6077 20 13.7385 20 10C20 6.26154 20 4.3923 19.1962 3C18.6695 2.08788 17.9121 1.33046 17 0.803847C15.6077 0 13.7385 0 10 0C6.26154 0 4.3923 0 3 0.803847C2.08788 1.33046 1.33046 2.08788 0.803847 3Z"
          fill="#808191"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.96967 6.46967C10.2626 6.17678 10.7374 6.17678 11.0303 6.46967L14.0303 9.46967C14.3232 9.76256 14.3232 10.2374 14.0303 10.5303L11.0303 13.5303C10.7374 13.8232 10.2626 13.8232 9.96967 13.5303C9.67678 13.2374 9.67678 12.7626 9.96967 12.4697L11.6894 10.75H6.3C5.88579 10.75 5.55 10.4142 5.55 9.99999C5.55 9.58577 5.88579 9.24999 6.3 9.24999L11.6893 9.24999L9.96967 7.53033C9.67678 7.23744 9.67678 6.76256 9.96967 6.46967Z"
          fill="#808191"
        />
      </svg>
    </div>
  );
};

export default ArrowRightIcon;
