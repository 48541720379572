import React from "react";
import { toast, ToastContainer } from "react-toastify";

const ErrorToast = () => {
  const activeToast = () =>
    toast.error("Error Occured ", {
      containerId: "active",
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
      onClose: () => {},
    });

  return (
    <div>
      <ToastContainer
        enableMultiContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        containerId={"inActive"}
      />
    </div>
  );
};

export default ErrorToast;
