import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";

import {
  LoadingComponent,
  News,
  NoteComponent,
  SourcesDropdown,
} from "../../components";
import jsonpath from "jsonpath";
import {
  managerReport,
  updateManagerReport,
  removeFromReport,
  downloadUniqueNews,
  managerReportDetails,
  downloadManagerReportDetails,
} from "./managerReport.service";

import { ROUTE } from "../../const";
import { MarkedLine } from "./components";
import { setPageName, usePageName } from "../../dataService/contexts";
import { CategoryOptions, Sentiment } from "../NewspaperReport/components";
import NewspaperSourceDropdown from "../NewspaperReport/components/NewspaperSourceDropdown";
import CategoryDropdown from "../../components/Layouts/components/Navbar/components/SearchVideoCrop/CategoryDropdown";
import { SourceTypeDropdown } from "../index";
import { DateCalender } from "../../components";
import { TagWiseTable } from "./components";
import { markingColors } from "../../const/markingColors";

const MarkingReportOverviewDetails = () => {
  const { dispatch: pageNameDispatch } = usePageName();

  const location = useLocation();
  const autoPlay = !!(location.hash && location.hash === "#auto-play");

  const [reports, setReports] = useState([]);
  const [negativeReport, setNegativeReport] = useState([]);
  const [positiveReport, setPositiveReport] = useState([]);
  const [desableDownload, setDesableDownload] = useState([]);

  const [loading, setLoading] = useState(false);

  const [URLParams, setURLParams] = useSearchParams();
  const initialParams = autoPlay
    ? {
        date: URLParams.get("startDate"),
        endDate: URLParams.get("endDate"),
        contentType: null,
        categoryType: URLParams.get("categoryName"),
        sentiment: URLParams.get("sentiment"),
        sourceName: URLParams.get("sourceName"),
      }
    : {
        date: new Date(),
        endDate: new Date(),
        contentType: null,
        categoryType: null,
        sentiment: null,
        sourceName: null,
      };

  //params
  const [params, setParams] = useState(initialParams);

  const fetchManagerReport = () => {
    setLoading(true);
    managerReportDetails(params, (status, data) => {
      if (status === "success") {
        if (data) {
          let _reports = [];
          if (data.negative) {
            setNegativeReport(data.negative.reports);
            data.negative.reports.forEach((item) => {
              let nh = item.highlights || [];
              nh.forEach((i) =>
                _reports.push({
                  ...i,
                  newsTimeAt: item?.newsContent?.newsTimeAt,
                })
              );
            });
          }
          if (data.positive) {
            setPositiveReport(data.positive.reports);
            data.positive.reports.forEach((item) => {
              let ph = item.highlights || [];
              ph.forEach((i) =>
                _reports.push({
                  ...i,
                  newsTimeAt: item?.newsContent?.newsTimeAt,
                })
              );
            });
          }

          setReports(_reports);
        }
      }
      setLoading(false);
    });
  };

  const handleDownload = () => {
    downloadManagerReportDetails(
      {
        sourceName: params.sourceName,
        date: params.date,
        categoryType: params.categoryType,
        contentType: params.contentType,
        endDate: params.endDate,
        sentiment: params.sentiment,
      },
      (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${moment(params.date).format(
              "DD-MM-YYYY"
            )}_marking-report-overview-details.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    );
  };

  useEffect(() => {
    setDesableDownload((prev) => [
      ...jsonpath.query(positiveReport, "$..marked"),
      ...jsonpath.query(negativeReport, "$..marked"),
    ]);
  }, [positiveReport, negativeReport]);
  useEffect(() => {
    setPageName(pageNameDispatch, "Marking Report Overview Details");
  }, []);

  useEffect(() => {
    fetchManagerReport();
  }, [params]);

  return (
    <div className="d-flex flex-column  p-1 gap-2">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-between gap-3 flex-wrap flex-row">
          <div className="flex-wrap d-flex flex-row gap-4">
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Start Date: {moment(params.date).format("YYYY-MM-DD, hh:mm A")}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              End Date: {moment(params.endDate).format("YYYY-MM-DD, hh:mm A")}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Source Name: {params.sourceName}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Category Name: {params.categoryType}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Sentiment:{" "}
              {Number(params.sentiment) === 1 ? "Positive" : "Negative"}
            </div>
          </div>
          <button
            className="btn btn-primary font-s-md font-w-bolder px-3 py-2 "
            onClick={() => {
              handleDownload();
            }}
          >
            Download
          </button>
        </div>
      </div>
      <NoteComponent
        time={{ startDate: params.date, endDate: params.endDate }}
      />
      <div>
        {/*<TagWiseTable report={reports} />*/}
        reports.length ? (
        <table className="p-4 w-100 table--topicManagement ">
          <thead>
            <tr className="text-light font-s-n font-w-bolder text-uppercase ">
              <th className="text-nowrap">
                <div className="pe-3">SOURCE</div>
              </th>
              <th className="text-nowrap">
                <div className="pe-3">Tags</div>
              </th>
              <th className="text-nowrap">
                <div className="pe-3">Category</div>
              </th>
              <th className="text-nowrap">
                <div className="pe-3">Report Created At</div>
              </th>
              <th className="text-nowrap">
                <div className="pe-3">News Time At</div>
              </th>
              <th className="text-nowrap">
                <div className="pe-3">Sentiment</div>
              </th>
              <th className="text-nowrap">
                <div className="pe-3">NEWS MARKED</div>
              </th>
            </tr>
          </thead>
          {reports.length ? (
            <tbody className="rounded-5 ">
              {reports.map((item) => {
                return (
                  <tr>
                    <td
                      className="text-white font-s-n font-w-bolder"
                      style={{ width: "12%" }}
                    >
                      {item.sourceName || "..."}
                    </td>
                    <td
                      className="text-light text-nowrap font-s-n font-w-bolder"
                      style={{ width: "12%" }}
                    >
                      {item.tags && Array.isArray(item.tags) && item.tags.length
                        ? item.tags.map((i) => i.word).toString()
                        : item.tag}
                    </td>
                    <td
                      className="text-light text-nowrap font-s-n font-w-bolder"
                      style={{ width: "12%" }}
                    >
                      {item.categoryTypes &&
                      Array.isArray(item.categoryTypes) &&
                      item.categoryTypes.length
                        ? item.categoryTypes
                            .map((i) => i.categoryName)
                            .toString()
                        : item.categoryTypes}
                    </td>
                    <td
                      className="text-light text-nowrap font-s-n font-w-bolder"
                      style={{ width: "12%" }}
                    >
                      {moment(item.createdAt).format("YYYY-MM-DD hh:mm A")}
                    </td>

                    <td
                      className="text-light text-nowrap font-s-n font-w-bolder"
                      style={{ width: "12%" }}
                    >
                      {moment(item.newsTimeAt).format("YYYY-MM-DD hh:mm A")}
                    </td>
                    <td
                      className="text-light text-nowrap font-s-n font-w-bolder"
                      style={{ width: "12%" }}
                    >
                      {Number(params.sentiment) === 1 ? "Positive" : "Negative"}
                    </td>
                    <td
                      className="font-s-n font-w-bolder"
                      style={{
                        color: item.sentiment
                          ? markingColors[item.sentiment]
                          : "white",
                        width: "28%",
                      }}
                    >
                      {item.highlightedContent}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <></>
          )}
        </table>
        ) :
      </div>

      {loading && (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          <LoadingComponent size={1} />
        </div>
      )}
    </div>
  );
};

export default MarkingReportOverviewDetails;
