import React from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import "./style.scss";
const ConfirmationAlert = ({
  show,
  handleConfirm,
  id,
  handleShow,
  contentType,
  actionType = "",
}) => {
  return (
    <div>
      <AlertDialog.Root open={show}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="AlertDialogOverlay" />
          <AlertDialog.Content className="AlertDialogContent">
            <div className="d-flex flex-column gap-4">
              <AlertDialog.Title className="AlertDialogTitle">
                Are you absolutely sure?
              </AlertDialog.Title>
              <AlertDialog.Description className="AlertDialogDescription">
                {actionType === "Add" ? (
                  <div>
                    Are You sure you want to {actionType} this {contentType} ?
                  </div>
                ) : (
                  <div>
                    This action cannot be undone. This will permanently delete
                    this {contentType} and remove the data from our servers.
                  </div>
                )}
              </AlertDialog.Description>
              <div className="d-flex justify-content-end gap-4">
                <AlertDialog.Action asChild>
                  <button
                    className="btn btn-success btn-lg text-white font-w-bolder"
                    onClick={() => {
                      handleConfirm(id);
                      handleShow();
                    }}
                  >
                    Yes, {actionType === "Add" ? actionType : "delete"}{" "}
                    {contentType}
                  </button>
                </AlertDialog.Action>
                <AlertDialog.Cancel asChild>
                  <button
                    className="btn btn-lg btn-danger text-white font-w-bolder"
                    onClick={() => handleShow()}
                  >
                    Cancel
                  </button>
                </AlertDialog.Cancel>
              </div>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};

export default ConfirmationAlert;
