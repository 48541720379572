import { createContext, useContext, useMemo, useReducer } from "react";

import actionTypes from "../actionTypes";
import apiService from "../apiService";

import { getEnvironment } from "../../const/environment";

const UserContext = createContext();

const initialState = {
  user: null,
  loading: false,
  error: false,
  errorMsg: "",
};

const userReducer = function (state, action) {
  switch (action.type) {
    case actionTypes.userInfoRequest:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };

    case actionTypes.userInfoSuccess:
      return {
        ...state,
        user: action.user,
        loading: false,
        errorMsg: "",
        error: false,
      };

    case actionTypes.userInfoFail:
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
        error: true,
      };

    default:
      break;
  }
};

export function UserProvider(props) {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <UserContext.Provider value={value} {...props} />;
}

export function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("use user must be used within a user provider");
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

const request = () => ({
  type: actionTypes.userInfoRequest,
});

const success = (user) => ({
  type: actionTypes.userInfoSuccess,
  user,
});

const fail = (errorMsg) => ({
  type: actionTypes.userInfoFail,
  errorMsg,
});

export const getUser = (dispatch, done = () => {}) => {
  const env = getEnvironment();

  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/users-info`;

  dispatch(request());

  apiService.get(url, (status, data) => {
    if (status > 199 && status < 300) {
      dispatch(success(data));
      done("success", data);
    } else {
      dispatch(fail(data));
      done("error", data);
    }
  });
};
