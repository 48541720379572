import React from "react";

const TvScrollLiveIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.31355 13.1864C8.89757 12.6024 9.18958 12.3104 9.52528 12.1984C9.83341 12.0956 10.1666 12.0956 10.4747 12.1984C10.8104 12.3104 11.1024 12.6024 11.6864 13.1864L12.9286 14.4286C14.1919 15.6919 14.8236 16.3236 14.8726 16.8647C14.917 17.3553 14.7177 17.8365 14.3393 18.152C13.922 18.5 13.0287 18.5 11.2421 18.5H8.7579C6.97127 18.5 6.07796 18.5 5.66065 18.152C5.28231 17.8365 5.083 17.3553 5.12743 16.8647C5.17645 16.3236 5.80811 15.6919 7.07145 14.4285L8.31355 13.1864Z"
        fill="#808191"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.46558 1.5H12.5344C13.6726 1.49999 14.5745 1.49999 15.3005 1.56174C16.0431 1.6249 16.6701 1.75674 17.2391 2.06087C18.0677 2.50373 18.7463 3.18233 19.1891 4.01087C19.4933 4.57985 19.6251 5.20693 19.6883 5.94952C19.75 6.67548 19.75 7.57741 19.75 8.71554V8.78446C19.75 9.92259 19.75 10.8245 19.6883 11.5505C19.6251 12.2931 19.4933 12.9201 19.1891 13.4891C18.7463 14.3177 18.0677 14.9963 17.2391 15.4391C16.8738 15.6344 16.4194 15.4965 16.2241 15.1312C16.0289 14.7659 16.1667 14.3115 16.532 14.1162C17.0989 13.8132 17.5632 13.3489 17.8662 12.782C18.0344 12.4674 18.1387 12.069 18.1937 11.4234C18.2494 10.7684 18.25 9.93023 18.25 8.75C18.25 7.56978 18.2494 6.7316 18.1937 6.07665C18.1387 5.43101 18.0344 5.03261 17.8662 4.71796C17.5632 4.15107 17.0989 3.68676 16.532 3.38376C16.2174 3.21557 15.819 3.11126 15.1734 3.05634C14.5184 3.00063 13.6802 3 12.5 3H7.5C6.31978 3 5.4816 3.00063 4.82665 3.05634C4.18101 3.11126 3.78261 3.21557 3.46796 3.38376C2.90107 3.68676 2.43676 4.15107 2.13376 4.71796C1.96557 5.03261 1.86126 5.43101 1.80634 6.07665C1.75063 6.7316 1.75 7.56978 1.75 8.75C1.75 9.93023 1.75063 10.7684 1.80634 11.4234C1.86126 12.069 1.96557 12.4674 2.13376 12.782C2.43676 13.3489 2.90107 13.8132 3.46796 14.1162C3.83326 14.3115 3.97111 14.7659 3.77585 15.1312C3.5806 15.4965 3.12617 15.6344 2.76087 15.4391C1.93233 14.9963 1.25373 14.3177 0.810874 13.4891C0.506744 12.9201 0.374901 12.2931 0.311738 11.5505C0.249989 10.8245 0.249994 9.92257 0.25 8.78442V8.71558C0.249994 7.57743 0.249989 6.67549 0.311738 5.94952C0.374901 5.20693 0.506744 4.57985 0.810874 4.01087C1.25373 3.18233 1.93233 2.50373 2.76087 2.06087C3.32985 1.75674 3.95693 1.6249 4.69952 1.56174C5.42549 1.49999 6.32743 1.49999 7.46558 1.5Z"
        fill="#808191"
      />
    </svg>
  );
};

export default TvScrollLiveIcon;
