import React, { useEffect, useState } from "react";
import { getTags, removeTag } from "./tags.service";

import { ConfirmationAlert, UpdatedPagination } from "../../../../components";

import "./style.scss";
import { useUser } from "../../../../dataService/contexts";
import { editTagsPermission } from "../../../../components/NewsFullView/permission.controller";
import { getCategory } from "../../../Category/components/CategoryTable/categoryTable.service";

const TagTable = () => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const initialParams = {
    size: 40,
    page: 0,
  };
  const [params, setParams] = useState(initialParams);

  const initialState = {
    tags: [],
    total: 0,
    page: 0,
  };

  const [state, setState] = useState(initialState);
  const [selectedTagId, setSelectedTagId] = useState(0);

  const [showAlert, setShowAlert] = useState(false);

  const handleParams = (key, value) => {
    if (key === "page") {
      // wrapperRef.current.scrollIntoView();
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams(value);
    }
  };
  const handleState = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleOpenAlert = () => setShowAlert(true);

  const handleCloseAlert = () => setShowAlert(false);

  const fetchTags = () => {
    getTags(params, (status, data) => {
      if (status === "success") {
        const { words, total, page } = data;
        handleState("tags", words);
        handleState("total", total);
        handleState("page", page);
      }
    });
  };

  const handleDelete = (id) => {
    removeTag(id, (status, data) => {
      if (status === "success") {
        setState((prev) => {
          return {
            tags: prev.tags.filter((item) => item.id !== id),
            total: prev.total - 1,
          };
        });
      }
    });
  };

  useEffect(() => {
    fetchTags();
  }, [params]);
  return (
    <div className="text-silver">
      <table className="w-100 table--dictionary ">
        <thead>
          <tr
            className="text-light font-s-n font-w-bolder text-uppercase"
            style={{ letterSpacing: "1px" }}
          >
            <th>SL</th>
            <th>Tag</th>
            {editTagsPermission(user) && (
              <th>
                <div className="d-flex justify-content-end">ACTION</div>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {state.tags.length > 0 &&
            state.tags.map((tag, index) => (
              <tr key={index} className="">
                <td className="my-2">
                  <div className="text-white font-w-bold text-nowrap">
                    #{" "}
                    {state.page === 0 ? index + 1 : index + 1 + 40 * state.page}
                  </div>
                </td>
                <td className="text-white font-s-md font-w-bolder">
                  {tag.word}
                </td>

                {editTagsPermission(user) && (
                  <td className="text-white">
                    <div className="d-flex justify-content-end">
                      <button
                        className="bg-danger btn btn-md text-white px-4 font-s-md font-w-bolder"
                        onClick={() => {
                          handleOpenAlert();
                          setSelectedTagId(tag.id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <div className="mt-3">
        <UpdatedPagination
          itemsPerPage={40}
          total={state.total}
          action={handleParams}
        />
      </div>

      <ConfirmationAlert
        show={showAlert}
        handleConfirm={handleDelete}
        id={selectedTagId}
        handleShow={handleCloseAlert}
        contentType={"tag"}
      />
    </div>
  );
};

export default TagTable;
