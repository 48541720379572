import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";

import { getKeywordList } from "./keywordList.service";

import LoadMoreIcon from "../../../../assets/loadMore";

import "./style.scss";
import { KeywordDetails } from "../../../Dashboard/components";
import { getDateTime_12T2D3M2Y } from "../../../../const/dateFormat";

const getTimeRange = () => {
  const currentTimeDate = moment(new Date()).toISOString();
  const prevTimeDate = moment(
    moment(currentTimeDate).startOf("day")
  ).toISOString();

  return {
    startDate: prevTimeDate,
    endDate: currentTimeDate,
  };
};

const KeywordList = ({ activeStatus }) => {
  const getQueryString = (data) => {
    const dateRange = getTimeRange(activeStatus);
    let queryString = `?keys=${data.keyword}&start=${dateRange.startDate}&end=${dateRange.endDate}`;

    return queryString;
  };
  const [keywords, setKeywords] = useState([]);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    page: -1,
    size: 10,
  });

  const [activeKeyword, setActiveKeyword] = useState("");
  const handleActiveKeyword = (keyword) => {
    keyword === activeKeyword
      ? setActiveKeyword("")
      : setActiveKeyword(keyword);
  };
  const updateParams = (key, value) => {
    setParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  const loadMore = () => {
    setOnGoingActions("LOADMORE");
    fetchKeyword("LOADMORE");
  };

  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const { _keywords, _total } = data;

    if (actionType === "LOADMORE") {
      setKeywords((prev) => [...prev, ..._keywords]);
      setTotalKeywords(_total);
      updateParams("page", params.page + 1);
    }
  };

  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return params.page + 1;
    } else {
      return 1;
    }
  };

  const fetchKeyword = (actionType) => {
    setLoading(true);
    getKeywordList(
      {
        ...params,
        page: getEffectiveParamsPage(actionType),
      },
      (_status, data) => {
        if (_status === "success") {
          if (data && data.keywordCounts.length > 0) {
            actionHandler(actionType, {
              _keywords: data.keywordCounts,
              _total: data.total,
            });
          }
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    loadMore("LOADMORE");
  }, []);

  return (
    <div className="d-flex flex-column justify-content-between gap-4">
      <div className="d-flex flex-row justify-content-between w-100 flex-wrap">
        <div className=" text-light  font-w-bold">Last Updated At</div>
        <div className=" text-light  font-w-bold">
          {getDateTime_12T2D3M2Y(keywords.lastUpdatedAt)}
        </div>
      </div>
      <div className="overflow-auto">
        <table className="table--keyword w-100">
          <thead>
            <tr className="text-light font-s-sm font-w-bolder text-uppercase bg-black__light shadow">
              <th className="py-2  ps-4">Keyword</th>

              <th className="py-2  ps-2">Total</th>
              <th className="py-2  ps-2">TV</th>
              <th className="py-2  ps-2">News</th>
              <th className="py-2  pe-4 float-end">Action</th>
            </tr>
          </thead>

          <tbody className="rounded-5">
            {keywords.map((alert) => (
              <>
                <tr
                  className={`${
                    activeKeyword === alert.keyword ? "tr--active" : ""
                  }`}
                >
                  <td className="text-white font-s-n ps-4 font-w-bolder">
                    <div
                      onClick={() => handleActiveKeyword(alert.keyword)}
                      className={`${alert.severity === 1 && "text-success"} 
                      ${alert.severity === 2 && "text-warning"} 
                      ${alert.severity === 3 && "text-danger"} 
                      d-flex justify-content-left text-nowrap`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {alert.keyword}
                    </div>
                  </td>
                  <td className="text-white font-s-n font-w-bolder">
                    {alert.total}
                  </td>
                  <td className="text-white font-s-n font-w-bolder">
                    {alert.tvTotal}
                  </td>
                  <td className="text-white font-s-n font-w-bolder">
                    {alert.newspaperTotal}
                  </td>
                  <td className="text-white font-s-n font-w-bolder pe-4">
                    <div className="d-flex justify-content-end">
                      <Link
                        to={`/news${getQueryString(alert)}#auto-play`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-s-n font-w-bolder"
                      >
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
                {activeKeyword === alert.keyword && (
                  <tr>
                    <td colSpan={5}>
                      <KeywordDetails
                        keyword={activeKeyword}
                        severity={alert.severity}
                      />
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-center">
        {keywords && keywords.length && keywords.length < totalKeywords ? (
          <div className="d-flex justify-content-center">
            <button
              onClick={() => loadMore()}
              className="btn btn-sm btn-primary px-4 py-2 rounded-4 font-s-n"
              disabled={loading ? "true" : ""}
            >
              <div className="px-3 py-2 font-w-bolder">
                {onGoingAction === "LOADMORE" ? <LoadMoreIcon /> : `Load More`}
              </div>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { KeywordList };
