import { getEnvironment } from "../../../../const/environment";
import apiService from "../../../../dataService/apiService";

export const getVideoCrop = (params, user, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-crop-service/api/${env.version}/${env.type}/video/crop`;

  const _params = {
    cropStart: params.startDate,
    cropEnd: params.endDate,
    cropTitle: params.cropTitle,
    userId: user.userId,
    sourceName: params.sourceName,
    url: params.url,
    subsChannel: user.userId,
    id: params.id,
    description: params.description,
    categoryId: params.categoryId,
    tags: params.tags,
    sentiment: params.sentiment,
  };

  apiService.post({ url, data: _params }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
