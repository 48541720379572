import _ from "lodash";

const capitalized = (words) => words.charAt(0).toUpperCase() + words.slice(1);

const smaller = (words) => (words || "...").toLocaleLowerCase();

const camelCaseToNormal = (words) => _.startCase(words);

const underscoreToNormal = (words) => words.split("_").join(" ");

const getQueryParams = (params) => {
  let queryParams = "";
  const queries = Object.keys(params);

  let index;
  for (index = 0; index < queries.length - 1; index++) {
    queryParams = `${queryParams}${queries[index]}=${params[queries[index]]}&`;
  }

  return queryParams.length > 0
    ? `?${queryParams}${queries[index]}=${params[queries[index]]}`
    : ``;
};

const getTableData = (dataInObjStructure) => {
  return {
    columns: Object.keys(dataInObjStructure[0]).map((key) =>
      key ? camelCaseToNormal(key) : ""
    ),

    rows: dataInObjStructure.map((obj) =>
      Object.keys(dataInObjStructure[0]).map((column) => obj[column])
    ),
  };
};

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const getNumberWithOrdinal = (n) => {
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
};

const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  // var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min " : " mins ") : "";
  // var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  const time = dDisplay + hDisplay + mDisplay;
  return time === "" ? "1 min" : time;
  // + sDisplay;
};

export {
  capitalized,
  smaller,
  camelCaseToNormal,
  underscoreToNormal,
  getQueryParams,
  getTableData,
  parseJwt,
  getNumberWithOrdinal,
  secondsToDhms,
};
