import React from "react";
import { getTypeIcon } from "./_typeIcon";

const NewsShortContent = ({ news, type }) => {
  return (
    <div className="d-flex gap-4 align-items-end justify-content-between">
      <span
        className="text-white font-s-md font-w-normal news-content"
        dangerouslySetInnerHTML={{
          __html: `<p style="display: inline; word-break: break-all">${
            news.highlightedContent
              ? news.highlightedContent.length >= 150
                ? news.highlightedContent + "..."
                : news.highlightedContent + "..."
              : news.content
              ? news.content.length >= 150
                ? news.content + "..."
                : news.content + "..."
              : "..."
          }</p>`,
        }}
      />

      {news.type ? <div>{getTypeIcon(type, news.type)}</div> : <></>}
    </div>
  );
};

export default NewsShortContent;
