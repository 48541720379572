import React from "react";
const status = [
  {
    label: "All News",
    value: 0,
  },
  {
    label: "News Paper",
    value: 1,
  },
  {
    label: "TV Channel",
    value: 2,
  },
];
const TopicTabs = ({ activeStatus, handleActiveStatus }) => {
  return (
    <div>
      <div className="d-flex flex-row gap-5 flex-wrap ">
        <div className="d-flex flex-row " style={{ gap: "1rem" }}>
          <div className="d-flex flex-row gap-2 flex-wrap ">
            {status.map((s) => {
              return (
                <div
                  className={`text-light font-s-md font-w-bold status ${
                    activeStatus === s.value ? "status--active" : ""
                  }`}
                  id={s.value}
                  onClick={() => handleActiveStatus(s.value)}
                >
                  {s.label}
                </div>
              );
            })}
          </div>
          );
        </div>
      </div>
    </div>
  );
};

export default TopicTabs;
