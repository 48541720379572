import { createContext, useContext, useMemo, useReducer } from "react";

import apiService from "../apiService";
import actionTypes from "../actionTypes";

import { getEnvironment } from "../../const/environment";

const NotificationContext = createContext();

const initialState = {
  notifications: [],
  page: 0,
  total: 0,
  totalSeen: 0,
  totalUnseen: 0,
  loading: false,
  error: false,
  errorMsg: "",
};

const notificationReducer = function (state, action) {
  switch (action.type) {
    case actionTypes.notificationRequest:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };

    case actionTypes.notificationSuccess:
      return {
        ...state,
        notifications: [...state.notifications, ...action.notifications],
        page: action.page,
        total: action.total,
        totalSeen: action.totalSeen,
        totalUnseen: action.totalUnseen,

        loading: false,
        errorMsg: "",
        error: false,
      };

    case actionTypes.userInfoFail:
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
        error: true,
      };

    case actionTypes.notificationAdd:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
        loading: false,
        errorMsg: action.errorMsg,
        error: true,
      };

    default:
      break;
  }
};

export function NotificationProvider(props) {
  const [state, dispatch] = useReducer(notificationReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <NotificationContext.Provider value={value} {...props} />;
}

export function useNotification() {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      "use notification must be used within a notification provider"
    );
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}
const request = () => ({
  type: actionTypes.notificationRequest,
});

const success = (notification) => {
  const { notifications, page, total, totalSeen, totalUnseen } = notification;

  return {
    type: actionTypes.notificationSuccess,
    notifications,
    page,
    total,
    totalSeen,
    totalUnseen,
  };
};

const fail = (errorMsg) => ({
  type: actionTypes.notificationFail,
  errorMsg,
});

const addIntoNotification = (notification) => ({
  type: actionTypes.notificationAdd,
  notification,
});
export const getNotification = (dispatch, params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/v1/private/notification?page=${params.page}&size=${params.size}`;

  dispatch(request());

  apiService.get(url, (status, data) => {
    if (status > 199 && status < 300) {
      dispatch(success(data));
      done("success", data);
    } else {
      dispatch(fail(data));
      done("error", data);
    }
  });
};

export const updateNotification = (dispatch, id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/v1/private/notification/${id}`;
  dispatch(request());

  apiService.put({ url }, (status, data) => {
    if (status > 199 && status < 300) {
      // dispatch(success(data));
      done("success", data);
    } else {
      // dispatch(fail(data));
      done("error", data);
    }
  });
};
export const pushIntoNotification = (
  dispatch,
  notification,
  done = () => {}
) => {
  dispatch(addIntoNotification(notification));
  done("success");
};
