import React from "react";
import Select from "react-select";

import { useNewsSources, useUser } from "../../../../dataService/contexts";

import { getAllChannel, getBySourceType } from "../../../../const/newsSources";

import "./styles.scss";

const ChannelSearch = ({ handleChannel, currentChannel }) => {
  const { state: userState } = useUser();
  const { user } = userState || {};
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const permittedChannelList = (channels) => {
    const channelCodes =
      !!user && !!user.availableTvChannels && user.availableTvChannels?.length
        ? user.availableTvChannels.map((tc) => tc.code)
        : [];

    if (channelCodes.length) {
      const permittedChannels = channels.filter((c) =>
        channelCodes.includes(`${c.code}`)
      );

      return permittedChannels;
    } else return getBySourceType(2, newsSources);
  };

  const channelList = permittedChannelList(newsSources);

  return (
    <Select
      isSearchable={false}
      options={channelList}
      className="channel-select-container "
      classNamePrefix="channel-select"
      getOptionLabel={(options) => `${options.name}`}
      placeholder="Channel"
      value={currentChannel}
      onChange={(e) => {
        handleChannel(e);
      }}
    />
  );
};

export { ChannelSearch };
