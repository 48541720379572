import React, { useEffect, useRef, useState } from "react";

import { getDateTime_4Y2M2D24T } from "../../const/dateFormat";
import { useCategory } from "../../dataService/contexts";
import { Sentiment, SentimentDropdown } from "../index";
import { updateCategory } from "./category.service";
import TagsDropdown from "../Layouts/components/Navbar/components/SearchVideoCrop/TagsDropdown";
import { useTags } from "../../dataService/contexts/tags.context";

const Category = ({
  news,
  row = false,
  tagsAction,
  categoryAction,
  sentimentAction,
}) => {
  const [updatingId, setUpdatingId] = useState();
  const [initialSentiment, setInitialSentiment] = useState(0);
  const [tags, setTags] = useState([]);

  const isTagsMounted = useRef(false);
  const isSentimentMounted = useRef(false);
  const handleInitialSentiment = (_sentiment) => {
    setInitialSentiment(_sentiment ? 1 : 2);
    updateCategory(
      {
        contentId: news.contentId,
        contentType: news.type,
        title: news.title === null ? " " : news.title,
        sourceName: news.sourceName.name,
        sourceURL: news.sourceURL === null ? " " : news.sourceURL,
        categoryTypes: [{ id: news.categoryTypes[0].id }],
        newsTimeAt: getDateTime_4Y2M2D24T(news.newsTimeAt),
        sentiment: _sentiment ? 1 : 2,
        tags: tags,
      },
      (status, data) => {
        if (status === "success") {
          sentimentAction
            ? sentimentAction(data.sentiment, data.contentId)
            : console.log("No category");
        }

        setUpdatingId(null);
      }
    );
  };

  const handleTags = (key, value) => {
    setTags(value);
    updateCategory(
      {
        contentId: news.contentId,
        contentType: news.type,
        title: news.title === null ? " " : news.title,
        sourceName: news.sourceName.name,
        sourceURL: news.sourceURL === null ? " " : news.sourceURL,
        categoryTypes: [{ id: news.categoryTypes[0].id }],
        newsTimeAt: getDateTime_4Y2M2D24T(news.newsTimeAt),
        sentiment: news.sentiment,
        tags: value,
      },
      (status, data) => {
        if (status === "success")
          tagsAction
            ? tagsAction(data.tags, data.contentId)
            : console.log("No category");

        setUpdatingId(null);
      }
    );
  };

  const changeStateId = (_id) => {
    setUpdatingId(_id);

    updateCategory(
      {
        contentId: news.contentId,
        contentType: news.type,
        title: news.title === null ? " " : news.title,
        sourceName: news.sourceName.name,
        sourceURL: news.sourceURL === null ? " " : news.sourceURL,
        categoryTypes:
          Number(news?.categoryTypes[0]?.id) === Number(_id)
            ? []
            : _id
            ? [{ id: Number(_id) }]
            : [{ id: news?.categoryTypes[0]?.id }],

        newsTimeAt: getDateTime_4Y2M2D24T(news.newsTimeAt),
        sentiment: news.sentiment,
        tags: tags,
      },
      (status, data) => {
        if (status === "success")
          categoryAction
            ? categoryAction(data.categoryTypes, data.contentId)
            : console.log("No category");

        setUpdatingId(null);
      }
    );
  };

  const { state: categoryState } = useCategory();
  const { categories } = categoryState;

  useEffect(() => {
    setInitialSentiment(news.sentiment);
    if (news.tagList !== null && news.tagList.length) {
      setTags(
        news.tagList.map((tag) => ({
          id: tag.id,
          word: tag.word,
        }))
      );
    }
  }, [news.sentiment, news.tagList]);

  return categories.length ? (
    <div>
      <div
        className={`d-flex ${
          row ? "flex-row gap-5 flex-wrap" : "flex-column"
        } align-items-stretch p-1`}
      >
        {categories.map((item) => {
          return (
            <div className="form-check pb-3 d-flex gap-3 align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                value={item.id}
                id={item.id}
                checked={Number(news?.categoryTypes[0]?.id) === Number(item.id)}
                onChange={(e) => {
                  changeStateId(e.target.value);
                }}
                disabled={Number(updatingId) === Number(item.id) ? "true" : ""}
              ></input>
              <label
                className="form-check-label text-white font-s-md"
                htmlFor={item.id}
              >
                {item.categoryName}
              </label>
            </div>
          );
        })}
      </div>
      <div>
        {/*<Sentiment*/}
        {/*  currentSentiment={sentiment}*/}
        {/*  handleSentiment={handleSentiment}*/}
        {/*/>*/}
      </div>
      <div className="d-flex flex-column gap-3">
        {news.categoryTypes.length ? (
          news.sentiment && (
            <div>
              <SentimentDropdown
                currentSentiment={initialSentiment}
                handleSentiment={handleInitialSentiment}
              />
            </div>
          )
        ) : (
          <></>
        )}
        {news.categoryTypes.length ? (
          <div>
            <TagsDropdown
              handleTags={handleTags}
              currentTag={tags}
              forTags={false}
              news={news}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Category;
