import AlertNotification from "./AlertNotification";
import Analysis from "./Analysis";
import Bookmarks from "./Bookmarks";
import CaseManagement from "./CaseManagement";
import DailyReport from "./DailyReport";
import Dashboard from "./Dashboard";
import Dictionary from "./Dictionary";
import Home from "./Home";
import LiveStream from "./LiveStream";
import Login from "./Login";
import ManagerReport from "./ManagerReport";
import MarkingReportOverviewDetails from "./MarkingReportOverviewDetails";
import NewspapersNews from "./NewspapersNews";
import ReportPreview from "./ReportPreview";
import SingleNewsView from "./SingleNewsView";
import SourceWiseNews from "./SourceWiseNews";
import TVScrollNews from "./TVScrollNews";
import Tags from "./Tags";
import TopKeywordView from "./TopKeywordView";
import TopicRelatedNews from "./TopicRelatedNews";
import TrendingKeywordView from "./TrendingKeywordView";
import Video from "./Video";

import NewspaperLive from "./NewspaperLive";
import NewspaperSearch from "./NewspaperSearch";
import SingleNewspaperLive from "./SingleNewspaperLive";

import SingleTVScrollLive from "./SingleTVScrollLive";
import TVScrollLive from "./TVScrollLive";
import TVScrollSearch from "./TVScrollSearch";

import Category from "./Category";
import Icons from "./Icons";
import NewspaperReport from "./NewspaperReport";
import UniqueScrollNews from "./UniqueScrollNews";
import VideoCrop from "./VideoCrop";
import Notice from "./Notice";
import MyNotice from "./MyNotice";
import SeenNoticeList from "./SeenNoticeList";

import ReportOverview from "./ReportOverview";
import SourceTypeDropdown from "../components/SourceTypeDropdown";
import MarkingReportOverview from "./MarkingReportOverview";
import VideoCropReportOverview from "./VideoCropReportOverview";
import NewspaperReportOverviewDetails from "./NewspaperReportOverviewDetails";
import VideoCropOverviewDetails from "./VideoCropOverviewDetails";
import ManagerMarkedReport from "./ManagerMarkedReport";

import AnalyticalDashboard from "./AnalyticalDashboard";
import MinisterDashboard from "./MinisterDashboard";
import UniqueReportDashboard from "./UniqueReportDashboard";

import { SignIn } from "./Login/components";

export {
  AlertNotification,
  Analysis,
  Bookmarks,
  CaseManagement,
  Category,
  DailyReport,
  Dashboard,
  Dictionary,
  Home,
  Icons,
  LiveStream,
  Login,
  ManagerReport,
  NewspaperLive,
  NewspaperReport,
  NewspaperSearch,
  NewspapersNews,
  ReportPreview,
  SignIn,
  SingleNewsView,
  SingleNewspaperLive,
  SingleTVScrollLive,
  SourceWiseNews,
  TVScrollLive,
  TVScrollNews,
  TVScrollSearch,
  Tags,
  TopKeywordView,
  TopicRelatedNews,
  TrendingKeywordView,
  UniqueScrollNews,
  Video,
  VideoCrop,
  Notice,
  MyNotice,
  SeenNoticeList,
  ReportOverview,
  SourceTypeDropdown,
  MarkingReportOverview,
  VideoCropReportOverview,
  MarkingReportOverviewDetails,
  NewspaperReportOverviewDetails,
  VideoCropOverviewDetails,
  ManagerMarkedReport,
  AnalyticalDashboard,
  MinisterDashboard,
  UniqueReportDashboard,
};
