import React, { useEffect, useState } from "react";
import { getBySourceCode } from "../../../../const/newsSources";

import { useNewsSources } from "../../../../dataService/contexts";
import { getKeywordDetails } from "./keywordDetails.service";

const KeywordDetails = ({ keyword, severity }) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const [detailsNewspaper, setDetailsNewspaper] = useState(null);
  const [detailsTv, setDetailsTv] = useState(null);

  useEffect(() => {
    getKeywordDetails(
      {
        keywords: keyword,
        type: 1,
      },
      (status, data) => {
        if (status === "success") {
          setDetailsNewspaper(data);
        }
      }
    );

    getKeywordDetails(
      {
        keywords: keyword,
        type: 2,
      },
      (status, data) => {
        if (status === "success") {
          setDetailsTv(data);
        }
      }
    );

    return () => setDetailsNewspaper(null);
  }, [keyword]);

  return (
    <div className="pb-2">
      {detailsNewspaper && (
        <div
          className="pb-3"
          style={{
            borderBottom: "1px dashed silver",
          }}
        >
          <div className="pb-2 text-silver font-w-bolder">Newspaper</div>

          <div className=" d-flex gap-2 flex-wrap">
            {detailsNewspaper?.sourceWiseCount?.length ? (
              detailsNewspaper.sourceWiseCount.map((s) => (
                <div className="text-black border border-silver border-1 rounded-5 font-s-sm px-3 text-nowrap d-flex gap-1 align-items-center">
                  <div className="font-w-light text-silver">
                    {getBySourceCode(s.code, newsSources).name}
                  </div>
                  <span className="text-primary font-w-fat font-s-md">
                    {s.count}
                  </span>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {detailsTv && (
        <div className="pt-2">
          <div className="pb-2 text-silver font-w-bolder">TV</div>

          <div className=" d-flex gap-2 flex-wrap">
            {detailsTv?.sourceWiseCount?.length ? (
              detailsTv.sourceWiseCount.map((s) => (
                <div className="text-black border border-silver border-1 rounded-5 font-s-sm px-3 text-nowrap d-flex gap-1 align-items-center">
                  <div className="font-w-light text-silver">
                    {getBySourceCode(s.code, newsSources).name}
                  </div>
                  <span className="text-primary font-w-fat font-s-md">
                    {s.count}
                  </span>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { KeywordDetails };
