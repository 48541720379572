import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";

import moment from "moment";
// import { getDateTime_12T2D3M2Y } from "../../const/dateFormat";

import { getBySourceCode } from "../../../../../../const/newsSources";
import {
  useNewsSources,
  useUser,
} from "../../../../../../dataService/contexts";
import { MarkedNews } from "../../../../../NewsDetails/components";
import "../../../../../NewsDetails/styles.scss";
import { Playground, Sentiment, Tags } from "../../../../../index";
import { downloadUniqueNews } from "./uniqueDocDownload.service";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source && source.name ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source.code, newsSources).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source }) => {
  return source ? (
    <div className="font-s-md font-w-bold text-white">{source}</div>
  ) : (
    <></>
  );
};

const scrollTab = [
  {
    name: "Scroll 1",
    value: 1,
  },
  {
    name: "Scroll 2",
    value: 2,
  },
  {
    name: "Scroll 3",
    value: 3,
  },
  {
    name: "Scroll 4",
    value: 4,
  },
];

const UniqueNewsPopup = ({ handleClose, show, data }) => {
  const [scroll1, setScroll1] = useState("");
  const [scroll2, setScroll2] = useState("");
  const [scroll3, setScroll3] = useState("");
  const [scroll4, setScroll4] = useState("");

  const [currentTab, setCurrentTab] = useState(1);

  const [showPlayground, setShowPlayground] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [sentiment, setSentiment] = useState(1);
  const [state, setState] = useState({ value: [] });

  const { state: userState } = useUser();
  const { user } = userState || {};
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const newsChannelHasPermission = (filterItem) =>
    user &&
    user.availableNewsPapers.length > 0 &&
    user.availableNewsPapers.filter((i) => i.code === filterItem.code).length >
      0;

  const handlePlayground = () => {
    setShowPlayground((prev) => !prev);
  };

  const handleSelectedTag = (tag) => {
    setSelectedTag(tag);
  };

  const handleSentiment = (_sentiment) => {
    if (_sentiment) setSentiment(1);
    else setSentiment(2);
  };

  const handleAddState = (id, newMarked) => {
    setState((prev) => ({
      ...prev,
      value: [
        ...prev.value,
        {
          ...newMarked,
          id: id,
        },
      ],
    }));
  };
  const handleAddComment = (id, comment) => {
    setState((prev) => ({
      ...prev,
      value: prev.value.map((item) =>
        item.id === id
          ? {
              ...item,
              comment,
            }
          : { ...item }
      ),
    }));
  };

  const handleRemoveState = (value) => {
    setState((prev) => ({ ...prev, value }));
  };
  const handleValue = (data) => {
    setCurrentTab(data);
  };

  const handleDownload = () => {
    downloadUniqueNews(
      {
        sourceName: data.sourceName,
        startDate: data.startTime,
        endDate: data.endTime,
        contentOfFirstScroll: scroll1,
        contentOfSecondScroll: scroll2,
        contentOfThirdScroll: scroll3,
        contentOfFourthScroll: scroll4,
      },
      (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${moment(data.startDate).format("DD-MM-YYYY")}_to_${moment(
              data.endDate
            ).format("DD-MM-YYYY")}.docx`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    );
  };

  useEffect(() => {
    {
      data.data.scroll1 && setScroll1(data.data.scroll1);
    }
    {
      data.data.scroll2 && setScroll2(data.data.scroll2);
    }
    {
      data.data.scroll3 && setScroll3(data.data.scroll3);
    }
    {
      data.data.scroll4 && setScroll4(data.data.scroll4);
    }
  }, []);

  return (
    data && (
      <Modal
        size="xl"
        centered
        show={show}
        onHide={() => {
          handleClose();
        }}
        className="newspaper-details"
      >
        <Modal.Header closeButton>
          <div className="d-flex flex-row gap-5">
            <div className="d-flex flex-wrap flex-row gap-5">
              <div className="d-flex gap-3 align-items-center">
                <NewsSourceIcon
                  source={data.sourceName}
                  newsSources={newsSources}
                />
                <div>
                  <div>
                    <NewsSourceName source={data.sourceName} />
                  </div>
                  {/*<div className="text-light font-s-nm text-nowrap font-w-bolder">*/}
                  {/*  {getDateTime_12T2D3M2Y(data.newsTimeAt)}*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-row gap-2 justify-content-end">
              {scrollTab.map((option) => (
                <div key={option.name}>
                  <button
                    disabled={Number(currentTab) === Number(option.value)}
                    className={`btn btn-sm ${
                      Number(currentTab) === Number(option.value)
                        ? "text-info"
                        : "text-white"
                    } font-s-md font-w-bolder ${
                      Number(currentTab) === Number(option.value)
                        ? ""
                        : "border border-1"
                    } px-4 py-2 rounded-5`}
                    style={{ padding: 0 }}
                    onClick={() => handleValue(option.value)}
                    type="button"
                  >
                    {option.name}
                  </button>
                </div>
              ))}
            </div>
            {currentTab === 1 && (
              <div
                className="font-w-normal font-s-md text-white"
                style={{ letterSpacing: "1.25px" }}
              >
                {scroll1}
              </div>
            )}
            {currentTab === 2 && (
              <div
                className="font-w-normal font-s-md text-white"
                style={{ letterSpacing: "1.25px" }}
              >
                {scroll2}
              </div>
            )}
            {currentTab === 3 && (
              <div
                className="font-w-normal font-s-md text-white"
                style={{ letterSpacing: "1.25px" }}
              >
                {scroll3}
              </div>
            )}
            {currentTab === 4 && (
              <div
                className="font-w-normal font-s-md text-white"
                style={{ letterSpacing: "1.25px" }}
              >
                {scroll4}
              </div>
            )}
            <div className="d-flex justify-content-end ">
              <button
                className="btn btn-primary font-s-md font-w-bolder px-3 py-2 "
                onClick={() => {
                  handleDownload();
                }}
              >
                download
              </button>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex flex-column gap-4">
            {showPlayground && (
              <div className="d-flex flex-column">
                <div className="d-flex flex-wrap justify-content-end gap-4 align-items-center">
                  <div>
                    <Tags
                      selectedTag={selectedTag}
                      handleSelectedTag={handleSelectedTag}
                      warning={false}
                    />
                  </div>
                  {selectedTag ? (
                    <div>
                      <Sentiment
                        currentSentiment={sentiment}
                        handleSentiment={handleSentiment}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            {showPlayground && data ? (
              selectedTag ? (
                <Playground
                  tag={selectedTag.word}
                  content={
                    (currentTab === 1 && scroll1) ||
                    (currentTab === 2 && scroll2) ||
                    (currentTab === 3 && scroll3) ||
                    (currentTab === 4 && scroll4)
                  }
                  news={
                    (currentTab === 1 && scroll1) ||
                    (currentTab === 2 && scroll2) ||
                    (currentTab === 3 && scroll3) ||
                    (currentTab === 4 && scroll4)
                  }
                  state={state}
                  addState={handleAddState}
                  removeState={handleRemoveState}
                  sentiment={sentiment}
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    minWidth: "50%",
                  }}
                >
                  <div className="w-100 alert alert-danger text-nowrap d-flex align-items-center justify-content-center">
                    Please Select a Tag
                  </div>
                </div>
              )
            ) : (
              <></>
            )}

            {state.value.length ? (
              <div className="mt-5 d-flex flex-wrap gap-4">
                {state.value.map((item) => {
                  return (
                    <MarkedNews item={item} addComment={handleAddComment} />
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default UniqueNewsPopup;
