// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stat-container {
  background-color: #242731;
  border: 1px solid #33353b;
  color: #808191;
  font-size: 12px;
  line-height: 16px;
}

.progressbar-neutral {
  --bs-progress-bar-bg: #ff9f38 !important;
  --bs-progress-height: 0.125rem !important;
}

.progressbar-positive {
  --bs-progress-bar-bg: #3dbaa2 !important;
  --bs-progress-height: 0.125rem !important;
}

.progressbar-negative {
  --bs-progress-bar-bg: #ff7a68 !important;
  --bs-progress-height: 0.125rem !important;
}

.icon-neutral {
  background-color: #ff9f38 !important;
}

.icon-positive {
  background-color: #3dbaa2 !important;
}

.icon-negative {
  background-color: #ff7a68 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/MinisterDashboard/components/Stat/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,yBAAA;EAEA,cAAA;EACA,eAAA;EACA,iBAAA;AAAF;;AAGA;EACE,wCAAA;EACA,yCAAA;AAAF;;AAGA;EACE,wCAAA;EACA,yCAAA;AAAF;;AAGA;EACE,wCAAA;EACA,yCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF;;AAGA;EACE,oCAAA;AAAF","sourcesContent":[".stat-container {\n  background-color: #242731;\n  border: 1px solid #33353b;\n\n  color: #808191;\n  font-size: 12px;\n  line-height: 16px;\n}\n\n.progressbar-neutral {\n  --bs-progress-bar-bg: #ff9f38 !important;\n  --bs-progress-height: 0.125rem !important;\n}\n\n.progressbar-positive {\n  --bs-progress-bar-bg: #3dbaa2 !important;\n  --bs-progress-height: 0.125rem !important;\n}\n\n.progressbar-negative {\n  --bs-progress-bar-bg: #ff7a68 !important;\n  --bs-progress-height: 0.125rem !important;\n}\n\n.icon-neutral {\n  background-color: #ff9f38 !important;\n}\n\n.icon-positive {\n  background-color: #3dbaa2 !important;\n}\n\n.icon-negative {\n  background-color: #ff7a68 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
