// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-toggle .react-toggle-track {
  background-color: red !important;
}
.react-toggle .react-toggle-track:hover {
  background-color: rgb(141, 0, 0) !important;
}

.react-toggle--checked .react-toggle-track {
  background-color: green !important;
}
.react-toggle--checked .react-toggle-track:hover {
  background-color: rgb(0, 95, 0) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/SingleNewspaperLive/components/Category/styles.scss"],"names":[],"mappings":"AACI;EACI,gCAAA;AAAR;AAEQ;EACI,2CAAA;AAAZ;;AAMI;EACI,kCAAA;AAHR;AAKQ;EACI,0CAAA;AAHZ","sourcesContent":[".react-toggle {\n    .react-toggle-track {\n        background-color: red !important;\n\n        &:hover {\n            background-color: rgb(141, 0, 0) !important;\n        }\n    }\n}\n\n.react-toggle--checked {\n    .react-toggle-track {\n        background-color: green !important;\n\n        &:hover {\n            background-color: rgb(0, 95, 0) !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
