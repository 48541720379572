import React from "react";

import Skeleton from "react-loading-skeleton";

const CaseManagementLoading = ({ size }) => {
  const background = "#4e4d511a";
  const effect = "#3f3f3f";
  return (
    <div className="d-flex flex-column gap-5">
      <table className="p-4  table--topicManagement w-100 ">
        <thead>
          <tr className="text-light font-s-n font-w-bolder text-uppercase ">
            <th style={{ width: "15%" }}>NAME</th>
            <th style={{ width: "15%" }}>CREATED BY</th>
            <th style={{ width: "50%" }}>Details</th>
            <th style={{ width: "20%" }}>ACTION</th>
          </tr>
        </thead>
        <tbody className="rounded-5 ">
          {Array(size)
            .fill(0)
            .map((item) => (
              <tr>
                <td
                  className="text-white font-s-n font-w-bolder"
                  style={{ width: "15%" }}
                >
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
                <td className="" style={{ width: "15%" }}>
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder  ">
                  <div className="p-2">
                    <Skeleton
                      baseColor={background}
                      duration={4}
                      highlightColor={effect}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CaseManagementLoading;
