import React, { useEffect, useState } from "react";
import moment from "moment";

import { DateTime } from "../../../../components";

import LoadMoreIcon from "./../../../../assets/loadMore";

const DateRange = ({ onChange, loading, params }) => {
  const [state, setState] = useState({
    startDate: params.startDate,
    endDate: params.endDate,
    sourceName: "",
  });
  const handleState = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: date,
      };
    });
  };

  const handleSubmit = () => {
    onChange(state);
  };

  useEffect(() => {
    if (params.startDate && params.endDate)
      setState({ startDate: params.startDate, endDate: params.endDate });
  }, [params]);
  return (
    <div className="d-flex flex-row gap-4 flex-wrap">
      <div style={{ minWidth: "100px" }}>
        <DateTime
          placeholder={"From"}
          date={moment(state.startDate).format("YYYY-MM-DD, hh:mm A")}
          handleDateWithTime={(e) => {
            handleState("startDate", e);
          }}
          hasTime={true}
        />
      </div>
      <div style={{ minWidth: "100px" }}>
        <DateTime
          placeholder={"To"}
          date={moment(state.endDate).format("YYYY-MM-DD, hh:mm A")}
          handleDateWithTime={(e) => {
            handleState("endDate", e);
          }}
          hasTime={true}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary px-4 py-3 rounded-4"
        onClick={() => handleSubmit()}
        disabled={loading}
      >
        <div className="px-3 font-w-bolder font-s-md">
          {loading ? <LoadMoreIcon /> : "Search"}
        </div>
      </button>
    </div>
  );
};

export default DateRange;
