import { getEnvironment } from "../../../../../../const/environment";
import apiService from "../../../../../../dataService/apiService";

export const getTags = (done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/tag-words/all?size=100000`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },
    {}
  );
};
