import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getBySourceCode } from "../../../../../../const/newsSources";
import { useNewsSources } from "../../../../../../dataService/contexts";

const ScheduleCropSearch = ({ params, searchState, playSearchVideo }) => {
  const { channelName: channel } = params;
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const handleOnClick = (link) => {
    playSearchVideo();
  };

  return (
    <div
      style={{
        maxWidth: "1060px",
      }}
    >
      <div className="p-1">
        <div
          className="d-flex flex-wrap flex-sm-row flex-column  gap-4 justify-content-between  p-2 rounded-4 border-bottom-light border border-secondary border-2"
          style={{
            backgroundColor: "#222c3d",
            borderColor: "#3f8cff",
          }}
        >
          <div className="d-flex gap-4">
            <img
              alt="newspaper-logo"
              src={getBySourceCode(params.sourceName, newsSources).icon}
              style={{
                height: "3rem",
                width: "3rem",
              }}
            />

            <div className="text-white d-flex align-self-center font-s-md font-w-bold text-uppercase">
              {getBySourceCode(params.sourceName, newsSources).name}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="font-s-sm font-w-bold" style={{ color: "#454c5d" }}>
              Title
            </div>
            <div className="text-white font-s-md font-w-bold text-nowrap">
              {params.cropTitle}
            </div>
          </div>
          <div className="d-flex gap-4">
            <div className="d-flex flex-column">
              <div
                className="font-s-sm font-w-bold"
                style={{ color: "#454c5d" }}
              >
                From
              </div>
              <div className="text-white font-s-md font-w-bold text-nowrap">
                {params.startDate}
              </div>
            </div>

            <div className="d-flex flex-column">
              <div
                className="font-s-sm font-w-bold"
                style={{ color: "#454c5d" }}
              >
                To
              </div>
              <div className="text-white font-s-md font-w-bold text-nowrap">
                {params.endDate}
              </div>
            </div>
          </div>

          <div className="d-flex gap-4">
            <div className="d-flex align-items-center">
              {searchState === "ON_GOING" && (
                <div
                  className="font-s-md  font-w-bold d-flex align-self-center p-2 rounded-5 text-warning  justify-content-center"
                  style={{ backgroundColor: "#ae904552" }}
                >
                  <div className="px-1" style={{ color: "#a16207" }}>
                    Ongoing
                  </div>
                </div>
              )}
              {searchState === "CLICK_TO_PLAY" && (
                <div
                  className="font-s-md font-w-bold d-flex align-self-center p-2 rounded-5 text-success justify-content-center"
                  style={{ backgroundColor: "#294244", cursor: "pointer" }}
                  onClick={() => playSearchVideo(params.url)}
                >
                  <div className="px-1">Click To play</div>
                </div>
              )}
              {searchState === "ERROR" && (
                <div
                  className="font-s-md font-w-bold d-flex align-self-center p-2 rounded-5 text-danger justify-content-center"
                  style={{ backgroundColor: "#7f1d1d94" }}
                >
                  <div className="px-1">Not Found</div>
                </div>
              )}
            </div>

            <div className="d-flex align-self-center">
              <BsThreeDotsVertical color={"white"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCropSearch;
