import axios from "axios";

const filter = (body, done = () => {}) => {
    var config = {
        method: 'post',
        url: "http://10.101.3.11:30028/video-stream-service/api/v1/private/video/stream",
        headers: {
            'Content-Type': 'application/json'
        },
        data : {...body}

    };
  try{axios(config)
    .then((response,) => {
      if (response.status > 199 && response.status < 300) {
        done("success", response.data);
      } else {
        done("error", "Somthing went wrong");
      }
    })}catch (err){
  }
};

export { filter };
