import React, { useState } from "react";

import { VideoContainer, VideoMetaData } from "./components";

const Home = () => {
  const [videoLink, setVideoLink] = useState(null);
  const updateVideoLink = (links) => setVideoLink(links);

  return (
    <div className="d-flex flex-column gap-4">
      <VideoMetaData handleVideoLink={updateVideoLink} />
      <VideoContainer videoLink={videoLink} />
    </div>
  );
};

export default Home;
