import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

export const getReports = (params, user, done = () => {}) => {
  const env = getEnvironment();

  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/all`;
  const _params = {
    page: params.page,
    size: params.size,
    categoryName: params.categoryName,
  };
  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },
    {},
    { ..._params }
  );
};
