import React from "react";
import { io } from "socket.io-client";
import { getEnvironment } from "./const/environment";

const agency = "RAB";
const fullName = "Mr Tester";
const isAdmin = true;

const getSocket = (user) => {
  const env = getEnvironment();
  const socket = io(`${env.protocol}://${env.baseUrl}:${env.port}`, {
    query: `username=${user.userId} &agency=${agency}&fullName=${fullName}&isAdmin=${isAdmin}`,
    autoConnect: true,
    transports: ["websocket"],
  });

  socket.auth = { username: user.name };
  socket.connect();

  return socket;
};

export { getSocket };
