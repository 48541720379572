import React from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

import { getBySourceCode } from "../../../../const/newsSources";

import { useNewsSources } from "../../../../dataService/contexts";
import "../../../Video/components/VideoPopup/style.scss";

const VideoPlayer = ({ selectedVideo, removeSelectedVideo }) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  return (
    <Modal
      size="xl"
      centered
      show={!!selectedVideo}
      onHide={removeSelectedVideo}
      className="channel-video"
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-row align-items-center gap-3">
          {selectedVideo && (
            <div>
              <img
                alt="channel logo"
                src={
                  getBySourceCode(selectedVideo.sourceName, newsSources).icon
                }
                style={{
                  height: "3rem",
                  width: "3rem",
                  borderRadius: "12rem",
                }}
              />
            </div>
          )}
          <div className="d-flex flex-column">
            <div className="text-white text-capitalize font-s-md font-w-bold">
              {getBySourceCode(selectedVideo.sourceName, newsSources).name}
            </div>
            <div className="d-flex flex-column flex-wrap">
              <div className="font-s-nm text-light font-w-bold text-nowrap">
                {selectedVideo.start}
              </div>
              <div className="font-s-nm text-light font-w-bold text-nowrap">
                {selectedVideo.end}
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="rounded-4">
          {selectedVideo.link && (
            <ReactPlayer
              url={selectedVideo.link}
              width="100%"
              height="100%"
              controls={true}
              className="rounded-4"
              playing={true}
            />
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        {/*<div className="d-flex flex-column gap-4 w-100">*/}
        {/*  <div className="d-flex flex-row flex-wrap m-0 w-100 justify-content-end">*/}
        {/*    <button*/}
        {/*      type="button"*/}
        {/*      onClick={() => handleDownload()}*/}
        {/*      className="btn btn-primary p-0 px-4  rounded-3 d-flex align-self-center py-2 "*/}
        {/*    >*/}
        {/*      <div className="d-flex text-nowrap">Download Video</div>*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Modal.Footer>
    </Modal>
  );
};

export default VideoPlayer;
