import moment from "moment";

import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

const env = getEnvironment();

export const reportPreview = (date, done = () => {}) => {
  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/topic-management-service/api/${env.version}/${
    env.type
  }/report/manager-marked-report?date=${moment(date)
    .utc("Asia/Dhaka")
    .format()}`;

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    {}
  );
};
