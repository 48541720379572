import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNewsSources, useUser } from "../../dataService/contexts";
import { getBySourceType } from "../../const/newsSources";

const SourcesDropdown = ({ handleChannel, currentChannel, bg = true }) => {
  const { state: userState } = useUser();
  const { user } = userState || {};
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const handleChange = (e) => {
    handleChannel("sourceName", e);
  };

  return (
    <Select
      isSearchable={true}
      options={
        newsSources &&
        newsSources.length &&
        newsSources.map((item) => ({
          id: item.id,
          value: item.name,
          label: item.name,
        }))
      }
      className="channel-select-container "
      classNamePrefix={`${bg === true ? "channel-select" : "tags-select"}`}
      getOptionLabel={(options) => `${options.label}`}
      placeholder="News Sources"
      defaultValue={
        currentChannel
          ? {
              value: currentChannel,
              label: currentChannel,
            }
          : null
      }
      onChange={(e) => {
        if (e !== null) {
          handleChange(e.value);
        } else {
          handleChange("");
        }
      }}
      isClearable
    />
  );
};

export default SourcesDropdown;
