// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table--dictionary {
  border-bottom: 1px solid #33353b !important;
  background: #1f2128;
}
.table--dictionary thead {
  border-bottom: 1px solid #33353b !important;
}
.table--dictionary thead tr {
  border-bottom: 1px solid #33353b !important;
}
.table--dictionary thead tr th {
  border-bottom: 1px solid #33353b !important;
  border-top: 1px solid #33353b !important;
  padding: 0.75rem !important;
}
.table--dictionary tbody tr td {
  padding: 0.75rem !important;
}

.tbl {
  background: #1f2128 !important;
}

.Link {
  text-decoration: underline !important;
  line-height: 4px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Notice/components/NoticeTable/style.scss"],"names":[],"mappings":"AAEE;EACE,2CAAA;EACA,mBAAA;AADJ;AAII;EACE,2CAAA;AAFN;AAIM;EACE,2CAAA;AAFR;AAGQ;EACE,2CAAA;EACA,wCAAA;EACA,2BAAA;AADV;AAWQ;EACE,2BAAA;AATV;;AAeA;EACE,8BAAA;AAZF;;AAeA;EACE,qCAAA;EACA,2BAAA;AAZF","sourcesContent":[".table{\n\n  &--dictionary{\n    border-bottom: 1px solid #33353b !important;\n    background: #1f2128;\n\n\n    thead{\n      border-bottom: 1px solid #33353b !important;\n\n      tr{\n        border-bottom: 1px solid #33353b !important;\n        th{\n          border-bottom: 1px solid #33353b !important;\n          border-top: 1px solid #33353b !important;\n          padding: .75rem !important;\n\n        }\n\n\n\n      }\n    }\n    tbody{\n      tr{\n        td{\n          padding: .75rem !important;\n        }\n      }\n    }\n  }}\n\n.tbl{\n  background: #1f2128 !important;\n\n}\n.Link{\n  text-decoration: underline !important;\n  line-height: 4px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
