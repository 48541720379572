import React from "react";

const Download = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66591 0H14.3349C17.7385 0 19.9999 2.42931 19.9999 5.916V14.084C19.9999 17.567 17.7318 20 14.3349 20H5.66591C2.26212 20 -9.15527e-05 17.5705 -9.15527e-05 14.084V5.916C-9.15527e-05 2.4295 2.26212 0 5.66591 0ZM14.3349 1.5H5.66591C3.11541 1.5 1.49991 3.23496 1.49991 5.916V14.084C1.49991 16.765 3.11541 18.5 5.66591 18.5H14.3349C16.879 18.5 18.4999 16.7612 18.4999 14.084V5.916C18.4999 3.23468 16.8851 1.5 14.3349 1.5ZM9.99991 5.164C10.3796 5.164 10.6934 5.44615 10.7431 5.81223L10.7499 5.914V12.2692L13.2164 9.7931C13.4821 9.52627 13.8988 9.50117 14.1928 9.7184L14.2771 9.79084C14.5439 10.0565 14.569 10.4732 14.3518 10.7672L14.2794 10.8515L10.5314 14.6155C10.2649 14.8831 9.84674 14.9075 9.55267 14.6885L9.54956 14.6858C9.5217 14.6648 9.49535 14.642 9.4707 14.6174L9.46845 14.6155L5.72045 10.8515C5.42818 10.558 5.42919 10.0831 5.72271 9.79084C5.98954 9.52514 6.40626 9.50182 6.6994 9.7203L6.78337 9.7931L9.24991 12.2706V5.914C9.24991 5.49979 9.5857 5.164 9.99991 5.164Z"
        fill="#808191"
      />
    </svg>
  );
};

export default Download;
