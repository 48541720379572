import { actionPermission } from "../../const/pagePemission";

const markingViewPermission = (user) => {
  return (
    user &&
    user.roles &&
    user.roles.length &&
    user.roles.includes(actionPermission.viewMarkingOfAllUsers)
  );
};

export { markingViewPermission };
