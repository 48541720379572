const getEnvironment = () => {

    const processEnv = process.env;
    const {
        REACT_APP_MI_PROTOCOL: protocol,
        REACT_APP_MI_BASE_URL: baseUrl,
        REACT_APP_MI_PORT: port,
        REACT_APP_MI_API_VERSION: version,
        REACT_APP_MI_API_TYPE: type
    } = processEnv;
    
    return {
        protocol,
        baseUrl,
        port,
        version,
        type
    }
}


export { getEnvironment };