import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";
import moment from "moment/moment";

const getTimerange = (activeStatus) => {
  if (activeStatus) {
    const currentDateAndTime = moment();
    const endDate = moment(currentDateAndTime).utc("asia/dhaka").toISOString();
    let prevDateAndTime;

    if (activeStatus === "date")
      prevDateAndTime = moment(currentDateAndTime)
        .startOf("day")
        .utc("asia/dhaka")
        .toISOString();
    return {
      end: endDate,
      start: prevDateAndTime,
    };
  }
};

export const getKeywordsSearch = (
  params,
  user,
  name = [],
  keywords,

  done = () => {}
) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  const { availableNewsPapers } = user || {};
  const { availableTvChannels } = user || {};
  const permittedSource = [...availableNewsPapers, ...availableTvChannels];

  const _name = name?.length
    ? name
        .filter((name) => name.checked)
        .map((name) => name.code)
        .join(",")
    : permittedSource?.length
    ? permittedSource.map((an) => an.code).join(",")
    : "";

  const _params = {
    keys: keywords,
    nextNewsPaperOffset: params.nextNewsPaperOffset,
    nextTvOffset: params.nextTvOffset,
    size: params.size,
    type: params.type,
    isKeywordSearch: params.isKeywordSearch,
    ...getTimerange("date"),
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data, { ..._params });
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
      name: _name,
    }
  );
};
