import React from "react";
import ReactPlayer from "react-player";

const VideoContainer = ({ videoLink }) => {
  return (
    <div>
      {JSON.stringify(videoLink)}
      {videoLink?.data?.map((e) => <ReactPlayer
        url={e.streamLink}
        width="50vw"
        height="50vh"
        controls={true}
      />)}
    </div>
  );
};

export default VideoContainer;
