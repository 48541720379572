import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Aside, CustomNavbar } from "./components";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";

const Layout = ({ children }) => {
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const handleToggle = () => {
    setToggled((prev) => !prev);
  };
  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };
  return (
    <main
      id="main"
      className="overflow-hidden d-flex "
      style={{
        height: "100vh",
        backgroundColor: "#1f2128",
      }}
    >
      <Aside
        handleToggle={handleToggle}
        toggle={toggled}
        handleCollapse={handleCollapse}
        collapse={collapsed}
      />

      <div className="m-0">
        <CustomNavbar handleToggle={handleToggle} />
        <section
          className={`flex-grow-1 p-4 overflow-auto ${
            collapsed
              ? "section-min-breakpoint-collapse"
              : "section-min-breakpoint"
          } `}
          style={{
            height: "calc(100vh - 6rem)",
            overflowY: "auto",
          }}
        >
          <Outlet />
        </section>
      </div>
    </main>
  );
};

export default Layout;
