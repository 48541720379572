import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { Category, Playground, Sentiment, SentimentDropdown, Tags } from "..";
import { MarkedNews } from "./components";

import { useNewsSources, useUser } from "../../dataService/contexts";
import { getVideo } from "./video.service";

import {
  getDateTime_12T2D3M2Y,
  getDateTime_4Y2M2D24T,
} from "../../const/dateFormat";
import { getBySourceCode } from "../../const/newsSources";

import NewsPaperViewsIcon from "../../assets/NewsPaperViewsIcon";

import "./styles.scss";
import TagsDropdown from "../Layouts/components/Navbar/components/SearchVideoCrop/TagsDropdown";
import { getTagList } from "../../pages/SingleNewsView/singleNewsFullView.service";
import _ from "lodash";
import { markingColors } from "../../const/markingColors";
import MultiCategoryDropdown from "../MultipleCategoryDropdown";
import CategoryDropdown from "../Layouts/components/Navbar/components/SearchVideoCrop/CategoryDropdown";
import NewspaperSourceDropdown from "../../pages/NewspaperReport/components/NewspaperSourceDropdown";

const getVideoUrlQueryString = (data) => {
  let queryString = `?channelName=${data.sourceName?.code}&startDate=${data.newsTimeAt}&endDate=${data.newsTimeAt}`;
  return queryString;
};

const NewsSourceIcon = ({ source, newsSources }) => {
  return source && source.name ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source.code, newsSources).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source, newsSources }) => {
  return source && source.name ? (
    <div className="font-s-md font-w-bold text-white">
      {getBySourceCode(source.code, newsSources).name}
    </div>
  ) : (
    <></>
  );
};

const NewsDetails = ({
  show,
  data,
  handleClose,
  keyword,
  categoryAction,
  type = null,
  sentimentAction,
  tagsAction,
}) => {
  const [loading, setLoading] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [onlyUniqueNews, setOnlyUniqueNews] = useState(false); // this is only for TV news
  const [showPlayground, setShowPlayground] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [sentiment, setSentiment] = useState(1);
  const [category, setCategory] = useState(null);

  const [state, setState] = useState({ value: [] });

  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const handleUniqueNews = () => {
    setOnlyUniqueNews((prev) => !prev);
  };

  const handlePlayground = () => {
    setShowPlayground((prev) => !prev);
  };

  const handleSelectedTag = (tag, value) => {
    setSelectedTag(value);
  };
  const handleCategory = (tag, value) => {
    setCategory([{ id: value.id, word: value.categoryName }]);
  };

  const handleSentiment = (_sentiment) => {
    if (_sentiment) setSentiment(1);
    else setSentiment(2);
  };

  const handleAddState = (id, newMarked, category) => {
    setState((prev) => ({
      ...prev,
      value: [
        ...prev.value,
        {
          ...newMarked,
          id: id,
          category: category,
        },
      ],
    }));
  };

  const handleAddComment = (id, comment) => {
    setState((prev) => ({
      ...prev,
      value: prev.value.map((item) =>
        item.id === id
          ? {
              ...item,
              comment,
            }
          : { ...item }
      ),
    }));
  };
  const handleRemoveState = (value, id) => {
    setState((prev) => {
      return {
        value: prev.value.filter((item) => item.id !== id),
      };
    });
  };

  const fetchMarkedReports = () => {
    getTagList(user, data.contentId, (status, data) => {
      if (status === "success" && data && data.reports?.length) {
        const onlyHiglightedInfo = data.reports.length
          ? _.flatten(data.reports.map((report) => report.highlights))
          : [];
        setState((prev) => ({
          ...prev,
          value: onlyHiglightedInfo.length
            ? onlyHiglightedInfo.map((item) => {
                return {
                  tag: item.tags,
                  text: item.highlightedContent,
                  start: item.highlightedStartIndex,
                  end: item.highlightedEndIndex,
                  id: item.id,
                  color: markingColors[item.sentiment],
                  comment: item.comment,
                  category:
                    item.categoryTypes &&
                    item.categoryTypes.length &&
                    item.categoryTypes.map((i) => i.categoryName).toString(),
                };
              })
            : [],
        }));
      }
    });
  };
  useEffect(() => {
    fetchMarkedReports();
  }, []);

  useEffect(() => {
    if (Number(type) === 2) {
      setLoading(true);

      const params = {
        channelName: data.sourceName.code,
        startDate: getDateTime_4Y2M2D24T(data.newsTimeAt),
        endDate: getDateTime_4Y2M2D24T(data.newsTimeAt),
        userId: user.userId,
      };

      getVideo(params, (status, data) => {
        if (status === "success") {
          setVideoLink(data.streamLink);
        }

        setLoading(false);
      });
    }

    return setVideoLink("");
  }, []);

  const [defaultValue, setDefaultValue] = useState([]);
  useEffect(() => {
    setDefaultValue([]);
    if (state.value) {
      state.value.map((item) => {
        setDefaultValue((prev) => [
          ...prev,

          {
            text: item.text,
            end: item.end,
            start: item.start,
            id: item.id,
            color: item.color,
            tag:
              item.tag && Array.isArray(item.tag)
                ? item.tag.map((i) => i.word).toString()
                : item.tag,
            category:
              category && category.length ? (
                category.map((i) => i.word).toString()
              ) : (
                <></>
              ),
          },
        ]);
      });
    }
  }, [state.value]);

  return (
    data && (
      <Modal
        size="xl"
        centered
        show={show}
        onHide={() => {
          setVideoLink("");
          handleClose();
        }}
        className="newspaper-details"
      >
        <Modal.Header closeButton>
          <div className="d-flex flex-row gap-5">
            <div className="d-flex flex-wrap flex-row gap-5">
              <div className="d-flex gap-3 align-items-center">
                <NewsSourceIcon
                  source={data.sourceName}
                  newsSources={newsSources}
                />
                <div>
                  <div>
                    <NewsSourceName
                      source={data.sourceName}
                      newsSources={newsSources}
                    />
                  </div>
                  <div className="text-light font-s-nm text-nowrap font-w-bolder">
                    {getDateTime_12T2D3M2Y(data.newsTimeAt)}
                  </div>
                </div>
              </div>
              {keyword && keyword.length > 0 && (
                <div className="d-flex flex-column gap-2">
                  <div className="font-s-sm text-light">Matched Keywords</div>
                  <div className="d-flex flex-row gap-2 ">
                    {keyword.length > 0 ? (
                      <div className="d-flex flex-row gap-2 flex-wrap">
                        {keyword.map((e) => (
                          <span
                            className="text-white font-w-bolder font-s-sm"
                            dangerouslySetInnerHTML={{
                              __html: `<p 
                        class=keyword 
                        style="display: inline;"
                      >${e || ""}</p>`,
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          {/* This is only for tv scroll */}
          {type === 2 ? (
            <div className="text-white d-flex flex-row gap-3 mb-4">
              <input
                type="checkbox"
                checked={onlyUniqueNews ? true : ""}
                onChange={handleUniqueNews}
              />
              <div className="font-s-s fornt-w-bold">Unique News</div>
            </div>
          ) : (
            <></>
          )}

          <div className="inner">
            {/* TV scroll hasn't title but newspaper has */}
            {data.title && (
              <div className="font-w-bold font-s-lg text-white mb-4">
                {data.title}
              </div>
            )}

            {/* This is only for tv scroll */}
            {type === 2 ? (
              <div className="rounded-4 mb-4 d-flex align-items-center bg-dark align-items-center">
                {videoLink && (
                  <ReactPlayer
                    url={videoLink}
                    width="100%"
                    height="50vh"
                    controls={true}
                  />
                )}
                {loading ? (
                  <div className="text-warning fornt-s-lg font-w-bolder">
                    Loading...
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {/* This is content section for tv scroll or newspaper   */}
            <div
              className="font-w-normal font-s-md text-white"
              style={{ letterSpacing: "1.25px" }}
              dangerouslySetInnerHTML={{
                __html: `${
                  onlyUniqueNews
                    ? data.elevatedContent || "..."
                    : data.content || "..."
                }`,
              }}
            />

            {/* This is for newspaper section */}
            {Number(type) === 1 ? (
              <div
                className="p-3 mt-4 rounded-4"
                style={{ background: "#2f323c" }}
              >
                <Category
                  news={data}
                  categoryAction={categoryAction}
                  sentimentAction={sentimentAction}
                  tagsAction={tagsAction}
                  row={true}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-row gap-4 flex-wrap">
              <button
                onClick={() => handlePlayground()}
                className="btn btn-success btn-sm p-0 px-3 py-2 rounded-3 font-w-bold d-flex flex-row gap-2 text-white font-s-md"
              >
                Marking
              </button>
              {data.type && data.type === 1 ? (
                <a
                  type="button"
                  target="_blank"
                  href={data.sourceURL}
                  className="btn btn-danger btn-sm p-0 px-3 py-2 rounded-3 font-w-bold d-flex flex-row gap-3 align-items-center text-white font-s-md"
                  rel="noreferrer"
                >
                  <NewsPaperViewsIcon /> View Source
                </a>
              ) : (
                <Link
                  className="btn btn-danger btn-sm p-0 px-3 py-2 rounded-3 font-w-bold d-flex flex-row gap-3 align-items-center text-white font-s-md"
                  to={`/video${getVideoUrlQueryString(data)}#auto-play`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <NewsPaperViewsIcon /> <div>View</div>
                </Link>
              )}
            </div>

            {showPlayground && (
              <div className="d-flex flex-column">
                <div className="d-flex flex-wrap justify-content-end gap-4 align-items-center">
                  <div>
                    <TagsDropdown
                      handleTags={handleSelectedTag}
                      currentTag={data}
                      bg={false}
                      forTags={true}
                    />
                    {/*<Tags*/}
                    {/*  selectedTag={selectedTag}*/}
                    {/*  handleSelectedTag={handleSelectedTag}*/}
                    {/*  warning={false}*/}
                    {/*/>*/}
                  </div>
                  <div>
                    <CategoryDropdown
                      handleCategory={handleCategory}
                      currentTag={category}
                      bg={false}
                    />
                  </div>

                  <div>
                    <SentimentDropdown
                      handleSentiment={handleSentiment}
                      currentSentiment={sentiment}
                      bg={false}
                    />
                  </div>
                </div>
              </div>
            )}
            {showPlayground && sentiment && category && data.content ? (
              selectedTag ? (
                <Playground
                  tag={selectedTag}
                  content={data.content}
                  news={data}
                  state={state}
                  addState={handleAddState}
                  removeState={handleRemoveState}
                  sentiment={sentiment}
                  defaultValue={defaultValue}
                  category={category}
                  newsSources={data.sourceName.name}
                />
              ) : (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    minWidth: "50%",
                  }}
                >
                  <div className="w-100 alert alert-danger text-nowrap d-flex align-items-center justify-content-center">
                    Please Select a Tag
                  </div>
                </div>
              )
            ) : (
              <></>
            )}
            {state.value.length ? (
              <div className="mt-5 d-flex flex-wrap gap-4">
                {state.value.map((item) => {
                  return (
                    <MarkedNews
                      item={item}
                      addComment={handleAddComment}
                      category={category}
                    />
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default NewsDetails;
