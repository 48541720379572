import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

import { SelectSourceName, Category } from "..";
import { DateTime } from "../../../../components";

const Actions = ({
  handleSearch,
  sourceType,
  handleSearchBoxParams,
  searchBoxParams,
  redirect=false
}) => {
  const handleEndDate = (date) => {
    handleSearchBoxParams("endDate", date);
  };

  const handleStartDate = (date) => {
    handleSearchBoxParams("startDate", date);
  };

  const handleSubmit = () => {
    handleSearch();
  };

  let navigate = useNavigate();

  return (
    <div className="">
      <Accordion>
        <Accordion.Item className="border-0" eventKey="0">
          <Accordion.Header className="">
            <div className="text-dark w-100 font-s-lg font-w-bolder">
              Search
            </div>
          </Accordion.Header>
          <Accordion.Body className="bg-black__deep rounded-bottom">
            <div>
              <div className="d-flex flex-column flex-wrap gap-3">
                <div className="d-flex flex-row">
                  <div
                    className="input-group flex-nowrap"
                    style={{ maxWidth: "300px" }}
                  >
                    {/* <span
                      className="input-group-text bg-black__light border-0 text-white p-3 rounded-end rounded-4"
                      id="search"
                    >
                      @
                    </span> */}
                    <input
                      type="text"
                      className="p-3 form-control bg-black__light border-0 text-white font-s-md font-w-bolder rounded-4"
                      placeholder="Keyword"
                      aria-label="search"
                      aria-describedby="search"
                      value={searchBoxParams.searchText}
                      onChange={(e) =>
                        handleSearchBoxParams("searchText", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="d-flex gap-3 flex-row flex-wrap">
                  <div style={{ minWidth: "100px" }}>
                    <DateTime
                      placeholder={"From"}
                      date={searchBoxParams.startDate}
                      handleDateWithTime={handleStartDate}
                      hasTime={true}
                    />
                  </div>

                  <div style={{ minWidth: "100px" }}>
                    <DateTime
                      handleDateWithTime={handleEndDate}
                      placeholder="To"
                      hasTime={true}
                      date={searchBoxParams.endDate}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center flex-row gap-4">
                  <div className="">
                    <Category
                      handleCategory={handleSearchBoxParams}
                      currentCategory={searchBoxParams.sourceCategory}
                    />
                  </div>
                  <div className="">
                    <SelectSourceName
                      type={sourceType}
                      handleSelect={handleSearchBoxParams}
                      currentSourceNames={searchBoxParams.sourceNames}
                      currentCategory={searchBoxParams.sourceCategory}
                    />
                  </div>
                </div>

                <div>
                <button
                    type="button"
                    onClick={() => redirect? 
                      navigate(
                        `/tvscroll-search`, 
                        {state:{
                          ...searchBoxParams
                        }}
                      ) : handleSubmit()}
                      className="btn btn-lg btn-primary px-4 py-3 font-w-bold font-s-md rounded-4 font-s-md"
                      >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Actions;
