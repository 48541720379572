import React, { useEffect, useState } from "react";

import Select from "react-select";

import "./styles.scss";
const sentiment = [
  {
    name: "Positive",
    value: true,
  },
  {
    name: "Negative",
    value: false,
  },
];
const SentimentDropdown = ({
  handleSentiment,
  currentSentiment,
  bg = true,
}) => {
  const handleSelect = (data) => {
    handleSentiment(data.value);
  };
  return (
    <Select
      isSearchable={false}
      options={sentiment}
      className={`${
        bg === true ? "source-select-containerr" : "source-select-container"
      } w-100`}
      classNamePrefix={`${bg === true ? "channel-select" : "tags-select"}`}
      getOptionLabel={(options) => `${options.name}`}
      placeholder={`${currentSentiment === 1 ? "Positive" : "Negative"}`}
      value={
        Number(currentSentiment) === 2
          ? {
              name: "Negative",
              value: false,
            }
          : {
              name: "Positive",
              value: true,
            }
      }
      onChange={(e) => {
        handleSelect(e);
      }}
    />
  );
};

export default SentimentDropdown;
