import React from "react";

import { SignIn } from "./components";

import LoginPageImage from "../../assets/loginImage.png";
import LogoIcon from "../../assets/logo.png";

import "./style.scss";

const Login = () => {
  return (
    <div
      className="d-flex vw-100 vh-100"
      style={{
        background: `radial-gradient(103.03% 103.03% at 0% 0%, #D080FF 0%, #6C5DD3 100%)`,
      }}
    >
      <div
        className="d-none d-lg-flex position-relative"
        style={{ maxWidth: "30rem" }}
      >
        <div className="d-flex flex-column gap-3 p-5">
          <div className="mb-2">
            <img
              style={{ height: "3rem", minWidth: "4rem" }}
              src={LogoIcon}
              alt="login"
            />
          </div>
          <div
            className="text-white py-3"
            style={{
              fontSize: "3.5rem",
              lineHeight: "4rem",
              fontWeight: 600,
              zIndex: 1,
            }}
          >
            National news surveillance platform
          </div>
        </div>
        <img
          src={LoginPageImage}
          alt="login-pic"
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            zIndex: 0,
            width: "110%",
          }}
        />
      </div>

      <div
        className="d-flex  flex-grow-1 align-item-center justify-content-center "
        style={{ background: "#eceff4" }}
      >
        <SignIn />
      </div>
    </div>
  );
};

export default Login;
