import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import "./style.scss";

const Stat = (props) => {
  const { totalData } = props;
  const labels = Object.keys(totalData);
  const values = Object.values(totalData);
  const total = values.reduce((prevTotal, item) => {
    return prevTotal + item;
  }, 0);

  return (
    <div className="rounded-4 p-4 stat-container flex-grow-1">
      <div className="d-flex flex-column justify-content-between h-100 gap-5">
        {labels.length ? (
          labels.map((item) => (
            <div className="d-flex flex-column gap-1">
              <div className="d-flex gap-2 align-items-center">
                <div
                  className={`icon-${
                    item === "uncategorized" ? "neutral" : item
                  } p-1 rounded-3 d-flex align-items-center justify-content-center`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.35254 1.46984C6.35254 1.0265 6.71254 0.666504 7.15587 0.666504C7.59921 0.666504 7.95921 1.0265 7.95921 1.46984C7.95921 1.91317 7.59921 2.27317 7.15587 2.27317C6.71254 2.27317 6.35254 1.91317 6.35254 1.46984ZM5.06994 4.91984L6.03328 3.67684L6.01994 3.68351C6.07328 3.61018 6.08328 3.51684 6.04661 3.43351C6.01028 3.35018 5.92994 3.29351 5.84361 3.28684C5.75328 3.27684 5.66361 3.31684 5.60994 3.39018L4.80361 4.43351L3.87994 3.70684C3.82328 3.66351 3.75661 3.64651 3.68994 3.65351C3.62361 3.66351 3.56361 3.69984 3.52328 3.75318L2.53694 5.03684L2.51661 5.06684C2.45994 5.17318 2.48661 5.30984 2.58661 5.38351C2.63328 5.41351 2.68328 5.43351 2.73994 5.43351C2.81694 5.43684 2.88994 5.39651 2.93661 5.33351L3.77328 4.25651L4.72328 4.97018L4.75328 4.98984C4.85994 5.04651 4.99328 5.02018 5.06994 4.91984ZM5.7763 1.25994C5.76297 1.34327 5.7563 1.4266 5.7563 1.50994C5.7563 2.25994 6.36297 2.86627 7.10964 2.86627C7.19297 2.86627 7.27297 2.8566 7.3563 2.84327V5.53294C7.3563 6.66327 6.68964 7.33327 5.5563 7.33327H3.0933C1.95964 7.33327 1.29297 6.66327 1.29297 5.53294V3.0666C1.29297 1.93327 1.95964 1.25994 3.0933 1.25994H5.7763Z"
                      fill="#242731"
                    />
                  </svg>
                </div>
                <span className="font-w-bold text-white">
                  {item.toUpperCase()} NEWS TOTAL
                </span>
              </div>
              <div className="h2 text-white">{totalData[item]}</div>
              <ProgressBar
                animated
                className={`progressbar-${
                  item === "uncategorized" ? "neutral" : item
                }`}
                now={(totalData[item] * 100) / total}
              />
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Stat;
