import BanglaNews24 from "./../assets/newspaper/bangla_new24_logo.png";
import BanglaTribune from "./../assets/newspaper/bangla_tribune_logo.png";
import BangladeshPost from "./../assets/newspaper/bangladesh_post_logo.png";
import BangladeshPratidin from "./../assets/newspaper/bangladesh_pratidin_logo.png";
import BdNews24 from "./../assets/newspaper/bd_news_24.png";
import BssBangla from "./../assets/newspaper/bss_bangla_logo.png";
import Bss from "./../assets/newspaper/bss_logo.png";
import BusinessStandards from "./../assets/newspaper/business_standards.png";
import DailyStarBangla from "./../assets/newspaper/daily_star_bangla_logo.png";
import DailyStar from "./../assets/newspaper/daily_star_logo.png";
import DailySun from "./../assets/newspaper/daily_sun.png";
import DhakaPost from "./../assets/newspaper/dhaka_post_logo.png";
import DhakaTribuneBangla from "./../assets/newspaper/dhaka_tribune_bangla_logo.png";
import DhakaTribune from "./../assets/newspaper/dhaka_tribune_logo.png";
import EkusheyTvBangla from "./../assets/newspaper/ekushey_tv_bangla_logo.png";
import FinancialExpress from "./../assets/newspaper/financial_express_logo.png";
import Ittefaq from "./../assets/newspaper/ittefaq.png";
import JagoNews24 from "./../assets/newspaper/jago_news24_logo.png";
import Jugantor from "./../assets/newspaper/jugantor_logo.png";
import Kalbela from "./../assets/newspaper/kalbela_logo.png";
import NayaDigantaBangla from "./../assets/newspaper/naya_diganta_bangla_logo.png";
import {
  default as AsianAge,
  default as NewAge,
} from "./../assets/newspaper/new_age.png";
import ProthomAlo from "./../assets/newspaper/prothom_alo.png";
import Samakal from "./../assets/newspaper/samakal_logo.png";
import SaraBangla from "./../assets/newspaper/sara_bangla.png";
import UnitedNewsOfBangladesh from "./../assets/newspaper/united_news_of_bangladesh.png";
import AsianTv from "./../assets/tvChannel/asian_tv.png";
import AtnBangla from "./../assets/tvChannel/atn_bangla_logo.png";
import AtnNews from "./../assets/tvChannel/atn_news_logo.png";
import Btv from "./../assets/tvChannel/b_tv_logo.png";
import BanglaTv from "./../assets/tvChannel/bangla_tv.png";
import BanglaVision from "./../assets/tvChannel/banglavision_tv_logo.png";
import BijoyTv from "./../assets/tvChannel/bijoy_tv.png";
import BoishakhiTv from "./../assets/tvChannel/boishakhi_tv_logo.png";
import BtvWorld from "./../assets/tvChannel/btv_world_tv_logo.png";
import Channel24 from "./../assets/tvChannel/channel24_tv_logo.png";
import ChannelI from "./../assets/tvChannel/channel_i_logo.png";
import DbcNews from "./../assets/tvChannel/dbc_news_logo.png";
import DeshTv from "./../assets/tvChannel/desh_tv_logo.png";
import DiptoTv from "./../assets/tvChannel/dipto_tv_logo.png";
import EkattorTv from "./../assets/tvChannel/ekattor_tv_logo.png";
import EkhonTv from "./../assets/tvChannel/ekhon_tv.png";
import GlobalTv from "./../assets/tvChannel/global_tv.png";
import IndependentTv from "./../assets/tvChannel/independent_tv_logo.png";
import JamunaTv from "./../assets/tvChannel/jamuna_tv_logo.png";
import MasrangaTv from "./../assets/tvChannel/masranga_tv_logo.png";
import Ntv from "./../assets/tvChannel/n_tv_logo.png";
import NagorikTv from "./../assets/tvChannel/nagorik_tv.png";
import News24 from "./../assets/tvChannel/news24_logo.png";
import Rtv from "./../assets/tvChannel/rtv.png";
import SaTv from "./../assets/tvChannel/sa_tv_logo.png";
import ShomoyTV from "./../assets/tvChannel/shomoy_tv_logo.png";

import Default from "./../assets/newspaper/default.png";

const newsSources = {
  kalbela: {
    name: "Kalbela",
    icon: Kalbela,
    type: 1,
  },
  samakal: {
    name: "Samakal",
    icon: Samakal,
    type: 1,
  },
  daily_star_bangla: {
    name: "Daily Star - Bangla",
    icon: DailyStarBangla,
    type: 1,
  },
  jago_news_24: {
    name: "Jago News24",
    icon: JagoNews24,
    type: 1,
  },
  naya_diganta_bangla: {
    name: "Naya Diganta - Bangla",
    icon: NayaDigantaBangla,
    type: 1,
  },
  bangladesh_pratidin: {
    name: "Bangladesh Pratidin",
    icon: BangladeshPratidin,
    type: 1,
  },
  dhaka_post: {
    name: "Dhaka Post",
    icon: DhakaPost,
    type: 1,
  },
  bangla_news_24: {
    name: "Bangla News24",
    icon: BanglaNews24,
    type: 1,
  },
  ittefaq: {
    name: "Ittefaq",
    icon: Ittefaq,
    type: 1,
  },
  ittefaq_bangla: {
    name: "Ittefaq - Bangla",
    icon: Ittefaq,
    type: 1,
  },
  bangla_tribune: {
    name: "Bangla Tribune",
    icon: BanglaTribune,
    type: 1,
  },
  jugantor: {
    name: "Jugantor",
    icon: Jugantor,
    type: 1,
  },
  ekushey_television_bangla: {
    name: "Ekushey Television - Bangla",
    icon: EkusheyTvBangla,
    type: 1,
  },
  ekattor_tv: {
    name: "Ekattor TV",
    icon: EkattorTv,
    type: 1,
  },
  prothom_alo: {
    name: "Prothom Alo",
    icon: ProthomAlo,
    type: 1,
  },
  dhaka_tribune_bangla: {
    name: "Dhaka Tribune - Bangla",
    icon: DhakaTribuneBangla,
    type: 1,
  },

  somoy_tv: {
    name: "Somoy TV",
    icon: ShomoyTV,
    type: 1,
  },

  bss_bangla: {
    name: "BSS - Bangla",
    icon: BssBangla,
    type: 1,
  },
  bss_english: {
    name: "BSS - English",
    icon: Bss,
    type: 1,
  },
  atn_news_bangla: {
    name: "ATN Bangla",
    icon: AtnBangla,
    type: 1,
  },
  sara_bangla: {
    name: "Sara Bangla",
    icon: SaraBangla,
    type: 1,
  },
  the_business_standard_bangla: {
    name: "The Business Standard - Bangla",
    icon: BusinessStandards,
    type: 1,
  },
  theBusinessStandardEnglish: {
    name: "The Business Standard - English",
    icon: BusinessStandards,
    type: 1,
  },
  daily_sun: {
    name: "Daily Sun",
    icon: DailySun,
    type: 1,
  },
  daily_star_english: {
    name: "Daily Star - English",
    icon: DailyStar,
    type: 1,
  },
  dhaka_tribune_english: {
    name: "Dhaka Tribune - English",
    icon: DhakaTribune,
    type: 1,
  },
  new_age: {
    name: "New Age",
    icon: NewAge,
    type: 1,
  },
  united_news_of_bangladesh: {
    name: "United News Of Bangladesh",
    icon: UnitedNewsOfBangladesh,
    type: 1,
  },
  the_asian_age: {
    name: "The Asian Age",
    icon: AsianAge,
    type: 1,
  },
  bangladesh_post: {
    name: "Bangladesh Post",
    icon: BangladeshPost,
    type: 1,
  },
  the_business_standard_english: {
    name: "The Business Standard - English",
    icon: BusinessStandards,
    type: 1,
  },
  financial_express: {
    name: "Financial Express",
    icon: FinancialExpress,
    type: 1,
  },
  r_tv: {
    name: "RTV",
    icon: Rtv,
    type: 2,
  },
  banglatribune: {
    name: "Bangla Tribune",
    icon: BanglaTribune,
    type: 2,
  },
  bdnews24: {
    name: "Bd News 24",
    icon: BdNews24,
    type: 2,
  },
  bijoy_tv: {
    name: "Bijoy TV",
    icon: BijoyTv,
    type: 2,
  },
  ekhonTv: {
    name: "Ekhon TV",
    icon: EkhonTv,
    type: 2,
  },
  channelI: {
    name: "Channel I",
    icon: ChannelI,
    type: 2,
  },
  nagorik_tv: {
    name: "Nagorik TV",
    icon: NagorikTv,
    type: 2,
  },
  rtv: {
    name: "RTV",
    icon: Rtv,
    type: 2,
  },
  banglaTv: {
    name: "Bangla TV",
    icon: BanglaTv,
    type: 2,
  },
  globalTv: {
    name: "Global TV",
    icon: GlobalTv,
    type: 2,
  },
  asianTv: {
    name: "Asian TV",
    icon: AsianTv,
    type: 2,
  },
  independent: {
    name: "Independent TV",
    icon: IndependentTv,
    type: 2,
  },
  dbcNews: {
    name: "DBC NEWS",
    icon: DbcNews,
    type: 2,
  },
  news24: {
    name: "NEWS 24",
    icon: News24,
    type: 2,
  },
  banglaTribune: {
    name: "Bangla Tribune",
    icon: BanglaTribune,
    type: 1,
  },
  ekattorTv: {
    name: "Ekattor TV",
    icon: EkattorTv,
    type: 2,
  },

  bangladeshPost: {
    name: "Bangladesh Post",
    icon: BangladeshPost,
    type: 1,
  },
  banglaNews24: {
    name: "Bangla News24",
    icon: BanglaNews24,
    type: 1,
  },
  financialExpress: {
    name: "Financial Express",
    icon: FinancialExpress,
    type: 1,
  },
  bssEnglish: {
    name: "BSS - English",
    icon: Bss,
    type: 1,
  },
  dailyStarEnglish: {
    name: "Daily Star - English",
    icon: DailyStar,
    type: 1,
  },
  dailyStarBangla: {
    name: "Daily Star - Bangla",
    icon: DailyStarBangla,
    type: 1,
  },
  jagoNews24: {
    name: "Jago News24",
    icon: JagoNews24,
    type: 1,
  },
  nayaDigantaBangla: {
    name: "Naya Diganta - Bangla",
    icon: NayaDigantaBangla,
    type: 1,
  },
  bangladeshPratidin: {
    name: "Bangladesh Pratidin",
    icon: BangladeshPratidin,
    type: 1,
  },
  dhakaPost: {
    name: "Dhaka Post",
    icon: DhakaPost,
    type: 1,
  },
  ittefaqBangla: {
    name: "Ittefaq - Bangla",
    icon: Ittefaq,
    type: 1,
  },
  ekusheyTelevisionBangla: {
    name: "Ekushey Television - Bangla",
    icon: EkusheyTvBangla,
    type: 1,
  },
  prothomAlo: {
    name: "Prothom Alo",
    icon: ProthomAlo,
    type: 1,
  },
  dhakaTribuneBangla: {
    name: "Dhaka Tribune - Bangla",
    icon: DhakaTribuneBangla,
    type: 1,
  },

  somoyTv: {
    name: "Shomoy TV",
    icon: ShomoyTV,
    type: 1,
  },
  bssBangla: {
    name: "BSS - Bangla",
    icon: BssBangla,
    type: 1,
  },
  atnNewsBangla: {
    name: "ATN Bangla",
    icon: AtnBangla,
    type: 1,
  },
  atnBangla: {
    name: "ATN Bangla",
    icon: AtnBangla,
    type: 2,
  },
  saraBangla: {
    name: "Sara Bangla",
    icon: SaraBangla,
    type: 1,
  },
  theBusinessStandardBangla: {
    name: "The Business Standard - Bangla",
    icon: BusinessStandards,
    type: 1,
  },
  dailySun: {
    name: "Daily Sun",
    icon: DailySun,
    type: 1,
  },
  dhakaTribuneEnglish: {
    name: "Dhaka Tribune - English",
    icon: DhakaTribune,
    type: 1,
  },
  newAge: {
    name: "New Age",
    icon: NewAge,
    type: 1,
  },
  unitedNewsOfBangladesh: {
    name: "United News Of Bangladesh",
    icon: UnitedNewsOfBangladesh,
    type: 1,
  },
  theAsianAge: {
    name: "The Asian Age",
    icon: AsianAge,
    type: 1,
  },
  jamunaTv: {
    name: "Jamuna TV",
    icon: JamunaTv,
    type: 2,
  },
  atnNews: {
    name: "ATN News",
    icon: AtnNews,
    type: 2,
  },
  channel24: {
    name: "Channel 24",
    icon: Channel24,
    type: 2,
  },
  saTv: {
    name: "SA.TV",
    icon: SaTv,
    type: 2,
  },
  boishakhiTv: {
    name: "Boishakhi TV",
    icon: BoishakhiTv,
    type: 2,
  },
  boishakiTv: {
    name: "Boishakhi TV",
    icon: BoishakhiTv,
    type: 2,
  },
  btvWorld: {
    name: "BTV World",
    icon: BtvWorld,
    type: 2,
  },
  deshTv: {
    name: "Desh TV",
    icon: DeshTv,
    type: 2,
  },
  banglavision: {
    name: "Bangla Vision",
    icon: BanglaVision,
    type: 2,
  },
  banglaVision: {
    name: "Bangla Vision",
    icon: BanglaVision,
    type: 2,
  },
  masrangaTv: {
    name: "Masranga TV",
    icon: MasrangaTv,
    type: 2,
  },
  diptoTv: {
    name: "Dipto TV",
    icon: DiptoTv,
    type: 2,
  },
  ntv: {
    name: "NTV",
    icon: Ntv,
    type: 2,
  },
  btv: {
    name: "BTV",
    icon: Btv,
    type: 2,
  },
  bTv: {
    name: "BTV",
    icon: Btv,
    type: 2,
  },
  satv: {
    name: "SATV",
    icon: SaTv,
    type: 2,
  },
  channeli: {
    name: "Channel I",
    icon: ChannelI,
    type: 2,
  },
  shomoyTv: {
    name: "Shomoy TV",
    icon: ShomoyTV,
    type: 2,
  },
  independentTv: {
    name: "Independent Tv",
    icon: IndependentTv,
    type: 2,
  },
  nTv: {
    name: "NTV",
    icon: Ntv,
    type: 2,
  },
};

const arrayFormat_newsSources = Object.keys(newsSources).map((source) => ({
  ...newsSources[source],
  code: source,
}));

const getByCode = (code) => {
  return newsSources[code]
    ? {
        ...newsSources[code],
        code,
      }
    : {
        name: "Unknown",
        icon: Default,
        type: 0,
      };
};

const getBySourceType = (type, sources) => {
  return sources && sources.filter((source) => source.type === type);
};
const getBySourceCode = (code, sources) => {
  if (sources && sources.find((source) => source.code === code)) {
    return sources.find((source) => source.code === code);
  } else {
    return {
      name: "Unknown",
      icon: Default,
      code,
      type: 0,
    };
  }
};

const getBySourceName = (name, sources) => {
  if (
    sources &&
    sources.find((source) => source.name === name || source.code === name)
  ) {
    return sources.find(
      (source) => source.name === name || source.code === name
    );
  } else {
    return {
      name: "Unknown",
      icon: Default,
      type: 0,
    };
  }
};

const getAllChannel = () => {
  return arrayFormat_newsSources.filter((source) => source.type === 2);
};

const getAllNewspaper = () => {
  return arrayFormat_newsSources.filter((source) => source.type === 1);
};

const getAllSource = () => {
  return [...arrayFormat_newsSources];
};

export {
  getAllChannel,
  getAllNewspaper,
  getAllSource,
  getByCode,
  getBySourceCode,
  getBySourceType,
  getBySourceName,
};
