import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  BookmarkButton,
  NewsKeywords,
  NewsTitle,
  Playground,
  Sentiment,
  Tags,
} from "..";

import { useNewsSources, useUser } from "../../dataService/contexts";

import { getDateTime_12T2D3M2Y } from "../../const/dateFormat";
import { getBySourceCode } from "../../const/newsSources";

import NewsPaperViewsIcon from "../../assets/NewsPaperViewsIcon";

import { MarkedNews } from "./components";
import { markingPermission } from "./permission.controller";
import "./styles.scss";

const getVideoUrlQueryString = (data) => {
  let queryString = `?channelName=${data.sourceName?.code}&startDate=${data.newsTimeAt}&endDate=${data.newsTimeAt}`;
  return queryString;
};

const NewsSourceIcon = ({ source, newsSources }) => {
  return source && source.name ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source.code, newsSources).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source, newsSources }) => {
  return source && source.name ? (
    <div className="font-s-xlg font-w-bold text-white  align-self-center d-flex">
      {getBySourceCode(source.code, newsSources).name}
    </div>
  ) : (
    <></>
  );
};
const NewsFullView = ({
  news,
  keywords,
  bookmark,
  id,
  state,
  addState,
  removeState,
  addComment,
}) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const [showPlayground, setShowPlayground] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [onlyUniqueNews, setOnlyUniqueNews] = useState(false); // this is only for TV news
  const [sentiment, setSentiment] = useState(1);

  const handleSentiment = (_sentiment) => {
    if (_sentiment) setSentiment(1);
    else setSentiment(2);
  };

  const handleUniqueNews = () => {
    setOnlyUniqueNews((prev) => !prev);
  };

  const handlePlayground = () => {
    setShowPlayground((prev) => !prev);
  };

  const handleSelectedTag = (tag) => {
    setSelectedTag(tag);
  };

  return (
    <div className="d-flex flex-column  p-4 gap-4">
      <div className="d-flex flex-row gap-3">
        <NewsSourceIcon source={news.sourceName} newsSources={newsSources} />
        <div className="d-flex flex-row gap-4 justify-content-between">
          <NewsSourceName source={news.sourceName} newsSources={newsSources} />
          <BookmarkButton news={news} />
        </div>
      </div>

      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-column justify-content-between gap-2 flex-wrap">
          <NewsTitle title={news.title} />
          <div className="text-light font-s-sm text-nowrap font-w-bolder">
            {getDateTime_12T2D3M2Y(news.newsTimeAt)}
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row gap-4">
          <div className="d-flex flex-column gap-4 ">
            <div className=" text-light">Matched Keywords</div>
            <div className="custom-underline" />
            <div className="d-flex flex-column flex-md-row justify-content-between gap-4">
              <NewsKeywords keywords={keywords} />
              {showPlayground && !keywords.length && <div></div>}
              {showPlayground && (
                <div
                  className="d-flex flex-column"
                  style={{
                    maxWidth: `${showPlayground ? "50%" : "unset"}`,
                  }}
                >
                  <div className="d-flex flex-wrap justify-content-end gap-4 align-items-center">
                    <div>
                      <Tags
                        selectedTag={selectedTag}
                        handleSelectedTag={handleSelectedTag}
                        warning={false}
                      />
                    </div>
                    {selectedTag ? (
                      <div>
                        <Sentiment
                          currentSentiment={sentiment}
                          handleSentiment={handleSentiment}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>

            {news.type === 2 ? (
              <div className="text-white d-flex flex-row gap-4">
                <input
                  type="checkbox"
                  checked={onlyUniqueNews ? true : ""}
                  onChange={handleUniqueNews}
                />
                <div>Unique News</div>
              </div>
            ) : (
              <></>
            )}

            <div className="d-flex flex-column flex-md-row gap-4">
              <div className="d-flex flex-column gap-5">
                <div
                  className="font-w-normal font-s-nm text-white"
                  style={{ letterSpacing: "1px" }}
                  dangerouslySetInnerHTML={{
                    __html: `${
                      onlyUniqueNews
                        ? news.elevatedContent || "..."
                        : news.content || "..."
                    }`,
                  }}
                />
              </div>
              {showPlayground && news.content ? (
                selectedTag ? (
                  <Playground
                    tag={selectedTag.word}
                    content={news.content}
                    news={news}
                    state={state}
                    addState={addState}
                    removeState={removeState}
                    sentiment={sentiment}
                  />
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      minWidth: "50%",
                    }}
                  >
                    <div className="w-100 alert alert-danger text-nowrap d-flex align-items-center justify-content-center">
                      Please Select a Tag
                    </div>
                  </div>
                )
              ) : (
                <></>
              )}
            </div>

            <div className="d-flex flex-column gap-4">
              {state.value.length ? (
                <div className="mt-5 d-flex flex-wrap gap-4">
                  {state.value.map((item) => {
                    return <MarkedNews item={item} addComment={addComment} />;
                  })}
                </div>
              ) : (
                <></>
              )}
              <div className=" d-flex flex-row gap-4">
                {news.type && news.type === 1 ? (
                  <a
                    type="button"
                    target="_blank"
                    href={news.sourceURL}
                    className="btn btn-primary btn-sm p-0 px-3 py-2 rounded-3 font-w-bold d-flex flex-row gap-2 align-items-center"
                    rel="noreferrer"
                  >
                    <NewsPaperViewsIcon /> View
                  </a>
                ) : (
                  <Link
                    className="btn btn-primary btn-sm p-0 px-3 py-2 rounded-3 font-w-bold d-flex flex-row gap-2 align-items-center"
                    to={`/video${getVideoUrlQueryString(news)}#auto-play`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <NewsPaperViewsIcon /> <div>View</div>
                  </Link>
                )}
                {markingPermission(user) ? (
                  <button
                    className="btn  btn-sm p-0 px-3 py-2 rounded-3 font-w-bold d-flex flex-row gap-2 text-white"
                    style={{ backgroundColor: "#6c5dd3" }}
                    onClick={() => handlePlayground()}
                    disabled={news.content ? "" : true}
                  >
                    Marking Action
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsFullView;
