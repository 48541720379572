import { createContext, useContext, useMemo, useReducer } from "react";

import actionTypes from "../actionTypes";

const localStorageOngoingRequests = localStorage.getItem(
  "nnsp_ongoing_requests"
);

const OngoingRequestsContext = createContext();

const initialState = {
  ongoingRequests: localStorageOngoingRequests
    ? JSON.parse(localStorageOngoingRequests)
    : [],
};

const ongoingRequestsReducer = function (state, action) {
  switch (action.type) {
    case actionTypes.ongoingRequestsAdd:
      return {
        state,
        ongoingRequests: [...state.ongoingRequests, action.newRequest],
      };

    case actionTypes.ongoingRequestsUpdate:
      return {
        state,
        ongoingRequests: state.ongoingRequests.filter(
          (request) => request !== action.completedRequest
        ),
      };

    case actionTypes.ongoingRequestsReset:
      return {
        state,
        ongoingRequests: [...action.requests],
      };

    default:
      break;
  }
};

export function OngoingRequestsProvider(props) {
  const [state, dispatch] = useReducer(ongoingRequestsReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <OngoingRequestsContext.Provider value={value} {...props} />;
}

export function useOngoingRequests() {
  const context = useContext(OngoingRequestsContext);
  if (!context) {
    throw new Error("use ongoing must be used within a ongoing provider");
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

const add = (newRequest) => ({
  type: actionTypes.ongoingRequestsAdd,
  newRequest,
});

const update = (completedRequest) => ({
  type: actionTypes.ongoingRequestsUpdate,
  completedRequest,
});

const reset = (requests) => ({
  type: actionTypes.ongoingRequestsReset,
  requests,
});

export const addRequest = (dispatch, newRequest, done = () => {}) => {
  const _localStorageOngoingRequests = localStorage.getItem(
    "nnsp_ongoing_requests"
  );

  const _ongoingRequests = _localStorageOngoingRequests
    ? JSON.parse(_localStorageOngoingRequests)
    : [];

  localStorage.setItem(
    "nnsp_ongoing_requests",
    JSON.stringify([..._ongoingRequests, newRequest])
  );

  dispatch(add(newRequest));
  done("success", [..._ongoingRequests, newRequest]);
};

export const updateRequest = (dispatch, completedRequests, done = () => {}) => {
  const _localStorageOngoingRequests = localStorage.getItem(
    "nnsp_ongoing_requests"
  );

  const _ongoingRequests = _localStorageOngoingRequests
    ? JSON.parse(_localStorageOngoingRequests)
    : [];

  const _data = _ongoingRequests.filter(
    (request) => !request.includes(completedRequests)
  );

  localStorage.setItem("nnsp_ongoing_requests", JSON.stringify(_data));

  dispatch(reset(_data));
};

export const getRequests = (dispatch, done = () => {}) => {
  const _localStorageOngoingRequests = localStorage.getItem(
    "nnsp_ongoing_requests"
  );

  const _ongoingRequests = _localStorageOngoingRequests
    ? JSON.parse(_localStorageOngoingRequests)
    : [];

  dispatch(reset(_ongoingRequests));
  done("success", _ongoingRequests);
};

export const removeAllRequests = (dispatch, done = () => {}) => {
  const _data = [];

  localStorage.setItem("nnsp_ongoing_requests", JSON.stringify(_data));

  dispatch(reset(_data));
  done("success", _data);
};
