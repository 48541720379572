import apiService from "../../../../dataService/apiService";
import { getEnvironment } from "../../../../const/environment";

export const getLiveNews = (isKeywordSearch, user, done = () => {}) => {
  const { availableNewsPapers } = user || {};
  const params = {
    keys: "",
    nextNewsPaperOffset: 0,
    nextTvOffset: 0,
    size: 6,
    type: 1,
    name:
      availableNewsPapers.length > 0
        ? availableNewsPapers.map((an) => an.code).join(",")
        : "",
    isKeywordSearch: isKeywordSearch === true ? 1 : 0,
  };

  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },

    {},
    params
  );
};
