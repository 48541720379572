import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

export const getNotices = (done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/notices`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },

    {}
  );
};

export const noticeSeenInsert = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/notices/${id}`;
  apiService.post(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};

export const seenNotice = (data, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/noticeseen`;

  apiService.post({ url, data: data }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
