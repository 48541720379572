import React, { useEffect, useState } from "react";
import moment from "moment";

const AggregatedTable = ({ data, currentContentType, aggregated, params }) => {
  const [sourceName, setSourceName] = useState([]);

  useEffect(() => {
    setSourceName(data);
  }, [data]);

  return (
    sourceName && (
      <div className="text-silver " style={{ overflow: "auto" }}>
        <table className=" w-100 table--topicManagement ">
          <thead>
            <tr className=" text-white " style={{ letterSpacing: "1px" }}>
              {/*<th rowSpan={2}>SL</th>*/}
              <th>
                <div className="px-4 py-2 font-s-lg font-w-bold">
                  Source Name
                </div>
              </th>
              {Object.keys(sourceName).length ? (
                sourceName[Object.keys(sourceName)[0]][0].categoryWiseCount &&
                sourceName[Object.keys(sourceName)[0]][0].categoryWiseCount.map(
                  (key, i) => (
                    <th>
                      <div className="text-nowrap text-white   px-4 py-2 font-s-lg font-w-bold">
                        {key.categoryName}
                      </div>
                    </th>
                  )
                )
              ) : (
                <div></div>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.keys(sourceName).length &&
              Object.keys(sourceName).map((key, i) => (
                <>
                  {sourceName[key].map((row, index) => (
                    <tr>
                      {/*<td className="text-light font-s-n font-w-bolder">*/}
                      {/*  /!*<div className="text-nowrap">{`# ${index}`}</div>*!/*/}
                      {/*</td>*/}
                      {index === 0 && (
                        <td className="text-white ">
                          <div className="text-white  px-4 py-2 text-nowrap font-s-lg font-w-bold">
                            {key}
                          </div>
                        </td>
                      )}

                      {row.categoryWiseCount && row.categoryWiseCount.length ? (
                        row.categoryWiseCount.map((item) => (
                          <>
                            <td className="text-white  px-4 py-2 ">
                              <div className="d-flex flex-column gap-3 font-s-lg font-w-bolder">
                                <div className="text-center text-success font-s-lg font-w-bolder">
                                  {item.positive}
                                </div>
                                <div className="text-center text-danger font-s-lg font-w-bolder">
                                  {item.negative}
                                </div>
                              </div>
                            </td>
                          </>
                        ))
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </>
              ))}
          </tbody>
        </table>
      </div>
    )
  );
};

export default AggregatedTable;
