import apiService from "../../dataService/apiService";
import moment from "moment";
import { getEnvironment } from "../../const/environment";

const getTimeRange = (activeStatus) => {
  if (activeStatus) {
    const currentDateAndTime = moment();
    const endDate = moment(currentDateAndTime).utc("asia/dhaka").toISOString();
    let prevDateAndTime;

    if (activeStatus === 1)
      prevDateAndTime = moment(currentDateAndTime)
        .startOf("day")
        .utc("asia/dhaka")
        .toISOString();
    else if (activeStatus === 2)
      prevDateAndTime = moment(
        moment(currentDateAndTime)
          .subtract(7, "days")
          .startOf("day")
          .utc("asia/dhaka")
          .toISOString()
      ).toISOString();
    return {
      end: endDate,
      start: prevDateAndTime,
    };
  }
};

export const getTopicRelatedNews = (
  params,
  user,
  activeStatus,
  timeTabActiveStatus,
  keys,
  done = () => {}
) => {
  const { availableNewsPapers, availableTvChannels } = user || {};
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  const _params = {
    keys: keys,
    nextTvOffset: params.nextTvOffset,
    nextNewsPaperOffset: params.nextNewsPaperOffset,
    size: params.size,
    type: activeStatus,
    ...getTimeRange(timeTabActiveStatus),
    name:
      [...availableNewsPapers, ...availableTvChannels].length > 0
        ? [...availableNewsPapers, ...availableTvChannels]
            .map((an) => an.code)
            .join(",")
        : "",
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data, { ..._params });
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};

export const getIndividualTopic = (id, user, done = () => {}) => {
  const { availableNewsPapers } = user || {};
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/topic/${id}`;

  const _params = {
    name:
      availableNewsPapers.length > 0
        ? availableNewsPapers.map((an) => an.code).join(",")
        : "",
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    _params
  );
};
