import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";

import { markingColors } from "../../../../const/markingColors";

const MarkedLine = ({ highlight, news, loading, handleSelect }) => {
  const [fullView, setFullView] = useState(false);
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {highlight.id === loading && (
        <div
          style={{
            position: "absolute",
            height: "-webkit-fill-available",
            width: "-webkit-fill-available",
            background: "#1f2128c9",
            zIndex: 200,
          }}
        ></div>
      )}
      <div
        className="px-4 py-3 rounded-3 d-flex justify-content-between gap-4"
        style={{
          border: `${highlight.marked ? "1px solid #FF9F38" : ""}`,

          backgroundColor: "rgba(228, 228, 228, 0.1)",
        }}
      >
        <div className="d-flex flex-column gap-2 w-100">
          <div className="py-2 d-flex flex-row flex-wrap justify-content-between">
            <div className="d-flex flex-row gap-4 flex-wrap">
              <div className="d-flex align-items-center gap-2">
                <div className="text-light font-s-sm">Tags</div>
                <div className="text-white font-w-bold font-s-n">
                  {highlight.tags && Array.isArray(highlight.tags)
                    ? highlight.tags.map((i) => i.word).toString()
                    : highlight.tags}
                </div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="text-light font-s-sm">Category</div>
                <div className="text-white font-w-bold font-s-n">
                  {highlight.categoryTypes &&
                  Array.isArray(highlight.categoryTypes)
                    ? highlight.categoryTypes
                        .map((i) => i.categoryName)
                        .toString()
                    : highlight.categoryTypes}
                </div>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="text-light font-s-sm">Created By</div>
                <div className="text-white font-w-bold font-s-n">
                  {highlight.userName}
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-sm text-white p-0 btn-info px-3 py-1 font-w-bolder rounded-3"
                onClick={() => setFullView((prev) => !prev)}
              >
                {fullView ? `Only Highlighted` : `Full view`}
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between gap-4">
            <div>
              {fullView ? (
                <div className="text-white">
                  {news.newsContent.content
                    .replace(/(<([^>]+)>)/gi, "")
                    .substr(0, highlight.highlightedStartIndex)}
                </div>
              ) : (
                <></>
              )}
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {highlight.marked
                      ? "Click to remove from report"
                      : "Click to add on report"}
                  </Tooltip>
                }
              >
                <div
                  className="font-s-md font-w-bolder"
                  style={{
                    color: highlight.sentiment
                      ? markingColors[highlight.sentiment]
                      : "white",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleSelect(
                      highlight.id,
                      news.newsContent
                        ? news.newsContent.sourceName.name
                        : "...",
                      news.newsContent
                        ? news.newsContent.newsTimeAt
                        : moment(new Date()),
                      highlight.marked
                    )
                  }
                >
                  {highlight.highlightedContent}
                </div>
              </OverlayTrigger>
              {highlight.comment ? (
                <div className=" px-2 py-1 ">
                  <samp
                    className="font-w-bold text-white font-s-md  px-2 py-2 rounded "
                    style={{
                      background: "#25272e",
                    }}
                  >
                    <span className="text-primary">Comment:</span>{" "}
                    {highlight.comment}
                  </samp>
                </div>
              ) : (
                <></>
              )}

              {fullView ? (
                <div className="text-white">
                  {news.newsContent.content
                    .replace(/(<([^>]+)>)/gi, "")
                    .substr(
                      highlight.highlightedEndIndex,
                      news.newsContent.content.length
                    )}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="d-flex align-self-center">
              <BsThreeDotsVertical color={"white"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkedLine;
