// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.channel-video .modal-dialog .modal-content {
  background-color: #242731;
}
.channel-video .modal-dialog .modal-content .modal-header {
  padding: 1.5rem 1.5rem 0 1.5rem;
  border: 0 !important;
}
.channel-video .modal-dialog .modal-content .modal-header .btn-close {
  background-color: #949494 !important;
  height: 3rem !important;
  width: 3rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12rem;
}
.channel-video .modal-dialog .modal-content .modal-body {
  padding: 1.5rem;
}
.channel-video .modal-dialog .modal-content .modal-body .inner {
  border: 2px solid #355DFF;
  border-radius: 8px;
  padding: 1.5rem;
}
.channel-video .modal-dialog .modal-content .modal-footer {
  padding: 0 1.5rem 1.5rem 1.5rem;
  border: 0 !important;
  justify-content: flex-start !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Video/components/VideoPopup/style.scss"],"names":[],"mappings":"AAEQ;EACI,yBAAA;AADZ;AAGY;EACI,+BAAA;EACA,oBAAA;AADhB;AAGgB;EACI,oCAAA;EACA,uBAAA;EACA,sBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,oBAAA;AAHpB;AAOY;EACI,eAAA;AALhB;AAOgB;EACI,yBAAA;EACA,kBAAA;EACA,eAAA;AALpB;AASY;EACI,+BAAA;EACA,oBAAA;EACA,sCAAA;AAPhB","sourcesContent":[".channel-video {\n    .modal-dialog {\n        .modal-content {\n            background-color: #242731;\n            \n            .modal-header {\n                padding: 1.5rem 1.5rem 0 1.5rem;\n                border: 0 !important;\n\n                .btn-close {\n                    background-color: #949494 !important;\n                    height: 3rem !important;\n                    width: 3rem !important;\n\n                    display: flex;\n                    align-items: center;\n                    justify-content: center;\n\n                    border-radius: 12rem;\n                }\n            }\n\n            .modal-body {\n                padding: 1.5rem;\n\n                .inner {\n                    border: 2px solid #355DFF;\n                    border-radius: 8px;\n                    padding: 1.5rem;\n                }\n            }\n\n            .modal-footer {\n                padding: 0 1.5rem 1.5rem 1.5rem; \n                border: 0 !important;\n                justify-content: flex-start !important;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
