import React, { useState } from "react";

import { ConfirmationAlert } from "../../../../components";
import { removeIcon } from "../../icons.service";
import EditIcons from "../EditIcons";
import "./styles.scss";
import LivestreamPopup from "../LIvestreamPopup";

const IconsTable = ({ data, handleRemove, handleEdit }) => {
  const [selectedIconId, setSelectedIconId] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);

  const [showAlert, setShowAlert] = useState(false);

  const handleOpenAlert = () => setShowAlert(true);
  const handleCloseAlert = () => setShowAlert(false);

  const handleDelete = (id) => {
    removeIcon(id, (status, data) => {
      if (status === "success") {
        handleRemove(id);
      }
    });
  };

  const handleSelectedUrl = (url) => {
    setSelectedUrl(url);
  };

  const handleSelectedIcon = (icon) => {
    setSelectedIcon(icon);
  };
  const removeSelectedIcon = () => {
    setSelectedIcon(null);
  };

  const handleLivestreamPopupClose = () => {
    setSelectedUrl(null);
  };

  return (
    <div className="text-silver">
      <table className="w-100 table--dictionary ">
        <thead>
          <tr
            className="text-light font-s-n font-w-bolder text-uppercase"
            style={{ letterSpacing: "1px" }}
          >
            <th>SL</th>
            <th>Icons</th>
            <th>Name</th>
            <th>Code</th>
            <th>Type</th>
            <th>Channel URL</th>
            <th>
              <div className="d-flex justify-content-end">ACTIONS</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.icons.length > 0 &&
            data.icons.map((icon, index) => (
              <tr key={index}>
                <td className="my-2">
                  <div className="text-white font-s-n font-w-bold text-nowrap">
                    # {data.page === 0 ? index + 1 : index + 1 + 40}
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  <img
                    alt="newspaper-logo"
                    src={icon.icon}
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                  />
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {icon.name}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {icon.code}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {icon.type === 1 ? "Newspaper" : "TV Channel"}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  <div onClick={() => handleSelectedUrl(icon)}>{icon.url}</div>
                </td>

                <td className="text-white font-s-n font-w-bolder">
                  <div className="d-flex justify-content-end gap-4">
                    <button
                      className="btn btn-md  bg-warning text-white font-w-bolder px-4 font-s-md"
                      onClick={() => handleSelectedIcon(icon)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-danger btn btn-md text-white px-4 font-w-bolder font-s-md"
                      onClick={() => {
                        handleOpenAlert();
                        setSelectedIconId(icon.id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {selectedUrl ? (
        <LivestreamPopup
          selectedVideo={selectedUrl}
          removeSelectedUrl={handleLivestreamPopupClose}
        />
      ) : (
        <></>
      )}
      {selectedIcon ? (
        <EditIcons
          selectedIcon={selectedIcon}
          removeSelectedIcon={removeSelectedIcon}
          handleEdit={handleEdit}
        />
      ) : (
        <></>
      )}

      <ConfirmationAlert
        show={showAlert}
        handleConfirm={handleDelete}
        id={selectedIconId}
        handleShow={handleCloseAlert}
        contentType={"icon"}
      />
    </div>
  );
};

export default IconsTable;
