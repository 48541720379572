import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import getCrossIcon from "../../../../assets/icons/getCrossIcon";

import { AddUpdatedKeywordService } from "./AddUpdatedKeywords.service";

const AddKeyword = ({
  show,
  handleClose,
  id,
  detail,
  keyword,
  topic,
  getUpdatedData,
}) => {
  const [topics, setTopics] = useState("");
  const [topicDetails, setTopicDetails] = useState("");
  const [allTopicKeywords, setAllTopicKeywords] = useState([]);
  const [topicKeywords, setTopicKeywords] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const newArray = [...allTopicKeywords, topicKeywords];
      setAllTopicKeywords(newArray);
      setTopicKeywords("");
    }
  };
  const removeKeyword = (id) => {
    const newArray = [...allTopicKeywords];
    newArray.splice(id, 1);
    setAllTopicKeywords(newArray);
  };

  const onAddWord = () => {
    AddUpdatedKeywordService(
      {
        topic: topics,
        topicDetails: detail,
        allTopicKeywords: allTopicKeywords,
      },
      id,
      (status, data) => {
        if (status === "success") {
          window.location.reload(true);
        }
      }
    );
    handleClose();
  };

  useEffect(() => {
    setTopics(topic);
    setTopicDetails(topicDetails);
    setAllTopicKeywords((prevArray) => [...prevArray, ...keyword]);
  }, []);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="newspaper-details"
        size="lg"
        centered
      >
        <ModalHeader closeButton>
          <div
            className="text-white font-w-bold "
            style={{ fontSize: "1.25rem" }}
          >
            Update Topic
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Topic Keywords</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Topic Keywords"
                  value={topicKeywords}
                  onChange={(e) => setTopicKeywords(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="d-flex flex-row gap-3 flex-wrap">
                {allTopicKeywords.length > 0 &&
                  allTopicKeywords.map((keyword, index) => (
                    <button
                      className="px-3 py-1 border border-light rounded-5 text-white d-flex gap-2"
                      style={{ background: "#242731" }}
                      key={index}
                      onClick={() => removeKeyword(index)}
                    >
                      {keyword} <span>{getCrossIcon()}</span>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-md btn-primary rounded-3 py-2 px-4 font-w-bolder font-s-n "
            onClick={onAddWord}
            disabled={allTopicKeywords.length === 0}
          >
            Update Topic
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddKeyword;
