import React, { useEffect, useState } from "react";

import { useUser, usePageName, setPageName } from "../../dataService/contexts";
import { GetBookmarks } from "./bookmarkPage.service";

import LoadingComponent from "../../components/LoadingComponent";
import { News } from "./../../components";

import LoadMoreIcon from "../../assets/loadMore";

const Bookmarks = () => {
  const { dispatch: pageNameDispatch } = usePageName();

  const { state: userState } = useUser();
  const { user } = userState || {};

  const [news, setNews] = useState([]);
  const [totalNews, setTotalNews] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");
  const [loading, setLoading] = useState(false);
  //params
  const [params, setParams] = useState({
    page: -1,
    size: 20,
  });
  //to update the params
  const updateParams = (key, value) => {
    setParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  //this loadMore function is used to change the current ongoing action to "LOADMORE" fetchNews function to "LOADMORE"
  const loadMore = () => {
    setOnGoingActions("LOADMORE");
    fetchBookmarks("LOADMORE");
  };

  // this actionHandler function is used to change multiple states based on actionType, here we are sending action type and data that we are getting from our api.
  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const { _news, _total } = data;

    if (actionType === "LOADMORE") {
      setNews((prev) => [...prev, ..._news]);
      setTotalNews(_total);

      updateParams("page", params.page + 1);
    } else if (actionType === "SEARCH") {
    }
  };

  //this getEffectiveParamsPage function is to update params state to based on action to make api
  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return params.page + 1;
    } else if (action === "SEARCH") {
      return 1;
    } else {
      return 1;
    }
  };

  //this fetchBookmarks function is to make the api calls. loading state also gets updated before api calls.

  const fetchBookmarks = (actionType) => {
    setLoading(true);

    if (user)
      GetBookmarks(
        user,
        {
          ...params,
          page: getEffectiveParamsPage(actionType),
        },
        (_status, data) => {
          if (_status === "success") {
            if (data && data) {
              actionHandler(actionType, {
                _news:
                  data && data.data && data.data.length
                    ? data.data.map((item) => ({
                        ...item,
                        bookmarkInfo: {
                          id: item.id,
                        },
                        id: item.contentId,
                        highlightedTitle: item.title,
                      }))
                    : [],
                _total: data.total,
              });
            }
          }
          setLoading(false);
        }
      );
  };

  //this use effect function is used to make the api call when a user gets to this route
  useEffect(() => {
    setPageName(pageNameDispatch, "Bookmarked News");

    if (user) fetchBookmarks("LOADMORE");
  }, [user]);
  return (
    <>
      <div className="d-flex flex-column gap-2 p-1">
        {news.length > 0 && (
          <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
            {news.length > 0 &&
              news.map(
                (news) =>
                  news.bookmarkInfo && (
                    <News
                      key={news.id}
                      news={news}
                      keywords={
                        news.matchedKeywordList
                          ? news.matchedKeywordList.split(",")
                          : []
                      }
                      type={0}
                      tags=""
                    />
                  )
              )}
          </div>
        )}

        {loading && (
          <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
            <LoadingComponent size={1} />
          </div>
        )}

        {news && news.length && news.length < totalNews ? (
          <div className="d-flex justify-content-center">
            <button
              onClick={() => loadMore()}
              className="btn btn-sm btn-primary px-4 py-2 rounded-4 font-s-n"
              disabled={loading ? "true" : ""}
            >
              <div className="px-3 py-2 font-w-bolder">
                {onGoingAction === "LOADMORE" ? <LoadMoreIcon/> : `Load More`}
              </div>
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Bookmarks;
