import React from "react";

const Dictionary = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.566 14.3957H18.5177C17.0313 14.3948 15.8265 13.1909 15.8256 11.7045C15.8256 10.218 17.0313 9.01409 18.5177 9.01318H22.566"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9757 11.6429H18.664"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.67479 3H17.3182C20.2164 3 22.5659 5.34951 22.5659 8.24766V15.4247C22.5659 18.3229 20.2164 20.6724 17.3182 20.6724H8.67479C5.77663 20.6724 3.42712 18.3229 3.42712 15.4247V8.24766C3.42712 5.34951 5.77663 3 8.67479 3Z"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M7.96277 7.5382H13.3617"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Dictionary;
