// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newspaper-details .modal-dialog .modal-content {
  border: 1px solid hsla(0, 0%, 89%, 0.1);
  background-color: #242731;
  border-radius: 2rem;
}
.newspaper-details .modal-dialog .modal-content .modal-header {
  padding: 2rem 2rem 0 2rem;
  border: 0 !important;
}
.newspaper-details .modal-dialog .modal-content .modal-header .btn-close {
  background-color: #969696 !important;
  height: 2rem !important;
  width: 2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16rem;
  margin-left: 4rem;
}
.newspaper-details .modal-dialog .modal-content .modal-body {
  padding: 2rem;
}
.newspaper-details .modal-dialog .modal-content .modal-body .inner {
  border: 2px solid #355DFF;
  border-radius: 8px;
  padding: 2rem;
}
.newspaper-details .modal-dialog .modal-content .modal-footer {
  padding: 0 2rem 2rem 2rem;
  border: 0 !important;
  justify-content: flex-start !important;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsDetails/styles.scss"],"names":[],"mappings":"AAEQ;EACI,uCAAA;EACA,yBAAA;EACA,mBAAA;AADZ;AAGY;EACI,yBAAA;EACA,oBAAA;AADhB;AAGgB;EACI,oCAAA;EACA,uBAAA;EACA,sBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,oBAAA;EACA,iBAAA;AAHpB;AAOY;EACI,aAAA;AALhB;AAMgB;EACI,yBAAA;EACA,kBAAA;EACA,aAAA;AAJpB;AAQY;EACI,yBAAA;EACA,oBAAA;EACA,sCAAA;AANhB","sourcesContent":[".newspaper-details{\n    .modal-dialog{\n        .modal-content{\n            border: 1px solid hsla(0, 0%, 89%, 0.1);\n            background-color: #242731;\n            border-radius: 2rem;\n\n            .modal-header{\n                padding: 2rem 2rem 0 2rem;\n                border : 0 !important;\n\n                .btn-close{\n                    background-color: #969696 !important;\n                    height: 2rem !important;\n                    width: 2rem !important;\n\n                    display: flex;\n                    align-items: center;\n                    justify-content: center;\n\n                    border-radius: 16rem;\n                    margin-left: 4rem;\n                }\n            }\n\n            .modal-body{\n                padding: 2rem;\n                .inner{\n                    border: 2px solid #355DFF;\n                    border-radius: 8px;\n                    padding: 2rem;\n                }\n            }\n\n            .modal-footer{\n                padding: 0 2rem 2rem 2rem;\n                border : 0 !important;\n                justify-content: flex-start !important;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
