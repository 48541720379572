import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useUser } from "../../../../dataService/contexts";
import {
  deleteTopic,
  getTopicManagementData,
} from "./topicManagementTable.service";

import { UpdateCaseModal } from "./components";
import CaseManagementLoading from "../../CaseManagementLoading";

import { getDate_2D2M2Y } from "../../../../const/dateFormat";

import LoadMoreIcon from "../../../../assets/loadMore";

import "./style.scss";

const CaseListTable = () => {
  const [topics, setTopics] = useState([]);
  const [totalTopics, setTotalTopics] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(null);
  //params
  const [params, setParams] = useState({
    page: 0,
    size: 5,
  });
  //to show and hide modal
  const [show, setShow] = useState(false);

  //to update the params
  const updateParams = (key, value) => {
    setParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };
  //user info is store in this useUser hook
  const { state: userState } = useUser();
  const { user } = userState || {};

  // to open and close the modals
  const handleClose = () => {
    setSelectedTopic(null);
    setShow(false);
  };
  const handleShow = (news) => {
    setShow(true);
    setSelectedTopic(news);
  };
  //to handle delete api
  const handleDelete = (id) => {
    deleteTopic({ id: id }, (status, data) => {
      if (status === "success") {
        window.location.reload(true);
      }
    });
  };

  //this loadMore function is used to change the current ongoing action to "LOADMORE" fetchNews function to "LOADMORE"
  const loadMore = () => {
    setOnGoingActions("LOADMORE");
    fetchTopics("LOADMORE");
  };

  // this actionHandler function is used to change multiple states based on actionType, here we are sending action type and data that we are getting from our api.
  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const { _topics, _total } = data;

    if (actionType === "LOADMORE") {
      setTopics((prev) => [...prev, ..._topics]);
      setTotalTopics(_total);

      updateParams("page", params.page + 1);
    } else if (actionType === "SEARCH") {
      setTopics([..._topics]);
      setTotalTopics(_total);

      updateParams("page", 0);
    }
  };

  //this getEffectiveParamsPage function is to update params state to based on action to make api
  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return params.page + 1;
    } else {
      return 0;
    }
  };

  //this fetchTopics function is to make the api calls. loading state also gets updated before api calls.
  const fetchTopics = (actionType) => {
    setLoading(true);
    if (user)
      getTopicManagementData(
        {
          ...params,
          page: getEffectiveParamsPage(actionType),
        },
        user,
        (_status, data) => {
          if (_status === "success") {
            if (data && data.topics) {
              actionHandler(actionType, {
                _topics: data.topics,
                _total: data.total,
              });
            }
          }
          setLoading(false);
        }
      );
  };

  //this use effect function is used to make the api call when a user gets to this route
  useEffect(() => {
    if (user) {
      fetchTopics("SEARCH");
    }
  }, [user]);
  return (
    <div className="overflow-auto">
      <div>
        {topics.length > 0 ? (
          <table className="w-100 table--topicManagement ">
            <thead>
              <tr className="text-light font-s-n font-w-bold text-uppercase">
                <th className="text-nowrap">
                  <div className="px-4  py-2">NAME</div>
                </th>
                <th className="text-nowrap">
                  <div className="px-4  py-2">CREATED AT</div>
                </th>
                <th className="text-nowrap">
                  <div className="px-4  py-2">Details</div>
                </th>
                <th className="text-nowrap">
                  <div className="px-4  py-2">keywords</div>
                </th>
                <th className="text-nowrap d-flex justify-content-end">
                  <div className="px-4  py-2">ACTIONS</div>
                </th>
              </tr>
            </thead>
            <tbody className="rounded-5 ">
              {topics.map((topic, index) => (
                <tr className="" key={index}>
                  <td className="text-white text-nowrap px-4  py-2 font-s-md font-w-bold">
                    {topic.name}
                  </td>
                  <td className="text-light font-s-md px-4  py-2 text-nowrap font-w-bold">
                    {getDate_2D2M2Y(topic.createdAt)}
                  </td>
                  <td
                    className="text-white font-s-md px-4  py-2 font-w-bold"
                    style={{ maxWidth: "200px", whiteSpace: "wrap" }}
                  >
                    {`${topic.detail} `}
                  </td>
                  <td className="text-white font-s-md px-4  py-2 font-w-bold">
                    <div className="d-flex flex-row flex-wrap text-wrap align-items-center">
                      {topic.keywords.map((keyword, index) => (
                        <div>
                          {keyword}
                          {index !== topic.keywords.length - 1 && ","}
                          {index === topic.keywords.length - 1 && "."}
                          &nbsp;
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="text-white font-s-n font-w-bolder px-4  py-2 d-flex justify-content-end ">
                    <div className="d-flex gap-3 flex-row flex-wrap justify-content-end">
                      <div>
                        <Link
                          className="btn btn-md btn-primary rounded-3 py-2 px-4 font-w-bold font-s-md"
                          to={`/case-management/${topic.id}`}
                        >
                          View
                        </Link>
                      </div>
                      <div>
                        <button
                          className="btn btn-md btn-success rounded-3 py-2 px-4 font-w-bold font-s-md text-white"
                          onClick={() => handleShow(topic)}
                        >
                          Update
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn btn-md btn-danger rounded-3 py-2 px-4 font-w-bold font-s-md text-white"
                          onClick={() => handleDelete(topic.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <></>
        )}

        {loading ? <CaseManagementLoading size={8} /> : <></>}
      </div>

      {selectedTopic && (
        <UpdateCaseModal
          data={selectedTopic}
          handleClose={handleClose}
          show={show}
        />
      )}
      {topics && topics.length && topics.length < totalTopics ? (
        <div className="d-flex justify-content-center">
          <button
            onClick={() => loadMore()}
            className="btn btn-sm btn-primary px-3 py-2 rounded-4 font-s-n"
            disabled={loading ? "true" : ""}
          >
            <div className="px-3 py-2 font-w-bolder">
              {onGoingAction === "LOADMORE" ? <LoadMoreIcon /> : `Load More`}
            </div>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CaseListTable;
