import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { getIndividualNews, getTagList } from "./singleNewsFullView.service";

import { LoadingComponent, NewsFullView } from "../../components";
import { useUser } from "../../dataService/contexts";

import { markingColors } from "./../../const/markingColors";

const SingleNewsView = () => {
  const { query } = useParams();
  const _query = query ? query.split("&") : [];
  const newsId = _query.length ? _query[0] : null;
  const newsType = _query.length ? _query[1] : null;
  const newsTags = _query.length ? _query[2] : null;

  const { state: userState } = useUser();
  const { user } = userState || {};

  const [news, setNews] = useState(null);
  const [state, setState] = useState({ value: [] });
  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const handleAddState = (id, newMarked) => {
    setState((prev) => ({
      ...prev,
      value: [
        ...prev.value,
        {
          ...newMarked,
          id: id,
        },
      ],
    }));
  };
  const handleAddComment = (id, comment) => {
    setState((prev) => ({
      ...prev,
      value: prev.value.map((item) =>
        item.id === id
          ? {
              ...item,
              comment,
            }
          : { ...item }
      ),
    }));
  };

  const handleRemoveState = (value) => {
    setState((prev) => ({ ...prev, value }));
  };

  const search = () => {
    setNews(null);
    fetchNews("SEARCH");
  };

  const actionHandler = (actionType, data) => {
    const { _news } = data;
    if (actionType === "SEARCH") {
      setNews(_news);
    }
  };

  const fetchNews = (actionType) => {
    setTags(
      newsTags
        .toString()
        .split(" " && ",")
        .map((item) => `<span class='highlighted-text'>${item}</span>`)
    );

    setLoading(true);
    if (user && newsId && newsType) {
      getIndividualNews(newsId, newsType, user, newsTags, (status, data) => {
        if (status === "success" && data) {
          actionHandler(actionType, {
            _news: data,
          });

          getTagList(user, data.contentId, (status, data) => {
            if (status === "success" && data && data.reports?.length) {
              const onlyHiglightedInfo = data.reports.length
                ? _.flatten(data.reports.map((report) => report.highlights))
                : [];
              setState((prev) => ({
                ...prev,
                value: onlyHiglightedInfo.length
                  ? onlyHiglightedInfo.map((item) => {
                      return {
                        tag: item.tagWord,
                        text: item.highlightedContent,
                        start: item.highlightedStartIndex,
                        end: item.highlightedEndIndex,
                        id: item.id,
                        color: markingColors[item.sentiment],
                        comment: item.comment,
                      };
                    })
                  : [],
              }));
            }
          });
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    search();
  }, [user]);

  return (
    <>
      {news ? (
        <NewsFullView
          news={news}
          keywords={
            news.matchedKeywordList
              ? [...news.matchedKeywordList.split(",")]
              : []
          }
          bookmark={false}
          state={state}
          addState={handleAddState}
          removeState={handleRemoveState}
          addComment={handleAddComment}
        />
      ) : (
        <></>
      )}
      {loading ? <LoadingComponent size={1} /> : <></>}
    </>
  );
};

export default SingleNewsView;
