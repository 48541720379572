import { getDateTime_GMT } from "../../const/dateFormat";
import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";

export const getSourceNewsContent = (
  params,
  checked,
  name = "",
  done = () => {}
) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  const _params = {
    keys: params.keywords,
    nextNewsPaperOffset: params.nextNewsPaperOffset,
    nextTvOffset: params.nextTvOffset,
    size: params.size,
    start: getDateTime_GMT(params.startDate),
    end: getDateTime_GMT(params.endDate),
    type: params.type.value,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data, { ..._params });
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
      name: name,
      isKeywordSearch: checked === true ? 1 : 0,
    }
  );
};
