import { createContext, useContext, useMemo, useReducer } from "react";
import { parseJwt } from "../../utilities";
import actionTypes from "../actionTypes";
import apiService from "../apiService";

import { getEnvironment } from "../../const/environment";

const localStorageToken = localStorage.getItem("nnsp_token");

const AuthContext = createContext();

const initialState = {
  token: localStorageToken ? localStorageToken : null,
  loading: false,
  error: false,
  errorMsg: "",
};

const authReducer = function (state, action) {
  switch (action.type) {
    case actionTypes.authRequest:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };

    case actionTypes.authSuccess:
      return {
        ...state,
        token: action.token,
        loading: false,
        error: false,
        errorMsg: "",
      };

    case actionTypes.authFail:
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
        error: true,
      };

    case actionTypes.logoutRequest:
      return {
        ...state,
        user: null,
      };

    default:
      break;
  }
};

export function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <AuthContext.Provider value={value} {...props} />;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("use auth must be used within a auth provider");
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

const request = () => ({
  type: actionTypes.authRequest,
});

const success = (token) => ({
  type: actionTypes.authSuccess,
  token,
});

const fail = (errorMsg) => ({
  type: actionTypes.authFail,
  errorMsg,
});

const logout = () => ({
  type: actionTypes.logoutRequest,
});

export const getAuth = (dispatch, data, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/v1/private/login`;

  const userAuth = {
    username: data.username,
    password: data.password,
  };
  apiService.post(
    {
      url,
      data: userAuth,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    },
    (status, data) => {
      if (status > 199 && status < 300) {
        apiService.get(
          `${env.protocol}://${env.baseUrl}:${
            env.port
          }/public-dashboard-service/api/v1/private/otp?keycloakId=${
            parseJwt(data.access_token).sub
          }`,
          () => {
            if (status > 199 && status < 300) {
              done("success", data);
            }
          }
        );
      } else {
        dispatch(fail(data));
        done("error", data);
      }
    }
  );
};
export const getOtp = (userId, otp, dispatch, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/otp/validate?`;
  dispatch(request());
  const _params = {
    keycloakId: parseJwt(userId).sub,
    otp: otp,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        if (data) {
          dispatch(success(userId));
          localStorage.setItem("nnsp_token", userId);
          done("success", data);
        } else {
          done("success", data);
        }
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};
export const getLogout = (dispatch, done = () => {}) => {
  localStorage.clear();
  dispatch(logout(null));
  done();
};
