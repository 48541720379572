import React, { useEffect, useState } from "react";
import { Navbar, Container, DropdownButton, Dropdown } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";

import {
  getUser,
  getLogout,
  useUser,
  getNotification,
  getCategory,
  useCategory,
  updateNotification,
  useNotification,
  updateRequest,
  useOngoingRequests,
  usePageName,
  getNewsSources,
  useNewsSources,
} from "../../../../dataService/contexts";

import { getVideoDownload } from "../../../../pages/Video/videoContent.service";

import { VideoPopup } from "../../../../pages/Video/components";
import NotificationDropdown from "../../../NotificationDropdown";
import NoticePopup from "../../../NoticePopup";
import { getSocket } from "../../../../socket";

import { getByCode } from "../../../../const/newsSources";

import AvaterIcon from "./../../../../assets/avater.png";
import { ChangePasswordModal, UniqueNewsPopup } from "./components";
import {
  getTags,
  useTags,
} from "../../../../dataService/contexts/tags.context";
import Hamburger from "../../../../assets/hamburger";
import HamburgerIcon from "../../../../assets/hamburger";
import PwaButton from "../../../PwaButton";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getByCode(source).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};
const ShortUserInfo = ({ user }) => {
  return (
    <div className="d-flex gap-3 align-items-center ">
      <div
        className="bg-black"
        style={{
          padding: "1px",
          borderRadius: "5rem",
        }}
      >
        <img
          src={AvaterIcon}
          alt="pro-pic"
          style={{
            width: "2rem",
            height: "2rem",
          }}
        />
      </div>
      <div className="font-s-md font-w-bolder">{user.name}</div>
    </div>
  );
};

const CustomNavbar = ({ handleToggle }) => {
  const { dispatch: userDispatch, state: userState } = useUser();
  const { dispatch: notificationDispatch } = useNotification();
  const { state: pageNameState } = usePageName();
  const { dispatch: tagsDispatch, state: tagsState } = useTags();
  const { dispatch: categoryDispatch } = useCategory();

  const { pageName } = pageNameState || {};

  const { tags } = tagsState || {};
  const { user } = userState || {};
  const { dispatch: ongoingRequestsDispatch } = useOngoingRequests();
  const { dispatch: newsSourcesDispatch, state: newsSourcesState } =
    useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const [selectedUniqueNews, setSelectedUniqueNews] = useState(null);

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [params, setParams] = useState({
    channelName: "",
    endDate: "",
    startDate: "",
  });

  const [loadMoreNotifications, setLoadMoreNotifications] = useState(false);
  const handleLoadMoreNotifications = () => {
    setLoadMoreNotifications(true);
    handlePagination("page", pagination.page + 1);
  };

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });

  const [showChangePasswordModal, setChangePasswordModal] = useState(false);
  const handlePagination = (key, value) => {
    setPagination((prev) => ({
      ...pagination,
      [key]: value,
    }));
  };

  const [currentNotifications, setCurrentNotifications] = useState([]);
  const [unseen, setUnseen] = useState(0);

  const handleUniqueNewsPopup = (link) => {
    setSelectedUniqueNews(link);
    setParams({
      channelName:
        link.notificationType === 1 ? link.data.tvChannel : link.sourceName,
      endDate:
        link.notificationType === 1 ? link.data.searchEnd : params.endDate,
      startDate:
        link.notificationType === 1 ? link.data.searchStart : params.startDate,
    });
  };

  const handleVideoPopup = (link) => {
    setSelectedVideo(link.data);
    setParams({
      channelName:
        link.data.notificationType === 1
          ? link.data.tvChannel
          : link.data.sourceName,
      endDate: link.data.searchEnd,
      startDate: link.data.searchStart,
    });

    const handleNoticePopup = () => {};

    const id = link.id;
    updateNotification(notificationDispatch, id, (status, data) => {
      if (status === "success") {
        getCurrentNotifications();
      }
    });
  };
  //abc
  const handleChangePasswordModalClose = () => setChangePasswordModal(false);
  const handleChangePasswordModalShow = () => setChangePasswordModal(true);

  const handleUniqueNewsPopupClose = () => {
    setSelectedUniqueNews(null);
  };
  const handleVideoPopupClose = () => {
    setSelectedVideo(null);
  };

  const handleDownload = () => {
    getVideoDownload(params, (status, data, _params) => {
      if (status === "success" && data.url) {
        const blob = new Blob([data.url]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `video.mp4`;
        link.click();
      }
    });
  };

  const handleRequestedData = (data) => {
    // Handle the received event data
    let source;
    let startDate;
    let startTime;

    let endDate;
    let endTime;

    const type = data.notificationType;
    //Handle time change

    if (Number(type) === 2) {
      source = data.sourceName;

      startDate = moment.utc(data.startTime).format("YYYY-MM-DD");
      startTime = moment.utc(data.startTime).format("hh:mm A");

      endDate = moment.utc(data.endTime).format("YYYY-MM-DD");
      endTime = moment.utc(data.endTime).format("hh:mm A");
    } else {
      source = data.tvChannel;
      startDate = moment(data.searchStart).format("YYYY-MM-DD");
      startTime = moment(data.searchStart).format("hh:mm A");

      endDate = moment(data.searchEnd).format("YYYY-MM-DD");
      endTime = moment(data.searchEnd).format("hh:mm A");
    }

    updateRequest(
      ongoingRequestsDispatch,
      `${source}#${startDate}, ${startTime}#${endDate}, ${endTime}#${type}`
    );

    if (!data.error) {
      toast.success(
        <div
          onClick={() => {
            if (data.notificationType === 1) {
              handleVideoPopup(data.data);
            } else if (data.notificationType === 2) {
              handleUniqueNewsPopup(data);
            }
          }}
          className="d-flex flex-column gap-3"
        >
          <div className="d-flex flex-row align-items-center gap-2">
            <NewsSourceIcon source={source} />
            <div className="d-flex flex-column gap-1">
              <div className="font-s-md">{getByCode(source).name}</div>
              <div className="font-s-n d-flex text-nowrap">{`${startDate} ${startTime} ~ ${endDate} ${endTime}`}</div>
            </div>
          </div>
        </div>,
        {
          containerId: "active",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          closeOnClick: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
          data: data,
          icon: false,
        }
      );
      getCurrentNotifications();
    } else {
      toast.error(
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center gap-2">
            <NewsSourceIcon
              source={
                data.notificationType === 1 ? data.tvChannel : data.sourceName
              }
            />
            <div className="d-flex flex-column gap-1">
              <div>
                {data.notificationType === 1 ? data.tvChannel : data.sourceName}
              </div>
              {data.notificationType === 1 ? (
                <div className="font-s-xsm d-flex text-nowrap">{`${data.searchStart} ~ ${data.searchEnd}`}</div>
              ) : (
                <div className="font-s-xsm d-flex text-nowrap">{`${moment(
                  data.startTime
                ).format("YYYY-MM-DD, hh:mm A")} ~ ${moment(
                  data.endTime
                ).format("YYYY-MM-DD, hh:mm A")}`}</div>
              )}
            </div>
          </div>
          <div className="text-center">Not Found</div>
        </div>,
        {
          containerId: "active",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          closeOnClick: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
          data: data,
          icon: false,
        }
      );
    }
  };

  const getCurrentNotifications = () => {
    getNotification(notificationDispatch, pagination, (status, data) => {
      if (status === "success") {
        setCurrentNotifications(data.notifications);
        setUnseen(data.totalUnseen);
      }
    });
  };

  const loadMoreCurrentNotifications = () => {
    getNotification(notificationDispatch, pagination, (status, data) => {
      if (status === "success") {
        setCurrentNotifications((prev) => [...prev, ...data.notifications]);
        setLoadMoreNotifications(false);
      }
    });
  };

  useEffect(() => {
    getUser(userDispatch, (status, userInfo) => {
      if (status === "success") {
        const socket = getSocket(userInfo);
        socket.on("connect", function () {
          if (socket.connected) {
            const data = {
              username: userInfo.userId,
            };
            socket.emit("subscribe", JSON.stringify(data), (subStatus) => {});
            socket.on(`${userInfo.userId}`, (data) => {
              handleRequestedData(JSON.parse(JSON.parse(data)), userInfo);
            });

            getCurrentNotifications();
          }
        });
      }
    });

    getCategory(categoryDispatch, (state, data) => {});
    getTags(tagsDispatch, (status, data) => {
      if (status === "success") {
      }
    });
  }, [userDispatch, categoryDispatch]);
  useEffect(() => {
    getNewsSources(newsSourcesDispatch, (state, data) => {});
  }, [newsSourcesDispatch]);
  useEffect(() => {
    if (pagination.page > 0) loadMoreCurrentNotifications();
  }, [pagination]);

  const handleLogout = () =>
    getLogout(userDispatch, () => {
      window.location.replace("/login");
    });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define breakpoints
  const isMobile = windowSize.width <= 1024;
  return (
    <Navbar
      variant="dark"
      className="nav-width bg-black__deep-mod"
      style={{
        height: "6rem",
      }}
    >
      <Container fluid className="p-0 m-0 px-4">
        <div className="d-flex flex-row gap-3">
          {isMobile ? (
            <button
              className={"text-white btn bg-transparent p-0"}
              onClick={() => handleToggle()}
            >
              <HamburgerIcon />
            </button>
          ) : (
            <></>
          )}

          <div className="text-white px-1 font-s-xlg">{pageName}</div>
        </div>

        <div className="d-flex gap-4 justify-content-end">
          {/*<div className="d-flex align-self-center align-items-center">*/}
          {/*  <NotificationDropdown*/}
          {/*    unseen={unseen}*/}
          {/*    currentNotifications={currentNotifications}*/}
          {/*    handleVideoPopup={handleVideoPopup}*/}
          {/*    handleLoadMoreNotifications={handleLoadMoreNotifications}*/}
          {/*    loadMoreNotifications={loadMoreNotifications}*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div className="d-flex align-self-center align-items-center">*/}
          {/*  <NoticePopup />*/}
          {/*</div>*/}
          <PwaButton />
          {user && (
            <DropdownButton
              className="d-flex align-self-center align-items-center "
              variant="primary"
              drop="down"
              title={<ShortUserInfo user={user} />}
            >
              <Dropdown.Item onClick={() => handleChangePasswordModalShow()}>
                Change Password
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout()}>
                Logout
              </Dropdown.Item>
            </DropdownButton>
          )}
        </div>
      </Container>
      <div>
        <ToastContainer
          enableMultiContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          containerId={"active"}
        />
        <ToastContainer
          enableMultiContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          containerId={"inActive"}
        />
      </div>

      {selectedVideo && (
        <VideoPopup
          handleClose={handleVideoPopupClose}
          show={!!selectedVideo}
          videoData={selectedVideo}
          handleDownload={handleDownload}
        />
      )}
      {selectedUniqueNews && (
        <UniqueNewsPopup
          handleClose={handleUniqueNewsPopupClose}
          show={!!selectedUniqueNews}
          data={selectedUniqueNews}
        />
      )}
      <ChangePasswordModal
        open={showChangePasswordModal}
        handleClose={handleChangePasswordModalClose}
      />
    </Navbar>
  );
};

export { CustomNavbar };
