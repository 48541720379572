import React, { useEffect, useState } from "react";
import { TextAnnotator } from "react-text-annotate";

import { addMarkedText, removedMarkedText } from "./playGround.service";
import { useUser } from "../../dataService/contexts";

import { markingColors } from "../../const/markingColors";

import "./styles.scss";

const getNewMarkedText = (value, state) => {
  let newMarked = null;

  for (const v of value) {
    if (!!!v.id) newMarked = v;
  }

  return newMarked;
};

const getRemovedMarkedText = (value, state) => {
  let removedMarked = null;

  for (const s of state) {
    if (value.length) {
      let has = false;
      for (const v of value) {
        if (v.id === s.id) has = true;
      }

      if (!has) removedMarked = s;
    } else {
      removedMarked = s;
    }
  }

  return removedMarked;
};

const Playground = ({
  content,
  tag,
  news,
  state,
  addState,
  removeState,
  sentiment,
  defaultValue,
  category,
  newsSources,
}) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const [laoding, setLoading] = useState(false);

  const cleanContent = content.replace(/(<([^>]+)>)/gi, "");

  const handleChange = (value) => {
    // New marked text is added
    if (value.length > state.value.length) {
      //Find the new one
      setLoading(true);
      const newMarked = getNewMarkedText(value, defaultValue);
      addMarkedText(
        {
          ...newMarked,
          contentType: news.type,
          contentId: news.contentId,
          sentiment,
          tag,
          category,
          newsSources,
          news,
        },
        user,
        (status, data) => {
          if (status === "success") {
            addState(data.id, newMarked, category[0].word);
            setLoading(false);
          }
        }
      );
    } else if (value.length < state.value.length) {
      //Find the removed one
      setLoading(true);
      const removedMarked = getRemovedMarkedText(value, state.value);

      removedMarkedText(removedMarked.id, (status, data) => {
        if (status === "success") {
          removeState(value, removedMarked.id);
          setLoading(false);
        }
      });
    }
  };

  return (
    cleanContent && (
      <div className="text-white">
        <div style={{ position: "relative" }}>
          {laoding && (
            <div
              style={{
                position: "absolute",
                top: 0,
                zIndex: 200,
                background: "#1f2128c9",
                height: "-webkit-fill-available",
                width: "-webkit-fill-available",
              }}
            ></div>
          )}
          {defaultValue !== [] && (
            <TextAnnotator
              value={defaultValue}
              content={cleanContent}
              onChange={handleChange}
              getSpan={(span) => ({
                ...span,
                tag:
                  tag && tag.length && tag.map((item) => item.word).toString(),
                color: markingColors[sentiment],
              })}
            />
          )}
        </div>
      </div>
    )
  );
};

export default Playground;
