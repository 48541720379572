const newsSourceTypes = [
  {
    id: "0",
    label: "All",
    value: 0,
    type: 0
  },
  {
    id: "1",
    label: "Paper News",
    value: "1",
    type: 1
  },
  {
    id: "2",
    label: "TV News",
    value: 2,
    type: 2
  },
];

const getNewsSourceType = (value) => {
  return(newsSourceTypes[value])
}

export { newsSourceTypes, getNewsSourceType };