import { getEnvironment } from "../../const/environment";
import moment from "moment/moment";
import apiService from "../../dataService/apiService";
import { getDateTime_4Y2M2D24T } from "../../const/dateFormat";

export const getUniqueNews = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/scroll-news/${params.sourceName}?`;

  const _params = {
    channelName: params.channelName,
    startDatetime: moment(params.startDate).utc("asia/dhaka").toISOString(),
    endDatetime: moment(params.endDate).utc("asia/dhaka").toISOString(),
    limit: params.limit,
    offset: params.offset,
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data, { ..._params });
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};
