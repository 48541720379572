import React, { useEffect, useState } from "react";
import { AddNotice, NoticeTable } from "./components";
import { setPageName, usePageName } from "../../dataService/contexts";

const Notice = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { dispatch: pageNameDispatch } = usePageName();

  useEffect(() => {
    setPageName(pageNameDispatch, "Notice");
  }, []);
  return (
    <div className="d-flex flex-column  p-1 gap-3">
      <div className="d-flex mb-3 flex-row justify-content-between ">
        <div className="font-s-xlg text-white font-w-bold align-self-center"></div>
        <button
          className="btn btn-md  btn-primary text-white rounded-4 px-5 py-3 font-w-bold font-s-md"
          onClick={handleShow}
        >
          Add New Notice
        </button>
      </div>

      <div className="d-flex flex-column gap-4">
        <NoticeTable />
        <div>
          <AddNotice open={show} handleClose={handleClose} />
        </div>
      </div>
    </div>
  );
};

export default Notice;
