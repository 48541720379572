import React from "react";

import "../KeywordTabs/style.scss";

const status = [
  {
    label: "Actives",
    value: 1,
  },
  {
    label: "Inactives",
    value: 2,
  },
];

const KeywordTabs = ({ activeStatus, handleActiveStatus }) => {
  return (
    <div className="d-flex flex-wrap flex-row justify-content-between">
      <div className="text-white text-white font-s-md font-w-bold d-flex align-self-center">
        Keywords
      </div>
      <div className="d-flex flex-row gap-1">
        {status.map((s) => {
          return (
            <div
              key={s.value}
              className={`text-nowrap text-light font-s-md font-w-bold status 
              ${activeStatus === s.value ? "status--active" : ""}`}
              id={s.value}
              onClick={() => handleActiveStatus("activeStatus", s.value)}
            >
              {s.label}
            </div>
          );
        })}
      </div>
      <div className="d-flex flex-row gap-3 align-items-center">
        {/*<img*/}
        {/*  src={SearchIcon}*/}
        {/*  alt="search"*/}
        {/*  style={{*/}
        {/*    width: "1.5rem",*/}
        {/*    height: "1.5rem",*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<div className="text-white font-w-bold font-s-md align-self-center ">*/}
        {/*  Search*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default KeywordTabs;
