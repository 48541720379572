import React, { useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ page, total, action, setSize = 4 }) => {
  const [currentSet, setCurrentSet] = useState(1);

  const getPageNumbers = (currentSet, setSize, total) => {
    let pageNumbers = [];

    for (
      var i = (currentSet - 1) * setSize + 1;
      i <= setSize * currentSet && i <= Math.ceil(Number(total) / 40);
      i++
    ) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="text-white">
        Showing {page * 40 + 1} ~{" "}
        {total >= page * 40 + 40 ? page * 40 + 40 : total} of {total}
      </div>
      <div className="d-flex gap-4 justify-content-center">
        {currentSet === 1 ? (
          <></>
        ) : (
          <button
            className="btn btn-lg btn-primary font-w-bolder px-4 py-2"
            type="button"
            onClick={() => {
              setCurrentSet((prev) => {
                action("page", (prev - 1) * 4 - 1);
                return prev - 1;
              });
            }}
          >
            Prev
          </button>
        )}

        <div className="d-flex gap-2">
          {getPageNumbers(currentSet, setSize, total).map((i) => (
            <button
              disabled={Number(page) === i - 1 ? "true" : ""}
              type="button"
              onClick={() => action("page", i - 1)}
              className={`btn btn-lg ${
                Number(page) === i - 1 ? "true" : "btn-primary"
              } font-w-bolder px-3 py-2 text-white`}
            >
              {i}
            </button>
          ))}
        </div>

        {currentSet * setSize * 10 >= total ? (
          <></>
        ) : (
          <button
            className="btn btn-lg btn-primary font-w-bolder px-4 py-2"
            type="button"
            onClick={() => {
              setCurrentSet((prev) => {
                action("page", prev * 4);
                return prev + 1;
              });
            }}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

const UpdatedPagination = ({ itemsPerPage, total, action }) => {
  const items = [...Array(total).keys()].map((i) => i + 1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
    action("page", event.selected);
  };

  return (
    <div className="d-flex justify-content-between flex-wrap align-items-center">
      <div className="text-white">
        Showing {itemOffset + 1} ~ {total >= endOffset ? endOffset : total}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        className="pagination"
        pageClassName="pagination--page"
        activeClassName="pagination--page__active"
        pageLinkClassName="pagination--page__link"
      />
    </div>
  );
};

export { UpdatedPagination };

export default Pagination;
