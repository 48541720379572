const LoadMoreIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="2" r="1.5" fill="white" />
            <circle opacity="0.4" cx="8" cy="14" r="1.5" fill="white" />
            <circle opacity="0.6" cx="2" cy="8" r="1.5" fill="white" />
            <circle opacity="0.4" cx="14" cy="8" r="1.5" fill="white" />
            <circle
                opacity="0.4"
                cx="12.243"
                cy="3.75684"
                r="1.5"
                fill="white"
            />
            <circle
                opacity="0.4"
                cx="3.75696"
                cy="12.2432"
                r="1.5"
                fill="white"
            />
            <circle
                opacity="0.8"
                cx="3.75696"
                cy="3.75684"
                r="1.5"
                fill="white"
            />
            <circle
                opacity="0.4"
                cx="12.243"
                cy="12.2432"
                r="1.5"
                fill="white"
            />
        </svg>
    )
}

export default LoadMoreIcon