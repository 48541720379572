import apiService from "../../dataService/apiService/index";
import { getEnvironment } from "../../const/environment";

const getActiveStatus = (activeStatus) => {
  if (activeStatus === 1) return true;
  else if (activeStatus === 2) return false;
  else return null;
};

export const getDictionaryData = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/keywords`;

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    {
      page: params.page,
      size: 40,
      active: getActiveStatus(params.activeStatus),
    }
  );
};

export const downloadKeywordsExcel = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/keywords/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const downloadKeywordsDoc = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/keywords/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const postNewKeyWord = (data, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/keywords`;

  const _data = {
    value: data.value,
    severity: data.severity,
  };

  apiService.post({ url, data: _data }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
