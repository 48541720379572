// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-source-filter__button--toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #1f2128 !important;
  border: 0 !important;
}
.news-source-filter .show .news-source-filter__button--toggle-inner {
  filter: invert(33%) sepia(85%) saturate(4865%) hue-rotate(196deg) brightness(104%) contrast(101%) !important;
}
.news-source-filter__menu {
  background-color: #191B20 !important;
  border-radius: 1rem;
  padding: 2rem !important;
  border: 1px solid rgba(228, 228, 228, 0.1);
}

.news-source-filter__content ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 300px;
}

.news-source-filter__menu-item-inner:hover {
  background-color: rgba(255, 0, 0, 0) !important;
}

.dropdown-toggle::after {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsSourceFilter/style.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,oCAAA;EAEA,oBAAA;AADR;AAKQ;EACI,4GAAA;AAHZ;AAOI;EACI,oCAAA;EACA,mBAAA;EACA,wBAAA;EACA,0CAAA;AALR;;AAWI;EACI,aAAA;EACA,sBAAA;EACA,SAAA;EAEA,iBAAA;AATR;;AAcI;EACI,+CAAA;AAXR;;AAeA;EACI,wBAAA;AAZJ","sourcesContent":[".news-source-filter{\n    &__button--toggle{\n        display: flex;\n        align-items: center;\n        gap: 1rem;\n        background-color: #1f2128 !important;\n\n        border: 0 !important;\n    }\n\n    .show {\n        .news-source-filter__button--toggle-inner {\n            filter: invert(33%) sepia(85%) saturate(4865%) hue-rotate(196deg) brightness(104%) contrast(101%) !important;\n        }\n    }\n\n    &__menu{\n        background-color: #191B20 !important;\n        border-radius: 1rem;\n        padding: 2rem !important;\n        border: 1px solid rgba(228, 228, 228, 0.1);\n    }\n}\n\n\n.news-source-filter__content{\n    ul{\n        display: flex;\n        flex-direction: column;\n        gap: 1rem;\n\n        max-height: 300px;\n    }\n}\n\n.news-source-filter__menu-item-inner{ \n    &:hover{\n        background-color: rgba(255, 0, 0, 0.0) !important;\n}}\n\n\n.dropdown-toggle::after{\n    display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
