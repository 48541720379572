import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import AnalyticsPopup from "../../../../components/AnalyticsPopup";
import ExpandIcon from "../../../../assets/ExpandIcon";
import { MoonLoader } from "react-spinners";

const SingleBarChart = ({
  barData,
  sentiment = false,
  type,
  param,
  handleParams,
  top,
  loading,
}) => {
  const { series, categories } = barData || {};
  const [seriesIndex, setSeriesIndex] = useState(null);
  const [dataPointIndex, setDataPointIndex] = useState(null);
  const [senti, setSenti] = useState(null);
  const showModal = (config) => {
    setSeriesIndex(categories[config.dataPointIndex]);
    setDataPointIndex(config.dataPointIndex);
    setSenti(config.seriesIndex);
  };

  const closeModal = () => {
    setSeriesIndex(null);
    setDataPointIndex(null);
    setSenti(null);
  };
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define breakpoints
  const isMobile = windowSize.width <= 1024;
  const options = {
    series: [
      {
        name: "Inflation",
        data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8],
      },
    ],
    options: {
      chart: {
        height: "500px",
        type: "bar",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            showModal(config);
          },
        },
      },

      plotOptions: {
        bar: {
          columnWidth: "80%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: [sentiment ? "#ea7665" : "#61bba3"],
      dataLabels: {
        enabled: true,
        // formatter: function (val) {
        //   return val + "%";
        // },
        offsetY: -30,
        style: {
          fontSize: "1.2rem",
          colors: ["#746e75"],
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: "#746e75",
          },
        },
        categories: categories || [],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#746e75",
          },
          enabled: false,
        },

        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    },
  };

  return options ? (
    <div
      className={`d-flex flex-column rounded-4 p-4  w-100 ${
        isMobile ? "" : "bar-container"
      } `}
    >
      <button disabled={loading}>
        <span
          onClick={() => {
            handleParams(!top);
          }}
        >
          <ExpandIcon />
        </span>
      </button>

      <div>
        {loading ? (
          <div
            style={{ width: "336px" }}
            className="d-flex justify-content-center h-100"
          >
            <MoonLoader color="#36d7b7" />
          </div>
        ) : (
          <ReactApexChart
            height={isMobile ? "400px" : "400px"}
            width={isMobile ? "700px" : "100%"}
            options={{
              ...options.options,
            }}
            series={series || []}
            type="bar"
          />
        )}
      </div>

      <AnalyticsPopup
        show={seriesIndex}
        handleClose={closeModal}
        dataPointIndex={dataPointIndex}
        seriesIndex={seriesIndex}
        type={type}
        param={param}
        sentiment={sentiment === true ? 1 : 0}
      />
    </div>
  ) : (
    <></>
  );
};

export default SingleBarChart;
