import React from "react";
import ReactPlayer from "react-player";

const VideoContainer = ({ videoLink }) => {
  return (
    <div className="rounded-4">
      {videoLink && (
        <ReactPlayer
          url={videoLink}
          width="50vw"
          height="50vh"
          controls={true}
        />
      )}
    </div>
  );
};

export { VideoContainer };
