import React from "react";

const ScrollIIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.90136 10L8.99997 8.64792L8.09858 10L8.99997 11.3521L9.90136 10Z"
        fill="#D6D6D6"
      />
      <path
        d="M9.6009 12.2535L10.5023 10.9014L10.8428 11.4122C11.8165 12.8727 13.4557 13.75 15.2111 13.75H17.1893L15.4697 12.0303C15.1768 11.7374 15.1768 11.2626 15.4697 10.9697C15.7626 10.6768 16.2374 10.6768 16.5303 10.9697L19.5303 13.9697C19.8232 14.2626 19.8232 14.7374 19.5303 15.0303L16.5303 18.0303C16.2374 18.3232 15.7626 18.3232 15.4697 18.0303C15.1768 17.7374 15.1768 17.2626 15.4697 16.9697L17.1893 15.25H15.2111C12.9579 15.25 10.8535 14.1258 9.6009 12.2535Z"
        fill="#D6D6D6"
      />
      <path
        d="M7.49766 9.09861L8.39904 7.74654C7.1464 5.87424 5.04205 4.75 2.78887 4.75H1C0.585786 4.75 0.25 5.08579 0.25 5.5C0.25 5.91421 0.585786 6.25 1 6.25H2.78887C4.54422 6.25 6.18344 7.12728 7.15713 8.58782L7.49766 9.09861Z"
        fill="#D6D6D6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.4697 1.96967C15.7626 1.67678 16.2374 1.67678 16.5303 1.96967L19.5303 4.96967C19.8232 5.26256 19.8232 5.73744 19.5303 6.03033L16.5303 9.03033C16.2374 9.32322 15.7626 9.32322 15.4697 9.03033C15.1768 8.73744 15.1768 8.26256 15.4697 7.96967L17.1893 6.25H15.2111C13.4557 6.25 11.8165 7.12728 10.8428 8.58782L8.40521 12.2442C7.15331 14.1221 5.04575 15.25 2.78887 15.25H1C0.585786 15.25 0.25 14.9142 0.25 14.5C0.25 14.0858 0.585786 13.75 1 13.75H2.78887C4.54422 13.75 6.18344 12.8727 7.15713 11.4122L9.59473 7.75577C10.8466 5.87793 12.9542 4.75 15.2111 4.75H17.1893L15.4697 3.03033C15.1768 2.73744 15.1768 2.26256 15.4697 1.96967Z"
        fill="#D6D6D6"
      />
    </svg>
  );
};

export default ScrollIIcon;
