// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table--report {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
.table--report thead tr th:first-child {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.table--report thead tr th:last-child {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}
.table--report tbody tr td:first-child {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.table--report tbody tr td:last-child {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/DailyReport/styles.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,wBAAA;AAAR;AAKgB;EACI,2CAAA;AAHpB;AAMgB;EACI,2CAAA;AAJpB;AAagB;EACE,2CAAA;AAXlB;AAcgB;EACE,2CAAA;AAZlB","sourcesContent":[".table{\n    &--report{\n        border-collapse: separate;\n        border-spacing: 0 .5rem;\n\n        thead {\n            tr {\n                th {\n                &:first-child {\n                    border-radius: .5rem 0 0 .5rem !important;\n                }\n        \n                &:last-child {\n                    border-radius: 0 .5rem .5rem 0 !important;\n                }\n                }\n            }\n        }\n\n        tbody{\n            tr{\n              td{\n                &:first-child{\n                  border-radius: .5rem 0 0 .5rem !important;\n                }\n      \n                &:last-child {\n                  border-radius:  0 .5rem .5rem 0 !important;\n                  // \n                //   a{\n                //     visibility: hidden;\n                //   }\n                }\n              }\n            }\n      \n            // :hover{\n            //   td{\n            //     background-color: white  !important;\n                \n            //     color: black !important;\n                \n      \n            //     &:last-child{\n            //       a{\n            //         visibility: visible;\n            //       }\n            //     }\n            //     &:first-child{\n      \n            //       input{\n            //         outline: 2rem !important;\n            //       }\n            //     }\n            //   }\n            // }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
