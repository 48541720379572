import apiService from "../../../../dataService/apiService";
import { getEnvironment } from "../../../../const/environment";

export const getLiveTVScroll = (isKeywordSearch, user, done = () => {}) => {
  const { availableTvChannels } = user || {};

  const params = {
    keys: "",
    nextNewsPaperOffset: 0,
    nextTvOffset: 0,
    size: 6,
    type: 2,
    name:
      availableTvChannels.length > 0
        ? availableTvChannels.map((an) => an.code).join(",")
        : "",
    isKeywordSearch: isKeywordSearch === true ? 1 : 0,
  };

  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },

    {},
    params
  );
};
