import { Outlet, Route } from "react-router-dom";

import { Layout } from "../components";

import {
  AlertNotification,
  Analysis,
  Bookmarks,
  CaseManagement,
  Category,
  DailyReport,
  Dashboard,
  Dictionary,
  Icons,
  LiveStream,
  Login,
  ManagerReport,
  NewspaperLive,
  NewspaperReport,
  NewspaperSearch,
  NewspapersNews,
  ReportPreview,
  SingleNewsView,
  SingleNewspaperLive,
  SingleTVScrollLive,
  SourceWiseNews,
  TVScrollLive,
  TVScrollNews,
  TVScrollSearch,
  Tags,
  TopKeywordView,
  TopicRelatedNews,
  TrendingKeywordView,
  UniqueScrollNews,
  Video,
  VideoCrop,
  Notice,
  MyNotice,
  SeenNoticeList,
  ReportOverview,
  VideoCropReportOverview,
  MarkingReportOverview,
  MarkingReportOverviewDetails,
  NewspaperReportOverviewDetails,
  VideoCropOverviewDetails,
  ManagerMarkedReport,
  AnalyticalDashboard,
  MinisterDashboard,
  UniqueReportDashboard,
} from "../pages";

import { PermissionAlert } from "./../components";

import { ROUTE } from "../const";
import { pagePermission } from "../const/pagePemission";

import AllowedComponent from "./AllowedComponent";

const routes = [
  {
    id: "1",
    path: "",
    component: (
      <div>
        <Layout>
          <Outlet />
        </Layout>
      </div>
    ),
    permissions: {
      auth: ["token"],
      userCode: [],
    },
    children: [
      {
        id: "1.1",
        path: `${ROUTE.DASHBOARD}`,
        component: <MinisterDashboard />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.dashboard],
        },
        children: [],
      },
      {
        id: "1.2",
        path: `${ROUTE.VIDEO}`,
        component: <Video />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.monitor],
        },
        children: [],
      },
      {
        id: "1.3",
        path: `${ROUTE.NEWS}`,
        component: <Analysis />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],

          userCode: [pagePermission.analysis],
        },
        children: [],
      },
      {
        id: "1.3.1",
        path: `${ROUTE.NEWSFULLVIEW}/:query`,
        component: <SingleNewsView />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.analysis],
        },
        children: [],
      },
      {
        id: "1.4",
        path: `${ROUTE.DICTIONARY}`,
        component: <Dictionary />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.dictionary],
        },
        children: [],
      },
      {
        id: "1.5",
        path: `${ROUTE.NEWSPAPERNEWS}`,
        component: <NewspapersNews />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.allNewspapers],
        },
        children: [],
      },
      {
        id: "1.5.1",
        path: `${ROUTE.NEWSPAPERNEWS}/:newspaperName`,
        component: <SourceWiseNews />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.6",
        path: `${ROUTE.TVSCROLLNEWS}`,
        component: <TVScrollNews />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.allTVs],
        },
        children: [],
      },
      {
        id: "1.7",
        path: `${ROUTE.CASEMANAGEMENT}`,
        component: <CaseManagement />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.caseManagement],
        },
        children: [],
      },
      {
        id: "1.7.1",
        path: `${ROUTE.CASEMANAGEMENT}/:relatedTopicId`,
        component: <TopicRelatedNews />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.singleCaseManagement],
        },
        children: [],
      },
      {
        id: "1.8",
        path: `${ROUTE.ALERTNOTIFICATION}`,
        component: <AlertNotification />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.alerts],
        },
        children: [],
      },
      {
        id: "1.9",
        path: `${ROUTE.BOOKMARKS}`,
        component: <Bookmarks />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.bookmark],
        },
        children: [],
      },

      {
        id: "1.10",
        path: `${ROUTE.LIVERSTREAM}`,
        component: <LiveStream />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.liveStream],
        },
        children: [],
      },
      {
        id: "1.11",
        path: `${ROUTE.TOPKEYWORDVIEW}/:keywords`,
        component: <TopKeywordView />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.12",
        path: `${ROUTE.TRENDINGKEYWORDVIEW}/:trendingKeywords`,
        component: <TrendingKeywordView />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.13",
        path: `${ROUTE.DAILYREPORT}`,
        component: <DailyReport />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.dailyReport],
        },
        children: [],
      },
      {
        id: "1.14",
        path: `${ROUTE.MANAGERREPORT}`,
        component: <ManagerMarkedReport />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.managerReport],
        },
        children: [],
      },
      {
        id: "1.15",
        path: `${ROUTE.MANAGERREPORT_PREVIEW}`,
        component: <ReportPreview />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.managerReport],
        },
        children: [],
      },
      {
        id: "1.16",
        path: `${ROUTE.TAGS}`,
        component: <Tags />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.tags],
        },
        children: [],
      },
      {
        id: "1.17",
        path: `${ROUTE.LIVENEWSPAER}`,
        component: <NewspaperLive />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.newspaperLive],
        },
        children: [],
      },
      {
        id: "1.18",
        path: `${ROUTE.LIVENEWSPAER}/:newspaperName`,
        component: <SingleNewspaperLive />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.newspaperLive],
        },
        children: [],
      },
      {
        id: "1.19",
        path: `${ROUTE.LIVETVSCROLL}`,
        component: <TVScrollLive />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.tvScrollLive],
        },
        children: [],
      },
      {
        id: "1.20",
        path: `${ROUTE.LIVETVSCROLL}/:channelName`,
        component: <SingleTVScrollLive />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.tvScrollLive],
        },
        children: [],
      },
      {
        id: "1.21",
        path: `${ROUTE.NEWSPAPERSEARCH}`,
        component: <NewspaperSearch />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.newspaperSearch],
        },
        children: [],
      },
      {
        id: "1.22",
        path: `${ROUTE.TVSCROLLSEARCH}`,
        component: <TVScrollSearch />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.tvScrollSearch],
        },
        children: [],
      },

      {
        id: "1.23",
        path: `${ROUTE.VIDEO_CROP}`,
        component: <VideoCrop />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.videoCrop],
        },
        children: [],
      },
      {
        id: "1.24",
        path: `${ROUTE.UNIQUE_SCROLL_NEWS}`,
        component: <UniqueScrollNews />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.uniqueScrollNews],
        },
        children: [],
      },
      {
        id: "1.25",
        path: `${ROUTE.ICONS}`,
        component: <Icons />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.icons],
        },
        children: [],
      },
      {
        id: "1.26",
        path: `${ROUTE.REPORT_OVERVIEW}`,
        component: <ReportOverview />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.reportOverview],
        },
        children: [],
      },
      {
        id: "1.27",
        path: `${ROUTE.MARKING_REPORT_OVERVIEW}`,
        component: <MarkingReportOverview />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.28",
        path: `${ROUTE.VIDEO_CROP_REPORT_OVERVIEW}`,
        component: <VideoCropReportOverview />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.29",
        path: `${ROUTE.CATEGORY}`,
        component: <Category />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.category],
        },
        children: [],
      },
      {
        id: "1.30",
        path: `${ROUTE.NEWSPAPER_REPORT}`,
        component: <NewspaperReport />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.newspaperReport],
        },
        children: [],
      },

      {
        id: "1.31",
        path: `${ROUTE.NOTICE}`,
        component: <Notice />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.notice],
        },
        children: [],
      },
      {
        id: "1.32",
        path: `${ROUTE.MYNOTICE}`,
        component: <MyNotice />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.33",
        path: `${ROUTE.SEENNOTICELIST}/:seen_notice`,
        component: <SeenNoticeList />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },

      {
        id: "1.34",
        path: `${ROUTE.NEWSPAPER_REPORT_OVERVIEW_DETAILS}`,
        component: <NewspaperReportOverviewDetails />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.35",
        path: `${ROUTE.MARKING_REPORT_OVERVIEW_DETAILS}`,
        component: <MarkingReportOverviewDetails />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.36",
        path: `${ROUTE.VIDEO_CROP_OVERVIEW_DETAILS}`,
        component: <VideoCropOverviewDetails />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [],
        },
        children: [],
      },
      {
        id: "1.37",
        path: `${ROUTE.ANALYTICAL_DASHBOARD}`,
        component: <AnalyticalDashboard />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.analyticalDashbaord],
        },
        children: [],
      },
      {
        id: "1.38",
        path: `${ROUTE.MINISTER_DASHBOARD}`,
        component: <MinisterDashboard />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.analyticalDashbaord],
        },
        children: [],
      },
      {
        id: "1.38",
        path: `${ROUTE.UNIQUE_REPORT_DASHBOARD}`,
        component: <UniqueReportDashboard />,
        _throw: <PermissionAlert />,
        redirection: <Login />,
        permissions: {
          auth: ["token"],
          userCode: [pagePermission.analyticalDashbaord],
        },
        children: [],
      },
    ],
  },
  {
    id: "2",
    path: `${ROUTE.LOGIN}`,
    component: <Login />,
    _throw: <PermissionAlert />,
    redirection: <Login />,
    permissions: {
      auth: ["token"],
      userCode: [],
    },
    children: [],
  },
];

const buildRoute = (route) => {
  if (route === undefined || null) return;
  const {
    id,
    children,
    path,
    component,
    permissions,
    redirection,
    index,
    _throw,
  } = route;
  return (
    <Route
      key={id}
      path={path}
      index={index}
      element={
        component &&
        AllowedComponent({ component, redirection, permissions, path, _throw })
      }
    >
      {children &&
        children.length > 0 &&
        children.map((child) => buildRoute(child))}
    </Route>
  );
};

const getRoute = () => routes.map((route) => buildRoute(route));

export { getRoute };
