import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";

export const downloadCategoryExcel = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/category-list/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const downloadCategoryDoc = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/category-list/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
