import React from "react";
import { BsDot } from "react-icons/bs";
import moment from "moment";
const NoteComponent = ({ time }) => {
  return (
    <div
      className="bg-black__light my-4 w-100 px-4 py-4 rounded-4  shadow"
      style={{ maxWidth: "30rem" }}
    >
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-row gap-2 align-items-center">
          <div
            style={{ height: "1rem", width: "1rem", background: "#fbbc05" }}
            className="rounded-circle"
          ></div>
          <div className="text-white font-s-md font-w-bolder">Note</div>
        </div>

        <div className="text-light font-s-n font-w-bold">
          Aggregated data is processed in 1 Hour interval. So following data is
          presented here from{" "}
          {moment(time.startDate).startOf("hour").format("hh:mm A")} to{" "}
          {moment(time.endDate).endOf("hour").format("hh:mm A")} .
        </div>
      </div>
    </div>
  );
};

export default NoteComponent;
