import Dashboard from "../SidebarSvg/Dashboard/index";
import Setting from "../SidebarSvg/Setting/index";
import Monitor from "../SidebarSvg/Monitor/index";
import Dictionary from "../SidebarSvg/Dictionary/index";
import CaseManagement from "../SidebarSvg/CaseManagement/index";
import Alerts from "../SidebarSvg/Alerts/index";
import Analysis from "../SidebarSvg/Analysis/index";
import Report from "../SidebarSvg/Report/index";
import Bookmark from "./Bookmark";
import GetLiveStream from "./GetLiveStream";

import LiveNewspaper from "./LiveNewspaper";

export {
  Dictionary,
  Dashboard,
  Setting,
  Monitor,
  CaseManagement,
  Analysis,
  Alerts,
  Report,
  Bookmark,
  LiveNewspaper
};
