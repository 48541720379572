import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";

export const loadTags = async ( searchQuery, loadOptions, {page}) => {
    const env = getEnvironment();
    const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/tag-words/all`;
  
    const params = {
      page: page,
    };
  
    let loadedData = null;
    await apiService.get(
      url,
  
      (status, data) => {
        if (status > 199 && status < 300) {
          loadedData = {
            options: data.words,
            hasMore: data.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        } else {
          loadedData = {
            options: [],
            hasMore: false,
            additional: {
              page,
            },
          };
        }
      },
      {},
      params
    );
  
    return loadedData;
  };