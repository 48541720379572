import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

import { getDate_3W2D3M2Y, getTime_12T } from "../../../../const/dateFormat";
import { getBySourceCode } from "./../../../../const/newsSources";

import { useNewsSources, useUser } from "../../../../dataService/contexts";
import { SearchVideoCrop } from "../../../VideoCrop/components";
import "./style.scss";

import ScheduleCropSearch from "../../../../components/Layouts/components/Navbar/components/ScheduleCropSearch";
import { getVideoCrop } from "./videoProp.service";

const VideoPopup = ({ handleClose, show, videoData, handleDownload }) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const channel = getBySourceCode(videoData.tvChannel, newsSources);

  const { state: userState } = useUser();
  const { user } = userState || {};
  const [searchState, setSearchState] = useState("");

  const [cropLoading, setCropLoading] = useState(false);

  const [selectedVideoCrop, setSelectedVideoCrop] = useState([]);
  const [croppedVideo, setCroppedVideo] = useState("");

  const initialCropParams = {
    startDate: "",
    endDate: "",
    cropTitle: "",
    sourceName: "",
  };

  const handleSelectedCroppedVideo = (videoCrop) => {
    setSelectedVideoCrop((prev) => [
      {
        startDate: videoCrop.start,
        endDate: videoCrop.end,
        sourceName: videoCrop.sourceName,
        url: videoCrop.link,
        cropTitle: videoCrop.title,
      },

      ...prev,
    ]);
  };
  const [cropParams, setCropParams] = useState(initialCropParams);

  const handleCropParams = (cropParams) => {
    setCropParams((prev) => {
      return {
        ...prev,
        cropTitle: cropParams.cropTitle,
        startDate: cropParams.startDate,
        endDate: cropParams.endDate,
        sourceName: videoData.tvChannel,
        url: videoData.streamLink,
        id: cropParams.id,
        description: cropParams.cropDescription,
        categoryId: cropParams.cropCategory,
        tags: cropParams.cropTags,
        sentiment: cropParams.sentiment,
      };
    });
  };

  const handleClickToPlay = (link) => {
    setCroppedVideo(link);
  };

  const handleCroppedVideoClose = () => {
    setCroppedVideo("");
  };

  const fetchVideoCrop = useCallback(() => {
    setSearchState("ON_GOING");
    setCropLoading(true);
    if (user)
      getVideoCrop(cropParams, user, (status, data) => {
        if (status === "success") {
          if (data.link === null) {
            setSearchState("NOT_FOUND");
          } else {
            setSearchState("CLICK_TO_PLAY");
            handleSelectedCroppedVideo(data);
          }
        } else {
          setSearchState("ERROR");
        }
        setCropLoading(false);
      });
  }, [user, cropParams]);
  useEffect(() => {
    if (
      user &&
      cropParams.startDate &&
      cropParams.endDate &&
      cropParams.sourceName &&
      cropParams.url
    )
      fetchVideoCrop();
  }, [user, cropParams]);

  useEffect(() => {
    if (videoData.crops && videoData.crops.length) {
      videoData.crops.map((crop) => {
        setSelectedVideoCrop((prev) => [
          ...prev,
          {
            startDate: crop.start,
            endDate: crop.end,
            sourceName: crop.sourceName,
            url: crop.link,
            cropTitle: crop.title,
          },
        ]);
      });
    }
  }, []);
  return (
    <Modal
      size="xl"
      centered
      show={show}
      onHide={handleClose}
      className="channel-video"
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-row align-items-center gap-3">
          {videoData.tvChannel && (
            <div>
              <img
                alt="channel logo"
                src={channel.icon}
                style={{
                  height: "3rem",
                  width: "3rem",
                  borderRadius: "12rem",
                }}
              />
            </div>
          )}
          <div className="d-flex flex-column">
            <div className="text-white text-capitalize font-s-md font-w-bold">
              {channel.name}
            </div>
            <div className="d-flex flex-column flex-wrap">
              <div className="font-s-nm text-light font-w-bold text-nowrap">
                {`${getTime_12T(videoData.videoStartTime)} - ${getTime_12T(
                  videoData.videoEndTime
                )}`}
              </div>
              <div className="font-s-nm text-light font-w-bold text-nowrap">
                {`${getDate_3W2D3M2Y(videoData.videoEndTime)}`}
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="rounded-4">
          {videoData.streamLink && (
            <ReactPlayer
              url={videoData.streamLink}
              width="100%"
              height="100%"
              controls={true}
              className="rounded-4"
            />
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-column gap-4 w-100">
          <div className="d-flex flex-row flex-wrap m-0 w-100 justify-content-end">
            <button
              type="button"
              onClick={() => handleDownload()}
              className="btn btn-primary p-0 px-4  rounded-3 d-flex align-self-center py-2 "
            >
              <div className="d-flex text-nowrap">Download Video</div>
            </button>
          </div>
          {croppedVideo && (
            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-row justify-content-between ">
                <div className="text-white font-s-lg font-w-bolder">
                  Cropped Video
                </div>
                <button
                  className=" rounded-circle "
                  style={{ background: "#5c5d62" }}
                  onClick={handleCroppedVideoClose}
                >
                  <div className="p-4 btn-close"></div>
                </button>
              </div>
              <ReactPlayer
                url={croppedVideo}
                width="100%"
                height="100%"
                controls={true}
                className="rounded-4"
              />
            </div>
          )}
          {searchState !== "ON_GOING" ? (
            <div>
              <SearchVideoCrop
                onChange={handleCropParams}
                loading={cropLoading}
                selectedVideo={videoData}
              />
            </div>
          ) : (
            <></>
          )}
          {selectedVideoCrop.length > 0 && searchState !== "ON_GOING" && (
            <div className="text-white mb-3 font-s-lg font-w-bold">
              Scheduled Video Search
            </div>
          )}

          {searchState === "ON_GOING" ? (
            <div>
              <div className="text-white mb-3 font-s-lg font-w-bold">
                Scheduled Video Search
              </div>
              <ScheduleCropSearch
                params={cropParams}
                searchState={searchState}
                playSearchVideo={handleClickToPlay}
              />
            </div>
          ) : (
            searchState === "ERROR" && (
              <ScheduleCropSearch
                params={cropParams}
                searchState={searchState}
                playSearchVideo={handleClickToPlay}
              />
            )
          )}
          {selectedVideoCrop && selectedVideoCrop.length > 0 && (
            <div>
              {selectedVideoCrop.map((video) => (
                <ScheduleCropSearch
                  params={video}
                  searchState={"CLICK_TO_PLAY"}
                  playSearchVideo={handleClickToPlay}
                />
              ))}
            </div>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export { VideoPopup };
