import React, { useState } from "react";
import Toggle from "react-toggle";

import "./styles.scss";

const Category = ({ handleCategory, currentCategory }) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <div
        className={`font-s-md font-w-bolder ${
          !currentCategory ? "text-danger" : "text-light"
        }`}
      >
        All sources
      </div>
      <Toggle
        defaultChecked={currentCategory}
        onChange={(e) => handleCategory("sourceCategory", e.target.checked)}
      />
      <div
        className={`font-s-md font-w-bolder ${
          currentCategory ? "text-success" : "text-light"
        }`}
      >
        Only from my sources
      </div>
    </div>
  );
};

export default Category;
