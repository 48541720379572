import React from "react";

const CaseManagement = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79413 5.05623C2.79413 3.80723 3.80713 2.79423 5.05513 2.79423H6.08413C6.68013 2.79423 7.25313 2.55823 7.67713 2.13723L8.39613 1.41723C9.27713 0.532234 10.7091 0.528234 11.5941 1.40923L11.6031 1.41723L12.3231 2.13723C12.7461 2.55823 13.3191 2.79423 13.9161 2.79423H14.9441C16.1931 2.79423 17.2061 3.80723 17.2061 5.05623V6.08323C17.2061 6.68123 17.4421 7.25323 17.8631 7.67723L18.5831 8.39723C19.4681 9.27823 19.4731 10.7092 18.5921 11.5952L18.5831 11.6042L17.8631 12.3242C17.4421 12.7462 17.2061 13.3202 17.2061 13.9162V14.9452C17.2061 16.1942 16.1931 17.2062 14.9441 17.2062H13.9161C13.3191 17.2062 12.7461 17.4432 12.3231 17.8642L11.6031 18.5832C10.7231 19.4692 9.29113 19.4732 8.40513 18.5922C8.40213 18.5892 8.39913 18.5862 8.39613 18.5832L7.67713 17.8642C7.25313 17.4432 6.68013 17.2062 6.08413 17.2062H5.05513C3.80713 17.2062 2.79413 16.1942 2.79413 14.9452V13.9162C2.79413 13.3202 2.55713 12.7462 2.13613 12.3242L1.41713 11.6042C0.531134 10.7232 0.527134 9.29123 1.40813 8.40623L1.41713 8.39723L2.13613 7.67723C2.55713 7.25323 2.79413 6.68123 2.79413 6.08323V5.05623"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M7.42969 12.5697L12.5697 7.42969"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M12.5664 13.323C12.3664 13.323 12.1764 13.243 12.0364 13.103C11.9664 13.033 11.9164 12.943 11.8764 12.853C11.8364 12.763 11.8164 12.674 11.8164 12.573C11.8164 12.473 11.8364 12.373 11.8764 12.283C11.9164 12.193 11.9664 12.113 12.0364 12.043C12.3164 11.763 12.8164 11.763 13.0964 12.043C13.1664 12.113 13.2264 12.193 13.2664 12.283C13.2964 12.373 13.3164 12.473 13.3164 12.573C13.3164 12.674 13.2964 12.763 13.2664 12.853C13.2264 12.943 13.1664 13.033 13.0964 13.103C12.9564 13.243 12.7664 13.323 12.5664 13.323Z"
        fill="#808191"
      />
      <path
        opacity="0.5"
        d="M7.42627 8.18313C7.32627 8.18313 7.23627 8.16213 7.14627 8.12213C7.05627 8.08213 6.96627 8.03313 6.89627 7.96313C6.82627 7.88313 6.77627 7.80313 6.73627 7.71313C6.69627 7.62213 6.67627 7.53313 6.67627 7.43313C6.67627 7.33213 6.69627 7.23313 6.73627 7.14313C6.77627 7.05313 6.82627 6.96313 6.89627 6.90313C7.18627 6.62213 7.67627 6.62213 7.95627 6.90313C8.09627 7.04213 8.17627 7.23313 8.17627 7.43313C8.17627 7.53313 8.16627 7.62213 8.12627 7.71313C8.08627 7.80313 8.02627 7.88313 7.95627 7.96313C7.88627 8.03313 7.80627 8.08213 7.71627 8.12213C7.62627 8.16213 7.52627 8.18313 7.42627 8.18313Z"
        fill="#808191"
      />
    </svg>
  );
};

export default CaseManagement;
