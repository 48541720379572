import React, { useEffect, useState } from "react";
import Select from "react-select";

import "../../../../components/NewsSorceTypes/style.scss";
import { options } from "axios";
import { useCategory } from "../../../../dataService/contexts";

const CategoryOptions = ({ handleCategory, seletedCategory }) => {
  const { state: categoryState } = useCategory();
  const { categories } = categoryState;

  const [initialCategories, setInitialCategories] = useState([]);

  const updateCategory = (e) => {
    handleCategory("category", { categoryName: e });
  };

  useEffect(() => {
    if (categories && categories.length) {
      categories.map((item) => {
        setInitialCategories((prev) => [
          ...prev,
          { id: item.id, value: item.categoryName, label: item.categoryName },
        ]);
      });
    }
  }, [categories]);

  return (
    <Select
      isSearchable={true}
      options={initialCategories}
      className="news-source-types-container  "
      classNamePrefix="news-source-types"
      getOptionLabel={(options) => `${options.label}`}
      defaultValue={
        seletedCategory
          ? {
              value: seletedCategory.categoryName,
              label: seletedCategory.categoryName,
            }
          : null
      }
      placeholder="Categories"
      onChange={(e) => {
        if (e !== null) {
          updateCategory(e.value);
        } else {
          updateCategory("");
        }
      }}
      //
      isClearable
    />
  );
};

export default CategoryOptions;
