import apiService from "../../../../dataService/apiService";
import moment from "moment";

import { getEnvironment } from "../../../../const/environment";

export const getTrendingKeywordsAlert = (done = () => {}) => {
  const env = getEnvironment();
  const today = moment(new Date()).format("YYYY-MM-DD");

  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/v1/private/trending-keyword?date=${today}`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },
    {}
  );
};
