import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import GetAddNewIcon from "../../../../assets/GetAddNewIcon";
import AddKeyword from "../AddKeyword";
import { RemoveKeywordService } from "./RemoveKeyword.service";

const TopicKeywordList = ({
  keywords,
  name,
  detail,
  id,
  getKeywords,
  getUpdatedData,
}) => {
  const [show, setShow] = useState(false);

  const handleOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const removeKeyword = (id) => {
    const newArray = [...keywords];
    newArray.splice(id, 1);
    updateRemovedKeyword(newArray);
  };

  const updateRemovedKeyword = (keywords) => {
    RemoveKeywordService(
      {
        topic: name,
        topicDetails: detail,
        allTopicKeywords: keywords,
      },
      id,
      (status, data) => {
        if (status === "success") {
          window.location.reload(true);
        }
      }
    );
  };

  return (
    <div className="d-flex flex-column justify-content-between gap-4">
      <div className="d-flex flex-row justify-content-between">
        <div className="text-white font-s-md font-w-bold align-self-center">
          Topic Keyword
        </div>
        <button
          className="bg-transparent btn text-primary font-s-md font-w-bold align-self-center"
          onClick={handleOpen}
        >
          {GetAddNewIcon()} Add New
        </button>
      </div>

      <div>
        <table className="table--alerts w-100 ">
          <thead>
            <tr className="text-light font-s-sm font-w-bolder text-uppercase bg-black__light shadow">
              <th className="py-2  ps-3">Keyword</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {keywords.length > 0 &&
              keywords.map((alert, index) => (
                <tr key={alert} className="shadow bg-black__light">
                  <td className="text-danger font-s-sm font-w-bolder py-2  ps-3 d-flex flex-row gap-3">
                    <div
                      onClick={() => getKeywords([alert])}
                      style={{ cursor: "pointer" }}
                    >
                      {alert}
                    </div>
                    {/*<div className="d-flex gap-2 align-items-center">*/}
                    {/*  <input type="checkbox" name={alert} />*/}
                    {/*</div>*/}
                  </td>

                  <td className="text-white font-s-sm font-w-bolder py-2 pe-4">
                    <div className="d-flex justify-content-end">
                      <Link onClick={() => removeKeyword(index)}>Remove</Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {show && (
        <AddKeyword
          topic={name}
          keyword={keywords}
          id={id}
          detail={detail}
          handleClose={handleClose}
          show={show}
          getUpdatedData={getUpdatedData}
        />
      )}
    </div>
  );
};

export default TopicKeywordList;
