import React, { useEffect, useState } from "react";
import moment from "moment";

import { DateTime } from "../../../../components";

import LoadMoreIcon from "./../../../../assets/loadMore";

const date = {};
const DateRange = ({ onChange, loading, params, onDateChange }) => {
  const [state, setState] = useState({
    startDate: params.startDate,
    endDate: params.endDate,
    sourceName: "",
  });
  const handleState = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: date,
      };
    });
  };

  const handleSubmit = () => {
    onDateChange(state);
  };

  useEffect(() => {
    if (params.startDate && params.endDate)
      setState({ startDate: params.startDate, endDate: params.endDate });
  }, [params]);
  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex rounded-4 text-white  border border-2 border-light justify-content-evenly">
        <button
          className={`d-flex border border-2 border-light border-top-0 border-bottom-0 border-start-0 p-3 w-100 justify-content-center ${
            params.activeStatus === 4 ? "bg-light" : "bg-transparent"
          }`}
          disabled={params.activeStatus === 4}
          onClick={() => {
            onChange(4);
          }}
        >
          Custom Date
        </button>
        <button
          className={`d-flex border border-2 border-light border-top-0 border-bottom-0 border-start-0 p-3 w-100 justify-content-center ${
            params.activeStatus === 1 ? "bg-light" : "bg-transparent"
          }`}
          disabled={params.activeStatus === 1}
          onClick={() => {
            onChange(1);
          }}
        >
          Monthly
        </button>
        <button
          className={`d-flex  border border-2 border-light border-top-0 border-bottom-0 border-start-0 p-3 w-100 justify-content-center ${
            params.activeStatus === 2 ? "bg-light" : "bg-transparent"
          }`}
          disabled={params.activeStatus === 2}
          onClick={() => {
            onChange(2);
          }}
        >
          Weekly
        </button>
        <button
          className={`p-3 w-100 d-flex justify-content-center ${
            params.activeStatus === 3 ? "bg-light" : "bg-transparent"
          }`}
          disabled={params.activeStatus === 3}
          onClick={() => {
            onChange(3);
          }}
        >
          Daily
        </button>
      </div>
      {params.activeStatus === 4 ? (
        <div className="d-flex flex-row gap-4">
          <div style={{ minWidth: "100px" }}>
            <DateTime
              placeholder={"From"}
              date={moment(state.startDate).format("YYYY-MM-DD, hh:mm A")}
              handleDateWithTime={(e) => {
                handleState("startDate", e);
              }}
              hasTime={true}
            />
          </div>
          <div style={{ minWidth: "100px" }}>
            <DateTime
              placeholder={"To"}
              date={moment(state.endDate).format("YYYY-MM-DD, hh:mm A")}
              handleDateWithTime={(e) => {
                handleState("endDate", e);
              }}
              hasTime={true}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary px-4 py-3 rounded-4"
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            <div className="px-3 font-w-bolder font-s-md">
              {loading ? <LoadMoreIcon /> : "Search"}
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DateRange;
