const actionTypes = {
  authRequest: "AUTH_REQUEST",
  authSuccess: "AUTH_SUCCESS",
  authFail: "AUTH_FAIL",
  logoutRequest: "LOGOUT",

  userInfoRequest: "USER_INFO_REQUEST",
  userInfoSuccess: "USER_INFO_SUCCESS",
  userInfoFail: "USER_INFO_FAIL",

  notificationRequest: "NOTIFICATION_REQUEST",
  notificationSuccess: "NOTIFICATION_SUCCESS",
  notificationFail: "NOTIFICATION_FAIL",
  notificationAdd: "NOTIFICATION_ADD",

  ongoingRequestsAdd: "ONGOING_REQUESTS_ADD",
  ongoingRequestsUpdate: "ONGOING_REQUESTS_UPDATE",
  ongoingRequestsReset: "ONGOING_REQUESTS_RESET",

  pageNameSet: "PAGE_NAME_SET",

  categoryRequest: "CATEGORY_REQUEST",
  categorySuccess: "CATEGORY_SUCCESS",
  categoryFail: "CATEGORY_FAIL",

  newsSourcesRequest: "NEWSSOURCES_REQUEST",
  newsSourcesSuccess: "NEWSSOURCES_SUCCESS",
  newsSourcesFail: "NEWSSOURCES_FAIL",

  tagsRequest: "TAGS_REQUEST",
  tagsSuccess: "TAGS_SUCCESS",
  tagsFail: "TAGS_FAIL",
};

export default actionTypes;
