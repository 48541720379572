import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

export const getAnalyticalNewspaperReport = (
  params,
  sourceName,
  sentiment,
  type,
  done = () => {}
) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/public-dashboard-service/api/${env.version}/${env.type}/${
    type === 2
      ? "marked-report"
      : type === 4
      ? "unique-scroll"
      : "category-wise-report"
  }/${type === 4 ? "report" : "details"}?${
    type === 2 ? "date=" : "startDate="
  }${params.startDate}&${type === 2 ? "endDate=" : "endDate="}${
    params.endDate
  }`;
  const _params = {
    size: params.size,
    page: params.page,
    categoryName: type === 3 ? sourceName : "",
    sentiment: Number(sentiment) === 0 ? 1 : 2,
    sourceName: type === 3 ? "" : sourceName,
    details: true,
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};
