import apiService from "../../../../dataService/apiService";
import { getEnvironment } from "../../../../const/environment";

export const AddUpdatedKeywordService = (data, id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/topic/${id}`;

  const _data = {
    name: data.topic,
    detail: data.topicDetails,
    keywords: data.allTopicKeywords,
  };

  apiService.put({ url, data: _data }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
