import React, { useState, useEffect } from "react";
import { setPageName, usePageName } from "../../dataService/contexts";
import { Notice } from "../../assets/Navbar";
import { getNotices, seenNotice } from "./myNotice.service.js";

// import "./style.css";

const MyNotice = () => {
  const { dispatch: pageNameDispatch } = usePageName();

  useEffect(() => {
    setPageName(pageNameDispatch, "My Notice");
  }, []);

  const [notices, setNotice] = useState([]);

  const fetchNotice = () => {
    getNotices((status, data) => {
      if (status === "success") {
        setNotice(data.data);
      }
    });
  };

  const updateNoticeSeen = (id) => {
    //alert(id);
    const params = {
      noticeId: id,
    };
    seenNotice(params, (status, data) => {
      if (status === "success") {
        window.location.reload(true);
      }
    });
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        {notices.length > 0 &&
          notices.map((notice, index) => (
            <div className="d-flex w-100" key={index}>
              <div className="p-4 d-flex flex-row gap-4 bg-black__light rounded-start flex-grow-1">
                <div>
                  <Notice />
                </div>

                <div className="d-flex flex-column flex-grow-1 gap-3">
                  <div className="d-flex flex-column justify-content-between  gap-4 w-100">
                    <div className="d-flex flex-wrap flex-row justify-content-between mt-2 gap-2">
                      <div className="d-flex gap-3 align-self-center ">
                        <div className="text-light">{notice.title}</div>
                      </div>

                      <div className="text-light font-s-nm text-nowrap font-w-bolder d-flex flex-column justify-content-between  align-items-end">
                        <div className="d-flex flex-row gap-3 flex-wrap">
                          <div className="align-self-center font-s-n"></div>
                          {notice.flag == "0" ? (
                            <button
                              className="btn btn-primary text-white"
                              onClick={() => {
                                updateNoticeSeen(notice.id);
                              }}
                            >
                              {" "}
                              Read{" "}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="font-w-normal font-s-md text-white"
                    style={{ letterSpacing: "1.25px" }}
                  >
                    <p className="">{notice.detail}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
export default MyNotice;
