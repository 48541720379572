import React, { useEffect, useState } from "react";
import { getNewsSourceType } from "../../const/newsSourceTypes";
import { useNewsSources, useUser } from "../../dataService/contexts";

import { useParams } from "react-router-dom";
import LoadMoreIcon from "../../assets/loadMore";
import {
  LoadingComponent,
  News,
  NewsDetails,
  NewsSourceFilter,
} from "../../components";
import { getBySourceCode } from "../../const/newsSources";
import { getTrendingKeywordsSearch } from "./trendingKeywordSearch.service";

const TrendingKeywordView = () => {
  const { trendingKeywords } = useParams();
  const _trendingKeywords = trendingKeywords ? trendingKeywords.split("&") : [];
  const newsKeyword = _trendingKeywords.length ? _trendingKeywords[0] : null;
  const newsType = _trendingKeywords.length ? _trendingKeywords[1] : null;

  const [news, setNews] = useState([]);
  const [newsSources, setNewsSources] = useState([]); //source names like Prothom Alo , Ekattor TV ...
  const [newsSourceTypesCustom, setNewsSourceTypes] = useState([
    { ...getNewsSourceType(newsType), checked: true },
  ]);

  const { state: newsSourcesState } = useNewsSources();
  const { sources } = newsSourcesState || {};

  const [totalNews, setTotalNews] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");
  const [selectedNews, setSelectedNews] = useState(null);

  const [loading, setLoading] = useState(false);

  const [params, setParams] = useState({
    name: "",
    nextNewsPaperOffset: 0,
    nextTvOffset: 0,
    size: 20,
    type: 0,
  });

  const { state: userState } = useUser();
  const { user } = userState || {};

  const handleClose = () => setSelectedNews(null);
  const handleShow = (news) => setSelectedNews(news);

  const updateParams = (key, value) => {
    setParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  // filters
  const toggleFilter = (key, event) => {
    setNewsSources((prev) => {
      return [
        ...prev.map((source) => {
          return source.code === event.target.id
            ? {
                ...source,
                checked: !source.checked,
              }
            : { ...source };
        }),
      ];
    });
  };

  const toggleBulkFilter = (type, isTypeChecked) =>
    setNewsSources((prev) => {
      if (type === "0") {
        return [
          ...prev.map((source) => ({
            ...source,
            checked: !isTypeChecked,
          })),
        ];
      } else {
        return [
          ...prev.map((source) =>
            Number(source.type) === Number(type)
              ? {
                  ...source,
                  checked: !isTypeChecked,
                }
              : { ...source }
          ),
        ];
      }
    });

  const getFilterTypeStatus = (key) => {
    return newsSourceTypesCustom.find((type) => type.id === key);
  };

  const removeAllFilters = () => {
    setNewsSources((prev) => {
      return [
        ...prev.map((type) => ({
          ...type,
          checked: false,
        })),
      ];
    });
  };

  const toggleFilterType = (key, event) => {
    const isTypeChecked = getFilterTypeStatus(key).checked;

    toggleBulkFilter(key, isTypeChecked);

    setNewsSourceTypes((prev) => {
      return [
        ...prev.map((type) => {
          return type.id === event.target.id
            ? {
                ...type,
                checked: !type.checked,
              }
            : { ...type };
        }),
      ];
    });
  };
  const search = (keyword, type) => {
    setOnGoingActions("SEARCH");

    setNews([]);
    setNewsSources([]);
    setTotalNews(0);

    fetchNews("SEARCH");
  };

  const loadMore = () => {
    //load more will increse the page number
    setOnGoingActions("LOADMORE");
    fetchNews("LOADMORE");
  };

  const filter = () => {
    //filter will reset the page
    setOnGoingActions("FILTER");

    setNews([]);
    setTotalNews(0);

    fetchNews("FILTER");
  };

  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const { _news, _newsSource, _total, _nextTvOffset, _nextNewsPaperOffset } =
      data;
    if (actionType === "SEARCH") {
      setNews([..._news]);

      setNewsSources([..._newsSource]);
      setTotalNews(_total);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    } else if (actionType === "LOADMORE") {
      setNews((prevNewsContent) => [...prevNewsContent, ..._news]);
      setTotalNews(_total);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    } else if (actionType === "FILTER") {
      setNews([..._news]);
      setTotalNews(_total);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    }
  };

  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return {
        nextNewsPaperOffset: params.nextNewsPaperOffset,
        nextTvOffset: params.nextTvOffset,
      };
    } else if (action === "SEARCH") {
      return {
        nextNewsPaperOffset: 0,
        nextTvOffset: 0,
      };
    } else {
      return {
        nextNewsPaperOffset: 0,
        nextTvOffset: 0,
      };
    }
  };

  const fetchNews = (actionType) => {
    if (user) {
      setLoading(true);

      getTrendingKeywordsSearch(
        {
          ...params,
          ...getEffectiveParamsPage(actionType),
        },
        user,
        actionType === "SEARCH" ? [] : newsSources,
        newsKeyword,
        newsType,
        (status, data, _params) => {
          if (status === "success") {
            if (data && data.news) {
              actionHandler(actionType, {
                _news: data.news,
                _newsSource: [
                  ...data.newsPaperNames.map((source) => ({
                    ...getBySourceCode(source.code, sources),
                    checked: true,
                  })),
                ],
                _total: data.total,
                _nextNewsPaperOffset: data.nextNewsPaperOffset,
                _nextTvOffset: data.nextTvOffset,
              });
            }
          }

          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    search();
  }, [user]);

  return (
    <>
      <div className="d-flex flex-column gap-5 p-4">
        <div className="d-flex justify-content-between">
          <div className="font-s-xlg text-white font-w-bold">
            Search by keywords
          </div>
          <div className="d-flex flex-row gap-2 align-self-center">
            <NewsSourceFilter
              items={newsSources}
              toggleFilter={toggleFilter}
              reload={filter}
              types={newsSourceTypesCustom}
              toggleFilterType={toggleFilterType}
              loadingState={loading}
              removeAllFilters={removeAllFilters}
            />
          </div>
        </div>

        {news.length > 0 && (
          <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
            {news.length > 0 &&
              news.map((news) => (
                <News
                  news={news}
                  action={handleShow}
                  keywords={
                    news.matchedKeywordList
                      ? news.matchedKeywordList.split(",")
                      : []
                  }
                  type=""
                  tags=""
                />
              ))}
          </div>
        )}

        {loading && (
          <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
            <LoadingComponent size={1} />
          </div>
        )}

        {news && news.length !== totalNews ? (
          <div className="d-flex justify-content-center">
            <button
              onClick={() => loadMore()}
              className="btn btn-sm btn-primary px-4 py-2 rounded-4 font-s-n"
              disabled={loading ? "true" : ""}
            >
              <div className="px-3 py-2 font-w-bolder">
                {onGoingAction === "LOADMORE" ? <LoadMoreIcon /> : `Load More`}
              </div>
            </button>
          </div>
        ) : null}
      </div>

      {selectedNews && (
        <NewsDetails
          data={selectedNews}
          keyword={
            selectedNews.matchedKeywordList
              ? selectedNews.matchedKeywordList.split(",")
              : []
          }
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default TrendingKeywordView;
