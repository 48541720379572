import React, { useState } from "react";
import LoadMoreIcon from "../../../../assets/loadMore";
import { DateTime } from "../../../../components";
import { dateIsValid } from "../../../../const/dateFormat";
import { ChannelSearch } from "../../../Video/components";

const UniqueNewsSearch = ({ onChange, loading, searchState }) => {
  const [searchParams, setSearchParams] = useState({
    channelName: "",
    startDate: "",
    endDate: "",
  });

  const handleSearchParams = (key, date) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        [key]: date,
      };
    });
  };
  const handleSubmit = () => {
    onChange(searchParams);
  };
  return (
    <div className="d-flex flex-column flex-wrap gap-4">
      <div className="d-flex flex-row flex-wrap gap-4">
        <div style={{ minWidth: "100px" }}>
          <ChannelSearch
            handleChannel={(e) => {
              handleSearchParams("channelName", e);
            }}
            currentChannel={searchParams.channelName}
          />
        </div>

        <div style={{ minWidth: "100px" }}>
          <DateTime
            handleDateWithTime={(e) => {
              handleSearchParams("startDate", e);
            }}
            placeholder="From"
            hasTime={true}
            date={searchParams.startDate}
          />
        </div>

        <div style={{ minWidth: "100px" }}>
          <DateTime
            handleDateWithTime={(e) => {
              handleSearchParams("endDate", e);
            }}
            placeholder="To"
            hasTime={true}
            date={searchParams.endDate}
          />
        </div>

        <button
          type="button"
          className="btn btn-sm btn-primary px-4 py-2 rounded-4"
          onClick={() => handleSubmit()}
          disabled={
            searchParams.channelName === "" ||
            !dateIsValid(searchParams.startDate) ||
            !dateIsValid(searchParams.endDate) ||
            new Date(searchParams.startDate).getTime() >
              new Date(searchParams.endDate).getTime() ||
            searchState === "ON_GOING"
              ? true
              : ""
          }
        >
          <div className="px-3 py-2 font-w-bold font-s-md">
            {searchState === "ON_GOING" ? <LoadMoreIcon /> : "Search"}
          </div>
        </button>
      </div>

      {new Date(searchParams.startDate).getTime() >
        new Date(searchParams.endDate).getTime() &&
      searchParams.startDate !== "" &&
      searchParams.endDate !== "" ? (
        <div className="alert alert-danger text-danger">
          Invalid Date range!!! Please check and correct it and search Again.
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UniqueNewsSearch;
