import React from "react";

const options = [
  {
    name: "Scroll All",
    value: null,
  },
  {
    name: "Scroll 1",
    value: 1,
  },
  {
    name: "Scroll 2",
    value: 2,
  },
  {
    name: "Scroll 3",
    value: 3,
  },
  {
    name: "Scroll 4",
    value: 4,
  },
];
const ScrollTab = ({ handleScroll , currentScroll }) => {
  const handleValue = (value) => {
    handleScroll("scrollNumber", value);
  };

  return (
    <div className="d-flex flex-row gap-4">
      {options.map((option) => (
        <div key={option.name}>
          <button
            disabled={ Number(currentScroll) === Number(option.value) }
            className={`btn btn-sm ${Number(currentScroll) === Number(option.value)? "text-info" :"text-white"} font-s-md font-w-bolder ${Number(currentScroll) === Number(option.value)? ""  : "border border-1"} px-4 py-2 rounded-5`}
            style={{ padding: 0 }}
            onClick={() => handleValue(option.value)}
            type="button"
          >
            {option.name}
          </button>
        </div>
      ))}
    </div>
  );
};

export default ScrollTab;
