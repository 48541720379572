import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  setPageName,
  useNewsSources,
  usePageName,
  useUser,
} from "../../dataService/contexts";
import { getReports } from "./dailyReport.service";

import { Tags } from "../../components";
import DailyReportLoading from "./DailyReportLoading";

import { markingColors } from "../../const/markingColors";
import { getBySourceCode } from "../../const/newsSources";

import LoadMoreIcon from "../../assets/loadMore";

import "./styles.scss";
import { CategoryOptions } from "../NewspaperReport/components";
import TagsDropdown from "../../components/Layouts/components/Navbar/components/SearchVideoCrop/TagsDropdown";

const DailyReport = () => {
  const today = moment(new Date()).format("DD/MM/YYYY");
  const { dispatch: pageNameDispatch } = usePageName();
  const [loading, setLoading] = useState(false);

  const [reports, setReports] = useState([]);
  const [totalReports, setTotalReports] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  //user info is store in this useUser hook
  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  //params
  const [params, setParams] = useState({
    size: 20,
    page: 0,
  });

  const handleSelectedCategory = (key, value) => {
    setSelectedCategory(value);
  };

  const updateParams = (key, value) => {
    setParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  //this loadMore function is used to change the current ongoing action to "LOADMORE" fetchNews function to "LOADMORE"
  const loadMore = () => {
    //load more will increse the page number
    setOnGoingActions("LOADMORE");
    fetchReports("LOADMORE");
  };

  //this filter function is used to change the current ongoing action to "FILTER" fetchNews function to "FILTER"
  const filter = () => {
    setOnGoingActions("FILTER");

    setReports([]);
    setTotalReports(0);

    fetchReports("FILTER");
  };

  // this actionHandler function is used to change multiple states based on actionType, here we are sending action type and data that we are getting from our api.
  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const { _reports, _total } = data;
    if (actionType === "LOADMORE") {
      setReports((prev) => [...prev, ..._reports]);
      setTotalReports(_total);

      updateParams("page", params.page + 1);
    } else {
      setReports(_reports);
      setTotalReports(_total);

      updateParams("page", 0);
    }
  };

  //this getEffectiveParamsPage function is to update params state to based on action to make api
  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return params.page + 1;
    } else {
      return 0;
    }
  };

  //this fetchNews function is to make the api calls. loading state also gets updated before api calls.
  const fetchReports = (actionType) => {
    if (user) {
      setLoading(true);

      getReports(
        {
          ...params,
          page: getEffectiveParamsPage(actionType), //Page,
          categoryName:
            setSelectedCategory &&
            selectedCategory !== null &&
            selectedCategory.categoryName
              ? selectedCategory.categoryName
              : null,
        },
        user,
        (status, data) => {
          if (status === "success") {
            if (data && data.reports) {
              actionHandler(actionType, {
                _reports: data.reports,
                _total: data.total,
              });
            }
          }

          setLoading(false);
        }
      );
    }
  };
  useEffect(() => {
    setPageName(pageNameDispatch, "Daily Report");
  }, []);
  //this use effect function is used to make the api call when a user gets to this route
  useEffect(() => {
    filter();
  }, [user, selectedCategory]);

  return (
    <div className="d-flex flex-column gap-2 p-1 justify-content-between">
      <div className="d-flex  gap-4  justify-content-between align-items-center">
        <div className="d-flex gap-4 flex-rap align-items-center">
          <div className="font-s-xlg text-white font-w-bold">Daily Report</div>
          <div className="text-light font-s-md ">{today}</div>
        </div>

        {/*<Tags*/}
        {/*  selectedTag={selectedTag}*/}
        {/*  handleSelectedTag={handleSelectedTag}*/}
        {/*  warning={false}*/}
        {/*  isClearable={true}*/}
        {/*/>*/}
        <div className="d-flex flex-row gap-3">
          {/*<TagsDropdown*/}
          {/*  handleTags={handleSelectedTags}*/}
          {/*  bg={true}*/}
          {/*  currentTag={selectedTags}*/}
          {/*  forTags={false}*/}
          {/*/>*/}
          <CategoryOptions
            handleCategory={handleSelectedCategory}
            seletedCategory={selectedCategory}
          />
        </div>
      </div>

      <div>
        <table className="table--report w-100">
          <thead>
            <tr className="text-light font-s-sm font-w-bolder text-uppercase bg-black__light shadow">
              <th className="py-2  ps-4">Source</th>

              <th className="py-2  ps-4">Time</th>

              <th className="py-2  ps-4">Marked Text</th>

              <th className="py-2  ps-4">Tags</th>
              <th className="py-2  ps-4">Category</th>

              <th className="py-2  ps-4">Action</th>
            </tr>
          </thead>

          <tbody>
            {reports && reports.length ? (
              reports.map((item) => (
                <tr className="bg-black__light shadow my-2">
                  <td className="my-2">
                    <div className="text-white p-4 font-s-n font-w-bolder text-nowrap">
                      {
                        getBySourceCode(
                          item?.newsContent?.sourceName?.code,
                          newsSources
                        ).name
                      }
                    </div>
                  </td>

                  <td className="my-2">
                    <div className="text-light p-4 font-s-n font-w-bold">
                      {moment(item.updatedAt).format("DD/MM/YYYY")}
                    </div>
                  </td>

                  <td className="my-2">
                    <div
                      className="p-4 font-s-n font-w-bold"
                      style={{
                        color: markingColors[item.sentiment],
                      }}
                    >
                      {item.highlightedContent}
                    </div>
                  </td>

                  <td className="my-2">
                    <div className="text-white p-4 font-s-n font-w-bolder">
                      {item.tags && item.tags !== null && item.tags.length ? (
                        item.tags.map((i) => i.word).toString()
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                  <td className="my-2">
                    <div className="text-white p-4 font-s-n font-w-bolder">
                      {item.categoryTypes && item.categoryTypes.length ? (
                        item.categoryTypes[0].categoryName
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>

                  <td>
                    <div className="text-white p-4 font-s-sm font-w-bold">
                      <Link
                        className="btn btn-md bg-primary font-s-md font-w-bolder text-white px-4"
                        to={`/news-full-view/${item.contentId}&${item.contentType}&`}
                      >
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
            {loading && <DailyReportLoading />}
          </tbody>
        </table>

        <div className="d-flex justify-content-center">
          {reports && reports.length && reports.length < totalReports ? (
            <div className="d-flex justify-content-center">
              <button
                onClick={() => loadMore()}
                className="btn btn-sm btn-primary px-4 py-2 rounded-4 font-s-n"
                disabled={loading ? "true" : ""}
              >
                <div className="px-3 py-2 font-w-bolder">
                  {onGoingAction === "LOADMORE" ? (
                    <LoadMoreIcon />
                  ) : (
                    `Load More`
                  )}
                </div>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DailyReport;
