import { getEnvironment } from "../../const/environment";
import moment from "moment/moment";
import apiService from "../../dataService/apiService";

export const getVideCropList = (params, user, autoPlay, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-crop-service/api/${env.version}/${env.type}/video/crop/history?`;

  const _params = {
    userId: autoPlay ? null : user.userId,
    page: params.page,
    size: params.size,
    start: moment(params.start).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(params.end).format("YYYY-MM-DD HH:mm:ss"),
    categoryName: params.categoryName,
    sentiment:
      params.sentiment === "" || params.sentiment === null
        ? null
        : Number(params.sentiment),
    sourceName: params.sourceName,
    details: true,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data, { ..._params });
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};

export const removeCroppedVideo = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-crop-service/api/${env.version}/${env.type}/video/${id}`;
  apiService.delete(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};

export const updateCroppedVideo = (id, _params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-crop-service/api/${env.version}/${env.type}/video/${id} `;

  apiService.put({ url, data: _params }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data, _params);
    } else {
      done("danger", data, _params);
    }
  });
};
