import React, { useState } from "react";
 

import Filter from "./../Filter";

import { camelCaseToNormal } from "../../../../utils";

const VideoMetaData = ({ handleVideoLink }) => {
  const [metaData,setMetaData] = useState(null)
  //create an object to store metadata
  //create a function to update metadata
  const updateMetaData=(metadata)=> setMetaData(metadata)
  return (
    <div className="bg-white rounded-4 p-4 d-flex flex-column gap-2">
      <div className="d-flex justify-content-between align-items-center">
        <div className="text--subheading text-light">You are watching</div>
        {/* pass update function */}
        <Filter handleVideoLink={handleVideoLink} handleMetaData={updateMetaData} />
      </div>
      <div className="text--big">Channel {metaData ? camelCaseToNormal(metaData.selectedChannel).replace("tv","TV"):'...'}</div>
      <div className=" d-flex flex-row gap-3">
        <div className=" fs-6 text-light">{metaData ? metaData.startDateWithTime:"Start Date"}</div>
      <div className=" rounded-circle  border border-danger border-5 " style={{height:20,width:20}}></div>
      <div class="fs-6 text-light">{metaData ?metaData.endDateWithTime:"End Date"}</div>
      </div>
      
    </div>
  );
};

export default VideoMetaData;
