import React, { useState } from "react";
import DatePicker, { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment/moment";

const DateTime = ({ handleDateWithTime, placeholder, hasTime, date }) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startTime, setStartTime] = useState("");
  const [shouldCloseCalendar, setShouldCloseCalendar] = useState(false);

  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  //Handle date change
  const onChangeDate = (date) => {
    setStartDate(date.format("YYYY-MM-DD"));
  };

  //Handle time change
  const onChangeTime = (date) => {
    setStartTime(date.format("hh:mm A"));
  };

  //This function will save data into parent state
  const onSave = () => {
    handleDateWithTime(`${startDate}, ${startTime}`);
    setShouldCloseCalendar(true);
  };

  //Custom Header component
  const Header = () => {
    return (
      <div className="text-white text-start font-w-bold font-s-md">
        Select Date and Time
      </div>
    );
  };

  //Custom datepicker close button
  const CloseButton = () => {
    return (
      <button
        className="btn btn-sm text-white w-100 p-0 bg-primary rounded-4 py-2"
        onClick={() => {
          onSave();
        }}
        disabled={startDate === "" || startTime === ""}
      >
        <div className="py-1">Save</div>
      </button>
    );
  };

  //Custom input box for date value
  const CustomInput = ({ openCalendar, handleValueChange }) => {
    return (
      <input
        className="form-control rounded-4 bg-black__light text-white p-0 border-0 py-3 px-4 font-s-md font-w-bolder "
        onFocus={openCalendar}
        value={`${date}`}
        onChange={handleValueChange}
        placeholder={placeholder}
      />
    );
  };

  return (
    <>
      <DatePicker
        render={<CustomInput />}
        onChange={onChangeTime}
        format="hh:mm a"
        className="date-picker"
        arrowClassName="date-picker__arrow"
        disableDayPicker
        // onOpen={() => setShouldCloseCalendar(false)}
        // onClose={() => shouldCloseCalendar}
        plugins={[
          <Header />,
          <Calendar
            className="rounded-4 font-s-md bg-white"
            weekDays={weekDays}
            onChange={onChangeDate}
            format="YYYY-MM-DD"
          />,
          <TimePicker
            className="rounded-4 font-s-md bg-white"
            hideSeconds
            position="bottom"
          />,
          <CloseButton />,
        ]}
      />
    </>
  );
};

export default DateTime;
