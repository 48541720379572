import React from 'react';

const Profile = () => {
    return (
        <svg
    width={48}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  
  >
    <circle cx={24} cy={24} r={24} fill="#FF98E5" />
    <mask
      id="a"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={48}
      height={48}
    >
      <circle cx={24} cy={24} r={24} fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <circle cx={24} cy={24} r={24} fill="#3D42DF" />
      <path
        d="M36.904 26.79v2.33l-3.642 3.403-9.232-9.15V16.72l6.437-2.756v6.448l6.437 6.379Z"
        fill="#B3E5FC"
      />
      <path
        d="m33.261 32.523-4.81 4.463-10.87-10.774V13.964l6.449 2.756v6.654l9.231 9.15Z"
        fill="#4FC3F7"
      />
      <path
        d="m28.447 36.986-3.37 3.146a1.546 1.546 0 0 1-2.105 0L11.142 29.12V13.522c.002-.512.262-.99.694-1.27a1.548 1.548 0 0 1 1.452-.125l4.292 1.837v12.248l10.867 10.774ZM36.904 13.522v13.269l-6.437-6.38v-6.447l4.291-1.837a1.548 1.548 0 0 1 1.453.125c.431.28.692.758.693 1.27Z"
        fill="#2196F3"
      />
    </g>
  </svg>
    );
};

export default Profile;
