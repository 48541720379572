import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";
import moment from "moment";

export const getNewspaperReport = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/category-wise-report`;

  const _params = {
    startDate: moment(params.startDate).format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(params.endDate)
      .utc("asia/dhaka")
      .format("YYYY-MM-DD HH:mm:ss"),
    size: params.size,
    page: params.page,
    categoryName: params.category ? params.category.categoryName : "",
    sentiment: Number(params.sentiment) ? Number(params.sentiment) : "",
    sourceName: params.sourceName ? params.sourceName : "",
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};

export const downloadReport = (date, reportIds, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/case-management-service/api/${env.version}/${
    env.type
  }/category-wise-report/download?startDate=${moment(date.startDate).format(
    "YYYY-MM-DD HH:mm:ss"
  )}&endDate=${moment(date.endDate).format("YYYY-MM-DD HH:mm:ss")}&sourceName=${
    date.sourceName ? date.sourceName : ""
  }&categoryName=${date.category ? date.category.categoryName : ""}&sentiment=${
    date.sentiment
  }&ids=${reportIds}
  `;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };

  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};

export const removeNewspaperReport = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/category-wise-report/${id}`;
  apiService.delete(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};
