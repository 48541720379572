// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none !important;
}

.nav-width {
  min-width: 100vw;
}
@media screen and (min-width: 1024px) {
  .nav-width {
    min-width: calc(100vw - 23rem);
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;AACI;EAHJ;IAIQ,8BAAA;EAEN;AACF","sourcesContent":["a{\n    text-decoration: none !important;\n}\n\n.nav-width{\n    min-width: 100vw;\n\n    @media screen and (min-width: 1024px){\n        min-width: calc(100vw - 23rem  );\n        // overflow: hidden;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
