import React from "react";
import { Link } from "react-router-dom";

import {
  BookmarkButton,
  NewsKeywords,
  NewsShortContent,
  NewsTitle,
} from "../../components";

import { getDate_3W2D3M2Y2h2mA } from "../../const/dateFormat";
import { getBySourceCode } from "../../const/newsSources";

import { useNewsSources } from "../../dataService/contexts";
import "./styles.scss";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source && source.name ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source.code, newsSources).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source, newsSources }) => {
  return source && source.name ? (
    <Link
      to={`/newspaper-news/${source.code}`}
      target="_blank"
      className="font-s-md font-w-bold text-white"
    >
      {getBySourceCode(source.code, newsSources).name}
    </Link>
  ) : (
    <></>
  );
};

const News = ({
  news,
  keywords,
  type,
  hasIcon = true,
  tags,
  hasViewButton = true,
  children,
  bookmarkAble = true,
}) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  return (
    <div
      className="p-4 d-flex flex-row gap-4 bg-black__light news"
      key={news.id}
    >
      <div className="d-flex flex-column justify-content-between">
        {hasIcon && (
          <NewsSourceIcon source={news.sourceName} newsSources={newsSources} />
        )}
        {bookmarkAble && <BookmarkButton news={news} />}
        <div></div>
      </div>

      <div className="d-flex flex-column flex-grow-1 gap-3">
        <div className="d-flex flex-column justify-content-between  gap-4 w-100">
          <div className="d-flex flex-wrap flex-row justify-content-between mt-2 gap-2">
            <div className="align-self-center">
              <NewsSourceName
                source={news.sourceName}
                newsSources={newsSources}
              />
            </div>

            <div className="text-light font-s-nm text-nowrap font-w-bolder d-flex flex-column justify-content-between  align-items-end">
              <div className="d-flex flex-row gap-3 flex-wrap">
                {news.scrollNumber !== 0 && news.scrollNumber && (
                  <div className="align-self-center font-s-md text-white">
                    <span className="d-flex justify-content-center">
                      Scroll {news.scrollNumber}
                    </span>
                  </div>
                )}
                <div className="align-self-center font-s-n">
                  {getDate_3W2D3M2Y2h2mA(news.newsTimeAt)}
                </div>

                {hasViewButton && (
                  <div className="align-self-center d-flex flex-row gap-3">
                    <Link
                      className="btn btn-md bg-primary px-4 py-2 rounded-3 text-white font-s-md font-w-bolder"
                      to={`/news-full-view/${
                        news.contentId === undefined ? news.id : news.contentId
                      }&${news.type}&${tags}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
          <NewsKeywords keywords={keywords} />
        </div>

        <NewsTitle title={news.highlightedTitle || news.title} />
        <NewsShortContent news={news} type={type} />
        {!!children ? children : <></>}
      </div>
    </div>
  );
};

export default News;
