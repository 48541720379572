import React, { useCallback, useEffect, useState } from "react";
import {
  ChannelSearch,
  OngoingRequests,
  VideoPopup,
  VideoThumbnail,
} from "../Video/components";
import { DateTime } from "../../components";
import { dateIsValid } from "../../const/dateFormat";
import moment from "moment/moment";
import LoadMoreIcon from "../../assets/loadMore";
import { UniqueNewsSearch } from "./components";
import {
  addRequest,
  setPageName,
  useOngoingRequests,
  usePageName,
  useUser,
} from "../../dataService/contexts";
import { getUniqueNews } from "./uniqueNews.service";

const UniqueScrollNews = () => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const { dispatch: ongoingRequestsDispatch } = useOngoingRequests();

  const [searchParams, setSearchParams] = useState({
    sourceName: "",
    startDate: "",
    endDate: "",
    channelName: "",
    limit: 10,
    offset: 0,
  });

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchState, setSearchState] = useState("");

  const { dispatch: pageNameDispatch } = usePageName();
  const handleUniqueNewsSearch = (searchParams) => {
    setSearchParams((prev) => {
      return {
        ...prev,
        sourceName: searchParams.channelName.code,
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        channelName: user.userId,
      };
    });
  };

  const fetchUniqueNews = useCallback(() => {
    getUniqueNews(searchParams, (status, data, _params) => {
      if (status === "success") {
        const newRequest = `${searchParams.sourceName}#${searchParams.startDate}#${searchParams.endDate}#2`;
        addRequest(ongoingRequestsDispatch, newRequest);
        if (data.data === null) {
          setSearchState("NOT_FOUND");
        } else {
          setSearchState("CLICK_TO_PLAY");
        }
      } else {
        setSearchState("NOT_FOUND");
      }
    });
  }, [user, searchParams]);

  useEffect(() => {
    setPageName(pageNameDispatch, "Unique Scroll News");
  }, []);

  useEffect(() => {
    if (
      user &&
      searchParams.endDate &&
      searchParams.startDate &&
      searchParams.sourceName
    )
      fetchUniqueNews();
  }, [user, searchParams]);

  return (
    <div className="d-flex flex-column gap-3 p-1">
      <UniqueNewsSearch
        onChange={handleUniqueNewsSearch}
        loading={searchLoading}
      />

      <OngoingRequests type={2} />
    </div>
  );
};

export default UniqueScrollNews;
