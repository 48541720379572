import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import "./styles.scss";
import { Link } from "react-router-dom";
import { ROUTE } from "../../../../const";

const SentimentLink = ({
  route,
  params,
  sentiment,
  item,
  categoryName,
  date,
}) => {
  return (
    <>
      <Link
        className={`${
          sentiment === 1 ? "text-success" : "text-danger"
        } font-s-md font-w-bolder`}
        to={`/${route}${getQueryString({
          startDate:
            params.aggregated === true ? params.startDate : date?.searchStart,
          endDate:
            params.aggregated === true ? params.endDate : date?.searchEnd,
          sentiment: sentiment,
          categoryName: categoryName,
          sourceName: item.sourceName,
        })}#auto-play`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {Number(sentiment) === 1 ? item.positive : item.negative}
      </Link>
    </>
  );
};
const getQueryString = (data) => {
  let queryString = `?sourceName=${data.sourceName}&categoryName=${data.categoryName}&startDate=${data.startDate}&endDate=${data.endDate}&sentiment=${data.sentiment}`;

  return queryString;
};
const SourceReportTable = ({
  data,
  currentContentType,
  aggregated,
  params,
}) => {
  const [sourceName, setSourceName] = useState([]);

  useEffect(() => {
    setSourceName(data.reports);
  }, [data]);

  return (
    <div className="text-silver" style={{ overflow: "auto" }}>
      <table className="w-100 table--sourceReport ">
        <thead>
          <tr
            className="text-light font-s-md font-w-bolder "
            style={{ letterSpacing: "1px" }}
          >
            {/*<th rowSpan={2}>SL</th>*/}
            <th rowSpan={2} className="text-light font-s-md font-w-bolder">
              Category Name
            </th>
            {!aggregated && (
              <th rowSpan={2} className="text-light font-s-md font-w-bolder">
                Date
              </th>
            )}
            {Object.keys(sourceName).length ? (
              sourceName[Object.keys(sourceName)[0]][0].sourceWiseCount &&
              sourceName[Object.keys(sourceName)[0]][0].sourceWiseCount.map(
                (key, i) => (
                  <th colSpan={2}>
                    <div className="text-nowrap text-light font-s-md font-w-bolder">
                      {key.sourceName}
                    </div>
                  </th>
                )
              )
            ) : (
              <div></div>
            )}
          </tr>
          <tr>
            {Object.keys(sourceName).length ? (
              sourceName[Object.keys(sourceName)[0]][0].sourceWiseCount &&
              sourceName[Object.keys(sourceName)[0]][0].sourceWiseCount.map(
                (key, i) => (
                  <>
                    <th className=" text-light font-s-md font-w-bolder">
                      Positive
                    </th>
                    <th className="text-light font-s-md font-w-bolder">
                      Negative
                    </th>
                  </>
                )
              )
            ) : (
              <div></div>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.keys(sourceName).length &&
            Object.keys(sourceName).map((key, i) => (
              <>
                {sourceName[key].map((row, index) => (
                  <tr>
                    {/*<td className="text-light font-s-n font-w-bolder">*/}
                    {/*  /!*<div className="text-nowrap">{`# ${index}`}</div>*!/*/}
                    {/*</td>*/}
                    {index === 0 && (
                      <td
                        className="text-white font-s-md font-w-bolder"
                        rowSpan={sourceName[key].length}
                      >
                        <div className="text-nowrap text-white font-s-md font-w-bolder">
                          {key}
                        </div>
                      </td>
                    )}
                    {!aggregated && (
                      <td>
                        <div className="text-nowrap text-white font-s-md font-w-bolder">
                          {aggregated
                            ? row.dateRange
                            : moment(row.date).format("YYYY-MM-DD")}
                        </div>
                      </td>
                    )}
                    {row.sourceWiseCount && row.sourceWiseCount.length ? (
                      row.sourceWiseCount.map((item) => (
                        <>
                          <td className="text-white font-s-n font-w-bolder">
                            {currentContentType === 1 ? (
                              <SentimentLink
                                route={ROUTE.NEWSPAPER_REPORT_OVERVIEW_DETAILS}
                                sentiment={1}
                                params={params}
                                categoryName={key}
                                item={item}
                                date={row}
                              />
                            ) : (
                              <></>
                            )}
                            {currentContentType === 2 ? (
                              <SentimentLink
                                route={ROUTE.MARKING_REPORT_OVERVIEW_DETAILS}
                                sentiment={1}
                                params={params}
                                categoryName={key}
                                item={item}
                                date={row}
                              />
                            ) : (
                              <></>
                            )}
                            {currentContentType === 3 ? (
                              <SentimentLink
                                route={ROUTE.VIDEO_CROP_OVERVIEW_DETAILS}
                                sentiment={1}
                                params={params}
                                categoryName={key}
                                item={item}
                                date={row}
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="text-white font-s-n font-w-bolder">
                            {currentContentType === 1 ? (
                              <SentimentLink
                                route={ROUTE.NEWSPAPER_REPORT_OVERVIEW_DETAILS}
                                sentiment={2}
                                params={params}
                                categoryName={key}
                                item={item}
                                date={row}
                              />
                            ) : (
                              <></>
                            )}
                            {currentContentType === 2 ? (
                              <SentimentLink
                                route={ROUTE.MARKING_REPORT_OVERVIEW_DETAILS}
                                sentiment={2}
                                params={params}
                                categoryName={key}
                                item={item}
                                date={row}
                              />
                            ) : (
                              <></>
                            )}
                            {currentContentType === 3 ? (
                              <SentimentLink
                                route={ROUTE.VIDEO_CROP_OVERVIEW_DETAILS}
                                sentiment={2}
                                params={params}
                                categoryName={key}
                                item={item}
                                date={row}
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                        </>
                      ))
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
              </>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SourceReportTable;
