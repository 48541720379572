// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input--field {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/style.scss"],"names":[],"mappings":"AAIE;EACE,mBAAA;EACA,gBAAA;EACA,mBAAA;AAHJ","sourcesContent":[".input{\n  &--label{\n\n  }\n  &--field{\n    font-size: 0.875rem;\n    font-weight: 600;\n    line-height: 1.50rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
