// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table--topicManagement {
  border-spacing: 0 0.5rem;
}
.table--topicManagement thead tr {
  background: #23252c;
}
.table--topicManagement tbody tr:nth-child(odd) {
  background-color: #20222a !important;
}
.table--topicManagement tbody tr:nth-child(even) {
  background-color: #23252c !important;
}
.table--topicManagement tbody tr td:first-child {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.table--topicManagement tbody tr td:last-child {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/CaseManagement/components/CaseListTable/style.scss"],"names":[],"mappings":"AACE;EAEE,wBAAA;AADJ;AAKM;EACE,mBAAA;AAHR;AAoBQ;EACE,oCAAA;AAlBV;AAqBQ;EACE,oCAAA;AAnBV;AAsBU;EACE,2CAAA;AApBZ;AAwBU;EACE,2CAAA;AAtBZ","sourcesContent":[".table{\n  &--topicManagement{\n\n    border-spacing:0 0.5rem;\n\n    thead {\n\n      tr {\n        background: #23252c;\n\n        th {\n          &:first-child {\n            // border-radius: .5rem 0 0 .5rem !important;\n            // padding: 0.75rem 0.75rem 0.75rem 1.5rem !important;\n          }\n\n          &:last-child {\n            // border-radius: 0 .5rem .5rem 0 !important;\n            // padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;\n    }\n        }\n      }\n    }\n    tbody{\n      tr{\n        &:nth-child(odd){\n          background-color: #20222a !important;\n        }\n\n        &:nth-child(even) {\n          background-color: #23252c !important;\n        }\n        td{\n          &:first-child{\n            border-radius: .5rem 0 0 .5rem !important;\n            // padding: 0.75rem 0.75rem 0.75rem 1.5rem !important;\n          }\n\n          &:last-child {\n            border-radius:  0 .5rem .5rem 0 !important;\n            // padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;\n\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
