import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ROUTE } from "../../const";
import { Notice } from "../../assets/Navbar";

import "./styles.scss";
import { getNotice, seenNotice } from "./noticePopTable.service";

const NoticePopup = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [notices, setNotice] = useState([]);
  //const [noticeId, setNoticeId] = useState(0);

  const fetchNotice = () => {
    getNotice((status, data) => {
      if (status === "success") {
        setNotice(data);
      }
    });
  };
  const updateNoticeSeen = (id) => {
    //alert(id);
    const params = {
      noticeId: id,
    };
    seenNotice(params, (status, data) => {
      if (status === "success") {
        window.location.reload(true);
      }
    });
  };

  useEffect(() => {
    fetchNotice();
  }, []);

  return (
    <>
      <button
        className={`iconButton  btn`}
        aria-label="Customise options"
        onClick={handleShow}
      >
        <div className="d-flex flex-column ">
          <div className="w-100 d-flex justify-content-end">
            {notices.flag == "0" ? (
              <div
                className="bg-danger font-s-sm font-w-bold text-white d-flex justify-content-center "
                style={{
                  height: "1.2rem",
                  width: "1.2rem",
                  borderRadius: "100%",
                  position: "absolute",
                }}
              ></div>
            ) : null}
          </div>
          <Notice />
        </div>
      </button>

      <div>
        <Modal
          show={show}
          onHide={handleClose}
          className="newspaper-details"
          size="md"
          centered
        >
          <ModalHeader closeButton>
            <div className="text-white">{notices.title}</div>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex flex-column gap-3 flex-wrap">
                {/* <div className="text-light font-s-md">{notices.title}</div> */}
                <div style={{ minWidth: "250px" }}>
                  <div className="text-silver">
                    <div className="d-flex w-100">
                      <div className="p-4 d-flex flex-row gap-4 bg-black__light rounded-start flex-grow-1">
                        <div></div>

                        <div className="d-flex flex-column flex-grow-1 gap-3">
                          {/* <div className="d-flex flex-column justify-content-between  gap-4 w-100">
                            <div className="d-flex flex-wrap flex-row justify-content-between mt-2 gap-2">
                              <div className="d-flex gap-3 align-self-center ">
                                <div className="text-light">
                                  {notices.title}
                                </div>
                              </div>
                            </div>
                          </div> */}

                          <div
                            className="font-w-normal font-s-md text-white"
                            style={{ letterSpacing: "1.25px" }}
                          >
                            <p className="">{notices.detail}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {notices.flag == "0" ? (
              <button
                className="btn btn-primary "
                onClick={() => {
                  handleClose();
                  updateNoticeSeen(notices.id);
                }}
              >
                {" "}
                Read{" "}
              </button>
            ) : null}

            <Link to={ROUTE.MYNOTICE}>
              <button
                className="btn btn-primary text-white"
                onClick={handleClose}
              >
                {" "}
                View All{" "}
              </button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default NoticePopup;
