import apiService from "../../dataService/apiService";

import { markingViewPermission } from "./permission.controller";

import { getEnvironment } from "../../const/environment";

export const getIndividualNews = (id, type, user, tags, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news/${id}`;

  const _params = {
    type,
    keys: tags,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    {
      ..._params,
    }
  );
};

export const getTagList = (user, id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/unique-content-report?contentId=${id}`;

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {}
  );
};
