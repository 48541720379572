import React, { useCallback, useEffect, useState } from "react";

import { ScheduleVideoSearch } from "..";

import {
  useOngoingRequests,
  getRequests,
} from "../../../../dataService/contexts";

const OngoingRequests = ({ type = 1 }) => {
  const { state: ongoingRequestsState, dispatch: ongoingRequestsDispatch } =
    useOngoingRequests();
  const { ongoingRequests } = ongoingRequestsState || [];

  const getCurrentOngoingRequests = useCallback(() => {
    getRequests(ongoingRequestsDispatch);
  }, [ongoingRequestsDispatch]);

  useEffect(() => {
    window.addEventListener("storage", getCurrentOngoingRequests);

    return () => {
      window.removeEventListener("storage", getCurrentOngoingRequests);
    };
  }, [getCurrentOngoingRequests]);

  return ongoingRequests && ongoingRequests.length ? (
    <div className="mt-3">
      <div className="text-white mb-3 font-s-md font-w-bold">
        Scheduled Video Search
      </div>

      <div className="d-flex flex-column gap-3">
        {ongoingRequests.map((request) => {
          const _request = request.split("#");
          const _params = {
            channelName: _request[0],
            startDate: _request[1],
            endDate: _request[2],
            type: _request[3],
          };
          return Number(type) === Number(_params.type) ? (
            <div className="">
              <ScheduleVideoSearch params={_params} />
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export { OngoingRequests };
