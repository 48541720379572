import React, { useEffect, useState } from "react";

import Select from "react-select";

const sentiment = [
  {
    id: 1,
    name: "Report By Source",
    value: 1,
  },
  {
    id: 2,
    name: "Report by Category",
    value: 2,
  },
];
const ReportListDropdown = ({ handleSentiment }) => {
  const handleSelect = (data) => {
    handleSentiment(data.value);
  };
  return (
    <Select
      isSearchable={false}
      options={sentiment}
      className="channel-select-containerr"
      classNamePrefix="channel-select"
      getOptionLabel={(options) => `${options.name}`}
      placeholder={`Filter`}
      onChange={(e) => {
        handleSelect(e);
      }}
      defaultValue={{
        id: 1,
        name: "Report By Source",
        value: 1,
      }}
    />
  );
};

export default ReportListDropdown;
