import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";

import FilterForm from "../FilterForm";

const Filter = (props) => {
  const { handleVideoLink,handleMetaData } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className="btn btn-sm bg-white rounded-pill px-4 shadow-sm text-primary"
        onClick={handleShow}
      >
        Filter
      </button>
      <Offcanvas className="" show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="align-items-center justify-content-center  d-flex">
          <FilterForm handleVideoLink={handleVideoLink} handleMetaData={handleMetaData}/>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Filter;
