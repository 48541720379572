import moment from "moment";

import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

const env = getEnvironment();

export const managerReportDetails = (params, done = () => {}) => {
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/report-overview-highlighted-report?`;
  const _params = {
    date: params.date,
    endDate: params.endDate,
    contentType: params.contentType === "" ? null : params.contentType,
    categoryType: params.categoryType === "" ? null : params.categoryType,
    sentiment: params.sentiment === "" ? null : params.sentiment,
    sourceName: params.sourceName === "" ? null : params.sourceName,
    details: true,
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    { ..._params }
  );
};

export const downloadManagerReportDetails = (params, done = () => {}) => {
  const env = getEnvironment();

  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/report-overview-highlighted-report/download?sourceName=${params.sourceName}&date=${params.date}&endDate=${params.endDate}&categoryType=${params.categoryType}&sentiment=${params.sentiment}`;



  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
