import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

import { editCategory } from "./editCategory.service";

const EditCategory = ({ selectedCategory, removeSelectedCategory }) => {
  const [category, setCategory] = useState(selectedCategory.categoryName);
  const [color, setColor] = useState(selectedCategory.color);
  const [description, setDescription] = useState(selectedCategory.description);
  const updateCategory = (id) => {
    const params = {
      categoryName: category,
      color: color,
      description: description,
    };
    editCategory(id, params, (status, data) => {
      if (status === "success") {
        removeSelectedCategory();
        window.location.reload(true);
      }
    });
  };
  return (
    <div>
      <Modal
        show={!!selectedCategory}
        onHide={removeSelectedCategory}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">Edit Category</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Category</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Category Name"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Description</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Category Name"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Color</div>
              <div style={{ maxWidth: "100px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="color"
                  placeholder="Enter Color"
                  style={{ minHeight: "50px" }}
                  value={color}
                  onChange={(e) => setColor(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary "
            onClick={() => updateCategory(selectedCategory.id)}
          >
            Edit Category
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditCategory;
