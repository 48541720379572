import React from "react";

const GetAddNewIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8.5" r="8.5" fill="#355DFF" />
      <rect x="8" y="4" width="1" height="9" rx="0.5" fill="white" />
      <rect
        x="13"
        y="8"
        width="1"
        height="9"
        rx="0.5"
        transform="rotate(90 13 8)"
        fill="white"
      />
    </svg>
  );
};

export default GetAddNewIcon;
