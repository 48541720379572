import ReactApexChart from "react-apexcharts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmationAlert } from "../../../../components";
import AnalyticsPopup from "../../../../components/AnalyticsPopup";
import { useNewsSources } from "../../../../dataService/contexts";
import {
  getBySourceCode,
  getBySourceName,
} from "../../../../const/newsSources";
import ExpandIcon from "../../../../assets/ExpandIcon";
import { MoonLoader } from "react-spinners";

const StackedBar = ({ barData, type, params, handleParams, top, loading }) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const { series, categories } = barData || {};
  const [seriesIndex, setSeriesIndex] = useState(null);
  const [dataPointIndex, setDataPointIndex] = useState(null);
  const [sentiment, setSentiment] = useState(null);
  const showModal = (config) => {
    setSeriesIndex(categories[config.dataPointIndex]);
    setDataPointIndex(config.dataPointIndex);
    setSentiment(config.seriesIndex);
  };

  const closeModal = () => {
    setSeriesIndex(null);
    setDataPointIndex(null);
    setSentiment(null);
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define breakpoints
  const isMobile = windowSize.width <= 1024;
  const options = {
    series: [
      {
        name: "Marine Sprite",
        data: [44, 55, 41, 37, 22, 43, 21],
      },
      {
        name: "Striking Calf",
        data: [53, 32, 33, 52, 13, 43, 32],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 500,
        stacked: true,
        events: {
          dataPointSelection: (event, chartContext, config) => {
            showModal(config);
          },
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 7,
          horizontal: true,
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val + "%";
            },
            style: {
              fontSize: "1.2rem",
              colors: ["#746e75"],
            },
            total: {
              enabled: false,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
                colors: ["#746e75"],
              },
            },
          },
        },
      },
      dataLabels: {
        formatter: function (val) {
          return val + "%";
        },
      },
      stroke: {
        enabled: false,
      },
      legend: {
        show: false,
        position: "bottom",
        fontSize: "10px",
        labels: {
          colors: "#808191",
          useSeriesColors: false,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },

        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: ["#ff7a68", "#3DBAA2", "#ff9f38"],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
      colors: ["#3DBAA2", "#ff7a68"],
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        categories: categories || [],

        labels: { show: false },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },

      yaxis: {
        categories: categories || [],
        labels: {
          style: {
            colors: "#746e75",
          },
        },
        dataLabels: { enabled: false },
        title: {
          text: undefined,
        },
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: function (val) {
            return val + "K";
          },
        },
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return options ? (
    <div
      className={`d-flex flex-column ${
        isMobile ? "" : "bar-container"
      }  rounded-4 p-4  w-100 `}
    >
      <button disabled={loading}>
        <span
          onClick={() => {
            handleParams(!top);
          }}
        >
          <ExpandIcon />
        </span>
      </button>

      <div>
        {loading ? (
          <div
            style={{ width: "336px" }}
            className="d-flex justify-content-center h-100 "
          >
            <MoonLoader color="#36d7b7" />
          </div>
        ) : (
          <ReactApexChart
            height={isMobile ? "700px" : "700px"}
            width={isMobile ? "700px" : "100%"}
            options={{
              ...options.options,
            }}
            series={series || []}
            type="bar"
          />
        )}
      </div>

      <AnalyticsPopup
        show={seriesIndex}
        handleClose={closeModal}
        dataPointIndex={dataPointIndex}
        seriesIndex={seriesIndex}
        type={type}
        param={params}
        sentiment={sentiment}
      />
    </div>
  ) : (
    <></>
  );
};

export default React.memo(StackedBar);
