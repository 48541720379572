import React from "react";

import "./styles.scss";

const NewsTitle = ({title}) => {
    return(
        <>{title ? (
            <div className="text-white">
              <span
                className="text-white font-s-lg font-w-bolder news-title"
                dangerouslySetInnerHTML={{
                  __html: `<p style="display: inline">${title}</p>`,
                }}
              />
            </div>
          ) : (
            // <div className="text-white"></div>
            <></>
          )}</>
    )
}

export default NewsTitle