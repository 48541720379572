import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { addNewTag } from "./addTag.service";

const AddTag = ({ open, handleClose }) => {
  const [tag, setTag] = useState("");

  const handleTag = (e) => {
    setTag(e.target.value);
  };
  const onAddTag = () => {
    addNewTag({ word: tag }, (status, data) => {
      if (status === "success") {
        window.location.reload(true);
      }
    });
    handleClose();
  };
  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">New Tag</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Tag</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Tag"
                  value={tag}
                  onChange={handleTag}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary " onClick={onAddTag}>
            Add Tag
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddTag;
