import React, { useState } from "react";

import { VideoPopup } from "../VideoPopup";

import { thumbnailVideoDownload } from "../../videoContent.service";

import { getDate_3W2D3M2Y, getTime_12T } from "../../../../const/dateFormat";

import play from "../../../../assets/play.png";
import { getBySourceCode } from "../../../../const/newsSources";
import { useNewsSources } from "../../../../dataService/contexts";

const getDuration = (duration) => {
  const splitDuration = duration.split(":");

  const hour = Number(splitDuration[0]);
  const min = Number(splitDuration[1]);

  const formattedHour = hour ? `${hour} hour` : ``;
  const formattedMin = min ? `${min} min` : ``;

  const formattedDuration = `${formattedHour} ${formattedMin}`;

  return formattedDuration;
};

const VideoThumbnail = ({ data }) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const channel = getBySourceCode(data.tvChannel, newsSources);
  const [show, setShow] = useState(false);
  const [params, setParams] = useState({
    channelName: "",
    endDate: "",
    startDate: "",
  });
  const handleOpen = () => {
    setShow(true);
    setParams({
      channelName: data.tvChannel,
      endDate: data.searchEnd,
      startDate: data.searchStart,
    });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDownload = () => {
    thumbnailVideoDownload(params, (status, data, _params) => {
      if (status === "success" && data.url) {
        const blob = new Blob([data.url]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `video.mp4`;
        link.click();
      }
    });
  };

  return (
    <div>
      <div
        className="flex-grow-1"
        onClick={handleOpen}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          className="d-flex flex-column p-2 rounded-4 mb-2"
          style={{
            backgroundColor: "#242731",
            height: "20rem",
          }}
        >
          <div
            className="bg-dark text-white d-flex justify-content-between align-items-center rounded-4 flex-column p-1"
            style={{
              backgroundImage: `url(${data.thumbnailLink})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
              overflow: "auto",
              backgroundSize: "cover",
            }}
          >
            <div></div>
            <div>
              <img
                alt="play"
                style={{
                  height: "2rem",
                  width: "2rem",
                }}
                src={play}
              />
            </div>
            <div className="d-flex align-self-start bg-black__light py-1 px-3 rounded-4 font-s-n font-w-bolder">
              {getDuration(data.duration)}
            </div>
          </div>
          <div className="d-flex flex-row mt-2 px-3 py-2">
            <div className="d-flex flex-row gap-3 align-items-center w-100">
              <div className="">
                <img
                  alt="newspaper-logo"
                  src={channel.icon}
                  style={{
                    height: "3.5rem",
                    width: "3.5rem",
                    borderRadius: "12rem",
                  }}
                />
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="text-white font-s-md font-w-bold">
                  {channel.name}
                </div>
                <div className="d-flex flex-column flex-wrap">
                  <div className="font-s-n text-light font-w-bold ">
                    {`${getTime_12T(data.videoStartTime)} - ${getTime_12T(
                      data.videoEndTime
                    )}`}
                  </div>
                  <div className="font-s-n text-light font-w-bold ">
                    {" "}
                    {`${getDate_3W2D3M2Y(data.videoEndTime)}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {data && (
        <VideoPopup
          handleClose={handleClose}
          show={show}
          videoData={data}
          handleDownload={handleDownload}
        />
      )}
    </div>
  );
};

export { VideoThumbnail };
