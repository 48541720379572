import React, { useEffect, useState } from "react";

import Select from "react-select";

import { useCategory } from "../../../../../../../dataService/contexts";

const CategoryDropdown = ({ handleCategory, currentTag, bg = true }) => {
  const { state: categoryState } = useCategory();
  const { categories } = categoryState;

  const handleSelect = (data) => {
    handleCategory("cropCategory", data);
  };

  return (
    <Select
      isSearchable={false}
      options={categories}
      className="channel-select-containerr w-auto"
      classNamePrefix={`${bg === true ? "channel-select" : "tags-select"}`}
      getOptionLabel={(options) => `${options.categoryName}`}
      placeholder="Category"
      onChange={(e) => {
        handleSelect(e);
      }}
    />
  );
};

export default CategoryDropdown;
