import React from "react";

const HamburgerIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3.75C2 4.16421 2.33579 4.5 2.75 4.5H17.25C17.6642 4.5 18 4.16421 18 3.75C18 3.33579 17.6642 3 17.25 3H2.75C2.33579 3 2 3.33579 2 3.75Z"
        fill="#ffffff"
      />
      <path
        d="M2 16.25C2 16.6642 2.33579 17 2.75 17H17.25C17.6642 17 18 16.6642 18 16.25C18 15.8358 17.6642 15.5 17.25 15.5H2.75C2.33579 15.5 2 15.8358 2 16.25Z"
        fill="#ffffff"
      />
      <path
        d="M5 10C5 10.8284 5.67157 11.5 6.5 11.5H13.5C14.3284 11.5 15 10.8284 15 10C15 9.17157 14.3284 8.5 13.5 8.5H6.5C5.67157 8.5 5 9.17157 5 10Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default HamburgerIcon;
