import axios from "axios";

class apiService {
  constructor() {
    let service = axios.create();
    this.service = service;
  }

  get(path, callback, data, params) {
    return this.service
      .get(path, {
        ...data,
        params,
      })
      .then((response) => {
        callback(response.status, response.data, response.headers);
      })
      .catch((error) => {
        callback(
          error.response?.status,
          error.response?.data?.message ||
            error.response?.data?.error_description ||
            "Something went wrong"
        );
      });
  }

  patch(path, payload, callback) {
    return this.service
      .request({
        method: "PATCH",
        url: path,
        responseType: "json",
        data: payload,
      })
      .then((response) => callback(response.status, response.data));
  }

  post(config, callback) {
    const { url, data, responseType, headers } = config;

    return this.service
      .request({
        method: "POST",
        url,
        data,
        responseType,
        headers,
      })
      .then((response) =>
        callback(response.status, response.data, response.headers)
      )
      .catch((error) => {
        callback(
          error.response?.status,
          !!error.response?.data?.message
            ? error.response?.data?.message
            : "Something went wrong"
        );
      });
  }

  put(config, callback) {
    const { url, data, responseType, headers } = config;

    return this.service
      .request({
        method: "PUT",
        url,
        data,
        responseType,
        headers,
      })
      .then((response) => callback(response.status, response.data))
      .catch((error) => {
        callback(
          error.response?.status,
          error.response?.data?.error_description ||
            error.response?.data?.message ||
            "Something went wrong"
        );
      });
  }

  delete(path, callback) {
    return this.service
      .delete(path)
      .then((response) => callback(response?.status, response?.data))
      .catch((error) => callback(error.response?.status, error?.message));
  }

  custom(config, callback) {
    const { url, method, data, responseType, headers } = config;

    return this.service
      .request({
        url,
        method,
        data,
        responseType,
        headers,
      })
      .then((response) => callback(response.status, response.data))
      .catch((error) => {
        callback(
          error.response?.status,
          error.response?.data?.error_description ||
            error.response?.data?.message ||
            "Something went wrong"
        );
      });
  }
}

export default new apiService();
