import React from "react";

const MonitorIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.37258 0.0913088H9.90909C10.3509 0.0913088 10.7091 0.449482 10.7091 0.89131C10.7091 1.33314 10.3509 1.69131 9.90909 1.69131H7.40909C6.22801 1.69131 5.39232 1.69198 4.73998 1.74747C4.09757 1.80211 3.7068 1.90551 3.40062 2.06916C2.84246 2.36751 2.38529 2.82467 2.08695 3.38284C1.92329 3.68901 1.8199 4.07979 1.76525 4.72219C1.70977 5.37454 1.70909 6.21022 1.70909 7.39131C1.70909 8.57239 1.70977 9.40808 1.76525 10.0604C1.8199 10.7028 1.92329 11.0936 2.08695 11.3998C2.38529 11.9579 2.84246 12.4151 3.40062 12.7135C3.7068 12.8771 4.09757 12.9805 4.73998 13.0351C5.39232 13.0906 6.22801 13.0913 7.40909 13.0913H12.4091C13.5902 13.0913 14.4259 13.0906 15.0782 13.0351C15.7206 12.9805 16.1114 12.8771 16.4176 12.7135C16.9757 12.4151 17.4329 11.9579 17.7312 11.3998C17.9511 10.9885 18.0562 10.44 18.0913 9.36516C18.1058 8.92356 18.4755 8.57729 18.9171 8.59174C19.3586 8.60618 19.7049 8.97587 19.6905 9.41746C19.6543 10.5241 19.5481 11.3948 19.1423 12.154C18.6948 12.9913 18.0091 13.677 17.1718 14.1245C16.5943 14.4332 15.9596 14.566 15.2138 14.6294C14.4857 14.6913 13.582 14.6913 12.4456 14.6913H10.6319C10.6495 14.755 10.6589 14.8221 10.6589 14.8913V18.3913H15.1589C15.5731 18.3913 15.9089 18.7271 15.9089 19.1413C15.9089 19.5555 15.5731 19.8913 15.1589 19.8913H4.6589C4.24468 19.8913 3.9089 19.5555 3.9089 19.1413C3.9089 18.7271 4.24468 18.3913 4.6589 18.3913H9.1589V14.8913C9.1589 14.8221 9.16829 14.755 9.18586 14.6913H7.3726C6.23623 14.6913 5.33248 14.6913 4.60438 14.6294C3.85855 14.566 3.22385 14.4332 2.64639 14.1245C1.80914 13.677 1.12339 12.9913 0.675871 12.154C0.36721 11.5765 0.234448 10.9418 0.171011 10.196C0.10908 9.46792 0.109086 8.56419 0.109093 7.42782V7.3548C0.109086 6.21843 0.10908 5.3147 0.171011 4.58659C0.234448 3.84077 0.36721 3.20607 0.675871 2.62861C1.12339 1.79135 1.80914 1.10561 2.64639 0.658087C3.22385 0.349426 3.85855 0.216664 4.60438 0.153227C5.33248 0.0912954 6.23621 0.0913011 7.37258 0.0913088Z"
        fill="#808191"
      />
      <path
        d="M15.9756 0.141312C16.3898 0.141312 16.7256 0.477099 16.7256 0.891312V3.20798L18.9089 3.20798C19.3231 3.20798 19.6589 3.54377 19.6589 3.95798C19.6589 4.3722 19.3231 4.70798 18.9089 4.70798H16.7256V6.89131C16.7256 7.30552 16.3898 7.64131 15.9756 7.64131C15.5614 7.64131 15.2256 7.30552 15.2256 6.89131V4.70798L12.9089 4.70798C12.4947 4.70798 12.1589 4.3722 12.1589 3.95798C12.1589 3.54377 12.4947 3.20798 12.9089 3.20798H15.2256V0.891312C15.2256 0.477099 15.5614 0.141312 15.9756 0.141312Z"
        fill="#808191"
      />
    </svg>
  );
};

export default MonitorIcon;
