// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-underline {
  height: 0.25rem;
  width: 4.85rem;
  border-radius: 1rem;
  background: #6c5dd3;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsFullView/styles.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,mBAAA;EACA,mBAAA;AACJ","sourcesContent":[".custom-underline{\n    height: 0.25rem;\n    width: 4.85rem;\n    border-radius: 1rem;\n    background: #6c5dd3\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
