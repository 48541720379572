import apiService from "../../../../dataService/apiService";
import { getEnvironment } from "../../../../const/environment";

export const getKeywordDetails = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/keyword-count-service/api/${env.version}/${env.type}/keywords/filter-by-keyword`;

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data.keywords[0]);
      } else {
        done("error");
      }
    },
    {},
    { ...params }
  );
};
