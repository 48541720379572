// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-container {
  background-color: #242731;
  border: 1px solid #33353b;
  flex: 4 1;
}

.line-container > div {
  min-height: 300px !important;
  min-width: 300px !important;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/MinisterDashboard/components/Line/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,yBAAA;EACA,SAAA;AACF;;AAEA;EACE,4BAAA;EACA,2BAAA;EACA,WAAA;AACF","sourcesContent":[".line-container {\n  background-color: #242731;\n  border: 1px solid #33353b;\n  flex: 4;\n}\n\n.line-container > div {\n  min-height: 300px !important;\n  min-width: 300px !important;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
