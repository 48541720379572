import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { addNewIcon } from "../../icons.service";

const AddIcons = ({ open, handleClose }) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [addParams, setAddParams] = useState({
    name: "",
    code: "",
    iconType: 0,
    channelUrl: "",
  });

  const handleAddParams = (key, date) => {
    setAddParams((prev) => {
      return {
        ...prev,
        [key]: date,
      };
    });
  };
  const handleIcon = (event) => {
    setSelectedFile(event.target.files[0]);
    // addNewIcon(event.target.files[0], (status, data) => {
    //   if (status === "success") {
    //     // window.location.reload(true);
    //   }
    // });
  };

  const onAddTag = () => {
    addNewIcon(selectedFile, addParams, (status, data) => {
      if (status === "success") {
        handleClose();
      }
    });
  };
  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">New News Source</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Name</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  id="Name"
                  placeholder="Enter name"
                  value={addParams.name}
                  onChange={(e) => handleAddParams("name", e.target.value)}
                />
              </div>
              {/* { "id":18,(only for update) "name":"News", "code":"news24", "url":"http://192.168.160.230/hls/NEWS24.m3u8", "icon":"http://192.168.160.230/hls/NEWS24.m3u8", "type":1, "environment":"prod" } */}
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Code</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  id="code"
                  placeholder="Enter Code"
                  value={addParams.code}
                  onChange={(e) => handleAddParams("code", e.target.value)}
                />
              </div>
              {/* { "id":18,(only for update) "name":"News", "code":"news24", "url":"http://192.168.160.230/hls/NEWS24.m3u8", "icon":"http://192.168.160.230/hls/NEWS24.m3u8", "type":1, "environment":"prod" } */}
            </div>

            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Icon Type</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="number"
                  id="iconType"
                  placeholder="Enter Icon Type"
                  value={addParams.iconType}
                  onChange={(e) => handleAddParams("iconType", e.target.value)}
                />
              </div>
              {/* { "id":18,(only for update) "name":"News", "code":"news24", "url":"http://192.168.160.230/hls/NEWS24.m3u8", "icon":"http://192.168.160.230/hls/NEWS24.m3u8", "type":1, "environment":"prod" } */}
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Channel Url</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  id="channelUrl"
                  placeholder="Enter Channel Url"
                  value={addParams.channelUrl}
                  onChange={(e) =>
                    handleAddParams("channelUrl", e.target.value)
                  }
                />
              </div>
              {/* { "id":18,(only for update) "name":"News", "code":"news24", "url":"http://192.168.160.230/hls/NEWS24.m3u8", "icon":"http://192.168.160.230/hls/NEWS24.m3u8", "type":1, "environment":"prod" } */}
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Icon</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="file"
                  id="icon"
                  placeholder="Enter Icon"
                  value={undefined}
                  onChange={handleIcon}
                />
              </div>
              {/* { "id":18,(only for update) "name":"News", "code":"news24", "url":"http://192.168.160.230/hls/NEWS24.m3u8", "icon":"http://192.168.160.230/hls/NEWS24.m3u8", "type":1, "environment":"prod" } */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary " onClick={onAddTag}>
            Add News Source
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddIcons;
