import moment from "moment";

import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";

const env = getEnvironment();

export const downloadReport = (date, done = () => {}) => {
  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/topic-management-service/api/${env.version}/${
    env.type
  }/report/manager-marked-report/download?date=${moment(date)
    .utc("Asia/Dhaka")
    .format()}&format=excel`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };

  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};
