import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";

import { useAuth } from "../../../../dataService/contexts";

import { pagePermission } from "../../../../const/pagePemission";
import { parseJwt } from "../../../../utilities";
import { ROUTE } from "../../../../const";

import { Brand } from "../Brand";

import {
  Alerts,
  Analysis,
  Bookmark,
  CaseManagement,
  Dashboard,
  Dictionary,
  LiveNewspaper,
  Monitor,
  Report,
} from "../../../../assets/SidebarSvg";
import GetLiveStream from "../../../../assets/SidebarSvg/GetLiveStream";
import ArrowLeftIcon from "../../../../assets/arrowLeftIcon";
import Logo from "../../../../assets/nnsp.png";
import SearchIcon from "../../../../assets/SearchIcon";
import TopicManagementIcon from "../../../../assets/TopicManagementIcon";
import NewspaperLiveIcon from "../../../../assets/NewspaperLiveIcon";
import TvScrollLiveIcon from "../../../../assets/TvScrollLiveIcon";
import MonitorIcon from "../../../../assets/MonitorIcon";
import VideoCropIcon from "../../../../assets/VideoCropIcon";
import NewspaperSearchIcon from "../../../../assets/NewspaperSearchIcon";
import TvScrollSearchIcon from "../../../../assets/TvScrollSearchIcon";
import AnalysisIcon from "../../../../assets/AnalysisIcon";
import ManagerReportIcon from "../../../../assets/ManagerReportIcon";
import NewspaperReportIcon from "../../../../assets/NewspaperReportIcon";
import UniqueScrollNewsIcon from "../../../../assets/UniqueScrollNewsIcon";
import NewspaperReportOverviewIcon from "../../../../assets/NewspaperReportOverviewIcon";
import MarkedReportOverviewIcon from "../../../../assets/MarkedReportOverviewIcon";
import VideoCropReportOverviewIcon from "../../../../assets/VideoCropReportOverviewIcon";
import KeywordsIcon from "../../../../assets/KeywordsIcon";
import TagsIcon from "../../../../assets/TagsIcon";
import CategoryIcon from "../../../../assets/CategoryIcon";
import NoticeIcon from "../../../../assets/NoticeIcon";
import AnalyticalDashboardIcon from "../../../../assets/AnalyticalDashboardIcon";
import ArrowRightIcon from "../../../../assets/arrowRightIcon";
import AnalystReportsIcon from "../../../../assets/AnalystReportsIcon";

const hasUserCodePermission = (userCodePermission, token) => {
  const decodedJwt = parseJwt(token);

  return userCodePermission && userCodePermission.length > 0
    ? userCodePermission.reduce(
        (hasPermission, currentPermission) =>
          hasPermission ||
          (!!decodedJwt &&
            !!decodedJwt.client_roles &&
            decodedJwt?.client_roles?.includes(currentPermission)),
        false
      )
    : true;
};

const subMenuList = [
  // {
  //   id: "1",
  //   label: "Analytical Dashboard",
  //   path: `${ROUTE.ANALYTICAL_DASHBOARD}`,
  //   icon: <AnalyticalDashboardIcon />,
  //   permissions: {
  //     userCode: [pagePermission.analyticalDashbaord],
  //   },
  //   submenu: [],
  // },
  {
    id: "2",
    label: "Minister Dashboard",
    path: `${ROUTE.MINISTER_DASHBOARD}`,
    icon: <AnalyticalDashboardIcon />,
    permissions: {
      userCode: [pagePermission.analyticalDashbaord],
    },
    submenu: [],
  },
  {
    id: "2",
    label: "Unique Report Dashboard",
    path: `${ROUTE.UNIQUE_REPORT_DASHBOARD}`,
    icon: <Dashboard />,
    permissions: {
      userCode: [pagePermission.analyticalDashbaord],
    },
    submenu: [],
  },
  // {
  //   id: "2",
  //   label: "Dashboard",
  //   path: `${ROUTE.DASHBOARD}`,
  //   icon: <Dashboard />,
  //   permissions: {
  //     userCode: [pagePermission.dashboard],
  //   },
  //   submenu: [],
  // },

  // {
  //   id: "3",
  //   label: "Live Data Feed  ",
  //   path: `${ROUTE.LIVENEWSPAER}`,
  //   icon: <Monitor />,
  //   permissions: {
  //     userCode: [],
  //   },
  //   submenu: [
  //     {
  //       id: "3.1",
  //       label: "Newspaper Live",
  //       path: `${ROUTE.LIVENEWSPAER}`,
  //       icon: <NewspaperLiveIcon />,
  //       permissions: {
  //         userCode: [pagePermission.newspaperLive],
  //       },
  //       submenu: [],
  //     },
  //
  //     {
  //       id: "3.2",
  //       label: "TV Scroll Live",
  //       path: `${ROUTE.LIVETVSCROLL}`,
  //       icon: <TvScrollLiveIcon />,
  //       permissions: {
  //         userCode: [pagePermission.tvScrollLive],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "3.3",
  //       label: "Live Stream",
  //       path: `${ROUTE.LIVERSTREAM}`,
  //       icon: <GetLiveStream />,
  //       permissions: {
  //         userCode: [pagePermission.liveStream],
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   id: "4",
  //   label: "TV Video Crop  ",
  //   path: `${ROUTE.LIVENEWSPAER}`,
  //   icon: <GetLiveStream />,
  //   permissions: {
  //     userCode: [],
  //   },
  //   submenu: [
  //     {
  //       id: "4.1",
  //       label: "Monitor",
  //       path: `${ROUTE.VIDEO}`,
  //       icon: <MonitorIcon />,
  //       permissions: {
  //         userCode: [pagePermission.monitor],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "4.2",
  //       label: "Video Crop",
  //       path: `${ROUTE.VIDEO_CROP}`,
  //       icon: <VideoCropIcon />,
  //       permissions: {
  //         userCode: [pagePermission.videoCrop],
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   id: "5",
  //   label: "Search Content",
  //   path: `${ROUTE.LIVENEWSPAER}`,
  //   icon: <SearchIcon />,
  //   permissions: {
  //     userCode: [],
  //   },
  //   submenu: [
  //     {
  //       id: "5.1",
  //       label: "Newspaper Search",
  //       path: `${ROUTE.NEWSPAPERSEARCH}`,
  //       icon: <NewspaperSearchIcon />,
  //       permissions: {
  //         userCode: [pagePermission.newspaperSearch],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "5.2",
  //       label: "TV Scroll Search",
  //       path: `${ROUTE.TVSCROLLSEARCH}`,
  //       icon: <TvScrollSearchIcon />,
  //       permissions: {
  //         userCode: [pagePermission.tvScrollSearch],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "5.3",
  //       label: "Analysis",
  //       path: `${ROUTE.NEWS}`,
  //       icon: <AnalysisIcon />,
  //       permissions: {
  //         userCode: [pagePermission.analysis],
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   id: "6",
  //   label: " Data Insight",
  //   path: `${ROUTE.LIVENEWSPAER}`,
  //   icon: <CaseManagement />,
  //   permissions: {
  //     userCode: [],
  //   },
  //   submenu: [
  //     {
  //       id: "6.1",
  //       label: "Keyword Alerts",
  //       path: `${ROUTE.ALERTNOTIFICATION}`,
  //       icon: <Alerts />,
  //       permissions: {
  //         userCode: [pagePermission.alerts],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "6.2",
  //       label: "Topic Management",
  //       path: `${ROUTE.CASEMANAGEMENT}`,
  //       icon: <TopicManagementIcon />,
  //       permissions: {
  //         userCode: [pagePermission.caseManagement],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "6.3",
  //       label: "Bookmark",
  //       path: `${ROUTE.BOOKMARKS}`,
  //       icon: <Bookmark />,
  //       permissions: {
  //         userCode: [pagePermission.bookmark],
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   id: "7",
  //   label: "Analyst Reports",
  //   path: `${ROUTE.LIVENEWSPAER}`,
  //   icon: <AnalystReportsIcon />,
  //   permissions: {
  //     userCode: [],
  //   },
  //   submenu: [
  //     {
  //       id: "7.1",
  //       label: "Marked Report",
  //       path: `${ROUTE.DAILYREPORT}`,
  //       icon: <Report />,
  //       permissions: {
  //         userCode: [pagePermission.dailyReport],
  //       },
  //       submenu: [],
  //     },
  //
  //     {
  //       id: "7.2",
  //       label: "Newspaper Report",
  //       path: `${ROUTE.NEWSPAPER_REPORT}`,
  //       icon: <NewspaperReportIcon />,
  //       permissions: {
  //         userCode: [pagePermission.newspaperReport],
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   id: "8",
  //   label: "Manager Reports",
  //   path: `${ROUTE.LIVENEWSPAER}`,
  //   icon: <LiveNewspaper />,
  //   permissions: {
  //     userCode: [pagePermission.managementReports],
  //   },
  //   submenu: [
  //     {
  //       id: "8.1",
  //       label: "Marking Report",
  //       path: `${ROUTE.MANAGERREPORT}`,
  //       icon: <ManagerReportIcon />,
  //       permissions: {
  //         userCode: [pagePermission.managerReport],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "8.2",
  //       label: "Newspaper Report",
  //       path: `${ROUTE.NEWSPAPER_REPORT}`,
  //       icon: <NewspaperReportIcon />,
  //       permissions: {
  //         userCode: [pagePermission.newspaperReport],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "8.3",
  //       label: "Unique Scroll News",
  //       path: `${ROUTE.UNIQUE_SCROLL_NEWS}`,
  //       icon: <UniqueScrollNewsIcon />,
  //       permissions: {
  //         userCode: [],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "8.4",
  //       label: "Newspaper Report Overview",
  //       path: `${ROUTE.REPORT_OVERVIEW}`,
  //       icon: <NewspaperReportOverviewIcon />,
  //       permissions: {
  //         userCode: [pagePermission.newspaperLive],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "8.5",
  //       label: "Marked Report Overview",
  //       path: `${ROUTE.MARKING_REPORT_OVERVIEW}`,
  //       icon: <MarkedReportOverviewIcon />,
  //       permissions: {
  //         userCode: [pagePermission.newspaperLive],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "8.6",
  //       label: "Video Crop Report Overview",
  //       path: `${ROUTE.VIDEO_CROP_REPORT_OVERVIEW}`,
  //       icon: <VideoCropReportOverviewIcon />,
  //       permissions: {
  //         userCode: [pagePermission.newspaperLive],
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   id: "9",
  //   label: "Configuration",
  //   path: `${ROUTE.LIVENEWSPAER}`,
  //   icon: <Dictionary />,
  //   permissions: {
  //     userCode: [pagePermission.managementConfiguration],
  //   },
  //   submenu: [
  //     {
  //       id: "9.1",
  //       label: "Keywords",
  //       path: `${ROUTE.DICTIONARY}`,
  //       icon: <KeywordsIcon />,
  //       permissions: {
  //         userCode: [pagePermission.dictionary],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "9.2",
  //       label: "Tags",
  //       path: `${ROUTE.TAGS}`,
  //       icon: <TagsIcon />,
  //       permissions: {
  //         userCode: [pagePermission.tags],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "9.3",
  //       label: "Category",
  //       path: `${ROUTE.CATEGORY}`,
  //       icon: <CategoryIcon />,
  //       permissions: {
  //         userCode: [pagePermission.category],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "9.4",
  //       label: "News Sources",
  //       path: `${ROUTE.ICONS}`,
  //
  //       icon: <Monitor />,
  //       permissions: {
  //         userCode: [pagePermission.icons],
  //       },
  //       submenu: [],
  //     },
  //     {
  //       id: "9.5",
  //       label: "Notice",
  //       path: `${ROUTE.NOTICE}`,
  //       icon: <NoticeIcon />,
  //       permissions: {
  //         userCode: [pagePermission.notice],
  //       },
  //       submenu: [],
  //     },
  //   ],
  // },
];

const Aside = ({ toggle, handleToggle, handleCollapse, collapse }) => {
  const { state: authState } = useAuth();
  const [activeSidebar, setActiveSidebar] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [activePath, setActivePath] = useState("");

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    setActivePath(window.location.pathname);
  }, []);

  return (
    <div>
      <Sidebar
        customBreakPoint={"1024px"}
        breakPoint="always"
        onBackdropClick={handleToggle}
        toggled={toggle}
        collapsed={collapse}
        style={{ width: `${collapse ? "8rem" : "23rem"}` }}
      >
        <div
          className={`p-4 d-flex ${
            collapse ? "flex-column gap-3" : "flex-row"
          } justify-content-between`}
        >
          <div className="p-3">
            {collapse ? <img src={Logo} alt="logo" /> : <Brand />}
          </div>
          <div className="d-flex align-content-center justify-content-center">
            {!toggle ? (
              <button className="p-0" onClick={() => handleCollapse()}>
                {collapse ? (
                  <ArrowRightIcon />
                ) : (
                  <div className="mt-3">
                    <ArrowLeftIcon />
                  </div>
                )}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>

        <Menu>
          {subMenuList.length > 0 &&
            subMenuList.map((sideMenu) => {
              return hasUserCodePermission(
                sideMenu.permissions.userCode,
                authState.token
              ) ? (
                sideMenu.submenu.length ? (
                  <SubMenu
                    key={sideMenu.id}
                    icon={sideMenu.icon}
                    label={sideMenu.label}
                    className="text-light "
                  >
                    {sideMenu.submenu.map((submenu) =>
                      hasUserCodePermission(
                        submenu.permissions.userCode,
                        authState.token
                      ) ? (
                        <MenuItem key={submenu.id}>
                          <NavLink to={submenu.path}>
                            <div className="d-flex">
                              {submenu.icon && (
                                <div className="d-flex align-items-center justify-content-center p-0 menu-icon">
                                  {submenu.icon}
                                </div>
                              )}
                              {submenu.label && (
                                <div className="ms-3 d-flex align-items-center justify-content-center p-0 menu-label font-s-n text-silver font-w-bold">
                                  {submenu.label}
                                </div>
                              )}
                            </div>
                          </NavLink>
                        </MenuItem>
                      ) : (
                        <></>
                      )
                    )}
                  </SubMenu>
                ) : (
                  <MenuItem key={sideMenu.id}>
                    <NavLink to={sideMenu.path}>
                      <div className="d-flex flex-row gap-2">
                        {sideMenu.icon && (
                          <div className="d-flex align-items-center justify-content-center p-0 menu-icon">
                            {sideMenu.icon}
                          </div>
                        )}

                        {sideMenu.label && (
                          <div className="ms-3 d-flex align-items-center justify-content-center p-0 menu-label font-s-n text-silver font-w-bold">
                            {sideMenu.label}
                          </div>
                        )}
                      </div>
                    </NavLink>
                  </MenuItem>
                )
              ) : (
                <></>
              );
            })}
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Aside;
