import ReactApexChart from "react-apexcharts";
import moment from "moment";

import "./style.scss";

const getAllDates = function (startDate, endDate) {
  var now = startDate.clone(),
    dates = [];

  // Use isSameOrBefore for the loop condition
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("DD MMM"));
    now.add(1, "days");
  }

  // Ensure the last date is included in the array

  return dates;
};

const Line = (props) => {
  const { lineData, startDate, endDate } = props;
  const options = {
    chart: {
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 1,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
          },
          svg: { filename: "Analysis" },
          png: { filename: "Analysis" },
        },
        autoSelected: "zoom",
      },
      type: "line",
    },
    colors: ["#ff7a68", "#3DBAA2", "#ff9f38"],
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
    stroke: {
      width: [2, 2, 2],
      curve: "smooth",
    },
    legend: {
      show: true,
      position: "bottom",
      fontSize: "10px",
      labels: {
        colors: "#808191",
        useSeriesColors: false,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },

      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: ["#ff7a68", "#3DBAA2", "#ff9f38"],
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    xaxis: {
      labels: {
        style: {
          colors: "#808191",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: getAllDates(moment(startDate), moment(endDate)),
    },
  };

  return options ? (
    <div className="d-flex align-items-center rounded-4 p-4 line-container">
      <ReactApexChart
        height="100%"
        width="100%"
        options={{
          ...options,
        }}
        series={lineData}
        type="line"
      />
    </div>
  ) : (
    <></>
  );
};

export default Line;
