import ReactApexChart from "react-apexcharts";

import "./style.scss";

const Bar = (props) => {
  const { barData } = props;
  const { series, categories } = barData || {};

  const options = {
    colors: ["#3DBAA2", "#ff7a68", "#ff9f38"],
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
    stroke: {
      width: [2, 2, 2],
      curve: "smooth",
    },
    legend: {
      show: false,
      position: "bottom",
      fontSize: "10px",
      labels: {
        colors: "#808191",
        useSeriesColors: false,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },

    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },

    xaxis: {
      labels: {
        style: {
          colors: "#808191",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: categories || [],
    },
  };

  return options ? (
    <div className="d-flex align-items-center rounded-4 p-4 bar-container">
      <ReactApexChart
        height="100%"
        width="100%"
        options={{
          ...options,
        }}
        series={series || []}
        type="bar"
      />
    </div>
  ) : (
    <></>
  );
};

export default Bar;
