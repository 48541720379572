import React, { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  getLogout,
  useNewsSources,
  useUser,
} from "../../../../../../dataService/contexts";
import { updateNewPassword } from "./changePassword.service";

const ChangePasswordModal = ({ open, handleClose }) => {
  const { dispatch: userDispatch, state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { NewsSources } = newsSourcesState || {};
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handlePasswordChange = () => {
    if (password === confirmPassword) {
      updateNewPassword(
        {
          value: confirmPassword,
        },
        (status, data) => {
          if (status === "success") {
            getLogout(userDispatch, () => {
              window.location.replace("/login");
            });
          }
        }
      );
    } else {
    }
  };
  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">Change Password</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">New Password</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={handlePassword}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Confirm Password</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary "
            onClick={handlePasswordChange}
            disabled={
              (password !== confirmPassword && true) ||
              (confirmPassword === "" && password === "" && true)
            }
          >
            Update Password
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
