import ReactApexChart from "react-apexcharts";

const Line = (props) => {
    const { lineData } = props;

    const options = {
        chart: {
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 1
            },
            toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [],
                },
                export: {
                    csv: {
                        filename: undefined,
                        columnDelimiter: ",",
                        headerCategory: "category",
                        headerValue: "value",
                    },
                    svg: {filename: "Analysis"},
                    png: {filename: "Analysis"},
                },
                autoSelected: "zoom",
            },
            type: "line",
        },
        colors: ["#ff7a68", "#3DBAA2", "#ff9f38"] ,
        dataLabels: { enabled: false },
        tooltip: { enabled: false },
        stroke: {
            width: [2, 2, 2],
            curve: 'smooth'
        },        
        legend: {
            show: false,
            position: "bottom",
            fontSize: "10px",
            labels: {
                show: false,
                colors: "#808191",
                useSeriesColors: false,
            },
            itemMargin: {
                horizontal: 5,
                vertical: 5,
            },
        
            markers: {
                show: false,
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: ["#ff7a68", "#3DBAA2", "#ff9f38"],
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },        
        },  
        grid: {
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
        },
        
        yaxis: {
            show: false,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },

        xaxis: {
            show: false,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            // categories:getAllDates(moment(startDate), moment(endDate)),
        },
    } 

    return options ? (
        // <div className="d-flex align-items-center rounded-4 p-4 line-container">
            <ReactApexChart
                height="100%"
                width="100%"
                options={{
                    ...options,
                }}
                series={ lineData }
                type="line"
            />
        // </div>
    ) : (
        <></>
    );
};

export default Line;
