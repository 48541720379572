const ROUTE = {
  DASHBOARD: "/",
  VIDEO: "video",
  NEWS: "news",
  DICTIONARY: "dictionary",
  NEWSPAPERNEWS: "newspaper-news",
  TVSCROLLNEWS: "tv-scroll-news",
  CASEMANAGEMENT: "case-management",
  ALERTNOTIFICATION: "alert-notification",
  LOGIN: "login",
  BOOKMARKS: "bookmarks",
  NEWSFULLVIEW: "news-full-view",
  BOOKMARKNEWSFULLVIEW: "single-bookmark",
  LIVERSTREAM: "live-stream",
  TOPKEYWORDVIEW: "top-keyword-view",
  TRENDINGKEYWORDVIEW: "trending-keyword-view",
  DAILYREPORT: "daily-report",
  MANAGERREPORT: "report",
  MANAGERREPORT_PREVIEW: "report-preview",
  TAGS: "tags",
  LIVE: "live",
  LIVENEWSPAER: "live-newspapers",
  LIVETVSCROLL: "live-tvscroll",
  NEWSPAPERSEARCH: "newspaper-search",
  TVSCROLLSEARCH: "tvscroll-search",
  CATEGORY: "category",
  NEWSPAPER_REPORT: "newspaper-report",
  VIDEO_CROP: "video-crop",
  UNIQUE_SCROLL_NEWS: "unique-scroll-news",
  ADD_ICONS: "add-icons",
  ICONS: "icons",
  NOTICE: "notice",
  MYNOTICE: "mynotice",
  SEENNOTICELIST: "seennoticelist",
  REPORT_OVERVIEW: "report-overview",
  MARKING_REPORT_OVERVIEW: "marking-report-overview",
  VIDEO_CROP_REPORT_OVERVIEW: "video-crop-report-overview",
  NEWSPAPER_REPORT_OVERVIEW_DETAILS: "newspaper_report_overview_details",
  MARKING_REPORT_OVERVIEW_DETAILS: "marking_report_overview_details",
  VIDEO_CROP_OVERVIEW_DETAILS: "video_crop_overview_details",
  ANALYTICAL_DASHBOARD: "analytical_dashboard",
  MINISTER_DASHBOARD: "minister_dashboard",
  UNIQUE_REPORT_DASHBOARD: "unique_report_dashboard",
};

const channelList = [
  {
    id: 1,
    channelName: "Channel I",
    value: "channeli",
  },
  {
    id: 2,
    channelName: "Independent TV",
    value: "independent",
  },
  {
    id: 3,
    channelName: "ATN",
    value: "atn",
  },
  {
    id: 4,
    channelName: "Jamuna TV",
    value: "jamunaTv",
  },
  {
    id: 5,
    channelName: "ETV",
    value: "etv",
  },
  {
    id: 6,
    channelName: "NTV",
    value: "ntv",
  },
  {
    id: 7,
    channelName: "Somoy TV",
    value: "somoyTv",
  },
  {
    id: 8,
    channelName: "Bangla Vision",
    value: "banglaVision",
  },
  {
    id: 9,
    channelName: "Rtv",
    value: "rtv",
  },

  {
    id: 10,
    channelName: "Boishaki TV",
    value: "boishakiTv",
  },
];

export { ROUTE, channelList };
