import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { getTrendingKeywordsAlert } from "./trendingKeywordsAlert.service";

import "./style.scss";
import { getDateTime_12T2D3M2Y } from "../../../../const/dateFormat";

const getTimeRange = () => {
  const currentTimeDate = moment(new Date()).toISOString();
  const prevTimeDate = moment(
    moment(currentTimeDate).startOf("day")
  ).toISOString();

  return {
    startDate: prevTimeDate,
    endDate: currentTimeDate,
  };
};

const getQueryString = (data) => {
  const dateRange = getTimeRange();
  let queryString = `?keys=${data.keyword}&start=${dateRange.startDate}&end=${dateRange.endDate}`;

  return queryString;
};

const TrendingKeywordsAlert = () => {
  const [updatedTime, setUpdatedTime] = useState("");

  const [newspaperTrendingTopicList, setNewspaperTrendingTopicList] = useState(
    []
  );

  //to set data to setNewspaperTrendingTopicList after api call
  const handleNewspaperTrendingTopicList = (alerts) =>
    setNewspaperTrendingTopicList(alerts);

  const [scrollNewsTrendingTopicList, setScrollNewsTrendingTopicList] =
    useState([]);
  //to set data to setScrollNewsTrendingTopicList after api call
  const handleScrollNewsTrendingTopicList = (alerts) =>
    setScrollNewsTrendingTopicList(alerts);

  //this use effect function is used to make the api call when a user gets to this route
  useEffect(() => {
    const fetchTrendingKeywordsAlert = () => {
      getTrendingKeywordsAlert((status, data) => {
        if (status === "success") {
          handleNewspaperTrendingTopicList(data.newspaperTrendingTopicList);
          handleScrollNewsTrendingTopicList(data.scrollNewsTrendingTopicList);
          setUpdatedTime(data.updatedAt);
        }
      });
    };

    fetchTrendingKeywordsAlert(); //initial

    const timer = setInterval(() => {
      fetchTrendingKeywordsAlert();
    }, 5000 * 60);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="d-flex flex-column flex-grow-1 gap-3">
      <div className="d-flex gap-3 flex-wrap align-items-center ">
        <div className=" text-white font-s-lg font-w-bold">
          Top Trending Keywords
        </div>
        <div className="d-flex flex-row justify-content-between w-100 flex-wrap">
          <div className="d-flex flex-column gap-1">
            <div className="text-white font-s-md">Newspaper</div>
            <div className=" text-light font-s-md font-w-bold">
              Last Updated At
            </div>
            <div className=" text-light font-s-n font-w-bold">
              {getDateTime_12T2D3M2Y(updatedTime)}
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column">
        <div
          className="d-flex gap-3 flex-column rounded-4 shadow my-2 overflow-auto flex-grow-1"
          style={{
            height: "24rem",
          }}
        >
          <table className="table--keyword">
            <tbody>
              {newspaperTrendingTopicList &&
                newspaperTrendingTopicList?.length &&
                newspaperTrendingTopicList.map((alert) => (
                  <tr>
                    <td className="text-white font-s-md font-w-bold">
                      <div className={`text-white px-3`}>{alert.keyword}</div>
                    </td>
                    <td className="text-white font-s-md font-w-bold">
                      {alert.count}
                    </td>
                    <td className="text-white font-s-md font-w-bolder">
                      <div className="d-flex px-3 justify-content-end">
                        <Link
                          //`/trending-keyword-view/${alert.keyword}&${1}`
                          to={`/news${getQueryString(alert)}#auto-play`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex gap-3 flex-wrap align-items-center ">
        <div className="d-flex flex-row justify-content-between w-100 flex-wrap">
          <div className="d-flex flex-column gap-1">
            <div className="text-white font-s-md">TV</div>
            <div className=" text-light font-s-md font-w-bold">
              Last Updated At
            </div>
            <div className=" text-light font-s-n font-w-bold">
              {getDateTime_12T2D3M2Y(updatedTime)}
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex gap-3 flex-column rounded-4 shadow my-2 overflow-auto flex-grow-1"
        style={{
          height: "24rem",
        }}
      >
        <table className="table--keyword">
          <tbody>
            {scrollNewsTrendingTopicList &&
              scrollNewsTrendingTopicList?.length &&
              scrollNewsTrendingTopicList.map((alert) => (
                <tr>
                  <td className="text-white font-s-md font-w-bold">
                    <div className={`text-white px-3`}>{alert.keyword}</div>
                  </td>
                  <td className="text-white font-s-md font-w-bold">
                    {alert.count}
                  </td>
                  <td className="text-white font-s-md font-w-bolder">
                    <div className="d-flex justify-content-end px-3">
                      <Link
                        to={`/news${getQueryString(alert)}#auto-play`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { TrendingKeywordsAlert };
