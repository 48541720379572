import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  DateTime,
  LoadingComponent,
  News,
  NewsDetails,
  NewsSourceFilter,
  NewsSourceTypes,
} from "./../../components";

import {
  setPageName,
  useNewsSources,
  usePageName,
  useUser,
} from "../../dataService/contexts";
import { getNewspaperContent } from "./newspaperContent.service";

import { dateIsValid, getDateTime_4Y2M2D12T } from "../../const/dateFormat";
import {
  getNewsSourceType,
  newsSourceTypes,
} from "../../const/newsSourceTypes";
import { getBySourceCode } from "../../const/newsSources";

import LoadMoreIcon from "../../assets/loadMore";

const Analysis = () => {
  const location = useLocation();
  const autoPlay = !!(location.hash && location.hash === "#auto-play");

  const [news, setNews] = useState(null);
  const [newsSources, setNewsSources] = useState([]); //source names like Prothom Alo , Ekattor TV ...
  const [newsSourceTypesCustom, setNewsSourceTypes] = useState([
    ...newsSourceTypes.map((type) => ({
      ...type,
      checked: true,
    })),
  ]);
  const [totalNews, setTotalNews] = useState(0);
  const [activeType, setActiveType] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");

  const [tags, setTags] = useState([]);
  const [URLParams, setURLParams] = useSearchParams();

  const [selectedNews, setSelectedNews] = useState(null);

  const [loading, setLoading] = useState(false);

  //params
  const [params, setParams] = useState({
    keywords: URLParams.get("keys"),
    startDate: getDateTime_4Y2M2D12T(URLParams.get("start")),
    endDate: getDateTime_4Y2M2D12T(URLParams.get("end")),
    type: URLParams.get("type")
      ? getNewsSourceType(Number(URLParams.get("type")))
      : {
          id: "0",
          label: "All",
          value: 0,
        },
    name: "",
    nextNewsPaperOffset: 0,
    nextTvOffset: 0,
    size: 20,
  });

  const { dispatch: pageNameDispatch } = usePageName();

  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { sources } = newsSourcesState || {};

  const updateParams = (key, value) => {
    setParams(({ ...prevFilters }) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  /* we do not directly use update filters for date,
  because we may need to modify the date which is provided by Date Componet*/
  const handleStartDate = (date) => updateParams("startDate", date);
  const handleEndDate = (date) => updateParams("endDate", date);

  const handleType = (type) => updateParams("type", type);

  // selected News
  // selected news will handle the news details modal
  const handleClose = () => setSelectedNews(null);
  const handleShow = (news) => setSelectedNews(news);
  // selected News

  // filters
  const toggleFilter = (key, event) => {
    setNewsSources((prev) => {
      return [
        ...prev.map((source) => {
          return source.code === event.target.id
            ? {
                ...source,
                checked: !source.checked,
              }
            : { ...source };
        }),
      ];
    });
  };
  const toggleBulkFilter = (type, isTypeChecked) =>
    setNewsSources((prev) => {
      if (type === "0") {
        return [
          ...prev.map((source) => ({
            ...source,
            checked: !isTypeChecked,
          })),
        ];
      } else {
        return [
          ...prev.map((source) =>
            Number(source.type) === Number(type)
              ? {
                  ...source,
                  checked: !isTypeChecked,
                }
              : { ...source }
          ),
        ];
      }
    });

  const getFilterTypeStatus = (key) => {
    return newsSourceTypesCustom.find((type) => type.id === key);
  };

  const removeAllFilters = () => {
    setNewsSources((prev) => {
      return [
        ...prev.map((type) => ({
          ...type,
          checked: false,
        })),
      ];
    });
  };

  const toggleFilterType = (key, event) => {
    const isTypeChecked = getFilterTypeStatus(key).checked;

    toggleBulkFilter(key, isTypeChecked);

    setNewsSourceTypes((prev) => {
      return [
        ...prev.map((type) => {
          return type.id === event.target.id
            ? {
                ...type,
                checked: !type.checked,
              }
            : { ...type };
        }),
      ];
    });
  };

  const resetNewsSourceType = () => {
    setNewsSourceTypes([
      ...newsSourceTypes.map((type) => ({
        ...type,
        checked: true,
      })),
    ]);
  };

  //actions
  //here we have 3 actions , search , load more , filter and reset
  const search = () => {
    setOnGoingActions("SEARCH");
    fetchNews("SEARCH");
  };

  const loadMore = () => {
    setOnGoingActions("LOADMORE");
    fetchNews("LOADMORE");
  };

  const filter = () => {
    setOnGoingActions("FILTER");
    fetchNews("FILTER");
  };

  const reset = () => {
    setNews([]);
    setTotalNews(0);
  };
  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const {
      _news,
      _newsSource,
      _total,
      _params,
      _nextTvOffset,
      _nextNewsPaperOffset,
    } = data;

    if (actionType === "SEARCH") {
      setNews([..._news]);
      resetNewsSourceType();
      setNewsSources([..._newsSource]);
      setTotalNews(_total);
      setTags(
        _params.keys
          .split(" " && ",")
          .map((item) => `<span class='highlighted-text'>${item}</span>`)
      );

      setActiveType(_params.type);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    } else if (actionType === "LOADMORE") {
      setNews((prevNewsContent) => [...prevNewsContent, ..._news]);
      setTotalNews(_total);
      setTags(
        _params.keys
          .split("," && " ")
          .map((item) => `<span class='highlighted-text'>${item}</span>`)
      );

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    } else if (actionType === "FILTER") {
      setNews([..._news]);
      setTotalNews(_total);
      setTags(
        _params.keys
          .split("," && " ")
          .map((item) => `<span class='highlighted-text'>${item}</span>`)
      );

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    }
  };
  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return {
        nextNewsPaperOffset: params.nextNewsPaperOffset,
        nextTvOffset: params.nextTvOffset,
      };
    } else if (action === "SEARCH") {
      return {
        nextNewsPaperOffset: 0,
        nextTvOffset: 0,
      };
    } else {
      return {
        nextNewsPaperOffset: 0,
        nextTvOffset: 0,
      };
    }
  };

  //
  const fetchNews = (actionType) => {
    setLoading(true);
    if (user)
      getNewspaperContent(
        {
          ...params,
          ...getEffectiveParamsPage(actionType), //Page
        },
        user,
        actionType === "SEARCH" ? [] : newsSources,
        (status, data, _params) => {
          if (status === "success") {
            setURLParams(_params);
            if (data && data.news && data.news.length) {
              actionHandler(actionType, {
                _news: data.news,
                _newsSource: [
                  ...data.newsPaperNames.map((source) => ({
                    ...getBySourceCode(source.code, sources),
                    checked: true,
                  })),
                  ...data.tvNewsNames.map((source) => ({
                    ...getBySourceCode(source.code, sources),
                    checked: true,
                  })),
                ],
                _total: data.total,
                _params: _params,
                _nextNewsPaperOffset: data.nextNewsPaperOffset,
                _nextTvOffset: data.nextTvOffset,
              });
            } else {
              reset();
            }
          } else {
            reset();
          }
          setLoading(false);
        }
      );
  };

  useEffect(() => {
    setPageName(pageNameDispatch, "Search Result");

    if (autoPlay) {
      search();
    }
  }, [user]);

  return (
    <div className="d-flex flex-column gap-2 p-1">
      <div className="d-flex flex-row gap-3 flex-wrap">
        <div style={{ minWidth: "250px" }}>
          <input
            className="form-control rounded-4 bg-black__light text-white p-0 border-0 py-3 px-4 font-s-md font-w-bolder"
            type="text"
            placeholder="Search by keyword"
            value={!!params.keywords ? params.keywords : ""}
            onChange={(e) => updateParams("keywords", e.target.value)}
          />
        </div>

        <div style={{ minWidth: "100px" }}>
          <NewsSourceTypes
            handleCurrentNewsSourceType={handleType}
            currentNewsSourceType={params.type}
          />
        </div>

        <div style={{ minWidth: "100px" }}>
          <DateTime
            placeholder={"From"}
            date={params.startDate}
            handleDateWithTime={handleStartDate}
            hasTime={true}
          />
        </div>

        <div style={{ minWidth: "100px" }}>
          <DateTime
            handleDateWithTime={handleEndDate}
            placeholder="To"
            hasTime={true}
            date={params.endDate}
          />
        </div>
        <button
          type="button"
          className="btn btn-sm btn-primary px-4 py-2 rounded-4"
          onClick={() => search()}
          disabled={
            !dateIsValid(params.startDate) ||
            !dateIsValid(params.endDate) ||
            new Date(params.startDate).getTime() >
              new Date(params.endDate).getTime() ||
            loading
              ? true
              : ""
          }
        >
          <div className="px-3 py-2 font-w-bold font-s-md">
            {loading ? <LoadMoreIcon /> : "Search"}
          </div>
        </button>

        {new Date(params.startDate).getTime() >
          new Date(params.endDate).getTime() &&
        params.startDate !== "" &&
        params.endDate !== "" ? (
          <div className="text-danger font-s-md font-w-bolder">
            Invalid Date range!!! Please check and correct it and search Again.
          </div>
        ) : (
          <></>
        )}
      </div>
      {loading ? (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          <LoadingComponent size={4} />
        </div>
      ) : (
        <div className="d-flex flex-column gap-3">
          {totalNews && (
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-4 flex wrap">
                <div>
                  <span className="me-2 font-w-bold text-white">
                    {totalNews}
                  </span>
                  <span className="text-light font-w-bold">Result Found</span>
                </div>

                <div className="text-white font-w-bold">/</div>

                <div>
                  <span className="me-2 font-w-bold text-light">Showing </span>
                  <span className="font-w-bold text-warning">
                    {news.length}
                  </span>
                </div>
              </div>

              <NewsSourceFilter
                items={newsSources}
                toggleFilter={toggleFilter}
                types={newsSourceTypesCustom}
                toggleFilterType={toggleFilterType}
                reload={filter}
                loadingState={loading}
                removeAllFilters={removeAllFilters}
              />
            </div>
          )}
          {news && (
            <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
              {news.length > 0 &&
                news.map((news) => (
                  <News
                    key={news.id || news.title}
                    news={news}
                    action={handleShow}
                    keywords={
                      news.matchedKeywordList
                        ? [...news.matchedKeywordList.split(",")]
                        : [...tags]
                    }
                    type={activeType}
                    tags={params.keywords}
                  />
                ))}
            </div>
          )}
        </div>
      )}

      {news && news.length && news.length < totalNews ? (
        <div className="d-flex justify-content-center">
          <button
            onClick={() => loadMore()}
            className="btn btn-sm btn-primary px-4 py-2 rounded-4 font-s-n"
            disabled={loading ? "true" : ""}
          >
            <div className="px-3 py-2 font-w-bolder">
              {onGoingAction === "LOADMORE" ? <LoadMoreIcon /> : `Load More`}
            </div>
          </button>
        </div>
      ) : (
        <></>
      )}
      {selectedNews && (
        <NewsDetails
          data={selectedNews}
          keyword={tags}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default Analysis;
