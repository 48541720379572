import { getEnvironment } from "../../../../const/environment";
import apiService from "../../../../dataService/apiService";

export const editNotice = (id, data, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/notices/${id} `;

  apiService.put({ url, data: data }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
