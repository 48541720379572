import React, { useEffect, useState } from "react";
import { usePageName, setPageName, useUser } from "../../dataService/contexts";

import { AddTag, TagTable } from "./components";
import { editTagsPermission } from "../../components/NewsFullView/permission.controller";
import {
  downloadCategoryDoc,
  downloadCategoryExcel,
} from "../Category/category.service";
import moment from "moment";
import {
  downloadTagsDoc,
  downloadTagsExcel,
} from "./components/TagTable/tags.service";
import { CategoryDownloadDropdown } from "../Category/components";

const Tags = () => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const [show, setShow] = useState(false);

  const handleDownload = (doctype) => {
    if (doctype === 1) {
      downloadTagsExcel({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `tags.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    } else {
      downloadTagsDoc({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `tags.docx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { dispatch: pageNameDispatch } = usePageName();

  useEffect(() => {
    setPageName(pageNameDispatch, "Tags");
  }, []);

  return (
    <div className="d-flex flex-column  p-1 gap-3">
      {editTagsPermission(user) && (
        <div className="d-flex mb-3 flex-row justify-content-between ">
          <CategoryDownloadDropdown handleDownload={handleDownload} />
          <div>
            <button
              className="btn btn-md  btn-primary text-white rounded-4 px-5 py-3 font-w-bold font-s-md"
              onClick={handleShow}
            >
              Add New Tag
            </button>
          </div>
        </div>
      )}

      <div className="d-flex flex-column gap-4">
        <TagTable />
        <AddTag open={show} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default Tags;
