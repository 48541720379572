import React from "react";

const PermissionAlert = () => {
  return (
    <div className=" alert alert-warning">
      <h4 class="alert-heading text-center">Permission Denied</h4>
      <hr />
      <div className="text-center">
        The page you're trying to access has restricted access,
        <br />
        Please refer to your system administrator
      </div>
    </div>
  );
};

export default PermissionAlert;
