import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import getCrossIcon from "../../../../../../assets/icons/getCrossIcon";
import { updateTopic } from "./updateTopic.service";

import { useUser } from "../../../../../../dataService/contexts";

const UpdateCaseModal = ({ data, handleClose, show }) => {
  const [topic, setTopic] = useState("");
  const [topicDetails, setTopicDetails] = useState("");
  const [allTopicKeywords, setAllTopicKeywords] = useState([]);
  const [topicKeywords, setTopicKeywords] = useState("");

  const { state: userState } = useUser();
  const { user } = userState || {};

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const newArray = [...allTopicKeywords, topicKeywords];
      setAllTopicKeywords(newArray);
      setTopicKeywords("");
    }
  };
  const handleTopic = (e) => {
    setTopic(e.target.value);
  };
  const handleTopicDetails = (e) => {
    setTopicDetails(e.target.value);
  };
  // const handleNotifications = (e) => {
  //   setNotifications(e.target.value);
  // };

  const removeKeyword = (id) => {
    const newArray = [...allTopicKeywords];
    newArray.splice(id, 1);
    setAllTopicKeywords(newArray);
  };
  const onAddWord = () => {
    if (user)
      updateTopic(
        {
          topic: topic,
          topicDetails: topicDetails,
          allTopicKeywords: allTopicKeywords,
        },
        data.id,
        user,
        (status, data) => {
          if (status === "success") {
            window.location.reload(true);
          }
        }
      );
    handleClose();
  };
  useEffect(() => {
    setTopic(data.name);
    setTopicDetails(data.detail);
    setAllTopicKeywords((prevArray) => [...prevArray, ...data.keywords]);
  }, []);
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        className="newspaper-details"
        size="lg"
        centered
      >
        <ModalHeader closeButton>
          <div
            className="text-white font-w-bold "
            style={{ fontSize: "1.25rem" }}
          >
            Update Topic
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Topic Name</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Topic Name"
                  value={topic}
                  onChange={handleTopic}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Topic Details</div>
              <div style={{ minWidth: "250px" }}>
                <textarea
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  rows="4"
                  placeholder="Enter Topic Details"
                  value={topicDetails}
                  onChange={handleTopicDetails}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Topic Keywords</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Topic Keywords"
                  value={topicKeywords}
                  onChange={(e) => setTopicKeywords(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="d-flex flex-row gap-3">
                {allTopicKeywords.length > 0 &&
                  allTopicKeywords.map((keyword, index) => (
                    <button
                      className="px-3 py-1 border border-light rounded-5 text-white d-flex gap-2"
                      style={{ background: "#242731" }}
                      key={index}
                      onClick={() => removeKeyword(index)}
                    >
                      {keyword} <span>{getCrossIcon()}</span>
                    </button>
                  ))}
              </div>
            </div>
            {/*<div className="d-flex flex-column gap-3">*/}
            {/*  <div className="text-light font-s-md">Notifications</div>*/}
            {/*  <div className="d-flex flex-column gap-3">*/}
            {/*    <div className="form-check d-flex gap-2">*/}
            {/*      <input*/}
            {/*        className="form-check-input p-2 align-self-center"*/}
            {/*        type="checkbox"*/}
            {/*        name="dashboard"*/}
            {/*        id="dashboard"*/}
            {/*        checked*/}
            {/*      />*/}
            {/*      <label*/}
            {/*        className="form-check-label text-white   rounded-pill font-s-md font-w-bold"*/}
            {/*        htmlFor="dashboard"*/}
            {/*      >*/}
            {/*        Dashboard*/}
            {/*      </label>*/}
            {/*    </div>*/}
            {/*    <div className="form-check d-flex gap-2">*/}
            {/*      <input*/}
            {/*        className="form-check-input p-2 "*/}
            {/*        type="checkbox"*/}
            {/*        name="email"*/}
            {/*        id="email"*/}
            {/*      />*/}
            {/*      <label*/}
            {/*        className="form-check-label text-white  rounded-pill font-s-md font-w-bold  align-self-center justify-content-center"*/}
            {/*        htmlFor="email"*/}
            {/*      >*/}
            {/*        Email*/}
            {/*      </label>*/}
            {/*    </div>*/}
            {/*    <div className="form-check d-flex gap-2">*/}
            {/*      <input*/}
            {/*        className="form-check-input p-2 align-self-center"*/}
            {/*        type="checkbox"*/}
            {/*        name="sms"*/}
            {/*        id="sms"*/}
            {/*      />*/}
            {/*      <label*/}
            {/*        className="form-check-label text-white   rounded-pill font-s-md font-w-bold align-self-center"*/}
            {/*        htmlFor="sms"*/}
            {/*      >*/}
            {/*        SMS*/}
            {/*      </label>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-md btn-primary rounded-3 py-2 px-4 font-w-bolder font-s-n "
            onClick={onAddWord}
            disabled={
              topic === "" ||
              topicDetails === "" ||
              allTopicKeywords.length === 0
            }
          >
            Update Topic
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdateCaseModal;
