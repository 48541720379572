import apiService from "../../dataService/apiService";

import { getEnvironment } from "../../const/environment";
import { getDateTime_GMT } from "../../const/dateFormat";

export const getNewspaperNews = (user, params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  const { availableTvChannels } = user || {};
  const permittedSource = params.name.length
    ? params.name.map((item) => item)
    : !params.sourceCategory
    ? []
    : availableTvChannels.map((item) => item.code);

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    {
      keys: params.keys,
      size: 40,
      start: getDateTime_GMT(params.startDate),
      end: getDateTime_GMT(params.endDate),
      type: params.type,
      page: params.page,
      name: permittedSource.length ? permittedSource.join(",") : "",
      isKeywordSearch: params.isKeywordSearch ? 1 : 0,
      scrollNumber: params.scrollNumber,
    }
  );
};
