import React, { useState } from "react";
import Toggle from "react-toggle";

import "./styles.scss";

// checked === true === 1 === green === positive

const Sentiment = ({ handleSentiment, currentSentiment }) => {
  return (
    currentSentiment && (
      <div className="d-flex align-items-center gap-2">
        <div
          className={`font-s-md font-w-bold ${
            currentSentiment !== 1 ? "text-danger" : "text-light"
          }`}
        >
          Negative
        </div>

        <Toggle
          defaultChecked={currentSentiment === 1 ? true : false}
          onChange={(e) => handleSentiment(e.target.checked)}
        />
        <div
          className={`font-s-md font-w-bold ${
            currentSentiment === 1 ? "text-success" : "text-light"
          }`}
        >
          Positive
        </div>
      </div>
    )
  );
};

export default Sentiment;
