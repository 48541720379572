import { actionPermission } from "../../const/pagePemission";

const markingPermission = (user) => {
  return (
    user &&
    user.roles &&
    user.roles.length &&
    user.roles.includes(actionPermission.marking)
  );
};

const editTagsPermission = (user) => {
  return (
    user &&
    user.roles &&
    user.roles.length &&
    user.roles.includes(actionPermission.editTags)
  );
};

const editCategoryPermission = (user) => {
  return (
    user &&
    user.roles &&
    user.roles.length &&
    user.roles.includes(actionPermission.editCategory)
  );
};

export { markingPermission, editCategoryPermission, editTagsPermission };
