import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

export const addBookmark = (data, user, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/bookmarks`;

  const _data = {
    contentId: data.contentId,
    contentType: data.contentType,
  };

  apiService.post({ url, data: _data }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const removeBookmark = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/bookmarks/${id}`;

  apiService.delete(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
