import React, { useState, useEffect } from "react";

import { usePageName, setPageName } from "../../dataService/contexts";

import { CaseListTable, NewCaseModal } from "./components";

const CaseManagement = () => {
  const { dispatch: pageNameDispatch } = usePageName();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    setPageName(pageNameDispatch, "Topic List");
  }, []);

  return (
    <div className="d-flex flex-column gap-2 p-1">
      <div className="d-flex flex-row justify-content-between mb-3">
        <div className="d-flex flex-row">
          <div className="font-s-xlg text-white font-w-bold align-self-center">
            
          </div>
        </div>

        <button
          className="btn btn-md  btn-primary text-white rounded-4 px-4 py-3 font-s-md font-w-bold"
          onClick={handleShow}
        >
          Create New Topic
        </button>
      </div>

      <CaseListTable />

      <NewCaseModal open={show} handleClose={handleClose} />
    </div>
  );
};

export default CaseManagement;
