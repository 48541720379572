import React, { useCallback, useEffect, useState } from "react";
import { setPageName, usePageName, useUser } from "../../dataService/contexts";
import { AddIcons } from "./components";
import IconsTable from "./components/IconsTable";
import {
  downloadNewsSourcesDoc,
  downloadNewsSourcesExcel,
  getIcons,
} from "./icons.service";
import { UpdatedPagination } from "../../components";
import { CategoryDownloadDropdown } from "../Category/components";
import {
  downloadCategoryDoc,
  downloadCategoryExcel,
} from "../Category/category.service";

const Icons = () => {
  const { state: userState } = useUser();
  const { user } = userState || {};
  const [show, setShow] = useState(false);
  const { dispatch: pageNameDispatch } = usePageName();
  const initialValue = {
    icons: [],
    total: 0,
    page: 0,
  };
  const [iconState, setIconState] = useState(initialValue);

  const [params, setParams] = useState({
    size: 40,
    page: 0,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleState = (key, value) => {
    setIconState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleParams = (key, value) => {
    if (key === "page") {
      // wrapperRef.current.scrollIntoView();
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  const handleDelete = (id) => {
    setIconState((prev) => {
      return {
        icons: prev.icons.filter((item) => item.id !== id),
        total: prev.total - 1,
      };
    });
  };

  const handleEdit = (data) => {
    if (user) {
      fetchIcons();
    }
    /*if (data) {
      let targetObjectIndex = -1;

      for (let i = 0; i < iconState.icons.length; i++)
        if (iconState.videos[i].id === data.id) targetObjectIndex = i;


      if (targetObjectIndex > -1) {
        const updatedVideo = {
          ...iconState.icons[targetObjectIndex],
          name: data.name,
          icon: data.icon,
          code: data.code,
          type: data.type,
          url: data.url,
          environment: data.environment,
          id: data.id,
        };


        setIconState((prev) => {
          return {
            ...prev,
            // videos: [
            //   ...prev.videos.filter((item) => item.id !== id),
            //   updatedVideo,
            // ],

            icons: [
              ...prev.icons.slice(0, targetObjectIndex),
              updatedVideo,
              ...prev.icons.slice(targetObjectIndex + 1, prev.icons.length),
            ],
          };
        });
      }
    }*/
  };
  const fetchIcons = useCallback(() => {
    getIcons(params, user, (status, data) => {
      if (status === "success") {
        const { newsSourceMetaData, total, page } = data;

        handleState("icons", newsSourceMetaData);
        handleState("total", total);
        handleState("page", page);
      }
    });
  }, [params]);

  const handleDownload = (doctype) => {
    if (doctype === 1) {
      downloadNewsSourcesExcel({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `icon_list.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    } else {
      downloadNewsSourcesDoc({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `icon_list.docx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    }
  };
  useEffect(() => {
    setPageName(pageNameDispatch, "News Sources");
  }, []);
  useEffect(() => {
    if (user) fetchIcons();
  }, [user, fetchIcons, params]);

  return (
    <div className="d-flex flex-column  p-1 gap-3">
      <div className="d-flex mb-3 flex-row justify-content-between ">
        <CategoryDownloadDropdown handleDownload={handleDownload} />
        <div>
          <button
            className="btn btn-md  btn-primary text-white rounded-4 px-5 py-3 font-w-bold font-s-md"
            onClick={handleShow}
          >
            Add News Source
          </button>
        </div>
      </div>

      <div className="d-flex flex-column gap-4">
        {iconState && (
          <IconsTable
            data={iconState}
            handleRemove={handleDelete}
            handleEdit={handleEdit}
          />
        )}
        {iconState.total >= 40 ? (
          <UpdatedPagination
            itemsPerPage={40}
            total={iconState.total}
            action={handleParams}
          />
        ) : (
          <></>
        )}
        <AddIcons open={show} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default Icons;
