import React, { useEffect, useState } from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { getAuth, useAuth, getOtp } from "../../dataService/contexts";
import { getInvalidOtp } from "./otp.service";

const hasUserCodePermission = (userCodePermission, token) => {
  return userCodePermission && token.group && token.group
    ? !!token.group.includes(userCodePermission)
    : false;
};
const ConfirmationAlert = ({ show, handleShow, handleLogin, userId }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Initialize with 6 empty strings
  const [tryAgain, setTryAgain] = useState(false);
  const [time, setTime] = useState(120); // Initialize time to 60 seconds
  const [isRunning, setIsRunning] = useState(false);
  const { dispatch: authDispatch } = useAuth();
  let navigate = useNavigate();
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically move to the next input field on input
      if (value !== "" && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };
  console.log(otp.length);
  const handleSubmit = () => {
    setTryAgain(false);
    getOtp(userId, otp.join(""), authDispatch, (status, data) => {
      if (status === "success") {
        setOtp(["", "", "", "", "", ""]);
        if (data) {
          setTryAgain(false);
          if (hasUserCodePermission("MANAGER", userId)) {
            navigate("/minister_dashboard");
          } else {
            navigate(`/minister_dashboard`);
          }
        } else {
          setTryAgain(true);
        }
      } else {
      }
    });
  };
  const startTimer = () => {
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setIsRunning((prevState) => !prevState);
    setTime(120);
  };

  useEffect(() => {
    let timer;
    startTimer();
    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setTryAgain(false);
      setIsRunning(false);
      clearInterval(timer);
      getInvalidOtp(userId, (status, data) => {
        if (status === "success") {
          console.log("yahoo");
        } else {
        }
      });
    }

    return () => {
      clearInterval(timer);
    };
  }, [show, time, isRunning]);

  useEffect(() => {
    setOtp(["", "", "", "", "", ""]);
    setTryAgain(false);
    setTime(120);
  }, [show]);
  return (
    <div>
      <AlertDialog.Root open={show}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="AlertDialogOverlay" />
          <AlertDialog.Content className="AlertDialogContent">
            <div className="d-flex flex-column gap-5">
              <AlertDialog.Title className="AlertDialogTitle">
                <div className="text-center"> Please enter your OTP</div>
              </AlertDialog.Title>
              <div className="d-flex flex-row gap-2 justify-content-center align-content-center">
                {otp.map((digit, index) => (
                  <input
                    className="text-center font-s-xlg "
                    style={{
                      maxWidth: "40px",
                      borderRadius: "8px",
                      height: "40px",
                    }}
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                  />
                ))}
              </div>
              <div className="d-flex flex-row gap-4 justify-content-center">
                {time === 0 ? (
                  <button
                    className="btn bg-transparent border border-secondary border-1 p-2 btn-lg text-secondary font-w-bolder font-s-lg "
                    onClick={() => {
                      handleLogin();
                      resetTimer();
                      setTryAgain(false);
                    }}
                  >
                    Resend OTP ?
                  </button>
                ) : (
                  <></>
                )}
                <div className="d-flex flex-row gap-3">
                  <div className=" py-2   text-primary font-s-lg font-w-bolder text-center">
                    {time}
                  </div>
                  <div className=" py-2  text-primary font-s-lg font-w-bolder text-center">
                    second
                  </div>
                </div>
              </div>

              {tryAgain ? (
                <div className="text-danger font-s-lg font-w-bold text-center">
                  OTP didn't match please try again
                </div>
              ) : (
                <></>
              )}

              <AlertDialog.Action asChild>
                <div className="d-flex flex-row justify-content-center gap-4">
                  <button
                    className="btn btn-success btn-lg text-white font-w-bolder"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={
                      otp.some((digit) => digit === "") || otp.length !== 6
                    }
                  >
                    submit
                  </button>
                  <button
                    className="btn btn-lg btn-danger text-white font-w-bolder"
                    onClick={() => {
                      handleShow();
                      resetTimer();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </AlertDialog.Action>
              <AlertDialog.Cancel asChild></AlertDialog.Cancel>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};

export default ConfirmationAlert;
