import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";

import { TagWiseTable } from "./components";
import { DownloadReport } from "./../../components";

import { reportPreview } from "./reportPreview.service";
import { setPageName, usePageName } from "../../dataService/contexts";

const ReportPreview = () => {
  const { dispatch: pageNameDispatch } = usePageName();
  const [URLParams, setURLParams] = useSearchParams();

  const queryDate = URLParams.get("date");
  const date = queryDate ? new Date(queryDate) : new Date();

  const [report, setReport] = useState([]);
  const [startDate, setStartDate] = useState(date);

  const fetchReportPreview = () => {
    reportPreview(startDate, (status, data) => {
      if (status === "success") {
        if (data && data.reports) setReport(data.reports);
      }
    });
  };

  useEffect(() => {
    setPageName(pageNameDispatch, "Report Preview");
  }, []);

  useEffect(() => {
    fetchReportPreview();
  }, [startDate]);

  return (
    <div className="d-flex flex-column  p-4 gap-4">
      <div className="d-flex flex-column gap-4">
        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center gap-4 flex-wrap">
            <div className="font-s-xlg text-white font-w-bold">
              Daily Report - manager
            </div>

            <div>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  const _date = moment(date);
                  if (_date.isValid()) setStartDate(date);
                }}
              />
            </div>
          </div>
          <div>
            <DownloadReport date={startDate} />
          </div>
        </div>
      </div>

      {report.length ? (
        <div className="d-flex flex-column gap-4">
          {report.map((item) => {
            return (
              <div className="d-flex flex-column gap-4">
                <div className="text-white font-s-nm font-w-bolder">
                  {item.tagWord}
                </div>
                <div>
                  <TagWiseTable report={item.report} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReportPreview;
