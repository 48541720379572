import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";

import { Sentiment } from "./../../components";

import { loadTags } from "./tags.service";

import "./styles.scss";

const Tags = ({
  selectedTag,
  handleSelectedTag,
  warning = true,
  isClearable = false,
}) => {
  return (
    <>
      <AsyncPaginate
        value={selectedTag}
        isClearable={isClearable}
        loadOptions={loadTags}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => `${option.word}`}
        onChange={handleSelectedTag}
        placeholder="Select Tags"
        additional={{
          page: 0,
        }}
        className="tags-select-container"
        classNamePrefix="tags-select"
      />
      {!selectedTag && warning && (
        <div className="text-danger font-s-nm py-4"> Please Select a Tag</div>
      )}
    </>
  );
};

export default Tags;
