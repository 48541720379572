import React, { useState } from "react";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

import { updateKeyword } from "./dictionaryTable.service";
import { getDateTime_12T2D3M2Y } from "../../../../const/dateFormat";

import "./style.scss";

const getTimeRange = () => {
  const currentTimeDate = moment(new Date()).toISOString();
  const prevTimeDate = moment(
    moment(currentTimeDate).startOf("day")
  ).toISOString();

  return {
    startDate: prevTimeDate,
    endDate: currentTimeDate,
  };
};
const getQueryString = (data) => {
  const dateRange = getTimeRange();
  let queryString = `?keys=${data.value}&start=${dateRange.startDate}&end=${dateRange.endDate}`;
  return queryString;
};

const DictionaryTable = ({ dictionaryData }) => {
  const [isToastActive, setIsToastActive] = useState(false);
  const activeToast = () =>
    toast.success("Successfully in active", {
      containerId: "active",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClose: () => {
        setIsToastActive(false);
        window.location.reload(true);
      },
    });
  const inActiveToast = () =>
    toast.info("Successfully in active ", {
      containerId: "inActive",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      onClose: () => {
        setIsToastActive(false);
        window.location.reload(true);
      },
    });

  const handleActive = (
    id,
    value,
    severity,
    language,
    createBy,
    active,
    root
  ) => {
    updateKeyword(
      {
        id: id,
        value: value,
        severity: severity,
        language: language,
        createBy: createBy,
        active: active,
        root: root,
      },
      (status, data) => {
        if (active === true) {
          if (status === "success") {
            setIsToastActive(true);
            activeToast();
          }
        } else {
          if (status === "success") {
            setIsToastActive(true);
            inActiveToast();
          }
        }
      }
    );
  };

  return (
    <div>
      {dictionaryData.keywords.length > 0 ? (
        <table className="w-100 table--dictionary ">
          <thead>
            <tr
              className="text-light font-s-n font-w-bolder text-uppercase"
              style={{ letterSpacing: "1px" }}
            >
              <th>SL</th>
              <th>WORD</th>
              <th>
                <div className="d-flex justify-content-center">SEVERITY</div>
              </th>
              <th>
                <div className="d-flex justify-content-center">Updated At</div>
              </th>
              <th>
                <div className="d-flex justify-content-end">ACTIONS</div>
              </th>
            </tr>
          </thead>

          <tbody>
            {dictionaryData.keywords.map((alert, index) => (
              <tr key={index}>
                <td className="my-2">
                  <div className="text-white font-w-bold text-nowrap">
                    #{" "}
                    {dictionaryData.page === 0
                      ? index + 1
                      : index + 1 + 40 * dictionaryData.page}
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {alert.value}
                </td>
                <td
                  className={`${alert.severity === 1 && "text-success"} 
                ${alert.severity === 2 && "text-warning"} 
                ${alert.severity === 3 && "text-danger"} 
                font-s-n font-w-bolder`}
                >
                  <div className="d-flex justify-content-center">
                    {alert.severity === 1 && "Positive"}
                    {alert.severity === 2 && "Neutral"}
                    {alert.severity === 3 && "Critical"}
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  <div className="d-flex justify-content-center">
                    {getDateTime_12T2D3M2Y(alert.updatedAt)}
                  </div>
                </td>
                <td className="text-white font-s-n font-w-bolder  ">
                  <div className="d-flex gap-3 flex-row justify-content-end">
                    <div>
                      <Link
                        className="px-4 bg-primary btn btn-md text-white font-w-bolder font-s-md"
                        to={`/news${getQueryString(alert)}#auto-play`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </Link>
                    </div>
                    {alert.active === true ? (
                      <div>
                        <button
                          disabled={isToastActive && true}
                          className="px-4 bg-danger btn btn-md text-white font-w-bolder font-s-md"
                          onClick={() =>
                            handleActive(
                              alert.id,
                              alert.value,
                              alert.severity,
                              alert.language,
                              alert.createdBy,
                              false,
                              alert.root
                            )
                          }
                        >
                          Inactive
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          disabled={isToastActive && true}
                          className="Link bg-transparent btn btn-md text-primary p-0 font-w-bold"
                          onClick={() =>
                            handleActive(
                              alert.id,
                              alert.value,
                              alert.severity,
                              alert.language,
                              alert.createdBy,
                              true,
                              alert.root
                            )
                          }
                        >
                          Active
                        </button>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <></>
      )}
      ;
      <div>
        <ToastContainer
          enableMultiContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          containerId={"active"}
        />
        <ToastContainer
          enableMultiContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          containerId={"inActive"}
        />
      </div>
    </div>
  );
};

export default DictionaryTable;
