import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import {
  setPageName,
  useCategory,
  usePageName,
  useUser,
} from "../../dataService/contexts";

import { ConfirmationAlert, UpdatedPagination } from "../../components";
import { CategoryOptions, DateRange, Sentiment } from "./components";
import NewspaperReportLoading from "./NewspaperReportLoading";

import DeleteIcon from "../../assets/deleteIcon";
import ViewIcon from "../../assets/viewIcon";

import {
  downloadReport,
  getNewspaperReport,
  removeNewspaperReport,
} from "./newspaperReport.service";
import NewspaperSourceDropdown from "./components/NewspaperSourceDropdown";

const handleDownload = (date, handleDownloadState, reportIds) => {
  handleDownloadState(true);
  downloadReport({ ...date }, reportIds.toString(), (status, data) => {
    if (status === "success") {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${moment(date.startDate).format("DD-MM-YYYY")}_to_${moment(
          date.endDate
        ).format("DD-MM-YYYY")}_newspaper_report.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    handleDownloadState(false);
  });
};

const NewspaperReport = () => {
  const currentDateAndTime = moment();
  const location = useLocation();
  const autoPlay = !!(location.hash && location.hash === "#auto-play");

  const [URLParams, setURLParams] = useSearchParams();
  const { state: categoryState } = useCategory();
  const { categories } = categoryState;
  const [selectedReportId, setSelectedReportId] = useState(0);

  const [showAlert, setShowAlert] = useState(false);

  const [selectedReport, setSelectedReport] = useState([]);

  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const handleDownloadState = (state) => {
    setDownloading(state);
  };

  const wrapperRef = useRef(null);

  const initialValue = {
    reports: [],
    total: 0,
    page: 0,
  };

  const [state, setState] = useState({ ...initialValue });
  const handleState = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const { dispatch: pageNameDispatch } = usePageName();
  const { state: userState } = useUser();
  const { user } = userState || {};

  const initialParams = autoPlay
    ? {
        startDate: moment(currentDateAndTime)
          .startOf("day")
          .utc("asia/dhaka")
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(currentDateAndTime)
          .utc("asia/dhaka")
          .format("YYYY-MM-DD HH:mm:ss"),
        size: 40,
        page: 0,
        category: { categoryName: URLParams.get("categoryName") },
        sentiment: URLParams.get("sentiment"),
        sourceName: URLParams.get("sourceName"),
      }
    : {
        startDate: moment(currentDateAndTime)
          .startOf("day")
          .utc("asia/dhaka")
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(currentDateAndTime)
          .utc("asia/dhaka")
          .format("YYYY-MM-DD HH:mm:ss"),
        size: 40,
        page: 0,
        category: "",
        sentiment: "",
        sourceName: "",
      };
  //params
  const [params, setParams] = useState(initialParams);

  const hasId = new Set([...state.reports.map((item) => item.id)]);
  const handleOpenAlert = () => setShowAlert(true);

  const handleCloseAlert = () => setShowAlert(false);

  const handleBulkChange = () => {
    if (state.reports.every((item) => selectedReport.includes(item.id))) {
      setSelectedReport((prev) => [
        ...selectedReport.filter((name) => {
          return !hasId.has(name);
        }),
      ]);
    } else {
      setSelectedReport((prev) => [
        ...prev.filter((id) => {
          return !hasId.has(id);
        }),
        ...hasId,
      ]);
    }
  };
  const handleToggle = (report) => {
    setSelectedReport((prev) => {
      const index = prev.findIndex((id) => id === report.id);
      if (index !== -1) {
        // ID exists, remove it from the array
        const updatedArray = [...prev];
        updatedArray.splice(index, 1);
        return updatedArray;
      } else {
        // ID doesn't exist, add it to the array
        return [...prev, report.id];
      }
    });
  };

  const handleParams = (key, value) => {
    if (key === "page") {
      wrapperRef.current.scrollIntoView();
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        [key]: value,
        page: 0,
      }));
    }
  };

  const handleDateRange = (dateRange) => {
    setSelectedReport([]);
    setParams((prev) => {
      return {
        ...prev,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        page: 0,
      };
    });
  };

  const handleDelete = (id) => {
    removeNewspaperReport(id, (status, data) => {
      if (status === "success") {
        if (data && data.message) {
          setState((prev) => {
            return {
              reports: prev.reports.filter((item) => item.id !== id),
              total: prev.total - 1,
            };
          });
        }
      }
    });
  };

  const fetchReports = useCallback(() => {
    setLoading(true);

    getNewspaperReport(
      {
        ...params,
      },
      (status, data) => {
        if (status === "success") {
          setParams(params);
          const { reports, total, page } = data;
          handleState("reports", reports);
          handleState("total", total);
          handleState("page", page);
        }
        setLoading(false);
      }
    );
  }, [params]);

  useEffect(() => {
    setSelectedReport([]);
  }, [params.category]);
  //this use effect function is used to make the api call when a user gets to this route
  useEffect(() => {
    if (user) {
      fetchReports();
    }
  }, [user, fetchReports, params]);

  useEffect(() => {
    setPageName(pageNameDispatch, "Newspaper Report");
  }, []);

  return (
    <>
      <div
        className="d-flex flex-column gap-4 p-1 justify-content-between "
        ref={wrapperRef}
      >
        <div className="d-flex  gap-4 flex-column">
          <div className="d-flex gap-4 flex-row flex-wrap justify-content-between align-items-center w-100">
            <div>
              <DateRange
                onChange={handleDateRange}
                loading={loading}
                params={params}
              />
            </div>

            <div className="d-flex justify-content-center">
              <button
                className="btn btn-lg btn-primary font-w-bolder px-4 py-2"
                onClick={() => {
                  handleDownload(params, handleDownloadState, selectedReport);
                }}
                disabled={downloading ? true : false}
              >
                Download
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div></div>
          {state ? (
            <div className="d-flex flex-row gap-3">
              <CategoryOptions
                handleCategory={handleParams}
                seletedCategory={params.category}
              />
              <div style={{ minWidth: "100px" }}>
                <NewspaperSourceDropdown
                  currentChannel={params.sourceName}
                  handleChannel={handleParams}
                />
              </div>
              <div style={{ minWidth: "100px" }}>
                <Sentiment
                  currentSentiment={params.sentiment}
                  handleSentiment={handleParams}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {state.reports && state.reports.length && (
          <div style={{ overflow: "auto" }}>
            <table className="table--report w-100">
              <thead>
                <tr className="text-light font-s-n font-w-bolder text-uppercase bg-black__light shadow">
                  <th className="py-2  ps-4">
                    <div className="d-flex gap-2 align-items-center">
                      <input
                        className="m-0 form-check-input"
                        type="checkbox"
                        name="alertedNewsOnly"
                        id="allReport"
                        checked={
                          state.reports.every((item) =>
                            selectedReport.includes(item.id)
                          ) && true
                        }
                        onChange={() => handleBulkChange()}
                      />
                    </div>
                  </th>
                  <th className=" text-nowrap px-4 py-2">SL</th>
                  <th className=" text-nowrap px-4 py-2">User Name</th>
                  <th className=" text-nowrap px-4 py-2">Category</th>
                  <th className=" text-nowrap px-4 py-2">Newspaper Name</th>
                  <th className=" text-nowrap px-4 py-2">Newspaper Title</th>
                  <th className=" text-nowrap px-4 py-2">Sentiment</th>
                  <th className=" text-nowrap px-4 py-2">Tags</th>
                  <th className=" text-nowrap px-4 py-2">News Time At</th>
                  <th className=" text-nowrap px-4 py-2">Report Time At</th>
                  <th className=" text-nowrap px-4 py-2 float-end">Actions</th>
                </tr>
              </thead>

              <tbody>
                {state.reports.length ? (
                  state.reports.map((item, index) => (
                    <tr className="bg-black__light shadow my-2">
                      <td className="py-2  ps-4">
                        <div
                          className="d-flex gap-2 align-items-center h-100 w-100
                      "
                        >
                          <input
                            className="m-0 form-check-input"
                            type="checkbox"
                            name="alertedNewsOnly"
                            id="selectedReport"
                            checked={
                              selectedReport.find((obj) => obj === item.id) &&
                              true
                            }
                            onChange={() => handleToggle(item)}
                          />
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-md font-w-bold text-nowrap">
                          {state.page === 0 ? index + 1 : index + 1 + 40}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          {item.userName ? item.userName : "..."}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          {item.categoryTypes.length &&
                            item.categoryTypes[0].categoryName}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-nowrap text-light p-4 font-s-n font-w-bolder">
                          {item.sourceName}
                        </div>
                      </td>

                      <td className="my-2">
                        <div className="p-4 font-s-n font-w-bold text-white">
                          {item.title}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="p-4 font-s-n font-w-bold text-white">
                          {item.sentiment === 1 ? "Positive" : "Negative"}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="p-4 font-s-n font-w-bold text-white">
                          {item.tags.map((tag, index) => (
                            <div className="text-white  font-s-n font-w-bold text-nowrap">
                              {tag.word}
                              {index !== item.tags.length - 1 && ","}
                              {index === item.tags.length - 1 && "."}
                              &nbsp;
                            </div>
                          ))}
                        </div>
                      </td>

                      <td className="my-2">
                        <div className="text-nowrap text-light p-4 font-s-n font-w-bolder">
                          {moment(item.newsTimeAt).format(
                            "DD-MM-YYYY, hh:mm A"
                          )}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-nowrap text-light p-4 font-s-n font-w-bolder">
                          {moment(item.createdAt).format("DD-MM-YYYY, hh:mm A")}
                        </div>
                      </td>
                      <td className="my-2 pe-4">
                        <div className=" d-flex flex-row gap-4 justify-content-end">
                          <Link
                            className="btn p-0  "
                            to={`/news-full-view/${item.contentId}&${item.contentType}&`}
                          >
                            <ViewIcon />
                          </Link>
                          {item.userId && item.userId === user.userId ? (
                            <button
                              className="btn btn-sm p-0  "
                              onClick={() => {
                                handleOpenAlert();
                                setSelectedReportId(item.id);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
                {loading && <NewspaperReportLoading />}
              </tbody>
            </table>
          </div>
        )}
        <ConfirmationAlert
          show={showAlert}
          handleConfirm={handleDelete}
          id={selectedReportId}
          handleShow={handleCloseAlert}
          contentType={"report"}
        />
      </div>

      <div>
        {state.total >= 40 ? (
          // <Pagination
          //   page={params.page}
          //   total={state.total}
          //   action={handleParams}
          // />
          <UpdatedPagination
            itemsPerPage={40}
            total={state.total}
            action={handleParams}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default NewspaperReport;
