// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlighted-text {
  color: white;
  font-weight: bold;
  font-style: italic;
}
.highlighted-neutral {
  background-color: #FF9F38 !important;
  color: white;
  font-weight: bold;
  padding: 2px 4px;
}
.highlighted-positive {
  background-color: #7AA544 !important;
  color: white;
  font-weight: bold;
  padding: 2px 4px;
}
.highlighted-critical {
  background-color: #FF4438 !important;
  color: white;
  font-weight: bold;
  padding: 2px 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsCard/styles.scss"],"names":[],"mappings":"AAmBI;EACI,YAAA;EACA,iBAAA;EACA,kBAAA;AAlBR;AAqBI;EACI,oCAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AAnBR;AAsBI;EACI,oCAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AApBR;AAuBI;EACI,oCAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AArBR","sourcesContent":[".news:not(:first-child) {\n    // box-shadow: inset 0px 1px 0px rgba(228, 288, 288, 0.1) !important;\n}\n\n.news{\n    // &:hover{\n    //     background-color: rgba(228, 228, 228, 0.1) !important;\n    //     border-radius: 1rem;\n    //     box-shadow: none !important;\n    //     cursor: unset !important;\n\n    //     & + .news {\n    //         box-shadow: none !important;\n    //     }\n    // }\n}\n\n\n.highlighted{\n    &-text{\n        color: white; \n        font-weight: bold;\n        font-style: italic;\n    }\n\n    &-neutral {\n        background-color: #FF9F38 !important;\n        color: white;\n        font-weight: bold;\n        padding: 2px 4px;\n    }\n\n    &-positive {\n        background-color: #7AA544 !important;\n        color: white;\n        font-weight: bold;\n        padding: 2px 4px;\n    }\n\n    &-critical {\n        background-color: #FF4438 !important;\n        color: white;\n        font-weight: bold;\n        padding: 2px 4px;\n    }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
