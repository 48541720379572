import apiService from "../../dataService/apiService";

import { getEnvironment } from "../../const/environment";
import { getDateTime_GMT } from "../../const/dateFormat";
import moment from "moment";

export const getNewspaperNews = (user, params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/mi-query-builder-service/api/${env.version}/${env.type}/news`;

  const { availableNewsPapers } = user || {};
  const permittedSource = params.name.length
    ? params.name.map((item) => item)
    : !params.sourceCategory
    ? []
    : availableNewsPapers.map((item) => item.code);

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    {
      keys: params.keys,
      size: 40,
      start: getDateTime_GMT(params.startDate),
      end: getDateTime_GMT(params.endDate),
      type: params.type,
      page: params.page,
      name: permittedSource.length ? permittedSource.join(",") : "",
      isKeywordSearch: params.isKeywordSearch ? 1 : 0,
    }
  );
};

export const downloadNewspaperNews = (user, total, params, done = () => {}) => {
  const { availableNewsPapers, availableTvChannels } = user || {};
  const permittedSource =
    Number(params.type) === 1
      ? [...availableNewsPapers]
      : [...availableTvChannels];

  const _name = params.name?.length
    ? params.name.join(",")
    : permittedSource?.length
    ? permittedSource.map((an) => an.code).join(",")
    : "";

  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/case-management-service/api/${env.version}/${
    env.type
  }/search-report/download?keys=${params.keys}&start=${moment(
    params.startDate
  ).format("YYYY-MM-DDTHH:mm:ss")}&end=${moment(params.endDate).format(
    "YYYY-MM-DDTHH:mm:ss"
  )}&size=${total}&type=${
    params.type
  }&nextNewsPaperOffset=0&nextTvOffset=0&name=${_name}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};
