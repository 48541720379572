// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keyword {
  white-space: nowrap;
}
.keyword .highlighted-text {
  background-color: rgb(145, 145, 145);
  padding: 0.25rem 0.5rem;
  border-radius: 4rem;
}
.keyword .highlighted-neutral {
  background-color: #FF9F38 !important;
  padding: 0.25rem 0.5rem;
  border-radius: 4rem;
}
.keyword .highlighted-positive {
  background-color: #7AA544 !important;
  padding: 0.25rem 0.5rem;
  border-radius: 4rem;
}
.keyword .highlighted-critical {
  background-color: #FF4438 !important;
  padding: 0.25rem 0.5rem;
  border-radius: 4rem;
}`, "",{"version":3,"sources":["webpack://./src/components/NewsKeywords/styles.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AAEQ;EACI,oCAAA;EACA,uBAAA;EACA,mBAAA;AAAZ;AAGQ;EACI,oCAAA;EACA,uBAAA;EACA,mBAAA;AADZ;AAIQ;EACI,oCAAA;EACA,uBAAA;EACA,mBAAA;AAFZ;AAKQ;EACI,oCAAA;EACA,uBAAA;EACA,mBAAA;AAHZ","sourcesContent":[".keyword{\n    white-space: nowrap;\n    \n    & .highlighted{\n        &-text{\n            background-color: rgb(145, 145, 145);\n            padding: .25rem .5rem;\n            border-radius: 4rem;\n        }\n\n        &-neutral {\n            background-color: #FF9F38 !important;\n            padding: .25rem .5rem;\n            border-radius: 4rem;\n        }\n\n        &-positive {\n            background-color: #7AA544 !important;\n            padding: .25rem .5rem;\n            border-radius: 4rem;\n        }\n\n        &-critical {\n            background-color: #FF4438 !important;\n            padding: .25rem .5rem;\n            border-radius: 4rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
