// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dictionary-add .modal-dialog .modal-content {
  background-color: #242731;
}
.dictionary-add .modal-dialog .modal-content .modal-header {
  padding: 2rem 2rem 0 2rem;
  border: 0 !important;
}
.dictionary-add .modal-dialog .modal-content .modal-header .btn-close {
  background-color: #1B1D21 !important;
  height: 3rem !important;
  width: 3rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12rem;
}
.dictionary-add .modal-dialog .modal-content .modal-body {
  padding: 2rem;
}
.dictionary-add .modal-dialog .modal-content .modal-body .inner {
  border: 2px solid #355DFF;
  border-radius: 8px;
  padding: 2rem;
}
.dictionary-add .modal-dialog .modal-content .modal-footer {
  padding: 0 2rem 2rem 2rem;
  border: 0 !important;
  justify-content: flex-start !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dictionary/components/AddNewWord/style.scss"],"names":[],"mappings":"AAEI;EACE,yBAAA;AADN;AAKM;EACE,yBAAA;EACA,oBAAA;AAHR;AAKQ;EACE,oCAAA;EACA,uBAAA;EACA,sBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,oBAAA;AALV;AASM;EACE,aAAA;AAPR;AAQQ;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;AANV;AAUM;EACE,yBAAA;EACA,oBAAA;EACA,sCAAA;AARR","sourcesContent":[".dictionary-add{\n  .modal-dialog{\n    .modal-content{\n      background-color: #242731;\n      //width: 0% !important;\n      // box-shadow: 0 0 10px 10px rgb(96, 96, 96);\n\n      .modal-header{\n        padding: 2rem 2rem 0 2rem;\n        border : 0 !important;\n\n        .btn-close{\n          background-color: #1B1D21 !important;\n          height: 3rem !important;\n          width: 3rem !important;\n\n          display: flex;\n          align-items: center;\n          justify-content: center;\n\n          border-radius: 12rem;\n        }\n      }\n\n      .modal-body{\n        padding: 2rem;\n        .inner{\n          border: 2px solid #355DFF;\n          border-radius: 8px;\n          padding: 2rem;\n        }\n      }\n\n      .modal-footer{\n        padding: 0 2rem 2rem 2rem;\n        border : 0 !important;\n        justify-content: flex-start !important;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
