import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";
import moment from "moment";

export const addMarkedText = (data, user, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/create`;

  const _data = {
    contentType: data.contentType,
    contentId: data.contentId,
    highlightedContent: data.text,
    highlightedStartIndex: data.start,
    highlightedEndIndex: data.end,
    tags: data.tag,
    comment: "",
    sentiment: data.sentiment,
    categoryTypes: data.category,
    sourceName: data.newsSources,
    newsTimeAt: moment(data.news.newsTimeAt).format("YYYY-MM-DD HH:mm:ss"),
  };

  apiService.post({ url, data: _data }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const removedMarkedText = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/delete/${id}`;

  apiService.delete(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
