import React, { useState } from "react";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment/moment";

const TimeComponent = ({ handleDateWithTime, placeholder, hasTime, date }) => {
  const defautlTime = moment();
  const initialValue = moment(defautlTime).startOf("day").toISOString();
  const [startTime, setStartTime] = useState("");
  const [shouldCloseCalendar, setShouldCloseCalendar] = useState(false);

  //Handle time change
  const onChangeTime = (date) => {
    setStartTime(date);
  };

  //This function will save data into parent state
  const onSave = () => {
    handleDateWithTime(`${startTime === "" ? "00:00:00" : startTime}`);
    setShouldCloseCalendar(true);
  };

  //Custom Header component
  const Header = () => {
    return (
      <div className="text-white text-start font-w-bold font-s-md">
        Select Time
      </div>
    );
  };

  //Custom datepicker close button
  const CloseButton = () => {
    return (
      <button
        className="btn btn-sm text-white w-100 p-0 bg-primary rounded-4 py-2"
        onClick={() => {
          onSave();
        }}
      >
        <div className="py-1">Save</div>
      </button>
    );
  };

  //Custom input box for date value
  const CustomInput = ({ openCalendar, handleValueChange }) => {
    return (
      <input
        className="form-control rounded-4 bg-black__light text-white p-0 border-0 py-3 px-4 font-s-md font-w-bolder "
        onFocus={openCalendar}
        value={date && `${date}`}
        onChange={handleValueChange}
        placeholder={placeholder}
      />
    );
  };

  return (
    <>
      <DatePicker
        render={<CustomInput />}
        format="HH:mm:ss"
        onChange={onChangeTime}
        className="date-picker w-0 "
        arrowClassName="date-picker__arrow"
        disableDayPicker
        // onOpen={() => setShouldCloseCalendar(false)}
        // onClose={() => shouldCloseCalendar}
        value={initialValue}
        plugins={[
          <Header />,
          <TimePicker
            className="rounded-4 font-s-md bg-white"
            position="bottom"
          />,
          <CloseButton />,
        ]}
      />
    </>
  );
};

export default TimeComponent;
