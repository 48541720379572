import React from "react";

const BookmarkOff = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7C3 4.19108 3 2.78661 3.67412 1.77772C3.96596 1.34096 4.34096 0.965956 4.77772 0.674122C5.78661 0 7.19108 0 10 0C12.8089 0 14.2134 0 15.2223 0.674122C15.659 0.965956 16.034 1.34096 16.3259 1.77772C17 2.78661 17 4.19108 17 7V16.1136C17 17.8158 17 18.6668 16.4576 18.946C15.9152 19.2251 15.2226 18.7304 13.8375 17.7411L11.1625 15.8303C10.6011 15.4294 10.3205 15.2289 10 15.2289C9.67953 15.2289 9.39886 15.4294 8.83752 15.8303L6.16248 17.7411C4.77738 18.7304 4.08484 19.2251 3.54242 18.946C3 18.6668 3 17.8158 3 16.1136V7Z"
        fill="#355dff"
      />
    </svg>
  );
};

export default BookmarkOff;
