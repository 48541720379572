import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

export const removeIcon = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data/${id}`;
  apiService.delete(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};

export const updateIcon = (icon, state, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data/upload-logo `;
  const url2 = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data`;
  const formData = new FormData();
  formData.append("file", icon);

  if (icon === null) {
    apiService.post(
      {
        data: {
          id: state.id,
          name: state.name,
          type: Number(state.iconType),
          code: state.code,
          icon: state.icon,
          url: state.channelUrl,
          environment: "prod",
        },
        url: url2,
      },
      (status, data) => {
        if (status > 199 && status < 300) {
          done("success", data);
        } else {
          done("danger", data);
        }
      }
    );
  } else {
    apiService.post(
      {
        data: formData,
        url,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      (status, data) => {
        if (status > 199 && status < 300) {
          done("success", data);
          apiService.post(
            {
              data: {
                id: state.id,
                name: state.name,
                type: Number(state.iconType),
                code: state.code,
                icon: data.url,
                url: state.channelUrl,
                environment: "prod",
              },
              url: url2,
            },
            (status, data) => {
              if (status > 199 && status < 300) {
                done("success", data);
              } else {
                done("danger", data);
              }
            }
          );
        } else {
          done("danger", data);
        }
      }
    );
  }
};

export const addNewIcon = (data, state, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data/upload-logo `;
  const url2 = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data`;
  const formData = new FormData();
  formData.append("file", data);

  apiService.post(
    {
      data: formData,
      url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
        apiService.post(
          {
            data: {
              name: state.name,
              type: Number(state.iconType),
              code: state.code,
              icon: data.url,
              url: state.channelUrl,
              environment: "prod",
            },
            url: url2,
          },
          (status, data) => {
            if (status > 199 && status < 300) {
              done("success", data);
            } else {
              done("danger", data);
            }
          }
        );
      } else {
        done("danger", data);
      }
    }
  );
};

export const getIcons = (params, user, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data?`;
  const _params = {
    page: params.page,
    size: params.size,
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("fail", data);
      }
    },
    {},
    { ..._params }
  );
};

export const downloadNewsSourcesExcel = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const downloadNewsSourcesDoc = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/news-source-meta-data/download?docType=${params.doctype}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
