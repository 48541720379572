import React, { useEffect, useState } from "react";

import { useCategory } from "../../dataService/contexts";
import Select from "react-select";

const MultiCategoryDropdown = ({
  handleCategories,
  currentCategory,
  bg = true,
  forCategory,
}) => {
  const { state: categoryState } = useCategory();
  const { categories } = categoryState;

  const [initialCategories, setInitialCategories] = useState([]);

  const handleSelect = (data) => {
    handleCategories(
      "cropTags",
      data.map((data) => ({ id: data.id, word: data.value }))
    );
  };

  useEffect(() => {
    if (categories && categories.length) {
      categories.map((item) => {
        setInitialCategories((prev) => [
          ...prev,
          { id: item.id, value: item.categoryName, label: item.categoryName },
        ]);
      });
    }
  }, []);

  return (
    <div>
      <Select
        placeholder={`Category`}
        isSearchable={true}
        isClearable
        options={initialCategories}
        isMulti
        className={`${
          bg === true ? "source-select-containerr" : "source-select-container"
        } w-100`}
        classNamePrefix={`${bg === true ? "channel-select" : "tags-select"}`}
        getOptionLabel={(options) => `${options.label}`}
        getOptionValue={(options) => `${options.value}`}
        onChange={(e) => {
          handleSelect(e);
        }}
      />
    </div>
  );
};

export default MultiCategoryDropdown;
