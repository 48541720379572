import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

import { useNewsSources } from "../../../../dataService/contexts";
import { getDateTime_12T2D3M2Y } from "./../../../../const/dateFormat";
import { getBySourceCode } from "./../../../../const/newsSources";

const ScheduleVideoSearch = ({ params }) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const channel = getBySourceCode(params.channelName, newsSources);

  return (
    <div
      style={{
        maxWidth: "1060px",
      }}
    >
      <div className="p-1">
        <div
          className="d-flex flex-wrap flex-sm-row flex-column  gap-4 justify-content-between  p-2 rounded-4 border-bottom-light border border-secondary border-2"
          style={{
            backgroundColor: "#222c3d",
            borderColor: "#3f8cff",
          }}
        >
          <div className="d-flex gap-4">
            <img
              alt="newspaper-logo"
              src={channel.icon}
              style={{
                height: "3rem",
                width: "3rem",
              }}
            />

            <div className="text-white d-flex align-self-center font-s-md font-w-bold text-uppercase">
              {channel.name}
            </div>
          </div>

          <div className="d-flex gap-4">
            <div className="d-flex flex-column">
              <div
                className="font-s-sm font-w-bold"
                style={{ color: "#454c5d" }}
              >
                From
              </div>
              <div className="text-white font-s-md font-w-bold text-nowrap">
                {`${getDateTime_12T2D3M2Y(params.startDate)}`}
              </div>
            </div>

            <div className="d-flex flex-column">
              <div
                className="font-s-sm font-w-bold"
                style={{ color: "#454c5d" }}
              >
                To
              </div>
              <div className="text-white font-s-md font-w-bold text-nowrap">
                {`${getDateTime_12T2D3M2Y(params.endDate)}`}
              </div>
            </div>
          </div>

          <div className="d-flex gap-4">
            <div
              className="font-s-sm font-w-bold d-flex align-self-center p-2 rounded-5 text-warning  justify-content-center"
              style={{ backgroundColor: "#ae904552" }}
            >
              <div className="px-1" style={{ color: "#a16207" }}>
                Ongoing
              </div>
            </div>

            <div className="d-flex align-self-center">
              <BsThreeDotsVertical color={"white"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ScheduleVideoSearch };
