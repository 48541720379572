import React, { useEffect, useState } from "react";

import "./styles.scss";
import Select from "react-select";
import { getTags } from "../tags.service";
import CreatableSelect from "react-select/creatable";
import { addNewTag } from "../../../../../../../pages/Tags/components/AddTag/addTag.service";
import { ConfirmationAlert } from "../../../../../../index";
import { useTags } from "../../../../../../../dataService/contexts/tags.context";

const TagsDropdown = ({
  handleTags,
  news,
  bg = true,
  forTags,
  currentTag = null,
}) => {
  const { state: tagsState } = useTags();
  const { tags } = tagsState || {};

  const [initialTags, setInitialTags] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const handleCloseAlert = () => setShowAlert(false);
  const handleOpenAlert = () => setShowAlert(true);
  const handleSelect = (data) => {
    handleTags(
      "cropTags",
      data.map((data) => ({ id: data.id, word: data.value }))
    );
  };
  const handleAddTag = (e) => {
    addNewTag({ word: e }, (status, data) => {
      if (status === "success") {
        setInitialTags((prev) => [
          ...prev,
          { id: data.id, value: data.word, label: data.word },
        ]);
      }
    });
  };
  useEffect(() => {
    if (tags && tags.length) {
      tags.map((item) => {
        setInitialTags((prev) => [
          ...prev,
          { id: item.id, value: item.word, label: item.word },
        ]);
      });
    }
  }, [tags]);

  return (
    <div>
      <CreatableSelect
        placeholder={`Tags`}
        isSearchable={true}
        isClearable
        options={initialTags}
        isMulti
        className={`${
          bg === true ? "source-select-containerr" : "source-select-container"
        } w-100`}
        classNamePrefix={`${bg === true ? "channel-select" : "tags-select"}`}
        getOptionLabel={(options) => `${options.label}`}
        getOptionValue={(options) => `${options.value}`}
        defaultValue={
          forTags
            ? null
            : news.tagList &&
              news.tagList !== null &&
              typeof news.tagList !== "string" &&
              news.tagList.length
            ? [
                ...news.tagList.map((item) => ({
                  id: item.id,
                  label: item.word,
                  value: item.word,
                })),
              ]
            : typeof news.tagList === "string" && []
        }
        onChange={(e) => {
          handleSelect(e);
        }}
        onCreateOption={(e) => {
          handleOpenAlert();
          setSelectedTag(e);
        }}
      />
      <ConfirmationAlert
        show={showAlert}
        handleConfirm={handleAddTag}
        id={selectedTag}
        handleShow={handleCloseAlert}
        contentType={"Tag"}
        actionType={"Add"}
      />
    </div>
  );
};

export default TagsDropdown;
