import apiService from "../../dataService/apiService";
import { getDateTime_GMT } from "../../const/dateFormat";
import { getEnvironment } from "../../const/environment";
import moment from "moment";

export const getSourceReport = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/aggregated-report/source-wise`;

  const _params = {
    start: moment(params.startDate).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(params.endDate).format("YYYY-MM-DD HH:mm:ss"),
    contentType: params.contentType,
    categoryName: params.categoryName,
    sourceName: params.sourceName,
    aggregated: params.aggregated,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        const _data = {};
        if (data.length) {
          data.map((item) => {
            if (item.reports.length) {
              _data[`${item.categoryName}`] = item.reports;
            }
          });
        }
        done("success", _data);
      } else {
        done("error");
      }
    },
    {},
    { ..._params }
  );
};

export const getCategoryReport = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/count/category-wise`;

  const _params = {
    start: params.startDate,
    end: params.endDate,
    contentType: params.contentType,
    categoryName: params.categoryName,
    sourceName: params.sourceName === "" ? null : params.sourceName,
    aggregated: params.aggregated,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        const _data = {};
        if (data.length) {
          data.map((item) => {
            if (item.reports.length) {
              _data[`${item.sourceName}`] = item.reports;
            }
          });
        }

        done("success", _data);
      } else {
        done("error");
      }
    },
    {},
    { ..._params }
  );
};

export const downloadSourceReport = (date, done = () => {}) => {
  const env = getEnvironment();

  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/topic-management-service/api/${env.version}/${
    env.type
  }/aggregated-report/source-wise/download?
  &contentType=${date.contentType}&aggregated=${date.aggregated}&categoryName=${
    date.categoryName
  }&sourceName=${date.sourceName}&start=${moment(date.startDate).format(
    "YYYY-MM-DD HH:mm:ss"
  )}&end=${moment(date.endDate).format("YYYY-MM-DD HH:mm:ss")}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };

  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};

export const downloadCategoryReport = (date, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/topic-management-service/api/${env.version}/${
    env.type
  }/aggregated-report/category-wise/download?&contentType=${
    date.contentType
  }&aggregated=${date.aggregated}&categoryName=${
    date.categoryName
  }&sourceName=${date.sourceName}&start=${moment(date.startDate).format(
    "YYYY-MM-DD HH:mm:ss"
  )}&end=${moment(date.endDate).format("YYYY-MM-DD HH:mm:ss")}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };

  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};
