import React, { useEffect, useState } from "react";
import { SentimentDropdown, TimeComponent } from "../../../../../index";

import LoadMoreIcon from "../../../../../../assets/loadMore";
import TagsDropdown from "./TagsDropdown";
import CategoryDropdown from "./CategoryDropdown";

const SearchVideoCrop = ({ onChange, loading, selectedVideo }) => {
  const [searchState, setSearchState] = useState("");
  const [duration, setduration] = useState();
  const [state, setState] = useState({
    cropDescription: "",
    cropTitle: "",
    startDate: "",
    endDate: "",
    cropTags: [],
    cropCategory: "",
    id: selectedVideo.id,
    sentiment: 1,
  });
  const [sentiment, setSentiment] = useState(0);
  const handleState = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: date,
      };
    });
  };
  const handleCategory = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: date.id,
      };
    });
  };
  const handleTags = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: date.map((item) => item.id),
      };
    });
  };
  const handleSentiment = (_sentiment) => {
    if (_sentiment) {
      setState((prev) => {
        return {
          ...prev,
          ["sentiment"]: 1,
        };
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          ["sentiment"]: 2,
        };
      });
    }
  };

  const handleSubmit = () => {
    onChange(state);
  };

  useEffect(() => {
    const [hours, minutes] = selectedVideo.duration.split(":");
    if (hours > 9 && minutes > 9) {
      setduration(`${hours}:${minutes}:00`);
    } else if (hours > 9 && minutes < 10) {
      setduration(`${hours}:0${minutes}:00`);
    } else if (hours < 10 && minutes > 9) {
      setduration(`0${hours}:${minutes}:00`);
    } else {
      setduration(`0${hours}:0${minutes}:00`);
    }
  }, []);

  return selectedVideo ? (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-row gap-3 flex-wrap bg-black__deep p-4   rounded-4 w-100">
        <div className="text-white font-s-lg font-w-bolder">Video Crop</div>
        <textarea
          className="p-3 form-control bg-black__light border-0 text-white font-s-md font-w-bolder rounded-4 w-100"
          placeholder="Title"
          aria-label="title"
          aria-describedby="title"
          value={state.cropTitle}
          rows={2}
          onChange={(e) => handleState("cropTitle", e.target.value)}
        />
        <textarea
          className="p-3 form-control bg-black__light border-0 text-white font-s-md font-w-bolder rounded-4 w-100"
          placeholder="Description"
          aria-label="description"
          aria-describedby="description"
          value={state.cropDescription}
          rows={2}
          onChange={(e) => handleState("cropDescription", e.target.value)}
        />
        <div className="w-100">
          <TagsDropdown
            handleTags={handleTags}
            currentTag={state.cropTags}
            forTags={true}
          />
        </div>

        <div className="w-100 d-flex flex-row  flex-wrap gap-2">
          <div style={{ minWidth: "100px" }}>
            <TimeComponent
              handleDateWithTime={(e) => {
                handleState("startDate", e);
              }}
              placeholder="From"
              hasTime={true}
              date={state.startDate}
            />
          </div>

          <div style={{ minWidth: "100px" }}>
            <TimeComponent
              handleDateWithTime={(e) => {
                handleState("endDate", e);
              }}
              placeholder="To"
              hasTime={true}
              date={state.endDate}
            />
          </div>
          <SentimentDropdown
            handleSentiment={handleSentiment}
            currentSentiment={state.sentiment}
          />
          <CategoryDropdown
            handleCategory={handleCategory}
            currentTag={state.cropCategory}
          />
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-sm btn-primary px-4 py-2 rounded-4"
              onClick={() => handleSubmit()}
              disabled={
                state.cropTitle === "" ||
                state.startDate > state.endDate ||
                state.startDate > duration ||
                (state.endDate > duration && true) ||
                state.cropTags === [] ||
                state.cropCategory === "" ||
                state.cropDescription === ""
              }
            >
              <div className="px-3 py-2 font-w-bold font-s-md">
                {loading ? <LoadMoreIcon /> : "Crop and Save"}
              </div>
            </button>
          </div>

          <div className="d-flex justify-content-end w-100"></div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100 ">
        {state.startDate > state.endDate && state.endDate && (
          <div className="alert alert-danger text-danger w-100 text-center font-s-lg rounded-3">
            Start Time cannot be greater then end time !!! Make sure you provide
            start Time less then end Time.
          </div>
        )}

        {state.startDate > duration && (
          <div className="alert alert-danger text-danger w-100 text-center font-s-lg rounded-3">
            Start Time cannot be greater then {duration} end time !!! Make sure
            you provide start Time less then end Time.
          </div>
        )}
        {state.endDate > duration && (
          <div className="alert alert-danger text-danger w-100 text-center font-s-lg rounded-3">
            End Time cannot be greater then {duration} end time !!! Make sure
            you provide start Time less then end Time.
          </div>
        )}
        {/*{state.startDate && state.endDate && state.cropTitle === "" && (*/}
        {/*  <div className="alert alert-danger text-danger w-100 text-center font-w-bolder font-s-lg rounded-3">*/}
        {/*    Please enter Title to proceed further.*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SearchVideoCrop;
