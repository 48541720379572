import React, { useCallback, useEffect, useState } from "react";

import moment from "moment";
import {
  ConfirmationAlert,
  NoteComponent,
  UpdatedPagination,
} from "../../components";
import { getBySourceCode } from "../../const/newsSources";
import {
  setPageName,
  useNewsSources,
  usePageName,
  useUser,
} from "../../dataService/contexts";
import { VideoPlayer, VideoSearch } from "./components";
import EditCroppedVideo from "./components/EditCroppedVideo";
import { getVideCropList, removeCroppedVideo } from "./videoCropList.service";
import { useLocation, useSearchParams } from "react-router-dom";

const VideoCropOverviewDetails = () => {
  const location = useLocation();
  const autoPlay = !!(location.hash && location.hash === "#auto-play");

  const [URLParams, setURLParams] = useSearchParams();
  const { state: userState } = useUser();
  const { user } = userState || {};
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const { dispatch: pageNameDispatch } = usePageName();

  // const [selectedReportId, setSelectedReportId] = useState(0);
  const [selectedReportId, setSelectedReportId] = useState(0);

  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState();
  const [loading, setLoading] = useState(false);

  const initialValue = {
    videos: [],
    total: 0,
    page: 0,
  };

  const [state, setState] = useState({ ...initialValue });
  const handleState = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const initialParams = autoPlay
    ? {
        start: URLParams.get("startDate"),
        end: URLParams.get("endDate"),
        size: 40,
        page: 0,
        categoryName: URLParams.get("categoryName"),
        sentiment: URLParams.get("sentiment"),
        sourceName: URLParams.get("sourceName"),
      }
    : {
        start: new Date(),
        end: new Date(),
        categoryName: null,
        sentiment: null,
        sourceName: null,
        size: 40,
        page: 0,
      };

  // //params
  const [params, setParams] = useState(initialParams);

  const handleParams = (key, value) => {
    if (key === "page") {
      // wrapperRef.current.scrollIntoView();
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams(value);
    }
  };

  const handleSelectedVideo = (video) => {
    setSelectedVideo(video);
  };
  const handleSelectedTitle = (video) => {
    setSelectedTitle(video);
  };
  const handleOpenAlert = () => setShowAlert(true);

  const handleCloseAlert = () => setShowAlert(false);

  const removeSelectedVideo = () => setSelectedVideo(null);
  const removeSelectedTitle = (id, title) => {
    setSelectedTitle(null);

    if (title) {
      let targetObjectIndex = -1;

      for (let i = 0; i < state.videos.length; i++)
        if (state.videos[i].id === id) targetObjectIndex = i;

      if (targetObjectIndex > -1) {
        const updatedVideo = {
          ...state.videos[targetObjectIndex],
          title: title.cropTitle,
        };

        setState((prev) => {
          return {
            ...prev,
            // videos: [
            //   ...prev.videos.filter((item) => item.id !== id),
            //   updatedVideo,
            // ],

            videos: [
              ...prev.videos.slice(0, targetObjectIndex),
              updatedVideo,
              ...prev.videos.slice(targetObjectIndex + 1, prev.videos.length),
            ],
          };
        });
      }
    }
  };
  const handleDelete = (id) => {
    removeCroppedVideo(id, (status, data) => {
      if (status === "success") {
        if (data) {
          setState((prev) => {
            return {
              videos: prev.videos.filter((item) => item.id !== id),
              total: prev.total - 1,
            };
          });
        }
      }
    });
  };

  const fetchReports = useCallback(() => {
    setLoading(true);
    getVideCropList(params, user, autoPlay, (status, videos) => {
      if (status === "success") {
        const { data, total, page } = videos;

        handleState("videos", data);
        handleState("total", total);
        handleState("page", page);
      }
      setLoading(false);
    });
  }, [params, user]);

  useEffect(() => {
    setPageName(pageNameDispatch, "Video Cropped List");
  }, []);

  useEffect(() => {
    if (user) {
      fetchReports();
    }
  }, [user, fetchReports, params, autoPlay]);
  return (
    <>
      <div className="d-flex  gap-4 flex-column">
        <div className="d-flex gap-4 flex-row flex-wrap justify-content-between align-items-center w-100">
          <div className="flex-wrap d-flex flex-row gap-4">
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Start Date: {moment(params.start).format("YYYY-MM-DD, hh:mm A")}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              End Date: {moment(params.end).format("YYYY-MM-DD, hh:mm A")}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Source Name: {params.sourceName}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Category Name: {params.categoryName}
            </div>
            <div className="text-nowrap text-white bg-black__light py-3 px-4 rounded-4 font-s-md font-w-bolder">
              Sentiment:{" "}
              {Number(params.sentiment) === 1 ? "Positive" : "Negative"}
            </div>
          </div>
        </div>
      </div>
      <NoteComponent time={{ startDate: params.start, endDate: params.end }} />
      <div className="d-flex flex-column gap-4 p-1  ">
        {state.videos && state.videos.length && (
          <div style={{ overflow: "auto" }}>
            <table className="table--report w-100">
              <thead>
                <tr className="text-light font-s-n font-w-bolder text-uppercase bg-black__light shadow">
                  <th className=" text-nowrap px-4 py-2">Logo</th>
                  <th className=" text-nowrap px-4 py-2">Source Name</th>
                  <th className="  px-4 py-2">Title</th>
                  <th className="  px-4 py-2">Description</th>
                  <th className="  px-4 py-2">Category</th>
                  <th className="  px-4 py-2">Tags</th>
                  <th className="  px-4 py-2">Sentiment</th>
                  <th className=" text-nowrap px-4 py-2">Start Time</th>
                  <th className=" text-nowrap px-4 py-2">End Time</th>
                  <th className=" text-nowrap px-4 py-2">Created At</th>

                  <th className="  px-4 py-2 float-end">Actions</th>
                </tr>
              </thead>

              <tbody>
                {state.videos.length ? (
                  state.videos.map((item) => (
                    <tr className="bg-black__light shadow my-2">
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          <img
                            alt="newspaper-logo"
                            src={
                              getBySourceCode(item.sourceName, newsSources).icon
                            }
                            style={{
                              height: "3rem",
                              width: "3rem",
                            }}
                          />
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          {getBySourceCode(item.sourceName, newsSources).name}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          {item.title}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          {item.description}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          {item.categoryType && item.categoryType.categoryName}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="d-flex p-4 flex-row  text-wrap align-items-center">
                          {item.tags.map((tag, index) => (
                            <div className="text-white  font-s-n font-w-bold text-nowrap">
                              {tag.word}
                              {index !== item.tags.length - 1 && ","}
                              {index === item.tags.length - 1 && "."}
                              &nbsp;
                            </div>
                          ))}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="text-white p-4 font-s-n font-w-bold text-nowrap">
                          {item.sentiment === 1 ? "Positive" : "Negative"}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="p-4 font-s-n font-w-bold text-white">
                          {item.start}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="p-4 font-s-n font-w-bold text-white">
                          {item.end}
                        </div>
                      </td>
                      <td className="my-2">
                        <div className="p-4 font-s-n font-w-bold text-white">
                          {moment(item.createdAt).format(
                            "DD-MM-YYYY hh:mm:ss A"
                          )}
                        </div>
                      </td>
                      <td className="my-2 pe-4">
                        <div className=" d-flex flex-row gap-4 justify-content-end">
                          <button
                            className="btn btn-md  bg-primary text-white font-w-bolder px-4 font-s-md"
                            onClick={() => {
                              handleSelectedVideo(item);
                            }}
                          >
                            Play
                          </button>
                          {/*{item.userId && item.userId === user.userId ? (*/}
                          {/*  <button*/}
                          {/*    className="btn btn-md  bg-warning text-white font-w-bolder px-4 font-s-md"*/}
                          {/*    onClick={() => {*/}
                          {/*      handleSelectedTitle(item);*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    Edit*/}
                          {/*  </button>*/}
                          {/*) : (*/}
                          {/*  <></>*/}
                          {/*)}*/}
                          {/*{item.userId && item.userId === user.userId ? (*/}
                          {/*  <button*/}
                          {/*    className="bg-danger btn btn-md text-white px-4 font-w-bolder font-s-md "*/}
                          {/*    onClick={() => {*/}
                          {/*      handleOpenAlert();*/}
                          {/*      setSelectedReportId(item.id);*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    Delete*/}
                          {/*  </button>*/}
                          {/*) : (*/}
                          {/*  <></>*/}
                          {/*)}*/}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
                {/*{loading && <NewspaperReportLoading />}*/}
              </tbody>
            </table>
          </div>
        )}
        {selectedTitle ? (
          <EditCroppedVideo
            selectedTitle={selectedTitle}
            removeSelectedTitle={removeSelectedTitle}
          />
        ) : (
          <></>
        )}
        {selectedVideo ? (
          <VideoPlayer
            selectedVideo={selectedVideo}
            removeSelectedVideo={removeSelectedVideo}
          />
        ) : (
          <></>
        )}
        <ConfirmationAlert
          show={showAlert}
          handleConfirm={handleDelete}
          id={selectedReportId}
          handleShow={handleCloseAlert}
          contentType={"video"}
        />
        {state.total >= 40 ? (
          <UpdatedPagination
            itemsPerPage={40}
            total={state.total}
            action={handleParams}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default VideoCropOverviewDetails;
