import React, { useEffect, useState } from "react";
import { setPageName, usePageName, useUser } from "../../dataService/contexts";
import {
  AddCategory,
  CategoryDownloadDropdown,
  CategoryTable,
} from "./components";
import download from "../../assets/Navbar/Download";
import {
  downloadCategory,
  downloadCategoryDoc,
  downloadCategoryExcel,
} from "./category.service";
import moment from "moment/moment";
import {
  editCategoryPermission,
  editTagsPermission,
} from "../../components/NewsFullView/permission.controller";
import TagsDropdown from "../../components/Layouts/components/Navbar/components/SearchVideoCrop/TagsDropdown";

const Category = () => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const [show, setShow] = useState(false);

  const handleDownload = (doctype) => {
    if (doctype === 1) {
      downloadCategoryExcel({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `category.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    } else {
      downloadCategoryDoc({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `category.docx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { dispatch: pageNameDispatch } = usePageName();

  useEffect(() => {
    setPageName(pageNameDispatch, "Category");
  }, []);

  return (
    <div className="d-flex flex-column  p-1 gap-3">
      <div className="d-flex mb-3 flex-row justify-content-between ">
        <CategoryDownloadDropdown handleDownload={handleDownload} />

        {editCategoryPermission(user) && (
          <button
            className="btn btn-md  btn-primary text-white rounded-4 px-5 py-3 font-w-bold font-s-md"
            onClick={handleShow}
          >
            Add New Category
          </button>
        )}
      </div>

      <div className="d-flex flex-column gap-4">
        <CategoryTable />
        <div>
          <AddCategory open={show} handleClose={handleClose} />
        </div>
      </div>
    </div>
  );
};

export default Category;
