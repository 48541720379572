import React, { useEffect } from "react";
import { Routes } from "react-router-dom";

import { removeAllRequests, useOngoingRequests } from "./dataService/contexts";

import { getRoute } from "./routes";

import "./App.scss";

function App() {
  const { dispatch } = useOngoingRequests();

  useEffect(() => removeAllRequests(dispatch), []);

  return <Routes>{getRoute()}</Routes>;
}

export default App;
