import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getSourceNewsContent } from "./sourceWiseNews.service";

import { News, NewsDetails } from "../../components";

import LoadMoreIcon from "../../assets/loadMore";
import LoadingComponent from "../../components/LoadingComponent";
import { getBySourceCode } from "../../const/newsSources";
import { useNewsSources, useUser } from "../../dataService/contexts";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source, newsSources).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source, newsSources }) => {
  return source ? (
    <div className="font-w-bold text-white font-s-xlg">
      {getBySourceCode(source, newsSources).name}
    </div>
  ) : (
    <></>
  );
};

const SourceWiseNews = () => {
  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [totalNews, setTotalNews] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    keywords: "",
    startDate: "",
    endDate: "",
    type: "",
    nextNewsPaperOffset: 0,
    nextTvOffset: 0,
    size: 20,
  });
  const [checked, setChecked] = useState(false);

  const { newspaperName } = useParams();

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const { state: userState } = useUser();
  const { user } = userState || {};

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };
  const updateParams = (key, value) => {
    setParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  const handleClose = () => setSelectedNews(null);
  const handleShow = (news) => setSelectedNews(news);

  const loadMore = () => {
    setOnGoingActions("LOADMORE");
    fetchNews("LOADMORE");
  };

  const filter = () => {
    //filter will reset the page
    setOnGoingActions("FILTER");
    setNews([]);
    setTotalNews(0);

    fetchNews("FILTER");
  };
  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const { _news, _total, _nextTvOffset, _nextNewsPaperOffset } = data;

    if (actionType === "LOADMORE") {
      setNews((prev) => [...prev, ..._news]);
      setTotalNews(_total);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    } else if (actionType === "FILTER") {
      setNews([..._news]);
      setTotalNews(_total);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    }
  };

  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return {
        nextNewsPaperOffset: params.nextNewsPaperOffset,
        nextTvOffset: params.nextTvOffset,
      };
    } else if (action === "FILTER") {
      return {
        nextNewsPaperOffset: params.nextNewsPaperOffset,
        nextTvOffset: params.nextTvOffset,
      };
    }
  };

  const fetchNews = (actionType) => {
    setLoading(true);
    getSourceNewsContent(
      {
        ...params,
        ...getEffectiveParamsPage(actionType),
      },
      checked,
      newspaperName,
      (_status, data) => {
        if (_status === "success") {
          if (data && data.news) {
            actionHandler(actionType, {
              _news: data.news,
              _total: data.total,
              _nextNewsPaperOffset: data.nextNewsPaperOffset,
              _nextTvOffset: data.nextTvOffset,
            });
          }
        }

        setLoading(false);
      }
    );
  };
  useEffect(() => {
    filter();
  }, [checked]);
  useEffect(() => {
    loadMore("LOADMORE");
  }, []);

  return (
    <div className="d-flex flex-column gap-4 p-4">
      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex align-item-center gap-4">
          <NewsSourceIcon source={newspaperName} newsSources={newsSources} />
          <NewsSourceName source={newspaperName} newsSources={newsSources} />
        </div>

        <div className="d-flex gap-2 align-items-center">
          <input
            className="m-0 form-check-input"
            type="checkbox"
            name="alertedNewsOnly"
            id="alertedNewsOnly"
            checked={checked}
            onChange={handleCheckboxChange}
          />
          <label
            className=" text-white font-s-md font-w-bold align-self-center"
            htmlFor="alertedNewsOnly"
          >
            Alerted News Only
          </label>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
            <LoadingComponent size={4} />
          </div>
        ) : (
          news.length > 0 && (
            <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
              {news.length > 0 &&
                news.map((news) => (
                  <News
                    news={news}
                    action={handleShow}
                    keywords={
                      news.matchedKeywordList
                        ? news.matchedKeywordList.split(",")
                        : []
                    }
                    type=""
                    tags=""
                  />
                ))}
            </div>
          )
        )}
      </div>
      <div>
        {news && news.length !== totalNews ? (
          <div className="d-flex justify-content-center">
            <button
              onClick={() => loadMore()}
              className="btn btn-sm btn-primary px-4 py-2 rounded-4 font-s-n"
              disabled={loading ? "true" : ""}
            >
              <div className="px-3 py-2 font-w-bolder">
                {onGoingAction === "LOADMORE" ? <LoadMoreIcon /> : `Load More`}
              </div>
            </button>
          </div>
        ) : null}
      </div>
      {selectedNews && (
        <NewsDetails
          data={selectedNews}
          keyword={
            selectedNews.matchedKeywordList
              ? selectedNews.matchedKeywordList.split(",")
              : []
          }
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default SourceWiseNews;
