// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table--keyword thead tr th {
  background-color: #23252c !important;
  padding: 0.5rem 0.75rem;
  letter-spacing: 1px;
}
.table--keyword thead tr th:first-child {
  border-radius: 0.5rem 0 0 0;
}
.table--keyword thead tr th:last-child {
  border-radius: 0 0.5rem 0 0;
}
.table--keyword tbody tr td {
  padding: 0.5rem 0.75rem;
}
.table--keyword tbody tr:nth-child(odd) {
  background-color: #282a31;
}
.table--keyword tbody tr:nth-child(even) {
  background-color: #23252c;
}
.table--keyword tbody tr:last-child td:first-child {
  border-radius: 0 0 0 0.5rem;
}
.table--keyword tbody tr:last-child td:last-child {
  border-radius: 0 0.5rem 0 0;
}

.tr--active {
  background-color: white !important;
  border-bottom: 1px solid #dadada;
}
.tr--active td {
  color: #23252c !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/components/KeywordsAlert/style.scss"],"names":[],"mappings":"AAIgB;EACI,oCAAA;EACA,uBAAA;EACA,mBAAA;AAHpB;AAKoB;EACI,2BAAA;AAHxB;AAMoB;EACI,2BAAA;AAJxB;AAYgB;EACI,uBAAA;AAVpB;AAagB;EACI,yBAAA;AAXpB;AAcgB;EACI,yBAAA;AAZpB;AAiBwB;EACI,2BAAA;AAf5B;AAkBwB;EACI,2BAAA;AAhB5B;;AAyBA;EACI,kCAAA;EACA,gCAAA;AAtBJ;AAuBI;EACI,yBAAA;AArBR","sourcesContent":[".table{\n    &--keyword{\n        thead{\n            tr {\n                th{\n                    background-color: #23252c !important;\n                    padding: .5rem .75rem;\n                    letter-spacing: 1px;\n\n                    &:first-child{\n                        border-radius: .5rem 0 0 0;\n                    }\n\n                    &:last-child {\n                        border-radius: 0 .5rem 0 0;\n                    }\n                }\n            }\n        }\n\n        tbody{\n            tr{\n                td{\n                    padding: .5rem .75rem;\n                }\n\n                &:nth-child(odd){\n                    background-color: #282a31;\n                }\n\n                &:nth-child(even) {\n                    background-color: #23252c;\n                }\n\n                &:last-child{\n                    td{\n                        &:first-child{ \n                            border-radius: 0 0 0 .5rem;\n                        }\n\n                        &:last-child {\n                            border-radius: 0 .5rem 0 0;\n                        }\n                    }\n                }\n            }\n        }\n    }\n}\n\n.tr--active {\n    background-color: white !important;\n    border-bottom: 1px solid #dadada;\n    & td{\n        color: #23252c !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
