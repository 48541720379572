import { createContext, useContext, useMemo, useReducer } from "react";

import actionTypes from "../actionTypes";

const PageNameContext = createContext();

const initialState = {
    pageName: "",
};


const pageNameReducer = function (state, action){
    switch (action.type){
        case actionTypes.pageNameSet:
            return {
                ...state,
                pageName: action.pageName
            };
        
        default:
            break;
    }
}

export function PageNameProvider(props){
    const [state, dispatch] = useReducer(pageNameReducer, initialState);
    const value = useMemo(() => [state, dispatch], [state]);
  
    return <PageNameContext.Provider value={value} {...props} />;
}

export function usePageName() {
    const context = useContext(PageNameContext);
    if (!context) {
        throw new Error("use page name must be used within a page name provider");
    }
  
    const [state, dispatch] = context;
  
    return {
        state,
        dispatch,
    };
}

const update = (pageName) => ({
    type: actionTypes.pageNameSet,
    pageName
});

export const setPageName = (dispatch, pageName, done=()=>{}) => {
    dispatch(update(pageName));
}