const pagePermission = {
  dashboard: "can_view_dashboard",
  monitor: "can_view_monitor",
  analysis: "can_view_analysis",
  dictionary: "can_view_dictionary",
  allNewspapers: "can_view_all_dashboard_newspaper_news",
  // singleNewspaper: "can_view_all_dasboard_newspaper_news",
  allTVs: "can_view_all_dashboard_tv_news",
  caseManagement: "can_view_case_management",
  singleCaseManagement: "can_view_case_management",
  alerts: "can_view_alerts",
  bookmark: "can_view_bookmark",
  liveStream: "can_view_live_stream",
  dailyReport: "can_view_daily_report",
  managerReport: "can_view_manager_report",
  tags: "can_view_tags",
  category: "can_view_category",
  newspaperReport: "can_view_news_report",
  videoCrop: "can_view_video_crop",
  uniqueScrollNews: "can_view_unique_scroll_news",
  icons: "can_view_icon",
  notice: "can_view_notice",
  newspaperLive: "can_view_newspaper_live",
  newspaperSearch: "can_view_newspaper_search",
  tvScrollLive: "can_view_tv_live",
  tvScrollSearch: "can_view_tv_search",
  reportOverview: "can_view_aggregated_report",
  managementReports: "can_view_management_reports",
  managementConfiguration: "can_view_management_configurations",
  analyticalDashbaord: "can_view_analytical_dashboard",
};

const actionPermission = {
  marking: "can_mark",
  viewMarkingOfAllUsers: "can_view_marking_of_all_users",
  editCategory: "can_edit_category",
  editTags: "can_edit_tags",
};

export { pagePermission, actionPermission };
