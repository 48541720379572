// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table--alerts {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
.table--alerts thead tr th:first-child {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.table--alerts thead tr th:last-child {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}
.table--alerts tbody tr td:first-child {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
.table--alerts tbody tr td:last-child {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}
.table--alerts tbody tr td:last-child a {
  visibility: hidden;
}
.table--alerts tbody :hover td {
  background-color: white !important;
  color: black !important;
}
.table--alerts tbody :hover td:last-child a {
  visibility: visible;
}
.table--alerts tbody :hover td:first-child input {
  outline: 2rem !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/AlertNotification/components/KeywordList/style.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,wBAAA;AAAJ;AAKU;EACE,2CAAA;AAHZ;AAMU;EACE,2CAAA;AAJZ;AAYU;EACE,2CAAA;AAVZ;AAaU;EACE,2CAAA;AAXZ;AAaY;EACE,kBAAA;AAXd;AAkBQ;EACE,kCAAA;EAEA,uBAAA;AAjBV;AAqBY;EACE,mBAAA;AAnBd;AAwBY;EACE,wBAAA;AAtBd","sourcesContent":[".table{\n  &--alerts{\n    border-collapse:separate;\n    border-spacing:0 0.5rem;\n\n    thead {\n      tr {\n        th {\n          &:first-child {\n            border-radius: .5rem 0 0 .5rem !important;\n          }\n  \n          &:last-child {\n            border-radius: 0 .5rem .5rem 0 !important;\n          }\n        }\n      }\n    }\n    tbody{\n      tr{\n        td{\n          &:first-child{\n            border-radius: .5rem 0 0 .5rem !important;\n          }\n\n          &:last-child {\n            border-radius:  0 .5rem .5rem 0 !important;\n            // \n            a{\n              visibility: hidden;\n            }\n          }\n        }\n      }\n\n      :hover{\n        td{\n          background-color: white  !important;\n          \n          color: black !important;\n          \n\n          &:last-child{\n            a{\n              visibility: visible;\n            }\n          }\n          &:first-child{\n\n            input{\n              outline: 2rem !important;\n            }\n          }\n        }\n      }\n\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
