import React from "react";

const Index = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M7.24487 14.7815L10.238 10.8914L13.6522 13.5733L16.5813 9.79297"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="19.9954"
        cy="4.20027"
        r="1.9222"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9245 3.12024H7.65679C4.64535 3.12024 2.77808 5.25296 2.77808 8.2644V16.3468C2.77808 19.3582 4.60874 21.4818 7.65679 21.4818H16.2609C19.2724 21.4818 21.1396 19.3582 21.1396 16.3468V9.30788"
        stroke="#808191"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Index;
