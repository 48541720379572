import React from "react";
import "./styles.scss";

const NewsKeywords = ({keywords}) => {
    return (
      <>{keywords.length > 0 ? (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex flex-row gap-2 flex-wrap">{keywords.map((e) => (
            <span
              key={e}
              className="text-white font-w-bolder font-s-sm"
              dangerouslySetInnerHTML={{
                __html: `<p 
                    class=keyword 
                    style="display: inline;"
                  >${e || ""}</p>`,
              }}
            />
          ))}</div>
        </div>) : (
        <></>
      )}</>   
    )
}

export default NewsKeywords;