import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

const LoadingComponent = ({ size }) => {
  const baseColor = "#4e4d511a";
  const highlightColor = "#3f3f3f";

  return (
    <div className="d-flex flex-column gap-5">
      {Array(size)
        .fill(0)
        .map(() => (
          <div className="d-flex flex-column gap-3 news p-4">
            <div className="d-flex flex-row justify-content-between">
              <div className="flex-row d-flex gap-3">
                <div>
                  <Skeleton
                    circle
                    height="3rem"
                    width="3rem"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                    duration={4}
                  />
                </div>
                <div></div>
                <div className="align-self-center d-flex flex-column gap-3">
                  <Skeleton
                    height="1.25rem"
                    width="8rem"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                    duration={4}
                  />
                  <div className="d-flex flex-row gap-3">
                    <div>
                      <Skeleton
                        height="1.25rem"
                        width="5rem"
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                        duration={4}
                      />
                    </div>
                    <div>
                      <Skeleton
                        height="1.25rem"
                        width="5rem"
                        baseColor={baseColor}
                        highlightColor={highlightColor}
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Skeleton
                baseColor={baseColor}
                highlightColor={highlightColor}
                duration={4}
              />
              <Skeleton
                baseColor={baseColor}
                highlightColor={highlightColor}
                duration={4}
              />
              <Skeleton
                baseColor={baseColor}
                highlightColor={highlightColor}
                duration={4}
              />
              <Skeleton
                baseColor={baseColor}
                highlightColor={highlightColor}
                duration={4}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default LoadingComponent;
