import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import { useNewsSources, useUser } from "../../../../dataService/contexts";
import { getLiveNewsStream } from "./liveNewsStream.service";

import {
  getBySourceCode,
  getBySourceType,
} from "../../../../const/newsSources";

import ViewAllIcon from "../../../../assets/icons/link.png";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source, newsSources).icon}
        style={{
          height: "4rem",
          width: "4rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};
const StreamList = ({ data, activeChannel, action, activeChannelLink }) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const permittedChannelList = (channels) => {
    const channelCodes =
      !!user && !!user.availableTvChannels && user.availableTvChannels?.length
        ? user.availableTvChannels.map((tc) => tc.code)
        : [];

    if (channelCodes.length) {
      const permittedChannels = channels.filter((c) =>
        channelCodes.includes(`${c.code}`)
      );

      return permittedChannels;
    } else return getBySourceType(2, newsSources);
  };

  const channelList = permittedChannelList(data);

  return channelList.length > 0 ? (
    <div
      className=""
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(6rem, 1fr))",
        gap: "1rem",
      }}
    >
      {channelList.map((channel) => (
        <div
          onClick={() => action(channel.code, channel.url)}
          key={channel.id}
          className="p-2 rounded-4"
          style={
            channel.code === activeChannel
              ? {
                  background: "rgba(53, 93, 255, 0.2)",
                  borderRadius: "1rem",
                  border: "1px solid rgba(255, 255, 255, 0.5)",
                }
              : {}
          }
        >
          <div
            className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
            style={{
              minWidth: "4rem",
              minHeight: "4rem",
            }}
          >
            <NewsSourceIcon source={channel.code} newsSources={newsSources} />
          </div>

          <div className="text-white font-s-n text-center font-w-bold">
            {channel.name}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

const LiveNewsStream = () => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const { state: userState } = useUser();
  const { user } = userState || {};
  const [activeChannel, setActiveChannel] = useState("");
  const [activeChannelLink, setActiveChannelLink] = useState("");

  const [channelList, setChannelList] = useState([]);
  const handleActiveChannel = (channel, link) => {
    setActiveChannel(channel);
    setActiveChannelLink(link);
  };
  const fetchLiveStream = () => {
    getLiveNewsStream((status, _data) => {
      if (status === "success") {
        if (_data && _data.channelData.length) {
          setChannelList(_data.channelData);
          handleActiveChannel(
            _data.channelData[0].channelCode,
            _data.channelData[0].url
          );
        }
      }
    });
  };

  useEffect(() => {
    if (
      newsSources &&
      newsSources.length &&
      user &&
      user.availableTvChannels.length
    ) {
      const channel = newsSources.filter(
        (item) => item.code === user.availableTvChannels[0].code
      );
      handleActiveChannel(channel[0].code, channel[0].url);
    } else if (newsSources && newsSources.length) {
      handleActiveChannel(
        getBySourceType(2, newsSources)[0].code,
        getBySourceType(2, newsSources)[0].url
      );
    }
  }, [newsSources, user]);

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex gap-4 flex-wrap align-items-center">
        <div className="text-white font-s-lg font-w-bolder">
          Live News Stream
        </div>
        <Link
          className="px-3 py-2 btn btn-sm p-0 text-white d-flex align-items-center gap-2"
          style={{
            border: "1px solid rgba(228, 228, 228, 0.1)",
            borderRadius: ".5rem",
          }}
          to="/live-stream"
        >
          <img
            src={ViewAllIcon}
            alt="view all live news"
            style={{
              width: "1rem",
              height: "1rem",
            }}
          />
          <div className="text-nowrap font-s-md">View All</div>
        </Link>
      </div>
      <div className="d-flex gap-4 flex-column bg-black__light rounded-4 p-4 shadow">
        <div className="rounded-4 overflow-hidden bg-dark">
          <ReactPlayer
            url={activeChannelLink}
            playing={true}
            controls={true}
            loop={true}
            muted={true}
            playsinline={true}
            width="100%"
            autoplay={true}
          />
        </div>
        <StreamList
          data={newsSources}
          action={handleActiveChannel}
          activeChannel={activeChannel}
          activeChannelLink={activeChannelLink}
        />
      </div>
    </div>
  );
};

export { LiveNewsStream };
