import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { LoadingComponent, News, NewsDetails } from "../../components";
import { TopicKeywordList, TopicTabs, TimeTabs } from "./components";

import { useUser } from "../../dataService/contexts";
import {
  getIndividualTopic,
  getTopicRelatedNews,
} from "./topicRelatedNews.service";

import LoadMoreIcon from "../../assets/loadMore";

import GetBackButtonIcon from "../../getBackButtonIcon";

const TopicRelatedNews = () => {
  const { relatedTopicId } = useParams();

  const { state: userState } = useUser();
  const { user } = userState || {};

  const [news, setNews] = useState([]);

  const [totalNews, setTotalNews] = useState(0);
  const [onGoingAction, setOnGoingActions] = useState("");
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    nextNewsPaperOffset: 0,
    nextTvOffset: 0,
    size: 20,
  });

  const [topicName, setTopicName] = useState("");
  const [topicDetail, setTopicDetail] = useState("");
  const [topicKeywords, setTopicKeywords] = useState([]);
  const [selectedTopicKeywords, setSelectedTopicKeywords] = useState([]);

  const [tags, setTags] = useState([]);
  const [activeStatus, setActiveStatus] = useState(0);
  const [activeTime, setActiveTime] = useState(1);

  const [selectedNews, setSelectedNews] = useState(null);
  const handleClose = () => setSelectedNews(null);
  const handleShow = (news) => setSelectedNews(news);

  const updateParams = (key, value) => {
    setParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  const hadleActiveStatusChange = (status) => {
    setActiveStatus(status);

    setNews([]);
    setTotalNews(0);

    setOnGoingActions("FILTER");
  };

  const handleActiveTime = (time) => {
    setActiveTime(time);

    setNews([]);
    setTotalNews(0);

    setOnGoingActions("FILTER");
  };

  const loadMore = () => {
    setOnGoingActions("LOADMORE");
    fetchTopicRelatedNews("LOADMORE", topicKeywords.toString());
  };

  const getUpdatedData = (data) => {
  
  };

  const getKeyword = (data) => {
    setSelectedTopicKeywords(data);

    setNews([]);
    setTotalNews(0);

    setTags(
      data.map((item) => `<span class='highlighted-text'>${item}</span>`)
    );

    setOnGoingActions("FILTER");
  };
  const actionHandler = (actionType, data) => {
    setOnGoingActions("");
    const { _keywords, _total, _nextTvOffset, _nextNewsPaperOffset } = data;

    if (actionType === "LOADMORE") {
      setNews((prev) => [...prev, ..._keywords]);
      setTotalNews(_total);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    } else if (actionType === "FILTER") {
      setNews([..._keywords]);
      setTotalNews(_total);

      updateParams("nextNewsPaperOffset", _nextNewsPaperOffset);
      updateParams("nextTvOffset", _nextTvOffset);
    }
  };

  const getEffectiveParamsPage = (action) => {
    if (action === "LOADMORE") {
      return {
        nextNewsPaperOffset: params.nextNewsPaperOffset,
        nextTvOffset: params.nextTvOffset,
      };
    } else if ("FILTER") {
      return {
        nextNewsPaperOffset: 0,
        nextTvOffset: 0,
      };
    }
  };

  const fetchTopicRelatedNews = (actionType) => {
    if (user && selectedTopicKeywords.length) {
      setLoading(true);

      getTopicRelatedNews(
        {
          ...params,
          ...getEffectiveParamsPage(actionType),
        },
        user,
        activeStatus,
        activeTime,
        selectedTopicKeywords.toString(),
        (_status, data) => {
          if (_status === "success") {
            if (data && data.news) {
              actionHandler(actionType, {
                _keywords: data.news,
                _total: data.total,
                _nextNewsPaperOffset: data.nextNewsPaperOffset,
                _nextTvOffset: data.nextTvOffset,
              });
              setLoading(false);
            }
            setLoading(false);
          }

          setLoading(false);
        }
      );
    }
  };

  const fetchKeywords = () => {
    if (user)
      getIndividualTopic(relatedTopicId, user, (_status, data) => {
        if (_status === "success") {
          if (data) {
            setTags(
              data.keywords.map(
                (item) => `<span class='highlighted-text'>${item}</span>`
              )
            );
            setTopicName(data.name);
            setTopicDetail(data.detail);
            setTopicKeywords(data.keywords);
            setSelectedTopicKeywords(data.keywords);
          }
        }
      });
  };

  useEffect(() => {
    if (user) fetchKeywords();
  }, [user]);

  useEffect(() => {
    if (user) fetchTopicRelatedNews("FILTER");
  }, [selectedTopicKeywords, activeStatus, activeTime, user]);

  return (
    <>
      <div className="d-flex flex-column  p-4 gap-4">
        <span className="d-flex justify-content-start">
          <Link
            to="/case-management"
            className="p-0 bg-transparent text-light btn btn-sm font-w-bold font-s-md d-flex flex-row gap-3 align-items-center"
          >
            {GetBackButtonIcon()}
            <div>Back</div>
          </Link>
        </span>

        <div className="text-white font-s-xlg font-w-bolder">{topicName}</div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(4rem, 1fr))",
            gap: "2rem",
          }}
        >
          <div
            style={{
              gridColumn: "span 2",
            }}
            className="d-flex  flex-column gap-4"
          >
            <div className="d-flex flex-column gap-4">
              <div className="text-white font-s-md font-w-bold">
                Topic Details
              </div>
              <div
                style={{
                  height: "0.25rem",
                  width: "4.85rem",
                  borderRadius: "1rem",
                  background: "#6c5dd3",
                }}
              ></div>
            </div>
            <div className="text-light ">{topicDetail}</div>
            <div className="d-flex flex-row justify-content-between">
              <div className="font-s-md text-white font-w-bold d-flex align-self-center">
                Topic Related News
              </div>
              <div className="d-flex flex-column gap-4">
                <div className="d-flex justify-content-end">
                  <TimeTabs
                    activeStatus={activeTime}
                    handleActiveStatus={handleActiveTime}
                  />
                </div>
                <div className="d-flex justify-content-end">
                  <TopicTabs
                    activeStatus={activeStatus}
                    handleActiveStatus={hadleActiveStatusChange}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex gap-4 flex wrap">
                <div>
                  <span className="me-2 font-w-bold text-white">
                    {totalNews}
                  </span>
                  <span className="text-light font-w-bold">Result Found</span>
                </div>

                <div className="text-white font-w-bold">/</div>

                <div>
                  <span className="me-2 font-w-bold text-light">Showing</span>
                  <span className="font-w-bold text-warning">
                    {news.length}
                  </span>
                </div>
              </div>
            </div>
            <div className=" d-flex flex-column gap-4">
              <div className="d-flex flex-column overflow-auto gap-2">
                {news.length > 0 ? (
                  <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
                    {news.length > 0 &&
                      news.map((news) => (
                        <News
                          news={news}
                          action={handleShow}
                          keywords={
                            news.matchedKeywordList
                              ? [...news.matchedKeywordList.split(","), ...tags]
                              : [...tags]
                          }
                          type=""
                          tags={topicKeywords.toString()}
                        />
                      ))}
                  </div>
                ) : (
                  <></>
                )}
                {loading && (
                  <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
                    <LoadingComponent size={1} />
                  </div>
                )}
              </div>
              {news && news.length && news.length < totalNews ? (
                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => loadMore()}
                    className="btn btn-sm btn-primary px-4 py-2 rounded-4 font-s-n"
                    disabled={loading ? "true" : ""}
                  >
                    <div className="px-3 py-2 font-w-bolder">
                      {onGoingAction === "LOADMORE"
                        ? <LoadMoreIcon/>
                        : `Load More`}
                    </div>
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            {/* <div className="d-flex flex-row gap-2 pt-2">
              {getClockIcon()}
              <span className="text-light font-s-sm align-self-center">
                Last Updated: 1.25.2023
              </span>
            </div> */}
            <div>
              <TopicKeywordList
                keywords={topicKeywords}
                name={topicName}
                detail={topicDetail}
                id={relatedTopicId}
                getKeywords={getKeyword}
                getUpdatedData={getUpdatedData}
              />
            </div>
          </div>
        </div>
      </div>
      {selectedNews && (
        <NewsDetails
          data={selectedNews}
          keyword={
            selectedNews.matchedKeywordList
              ? [...selectedNews.matchedKeywordList.split(","), ...tags]
              : [...tags]
          }
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default TopicRelatedNews;
