// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mark span {
  background-color: white;
  padding: 2px 6px;
  border-radius: 2px;
}

.tag-wrapper {
  padding: 0.75rem 1.25rem;
  position: relative;
  border: 1px solid rgb(19, 58, 92) !important;
}
.tag-wrapper .tag-label {
  position: absolute;
  top: -0.8rem;
  z-index: 1;
  left: 0.5rem;
  background-color: white;
  padding: 0 5px;
  color: black;
  border-radius: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Playground/styles.scss"],"names":[],"mappings":"AAEI;EAEI,uBAAA;EACA,gBAAA;EACA,kBAAA;AAFR;;AAMA;EACI,wBAAA;EACA,kBAAA;EACA,4CAAA;AAHJ;AAKI;EACI,kBAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;EACA,uBAAA;EACA,cAAA;EACA,YAAA;EACA,sBAAA;AAHR","sourcesContent":["mark{\n    // background-color: rgb(0, 154, 192) !important;\n    & span{\n        // display: none;\n        background-color: white;\n        padding: 2px 6px;\n        border-radius: 2px;\n    }\n}\n\n.tag-wrapper{\n    padding: .75rem 1.25rem;\n    position: relative;\n    border: 1px solid rgb(19, 58, 92) !important;\n\n    .tag-label{\n        position: absolute;\n        top: -.8rem;\n        z-index: 1;\n        left: .5rem;\n        background-color: white;\n        padding: 0 5px;\n        color: black;\n        border-radius: .25rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
