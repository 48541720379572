import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";

import moment from "moment";

const getTimeRange = (activeStatus) => {
  if (activeStatus) {
    const currentDateAndTime = moment();
    const endDate = moment(currentDateAndTime)
      .utc("asia/dhaka")
      .format("YYYY-MM-DD HH:mm:ss");
    let prevDateAndTime;

    if (activeStatus === 3) {
      prevDateAndTime = moment(currentDateAndTime)
        .startOf("day")
        .utc("asia/dhaka")
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (activeStatus === 2) {
      prevDateAndTime = moment(
        moment(currentDateAndTime)
          .subtract(7, "days")
          .startOf("day")
          .utc("asia/dhaka")
          .format("YYYY-MM-DD HH:mm:ss")
      );
    } else if (activeStatus === 1) {
      prevDateAndTime = moment(currentDateAndTime)
        .subtract(30, "d")
        .utc("asia/dhaka")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    return {
      end: endDate,
      start: prevDateAndTime,
    };
  }
};
export const getTotalCount = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/aggregated`;

  const _params = {
    start:
      params.activeStatus === 4
        ? moment(params.startDate).utc("asia/dhaka").format("YYYY-MM-DD")
        : moment(getTimeRange(params.activeStatus).start)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
    end:
      params.activeStatus === 4
        ? moment(params.endDate).utc("asia/dhaka").format("YYYY-MM-DD")
        : moment(getTimeRange(params.activeStatus).end)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) done("success", data);
      else done("error");
    },
    {},
    { ..._params }
  );
};

export const getNewspaperSourceWise = (params, top, sort, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/aggregated/source-wise`;

  const _params = {
    start:
      params.activeStatus === 4
        ? moment(params.startDate).utc("asia/dhaka").format("YYYY-MM-DD")
        : moment(getTimeRange(params.activeStatus).start)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
    end:
      params.activeStatus === 4
        ? moment(params.endDate).utc("asia/dhaka").format("YYYY-MM-DD")
        : moment(getTimeRange(params.activeStatus).end)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
    contentType: 4,
    top: top,
    sort: sort,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) done("success", data);
      else done("error");
    },
    {},
    { ..._params }
  );
};

export const getNewspaperSourceWiseNoSort = (params, top, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/aggregated/source-wise`;

  const _params = {
    start:
      params.activeStatus === 4
        ? moment(params.startDate).utc("asia/dhaka").format("YYYY-MM-DD")
        : moment(getTimeRange(params.activeStatus).start)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
    end:
      params.activeStatus === 4
        ? moment(params.endDate).utc("asia/dhaka").format("YYYY-MM-DD")
        : moment(getTimeRange(params.activeStatus).end)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD"),
    contentType: 4,
    top: top,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) done("success", data);
      else done("error");
    },
    {},
    { ..._params }
  );
};

export const getCategoryAggregatedReport = (
  params,
  contentType,
  done = () => {}
) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/public-dashboard-service/api/${env.version}/${env.type}/count/category-wise`;

  const _params = {
    start:
      params.activeStatus === 4
        ? moment(params.startDate)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD HH:mm:ss")
        : moment(getTimeRange(params.activeStatus).start)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD HH:mm:ss"),
    end:
      params.activeStatus === 4
        ? moment(params.endDate).utc("asia/dhaka").format("YYYY-MM-DD HH:mm:ss")
        : moment(getTimeRange(params.activeStatus).end)
            .utc("asia/dhaka")
            .format("YYYY-MM-DD HH:mm:ss"),
    contentType: contentType,
    categoryName: "",
    sourceName: "",
    aggregated: true,
  };

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        const _data = {};
        if (data.length) {
          data.map((item) => {
            if (item.reports.length) {
              _data[`${item.sourceName}`] = item.reports;
            }
          });
        }

        done("success", _data);
      } else {
        done("error");
      }
    },
    {},
    { ..._params }
  );
};
