import React from "react";

const NewsPaperViewsIcon = () => {
  return (
    <svg width={17} height={17} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M16 2v13a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1Z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m13 4-6 6M8 4h5v5"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewsPaperViewsIcon;
