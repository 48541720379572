import moment from "moment";

import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

const env = getEnvironment();

export const managerReport = (params, done = () => {}) => {
  const url = `${env.protocol}://10.101.122.16/topic-management-service/api/v1/${env.type}/report/manager-report?`;
  const _params = {
    date: moment(params.date).format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(params.endDate).format("YYYY-MM-DD HH:mm:ss"),
    contentType: params.contentType === "" ? null : params.contentType,
    categoryType: params.category === "" ? null : params.category,
    sentiment: params.sentiment === "" ? null : params.sentiment,
    sourceName: params.sourceName === "" ? null : params.sourceName,
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    { ..._params }
  );
};

export const updateManagerReport = (data, done = () => {}) => {
  const _data = {
    reportId: data.reportId,
    sourceName: data.sourceName,
    newsTimeAt: data.newsTimeAt,
  };

  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/create-marked-report`;

  apiService.post({ url, data: _data }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

export const removeFromReport = (id, done = () => {}) => {
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/manager-marked-report/${id}`;

  apiService.delete(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
