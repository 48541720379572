import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";

export const getVideo = (data, done = () => {}) => {
    const env = getEnvironment();
    const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-stream-service/api/${env.version}/${env.type}/video/search-stream`;

    apiService.post(
        { url, data }, 
        (status, data) => {
            if (status > 199 && status < 300) {
                done("success", data);
            } else {
                done("danger", data);
            }
        }
    );
};
