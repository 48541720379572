import React from "react";
import { toast } from "react-toastify";

const SuccessToast = () => {
  const activeToast = () =>
    toast.error("Error Occured ", {
      containerId: "active",
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
      onClose: () => {},
    });
  return <div></div>;
};

export default SuccessToast;
