import React, { useState } from "react";

const Select = ({ options, handleSelect }) => {
  const [searchText, setSearchText] = useState("");
  const updateSearchText = (text) => setSearchText(text);

  const updateSelected = (selected) => {
    updateSearchText(selected.channelName);
    handleSelect(selected.value);
  };

  return (
    <div>
      <div>
        <input
          className="form-control p-2 px-4"
          placeholder="Enter Channel Name"
          type="text"
          value={searchText}
          onChange={(e) => updateSearchText(e.target.value)}
        />
      </div>
      <div>
        {options
          .filter((item) => {
            const searchTerm = searchText.toLowerCase();
            const channelName = item.value.toLowerCase();
            return (
              searchTerm &&
              channelName.includes(searchTerm) &&
              channelName !== searchTerm
            );
          })
          .map((item) => (
            <div onClick={() => updateSelected(item)} key={item.channelName}>
              {item.channelName}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Select;
