import apiService from "../../dataService/apiService";
import { getDateTime_4Y2M2D24T } from "../../const/dateFormat";
import { getEnvironment } from "../../const/environment";

export const getVideoContent = (params, user, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-stream-service/api/${env.version}/${env.type}/video/stream`;

  const _params = {
    startDate: getDateTime_4Y2M2D24T(params.startDate),
    endDate: getDateTime_4Y2M2D24T(params.endDate),
    channelName: params.channelName.code,
    userId: user.userId,
    subsChannel: user.userId,
  };

  apiService.post({ url, data: _params }, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data, {
        ...params,
        channelName: params.channelName.code,
      });
    } else {
      done("danger", data);
    }
  });
};

export const getVideoDownload = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-stream-service/api/${env.version}/${env.type}/video/download`;
  const _params = {
    startDate: getDateTime_4Y2M2D24T(params.startDate),
    endDate: getDateTime_4Y2M2D24T(params.endDate),
    channelName: params.channelName,
  };

  apiService.post(
    {
      url,
      data: _params,
    },
    (status, _data) => {
      if (status > 199 && status < 300) {
        apiService.get(_data.url, () => {
          if (status > 199 && status < 300) {
            done("success", _data);
          }
        });
      } else {
        done("danger", _data);
      }
    }
  );
};
export const getVideoThumbnail = (user, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-stream-service/api/${env.version}/${env.type}/video-stream/metadata?page=0&size=1000&userId=${user.userId}`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },
    {}
  );
};

export const thumbnailVideoDownload = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/video-stream-service/api/${env.version}/${env.type}/video/download`;
  const _params = {
    startDate: getDateTime_4Y2M2D24T(params.startDate),
    endDate: getDateTime_4Y2M2D24T(params.endDate),
    channelName: params.channelName,
  };

  apiService.post(
    {
      url,
      data: _params,
    },
    (status, _data) => {
      if (status > 199 && status < 300) {
        apiService.get(_data.url, () => {
          if (status > 199 && status < 300) {
            done("success", _data);
          }
        });
      } else {
        done("danger", _data);
      }
    }
  );
};
