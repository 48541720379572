import React, { useState, useEffect } from "react";
import { setPageName, usePageName } from "../../dataService/contexts";
import { seenNotice } from "./seenNotice.service";
import { useParams } from "react-router-dom";

const SeenNoticeList = () => {
  const { dispatch: pageNameDispatch } = usePageName();
  const { seen_notice } = useParams();

  useEffect(() => {
    setPageName(pageNameDispatch, "Seen Notice List");
  }, []);

  const [notices, setNotices] = useState([]);

  const fetchNotice = () => {
    seenNotice(seen_notice, (status, data) => {
      if (status === "success") {
        setNotices(data);
      }
    });
  };
  useEffect(() => {
    fetchNotice();
  }, []);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <table className="w-100 table--dictionary ">
          <thead>
            <tr
              className="text-light font-s-n font-w-bolder text-uppercase"
              style={{ letterSpacing: "1px" }}
            >
              <th>SL</th>
              <th>USER</th>
              <th>DATE TIME</th>
            </tr>
          </thead>

          {notices.length > 0 &&
            notices.map((notice, index) => (
              <tr key={index}>
                <td className="text-light font-s-n font-w-bolder">
                  {`# ${index + 1}`}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {notice.createdBy}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {notice.createdAt}
                </td>
              </tr>
            ))}
        </table>
      </div>
    </>
  );
};

export default SeenNoticeList;
