import moment from "moment";
import { getEnvironment } from "../../../../../../const/environment";
import apiService from "../../../../../../dataService/apiService";

export const downloadUniqueNews = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/case-management-service/api/${env.version}/${env.type}/unique-scroll/download`;

  const _params = {
    sourceName: params.sourceName,
    startDate: moment(params.startDate).format("ddd, DD MMM YY, hh:mm A"),
    endDate: moment(params.startDate).format("ddd, DD MMM YY, hh:mm A"),
    contentOfFirstScroll: params.contentOfFirstScroll,
    contentOfSecondScroll: params.contentOfSecondScroll,
    contentOfThirdScroll: params.contentOfThirdScroll,
    contentOfFourthScroll: params.contentOfFourthScroll,
  };
  const config = {
    url,
    data: _params,
    method: "POST",
    responseType: "blob",
  };
  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};

// const _params = {
//     sourceName: params.sourceName,
//     startDate: moment(params.startDate).format("ddd, DD MMM YY, hh:mm A"),
//     endDate: moment(params.startDate).format("ddd, DD MMM YY, hh:mm A"),
//     contentOfFirstScroll: params.contentOfFirstScroll,
//     contentOfSecondScroll: params.contentOfSecondScroll,
//     contentOfThirdScroll: params.contentOfThirdScroll,
//     contentOfFourthScroll: params.contentOfFourthScroll,
//   };
