import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useNewsSources, useUser } from "../../../../dataService/contexts";

import {
  getAllChannel,
  getAllNewspaper,
  getBySourceType,
} from "../../../../const/newsSources";

import "./styles.scss";

const SelectSourceName = ({
  type,
  currentSourceNames,
  handleSelect,
  currentCategory,
}) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const [state, setState] = useState();
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const permittedSourcelList = (sources, type, category) => {
    if (Number(type) === 2) {
      const channelCodes =
        !!user && !!user.availableTvChannels && user.availableTvChannels?.length
          ? user.availableTvChannels.map((tc) => tc.code)
          : [];

      if (category) {
        if (channelCodes.length) {
          const permittedChannels = sources.filter((c) =>
            channelCodes.includes(`${c.code}`)
          );

          return permittedChannels;
        } else return getBySourceType(2, newsSources);
      } else {
        return getBySourceType(2, newsSources);
      }
    } else {
      const newspaperCodes =
        !!user && !!user.availableNewsPapers && user.availableNewsPapers?.length
          ? user.availableNewsPapers.map((tc) => tc.code)
          : [];

      if (category) {
        if (newspaperCodes.length) {
          const permittedNewspaper = sources.filter((c) =>
            newspaperCodes.includes(`${c.code}`)
          );

          return permittedNewspaper;
        } else return getBySourceType(1, newsSources);
      } else return getBySourceType(1, newsSources);
    }
  };

  useEffect(() => {
    if (Number(type) === 2) {
      setState(permittedSourcelList(newsSources, type, currentCategory));
    } else setState(permittedSourcelList(newsSources, type, currentCategory));
  }, [type, currentCategory]);

  return (
    <Select
      isSearchable={true}
      options={state}
      isMulti
      className="source-select-container "
      classNamePrefix="source-select"
      getOptionLabel={(options) => `${options.name}`}
      getOptionValue={(options) => `${options.code}`}
      placeholder={Number(type) === 2 ? "Channel" : "Newspaper"}
      value={currentSourceNames}
      onChange={(e) => {
        handleSelect("sourceNames", e);
      }}
    />
  );
};

export default SelectSourceName;
