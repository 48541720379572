import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import {
  AuthProvider,
  CategoryProvider,
  NewsSourcesProvider,
  PageNameProvider,
  UserProvider,
} from "./dataService/contexts";

import "typicons.font/src/font/typicons.css";
import {
  NotificationProvider,
  OngoingRequestsProvider,
} from "./dataService/contexts";
import "./styles/index.scss";
import { TagsProvider } from "./dataService/contexts/tags.context";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <UserProvider>
      <OngoingRequestsProvider>
        <TagsProvider>
          <NewsSourcesProvider>
            <NotificationProvider>
              <PageNameProvider>
                <CategoryProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CategoryProvider>
              </PageNameProvider>
            </NotificationProvider>
          </NewsSourcesProvider>
        </TagsProvider>
      </OngoingRequestsProvider>
    </UserProvider>
  </AuthProvider>
);

serviceWorkerRegistration.register();
