import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  BookmarkButton,
  Category,
  NewsDetails,
  NewsKeywords,
  NewsShortContent,
  NewsTitle,
} from "..";

import { useNewsSources, useUser } from "../../dataService/contexts";

import { ROUTE } from "../../const";
import { getDate_3W2D3M2Y2h2mA } from "../../const/dateFormat";
import { getBySourceCode } from "../../const/newsSources";

import "./styles.scss";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source && source.name ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source.code, newsSources).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source, type, newsSources }) => {
  return source && source.name ? (
    <Link
      target="_blank"
      to={
        Number(type) === 1
          ? `/${ROUTE.LIVENEWSPAER}/${source.code}`
          : `/${ROUTE.LIVETVSCROLL}/${source.code}`
      }
      className="font-s-md font-w-bold text-white"
    >
      {getBySourceCode(source.code, newsSources).name}
    </Link>
  ) : (
    <></>
  );
};

const NewsCard = ({
  sr,
  news,
  keywords,
  type,
  pageMode = "",
  hasViewButton = true,
  children,
  bookmarkAble = true,
  categoryAction = () => {},
  tagsAction = () => {},
  sentimentAction = () => {},
}) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const [showDetails, setShowDetails] = useState(false);
  const handleShowDetails = () => setShowDetails((prev) => !prev);

  // const newsChannelHasPermission = (filterItem) =>
  //   user &&
  //   user.availableNewsPapers.length > 0 &&
  //   user.availableNewsPapers.filter((i) => i.code === filterItem.code).length >
  //     0;

  return (
    <>
      <div className="d-flex w-100">
        <div
          className="p-4 d-flex flex-row gap-4 bg-black__light rounded-start flex-grow-1"
          key={news.id}
        >
          <div className="d-flex flex-column  gap-4">
            <div>
              {pageMode !== "Single Source" && (
                <NewsSourceIcon
                  source={news.sourceName}
                  newsSources={newsSources}
                />
              )}
            </div>
            {bookmarkAble && <BookmarkButton news={news} />}
            <div></div>
          </div>

          <div className="d-flex flex-column flex-grow-1 gap-3">
            <div className="d-flex flex-column justify-content-between  gap-4 w-100">
              <div className="d-flex flex-wrap flex-row justify-content-between mt-2 gap-2">
                <div className="d-flex gap-3 align-self-center ">
                  {pageMode !== "Single Source" && (
                    <NewsSourceName
                      source={news.sourceName}
                      type={type}
                      newsSources={newsSources}
                    />
                  )}
                  <div className="text-light">{`#${sr}`}</div>
                </div>

                <div className="text-light font-s-nm text-nowrap font-w-bolder d-flex flex-column justify-content-between  align-items-end">
                  <div className="d-flex flex-row gap-3 flex-wrap">
                    {news.scrollNumber !== 0 && news.scrollNumber && (
                      <div className="align-self-center font-s-md text-white">
                        <span className="d-flex justify-content-center">
                          Scroll {news.scrollNumber}
                        </span>
                      </div>
                    )}
                    <div className="align-self-center font-s-n">
                      {getDate_3W2D3M2Y2h2mA(news.newsTimeAt)}
                    </div>

                    {hasViewButton && (
                      <div className="align-self-center d-flex flex-row gap-3">
                        <button
                          className="btn  btn-sm btn-primary px-4 py-2 rounded-3 font-s-md font-w-bolder"
                          type="button"
                          onClick={() => handleShowDetails()}
                        >
                          View
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <NewsKeywords keywords={keywords} />
            </div>

            <NewsTitle title={news.highlightedTitle || news.title} />
            {Number(type) === 2 && pageMode === "Single Source" ? (
              <div
                className="font-w-normal font-s-md text-white"
                style={{ letterSpacing: "1.25px" }}
                dangerouslySetInnerHTML={{
                  __html: `${news.content || "..."}`,
                }}
              />
            ) : (
              <NewsShortContent news={news} type={type} />
            )}
            {!!children ? children : <></>}
          </div>
        </div>
        {Number(type) === 1 ? (
          <div
            className="p-3 rounded-end col-3"
            style={{ background: "#2f323c" }}
          >
            <Category
              news={news}
              categoryAction={categoryAction}
              tagsAction={tagsAction}
              sentimentAction={sentimentAction}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      {showDetails && (
        <NewsDetails
          show={showDetails}
          data={news}
          handleClose={handleShowDetails}
          keyword={keywords}
          categoryAction={categoryAction}
          type={type}
          tagsAction={tagsAction}
          sentimentAction={sentimentAction}
        />
      )}
    </>
  );
};

export default NewsCard;
