import React, { useCallback, useEffect, useRef, useState } from "react";

import { usePageName, setPageName } from "../../dataService/contexts";
import {
  downloadKeywordsDoc,
  downloadKeywordsExcel,
  getDictionaryData,
} from "./dictionary.service";

import { KeywordTabs, DictionaryTable, AddNewWord } from "./components";

import { UpdatedPagination } from "../../components";

import DictionaryLoading from "./dictionaryLoading";
import {
  downloadCategoryDoc,
  downloadCategoryExcel,
} from "../Category/category.service";
import { CategoryDownloadDropdown } from "../Category/components";

const Dictionary = () => {
  const wrapperRef = useRef(null);
  const { dispatch: pageNameDispatch } = usePageName();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialParams = {
    size: 40,
    page: 0,
    activeStatus: 1,
  };
  const [params, setParams] = useState(initialParams);

  const initialState = {
    keywords: [],
    total: 0,
    page: 0,
  };
  const [state, setState] = useState(initialState);

  const handleState = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleParams = (key, value) => {
    if (key === "page") {
      wrapperRef.current.scrollIntoView();
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (key === "activeStatus") {
      wrapperRef.current.scrollIntoView();
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams(value);
    }
  };
  const handleDownload = (doctype) => {
    if (doctype === 1) {
      downloadKeywordsExcel({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `keywords.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    } else {
      downloadKeywordsDoc({ doctype: doctype }, (status, data) => {
        if (status === "success" && data) {
          const url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `keywords.docx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchKeywords = useCallback(() => {
    setLoading(true);
    getDictionaryData(params, (status, videos) => {
      if (status === "success") {
        const { data, total, page } = videos;

        handleState("keywords", data);
        handleState("total", total);
        handleState("page", page);
      }
      setLoading(false);
    });
  }, [params]);

  useEffect(() => {
    fetchKeywords();
  }, [params]);
  useEffect(() => {
    setPageName(pageNameDispatch, "Keywords");
  }, []);
  return (
    <div className="d-flex flex-column  p-1 gap-2" ref={wrapperRef}>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-row justify-content-between ">
          <CategoryDownloadDropdown handleDownload={handleDownload} />
          <button
            className="btn btn-md  btn-primary text-white rounded-4 px-5 py-3 font-w-bold font-s-md "
            onClick={handleShow}
          >
            Add New Word
          </button>
        </div>
        <KeywordTabs
          activeStatus={params.activeStatus}
          handleActiveStatus={handleParams}
        />
      </div>

      <div className="d-flex flex-column gap-4">
        <table className="table--report w-100">
          <tbody>{loading && <DictionaryLoading />}</tbody>
        </table>

        {state.keywords && <DictionaryTable dictionaryData={state} />}

        {state.total >= 40 ? (
          <UpdatedPagination
            itemsPerPage={40}
            total={state.total}
            action={handleParams}
          />
        ) : (
          <></>
        )}
        <div>
          <AddNewWord open={show} handleClose={handleClose} />
        </div>
      </div>
    </div>
  );
};

export default Dictionary;
