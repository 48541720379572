import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { DateTime } from "../../components";
import {
  ChannelSearch,
  OngoingRequests,
  VideoPopup,
  VideoThumbnail,
} from "./components";

import {
  addRequest,
  setPageName,
  useNewsSources,
  useOngoingRequests,
  usePageName,
  useUser,
} from "../../dataService/contexts";
import {
  getVideoContent,
  getVideoDownload,
  getVideoThumbnail,
} from "./videoContent.service";

import { dateIsValid, getDateTime_4Y2M2D12T } from "./../../const/dateFormat";
import { getBySourceCode } from "./../../const/newsSources";

import moment from "moment";
import LoadMoreIcon from "../../assets/loadMore";

const Video = () => {
  const location = useLocation();
  const autoPlay = !!(location.hash && location.hash === "#auto-play");

  const { dispatch: pageNameDispatch } = usePageName();

  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};

  const { dispatch: ongoingRequestsDispatch } = useOngoingRequests();

  const [searchState, setSearchState] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [videoHistory, setVideoHistory] = useState(null);

  const [URLParams, setURLParams] = useSearchParams();

  /*params, to search the video or download we have to send some payloads in APIs.*/
  const [searchParams, setSearchParams] = useState({
    channelName: URLParams.get("channelName")
      ? getBySourceCode(URLParams.get("channelName"), newsSources)
      : "",
    startDate: getDateTime_4Y2M2D12T(URLParams.get("startDate")),
    endDate: getDateTime_4Y2M2D12T(URLParams.get("endDate")),
  });

  /*this function will set values on state under the respective key of params*/
  const updateParams = (key, value) => {
    setSearchState(""); //when we will change any params , we will also reset our search state

    setSearchParams((prevFilters) => {
      return {
        ...prevFilters,
        [key]: value,
      };
    });
  };

  /* this function will handle the dates(start and end) of params*/
  const handleStartDate = (date) => updateParams("startDate", date);
  const handleEndDate = (date) => updateParams("endDate", date);

  /* this function will handle the selected tv channel*/
  const handleChannel = (channel) => updateParams("channelName", channel);

  const handleClose = () => {
    setSelectedVideo(null);
    fetchVideoHistory();
  };

  //video content
  const searchVideo = () => {
    setSearchState("ON_GOING");
    if (user)
      getVideoContent(searchParams, user, (status, data, _params) => {
        if (status === "success") {
          const newRequest = `${searchParams.channelName.code}#${searchParams.startDate}#${searchParams.endDate}#1`;
          addRequest(ongoingRequestsDispatch, newRequest);
          if (data.data[0].streamLink === null) {
            setSearchState("NOT_FOUND");
          } else {
            setURLParams(_params);
            setSearchState("CLICK_TO_PLAY");
            if (autoPlay) setSelectedVideo(data.data[0]);
          }
        } else {
          setSearchState("NOT_FOUND");
        }
      });
  };

  //fetch video history
  const fetchVideoHistory = () => {
    if (user)
      getVideoThumbnail(user, (status, data) => {
        if (status === "success") {
          setVideoHistory(data.data);
        }
      });
  };

  //handle download
  const handleDownload = () => {
    getVideoDownload(searchParams, (status, data, _params) => {
      if (status === "success" && data.url) {
        const blob = new Blob([data.url]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `video.mp4`;
        link.click();
      }
    });
  };

  useEffect(() => {
    setPageName(pageNameDispatch, "Video Repository Search");

    if (autoPlay) {
      searchVideo();
    }

    fetchVideoHistory();
  }, [user]);

  return (
    <div className="d-flex flex-column gap-3 p-1">
      <div className="d-flex flex-row gap-3 flex-wrap">
        <div style={{ minWidth: "100px" }}>
          <ChannelSearch
            handleChannel={handleChannel}
            currentChannel={searchParams.channelName}
          />
        </div>

        <div style={{ minWidth: "100px" }}>
          <DateTime
            handleDateWithTime={handleStartDate}
            placeholder="From"
            hasTime={true}
            date={searchParams.startDate}
          />
        </div>

        <div style={{ minWidth: "100px" }}>
          <DateTime
            handleDateWithTime={handleEndDate}
            placeholder="To"
            hasTime={true}
            date={searchParams.endDate}
          />
        </div>

        <button
          type="button"
          className="btn btn-sm btn-primary px-4 py-2 rounded-4"
          onClick={() => searchVideo()}
          disabled={
            searchParams.channelName === "" ||
            !dateIsValid(searchParams.startDate) ||
            !dateIsValid(searchParams.endDate) ||
            new Date(searchParams.startDate).getTime() >
              new Date(searchParams.endDate).getTime() ||
            moment
              .duration(
                moment(searchParams.endDate).diff(
                  moment(searchParams.startDate)
                )
              )
              .asHours() > 2 ||
            searchState === "ON_GOING"
              ? true
              : ""
          }
        >
          <div className="px-3 py-2 font-w-bold font-s-md">
            {searchState === "ON_GOING" ? <LoadMoreIcon /> : "Search"}
          </div>
        </button>
      </div>
      {moment
        .duration(
          moment(searchParams.endDate).diff(moment(searchParams.startDate))
        )
        .asHours() > 2 ? (
        <div className="alert alert-danger text-danger">
          Time range exceeds 2 hours !!! Provide time in between 2 hour range.
        </div>
      ) : (
        <></>
      )}

      {new Date(searchParams.startDate).getTime() >
        new Date(searchParams.endDate).getTime() &&
      searchParams.startDate !== "" &&
      searchParams.endDate !== "" ? (
        <div className="alert alert-danger text-danger">
          Invalid Date range!!! Please check and correct it and search Again.
        </div>
      ) : (
        <></>
      )}

      <OngoingRequests />

      {videoHistory && videoHistory.length > 0 && (
        <div className="mt-3">
          <div className="text-white font-w-bolder font-s-lg mb-3">
            Video Search History
          </div>
          <div className="overflow-auto d-flex flex-column">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill,  minmax(200px, 1fr))",
                gridAutoRows: "auto",
                gridGap: "1rem",
              }}
            >
              {videoHistory.map((item) => (
                <VideoThumbnail key={item.id} data={item} />
              ))}
            </div>
          </div>
        </div>
      )}
      {selectedVideo && (
        <VideoPopup
          handleClose={handleClose}
          show={!!selectedVideo}
          videoData={selectedVideo}
          handleDownload={handleDownload}
        />
      )}
      {/*{state.total >= 40 ? (*/}
      {/*  // <Pagination*/}
      {/*  //   page={params.page}*/}
      {/*  //   total={state.total}*/}
      {/*  //   action={handleParams}*/}
      {/*  // />*/}
      {/*  <UpdatedPagination*/}
      {/*    itemsPerPage={40}*/}
      {/*    total={state.total}*/}
      {/*    action={handleParams}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <></>*/}
      {/*)}*/}
    </div>
  );
};

export default Video;
