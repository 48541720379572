import React, { useState } from "react";
import { downloadReport } from "./downloadReport.service";

import LoadMoreIcon from "./../../assets/loadMore";
import moment from "moment/moment";

const DownloadReport = ({ date }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    downloadReport(date, (status, data) => {
      if (status === "success") {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${moment(date).format("DD-MM-YYYY")}_to_${moment(date).format(
            "DD-MM-YYYY"
          )}_scroll_report.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      setLoading(false);
    });
  };

  return (
    <button
      onClick={() => {
        handleDownload();
      }}
      className="btn btn-sm btn-primary px-4 py-2 font-s-n font-w-bold"
    >
      {loading ? <LoadMoreIcon /> : "Download Report"}
    </button>
  );
};

export default DownloadReport;
