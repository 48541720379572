import { getEnvironment } from "../../const/environment";
import apiService from "../../dataService/apiService";

export const seenNotice = (id, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/noticeseen/${id}`;

  apiService.get(url, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("danger", data);
    }
  });
};
