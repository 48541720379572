import React, { useState } from "react";
import moment from "moment";

import { filter } from "./service/filter.servie";

import { DateTime, Select } from "../../../../components";
import { channelList } from "../../../../const";

import "react-datepicker/dist/react-datepicker.css";

const FilterForm = ({ handleVideoLink,handleMetaData }) => {
  const [selectedChannel, setSelectedChannel] = useState("");
  const updateChannel = (channelName) => setSelectedChannel(channelName);

  const [startDateWithTime, setStartDateWithTime] = useState("");
  const updateStartDateWithTime = (dateWithTime) => {
    const startDate = moment(dateWithTime).format("YYYY-MM-DD");
    const startTime = moment(dateWithTime).format("HH:mm:ss");

    setStartDateWithTime(`${startDate} ${startTime}`);
  };

  const [endDateWithTime, setEndDateWithTime] = useState("");
  const updateEndDateWithTime = (dateWithTime) => {
    const endDate = moment(dateWithTime).format("YYYY-MM-DD");
    const endTime = moment(dateWithTime).format("HH:mm:ss");

    setEndDateWithTime(`${endDate} ${endTime}`);

  };

  const handleFilter = () => {
    handleMetaData(null)
    filter(
      {
        startDate: startDateWithTime,
        endDate: endDateWithTime,
          channelName: selectedChannel,
         
      },
      (status, data) => {
        if (status === "success" ) {
          handleVideoLink(data)
          if(data.length>0){
            handleMetaData({
              selectedChannel, startDateWithTime, endDateWithTime
            })
          }
          
        }
      }
    );
  };

  return (
    <div className="d-flex flex-column gap-4">
      <Select options={channelList} handleSelect={updateChannel} />
      <DateTime
        handleDateWithTime={updateStartDateWithTime}
        placeholder="Select Start Date"
        hasTime={true}
      />
      <DateTime
        handleDateWithTime={updateEndDateWithTime}
        placeholder="Select End Date"
        hasTime={true}
      />
      <div>
        <button
          className="btn btn-primary rounded-pill p-0 py-2 px-5 text-white"
          onClick={() => handleFilter()}
        >
          {" "}
          Search
        </button>
      </div>
    </div>
  );
};

export default FilterForm;
