import React, { useState, useEffect } from "react";

import Line from "./Line";

import { useNewsSources } from "../../../../dataService/contexts";

import { getBySourceName } from "./../../../../const/newsSources";

import "./style.scss";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceName(source, newsSources).icon}
        style={{
          height: "4rem",
          width: "4rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source, newsSources }) => {
  return source ? (
    <div className="font-s-md font-w-bold text-white">
      {getBySourceName(source, newsSources).name}
    </div>
  ) : (
    <></>
  );
};

export function Card({ source }) {
  const [lineData, setLineData] = useState([]);

  const { sourceName, count } = source || {};
  const { aggregated, dailyCount } = count || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState;

  const handleLineData = () => {
    const _keys = Object.keys(dailyCount);
    const series = _keys.map((item) => ({
      name: item.toLocaleUpperCase(),
      data: dailyCount[item],
    }));
    setLineData(series);
  };

  useEffect(() => {
    if (dailyCount) handleLineData();
  }, [dailyCount]);

  return (
    <div className="source-card p-4 rounded-4">
      <div className="d-flex gap-4 align-items-center">
        <NewsSourceIcon source={sourceName} newsSources={newsSources} />
        <div className="d-flex flex-column gap-2">
          <NewsSourceName source={sourceName} newsSources={newsSources} />
          <div className="d-flex gap-3">
            <div className="d-flex gap-1 align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8.51309 3.27797C8.3669 3.10197 8.14889 3 7.91881 3C7.68872 3 7.47071 3.10197 7.32452 3.27797L0.63881 12.1083C0.469986 12.3313 0.44283 12.6296 0.568664 12.8789C0.694498 13.1282 0.951722 13.2857 1.2331 13.2857H14.6045C14.8859 13.2857 15.1431 13.1282 15.2689 12.8789C15.3948 12.6296 15.3676 12.3313 15.1988 12.1083L8.51309 3.27797Z"
                  fill="#3DBAA2"
                />
              </svg>
              <span
                style={{ color: "#3dbaa2" }}
                className="font-w-bold font-s-md"
              >
                {aggregated.positive}
              </span>
            </div>
            <span
              style={{ color: "#ff7a68" }}
              className="font-w-bold font-s-md"
            >
              {aggregated.negative}
            </span>
            <span
              style={{ color: "#ff9f38" }}
              className="font-w-bold font-s-md"
            >
              {aggregated.neutral}
            </span>
          </div>
        </div>
      </div>
      <div>{lineData.length ? <Line lineData={lineData} /> : <></>}</div>
    </div>
  );
}
