import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

import { editNotice } from "./editNotice.service";

const EditNotice = ({ selectedNotice, removeSelectedNotice }) => {
  const [notices, setNotice] = useState(selectedNotice.title);
  const [detail, setDetail] = useState(selectedNotice.detail);

  const updateNotice = (id) => {
    const params = {
      title: notices,
      detail: detail,
    };

    editNotice(id, params, (status, data) => {
      if (status === "success") {
        removeSelectedNotice();
        window.location.reload(true);
      }
    });
  };
  return (
    <div>
      <Modal
        show={!!selectedNotice}
        onHide={removeSelectedNotice}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">Edit Notice</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Title</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Category Name"
                  value={notices}
                  onChange={(e) => setNotice(e.target.value)}
                />
              </div>
              <div className="text-light font-s-md">Description</div>
              <div style={{ minWidth: "250px" }}>
                <textarea
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary "
            onClick={() => updateNotice(selectedNotice.id)}
          >
            Edit Notice
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditNotice;
