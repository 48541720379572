import apiService from "../../../../dataService/apiService";
import { getEnvironment } from "../../../../const/environment";

export const getKeywordsAlert = (done = () => {}) => {
  const env = getEnvironment();

  const url = `${env.protocol}://${env.baseUrl}:${env.port}/keyword-count-service/api/${env.version}/${env.type}/keywords/all-keywords-by-type?page=0&size=20`;

  apiService.get(
    url,
    (status, _data) => {
      if (status > 199 && status < 300) {
        done("success", _data);
      } else {
        done("error");
      }
    },
    {}
  );
};
