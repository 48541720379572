import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useNewsSources, useUser } from "../../dataService/contexts";
import { getBySourceType } from "../../const/newsSources";

const TvSourceDropdown = ({ handleChannel, currentChannel, bg = true }) => {
  const { state: userState } = useUser();
  const { user } = userState || {};
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const permittedChannelList = () => {
    const channelCodes =
      !!user && !!user.availableTvChannels && user.availableTvChannels?.length
        ? user.availableTvChannels.map((tc) => tc.code)
        : [];

    if (channelCodes.length) {
      const permittedChannels = newsSources.filter((c) =>
        channelCodes.includes(`${c.code}`)
      );

      return permittedChannels;
    } else return getBySourceType(2, newsSources);
  };

  const channelList = permittedChannelList(newsSources);

  const handleChange = (e) => {
    handleChannel("sourceName", e);
  };

  return (
    <Select
      isSearchable={true}
      options={
        channelList &&
        channelList.length &&
        channelList.map((item) => ({
          id: item.id,
          value: item.code,
          label: item.name,
        }))
      }
      className="channel-select-container "
      classNamePrefix={`${bg === true ? "channel-select" : "tags-select"}`}
      getOptionLabel={(options) => `${options.label}`}
      placeholder="Tv Channels"
      defaultValue={
        currentChannel
          ? {
              value: currentChannel,
              label: currentChannel,
            }
          : null
      }
      onChange={(e) => {
        if (e !== null) {
          handleChange(e.value);
        } else {
          handleChange("");
        }
      }}
      isClearable
    />
  );
};

export default TvSourceDropdown;
