import { createContext, useContext, useMemo, useReducer } from "react";

import actionTypes from "../actionTypes";
import apiService from "../apiService";

import { getEnvironment } from "../../const/environment";

const TagsContext = createContext();

const initialState = {
  words: [],
};

const tagsReducer = function (state, action) {
  switch (action.type) {
    case actionTypes.tagsRequest:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };

    case actionTypes.tagsSuccess:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",
        tags: action.tags,
      };

    case actionTypes.tagsFail:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.error,
      };

    default:
      break;
  }
};

export function TagsProvider(props) {
  const [state, dispatch] = useReducer(tagsReducer, initialState);
  const value = useMemo(() => [state, dispatch], [state]);

  return <TagsContext.Provider value={value} {...props} />;
}

export function useTags() {
  const context = useContext(TagsContext);

  if (!context) {
    throw new Error("use tags must be used within a category provider");
  }

  const [state, dispatch] = context;

  return {
    state,
    dispatch,
  };
}

const request = () => ({
  type: actionTypes.tagsRequest,
});

const success = (tags) => ({
  type: actionTypes.tagsSuccess,
  tags,
});

const fail = (errorMsg) => ({
  type: actionTypes.tagsFail,
  errorMsg,
});

export const getTags = (dispatch, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/${env.version}/${env.type}/report/tag-words/all?size=100000`;

  dispatch(request());

  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
        dispatch(success(data.words));
      } else {
        done("fail", data);
        dispatch(fail(data));
      }
    },
    {},
    {}
  );
};
