import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  setPageName,
  useNewsSources,
  usePageName,
  useUser,
} from "../../dataService/contexts";
import { getNewspaperNews } from "./newspaperLive.service";

import { LoadingComponent, NewsCard } from "../../components";
import { Actions } from "./components";

import LoadMoreIcon from "../../assets/loadMore.jsx";
import { getBySourceCode } from "../../const/newsSources";

const SingleNewspaperLive = () => {
  let { newspaperName } = useParams();

  const { dispatch: pageNameDispatch } = usePageName();

  const { state: userState } = useUser();
  const { user } = userState || {};

  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  //data state
  const initialState = {
    news: [],
    newsSources: [],
    total: 0,
  };
  const [state, setState] = useState(initialState);
  const handleState = (key, value) => {
    if (key === "news") {
      setState((prev) => ({
        ...prev,
        [key]: [...prev.news, ...value],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };
  const resetState = () => {
    setState({
      ...initialState,
    });
  };
  const updateCategory = (categoryTypes, contentId) => {
    if (state.news && state.news.length) {
      const index = state.news.findIndex(
        (news) => news.contentId === contentId
      );
      state.news[index].categoryTypes = categoryTypes;
      setState((prev) => ({ ...prev, news: Array(...state.news) }));
    }
  };

  const updateSentiment = (sentiment, contentId) => {
    if (state.news && state.news.length) {
      const index = state.news.findIndex(
        (news) => news.contentId === contentId
      );
      state.news[index].sentiment = sentiment;
      setState((prev) => ({ ...prev, news: Array(...state.news) }));
    }
  };

  const updateTags = (tags, contentId) => {
    if (state.news && state.news.length) {
      const index = state.news.findIndex(
        (news) => news.contentId === contentId
      );
      state.news[index].tags = tags;
      setState((prev) => ({ ...prev, news: Array(...state.news) }));
    }
  };

  //params for this page
  const initialParams = {
    name: [newspaperName],
    type: 1,
    isKeywordSearch: false,
    page: 0,
    startDate: "",
    endDate: "",
  };
  const [params, setParams] = useState(initialParams);
  const handleParams = (key, value) => {
    if (key === "isKeywordSearch") {
      setParams((prev) => {
        resetState();
        return {
          ...prev,
          page: 0,
          [key]: value,
        };
      });
    } else {
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  //searchbox params
  const initialSearchBoxParams = {
    searchText: "",
    sourceNames: [],
    startDate: "",
    endDate: "",
    sourceCategory: true,
  };
  const [searchBoxParams, setSearchBoxParams] = useState({
    ...initialSearchBoxParams,
  });
  const handleSearchBoxParams = (key, value) => {
    setSearchBoxParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSearch = () => {
    setParams((prev) => ({
      ...prev,
      page: 0,
      keys: searchBoxParams.searchText,
      startDate: searchBoxParams.startDate,
      endDate: searchBoxParams.endDate,
      name: searchBoxParams.sourceNames.map((source) => source.code),
    }));
  };

  //laoding
  const [loading, setLoading] = useState(false);
  const handleLoading = (loadingState) => {
    setLoading(loadingState);
  };

  // get data of newspaper
  const fetch = useCallback(() => {
    handleLoading(true);

    getNewspaperNews(user, { ...params }, (status, data) => {
      if (status === "success") {
        const { news, newsPaperNames, total } = data;

        handleState("news", news);
        handleState("newsSources", newsPaperNames);
        handleState("total", total);
      }
      handleLoading(false);
    });
  }, [params, user]);

  //get live data of newspaper
  const fetchLive = useCallback(() => {
    resetState();
    handleLoading(true);

    getNewspaperNews(
      user,
      {
        ...params,
        page: 0,
        size: Number(params.page + 1) * 40,
      },
      (status, data) => {
        if (status === "success") {
          const { news, newsPaperNames, total } = data;

          handleState("news", news);
          handleState("newsSources", newsPaperNames);
          handleState("total", total);
        }
        handleLoading(false);
      }
    );
  }, [params, user]);

  useEffect(() => {
    if (user) fetch();

    const timer = setInterval(() => {
      if (user) fetchLive();
    }, 5000 * 6); // this timer will git the fetchNews after every 5 min

    return () => clearTimeout(timer);
  }, [user, params, fetch]);

  // Source set
  useEffect(() => {
    if (newspaperName && newsSources.length) {
      setPageName(
        pageNameDispatch,
        `${getBySourceCode(newspaperName, newsSources)?.name} Live`
      );
    }
  }, [newspaperName, newsSources]);

  return user ? (
    <div className="d-flex flex-column gap-3 p-1">
      <Actions
        handleSearch={handleSearch}
        sourceType={params.type}
        handleSearchBoxParams={handleSearchBoxParams}
        searchBoxParams={searchBoxParams}
        redirect={true}
      />

      <div className="form-check d-flex gap-3 my-3 align-items-center">
        <input
          className="form-check-input"
          type="checkbox"
          value={params.isKeywordSearch}
          id="alerted_news_only"
          checked={params.isKeywordSearch}
          onChange={(e) => {
            handleParams("isKeywordSearch", e.target.checked);
          }}
        />
        <label
          className="form-check-label text-white font-s-md"
          for="alerted_news_only"
        >
          Alerted News Only
        </label>
      </div>

      {state.news.length ? (
        <div className="d-flex flex-column gap-3">
          {state.news.map((item, index) => {
            return (
              <NewsCard
                sr={index + 1}
                news={item}
                keywords={
                  item.matchedKeywordList
                    ? item.matchedKeywordList.split(",")
                    : []
                }
                type={params.type}
                tags=""
                name={params.name}
                pageMode="Single Source"
                categoryAction={updateCategory}
                sentimentAction={updateSentiment}
                tagsAction={updateTags}
              />
            );
          })}
        </div>
      ) : (
        <></>
      )}

      {loading && (
        <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
          <LoadingComponent size={1} />
        </div>
      )}
      {state.news.length && state.news.length !== state.total ? (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-lg btn-primary font-w-bolder px-5 py-3"
            onClick={() => {
              handleParams("page", params.page + 1);
            }}
            disabled={loading ? "true" : ""}
          >
            {loading ? <LoadMoreIcon /> : "LOAD MORE"}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

export default SingleNewspaperLive;
