import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { getBySourceCode, getBySourceName } from "../../const/newsSources";
import { getDateTime_12T2D3M2Y } from "../../const/dateFormat";
import {
  setPageName,
  useNewsSources,
  usePageName,
  useUser,
} from "../../dataService/contexts";
import {
  getNewspaperReport,
  removeNewspaperReport,
} from "../../pages/NewspaperReportOverviewDetails/newspaperReport.service";
import { getAnalyticalNewspaperReport } from "./analyticsPopup.service";
import LoadMoreIcon from "../../assets/loadMore";
import { LoadingComponent } from "../index";
import { Link } from "react-router-dom";

const NewsSourceIcon = ({ source, newsSources }) => {
  return source ? (
    <div>
      <img
        alt="newspaper-logo"
        src={getBySourceCode(source, newsSources).icon}
        style={{
          height: "3rem",
          width: "3rem",
          borderRadius: "12rem",
        }}
      />
    </div>
  ) : (
    <></>
  );
};

const NewsSourceName = ({ source, newsSources }) => {
  return source && source ? (
    <div className="font-s-md font-w-bold text-white">
      {getBySourceCode(source, newsSources).name}
    </div>
  ) : (
    <></>
  );
};

const AnalyticsPopup = ({
  dataPointIndex,
  handleClose,
  show,
  seriesIndex,
  type,
  param,
  sentiment,
}) => {
  const { state: newsSourcesState } = useNewsSources();
  const { newsSources } = newsSourcesState || {};
  const [loading, setLoading] = useState(false);

  const [markingReports, setMarkingReports] = useState([]);
  const [negativeReport, setNegativeReport] = useState([]);
  const [positiveReport, setPositiveReport] = useState([]);
  const initialState = {
    reports: [],
    total: 0,
    page: 0,
  };
  const [state, setState] = useState({ ...initialState });
  const handleState = (key, value) => {
    if (key === "reports") {
      setState((prev) => ({
        ...prev,
        [key]: [...value],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const initialParams = {
    startDate: param.startDate,
    endDate: param.endDate,
    size: 100000000,
    page: 0,
    category: null,
    sentiment: "",
    sourceName: seriesIndex,
  };
  //params
  const [params, setParams] = useState(initialParams);
  const handleParams = (key, value) => {
    if (key === "page") {
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        [key]: value,
        page: 0,
      }));
    }
  };
  console.log(seriesIndex);
  const fetchReports = useCallback(() => {
    setLoading(true);

    getAnalyticalNewspaperReport(
      {
        ...params,
      },
      type === 2 ? getBySourceCode(seriesIndex, newsSources).name : seriesIndex,
      sentiment,
      type,
      (status, data1) => {
        if (status === "success") {
          if (type === 2) {
            if (data1) {
              let _reports = [];
              if (data1.negative) {
                setNegativeReport(data1.negative.reports);
                data1.negative.reports.forEach((item) => {
                  let nh = item.highlights || [];
                  nh.forEach((i) =>
                    _reports.push({
                      ...i,
                      newsTimeAt: item?.newsContent?.newsTimeAt,
                    })
                  );
                });
              }
              if (data1.positive) {
                setPositiveReport(data1.positive.reports);
                data1.positive.reports.forEach((item) => {
                  let ph = item.highlights || [];
                  ph.forEach((i) =>
                    _reports.push({
                      ...i,
                      newsTimeAt: item?.newsContent?.newsTimeAt,
                    })
                  );
                });
              }

              setMarkingReports(_reports);
            }
          } else if (type === 4) {
            const { data, page, size } = data1;
            handleState("reports", data);
            handleState("total", size);
            handleState("page", page);
          } else {
            setParams(params);
            const { reports, total, page } = data1;
            handleState("reports", reports);
            handleState("total", total);
            handleState("page", page);
          }
        }
        setLoading(false);
      }
    );
  }, [params, param, sentiment, show, seriesIndex, type]);

  //this use effect function is used to make the api call when a user gets to this route
  useEffect(() => {
    if (show) {
      fetchReports();
    }
  }, [show, fetchReports, params, param, sentiment, seriesIndex, type]);
  console.log(state.reports);
  return state.reports ? (
    <Modal
      size="xl"
      centered
      onHide={() => {
        handleClose();
        setState((prev) => ({
          ...prev,
          ["reports"]: [],
        }));
        setMarkingReports();
      }}
      className="newspaper-details"
      show={show}
    >
      <Modal.Header closeButton>
        <div className="d-flex flex-wrap flex-row gap-5">
          <div className="d-flex gap-3 align-items-center">
            {/*<NewsSourceIcon*/}
            {/*  source={*/}
            {/*    type === 1*/}
            {/*      ? getBySourceName(seriesIndex, newsSources).code*/}
            {/*      : seriesIndex*/}
            {/*  }*/}
            {/*  newsSources={newsSources}*/}
            {/*/>*/}
            <div>
              <div>
                {type === 1 ? (
                  <NewsSourceName
                    source={
                      type === 1
                        ? getBySourceName(seriesIndex, newsSources).code
                        : seriesIndex
                    }
                    newsSources={newsSources}
                  />
                ) : (
                  <></>
                )}
                {type === 2 ? (
                  <NewsSourceName
                    source={
                      type === 1
                        ? getBySourceName(seriesIndex, newsSources).code
                        : seriesIndex
                    }
                    newsSources={newsSources}
                  />
                ) : (
                  <></>
                )}
                {type === 3 ? (
                  <div className="font-s-md font-w-bold text-white">
                    {seriesIndex}
                  </div>
                ) : (
                  <></>
                )}
                {type === 4 ? (
                  <div className="font-s-md font-w-bold text-white">
                    {seriesIndex}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div
          className="d-flex flex-column overflow-auto gap-4 "
          style={{ maxHeight: "300px" }}
        >
          <div>
            {type === 1 && state.reports && state.reports.length ? (
              state.reports.map((item, index) => (
                <div className="d-flex flex-row gap-2">
                  <div className="text-light font-s-lg">{`#${index + 1}`}</div>
                  <Link
                    className="text-white font-s-lg"
                    to={`${item.sourceURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </Link>
                </div>
              ))
            ) : (
              <></>
            )}
            {type === 2 && markingReports && markingReports.length ? (
              markingReports.map((item, index) => (
                <div className="d-flex flex-row gap-2">
                  <div className="text-light font-s-lg">{`#${index + 1}`}</div>
                  <div className="text-white font-s-lg">
                    {item.highlightedContent}
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
            {type === 4 && state.reports && state.reports.length ? (
              state.reports.map((item, index) => (
                <div className="d-flex flex-row gap-2">
                  <div className="text-light font-s-lg">{`#${index + 1}`}</div>
                  <div className="text-white font-s-lg">{item.marking}</div>
                </div>
              ))
            ) : (
              <></>
            )}
            {type === 3 && state.reports && state.reports.length ? (
              state.reports.map((item, index) => (
                <div className="d-flex flex-row gap-2">
                  <div className="text-light font-s-lg">{`#${index + 1}`}</div>
                  <Link
                    className="text-white font-s-lg"
                    to={`${item.sourceURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.title}
                  </Link>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          {loading && (
            <div className="d-flex flex-column overflow-auto bg-black__light rounded-4 p-4 shadow">
              <LoadingComponent size={1} />
            </div>
          )}
          {/*{state.reports &&*/}
          {/*state.reports.length &&*/}
          {/*state.reports.length !== state.total ? (*/}
          {/*  <div className="d-flex justify-content-center">*/}
          {/*    <button*/}
          {/*      className="btn btn-lg btn-primary font-w-bolder px-5 py-3"*/}
          {/*      onClick={() => {*/}
          {/*        handleParams("page", params.page + 1);*/}
          {/*      }}*/}
          {/*      disabled={loading ? "true" : ""}*/}
          {/*    >*/}
          {/*      {loading ? <LoadMoreIcon /> : "LOAD MORE"}*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <></>*/}
          {/*)}*/}
        </div>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
};

export default AnalyticsPopup;
