import React from "react";

const NewspaperLiveIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 9.5C4.33579 9.5 4 9.83579 4 10.25C4 10.6642 4.33579 11 4.75 11H6.25C6.66421 11 7 10.6642 7 10.25C7 9.83579 6.66421 9.5 6.25 9.5H4.75Z"
        fill="#808191"
      />
      <path
        d="M9.25 9.5C8.83579 9.5 8.5 9.83579 8.5 10.25C8.5 10.6642 8.83579 11 9.25 11H10.75C11.1642 11 11.5 10.6642 11.5 10.25C11.5 9.83579 11.1642 9.5 10.75 9.5H9.25Z"
        fill="#808191"
      />
      <path
        d="M13.75 9.5C13.3358 9.5 13 9.83579 13 10.25C13 10.6642 13.3358 11 13.75 11H15.25C15.6642 11 16 10.6642 16 10.25C16 9.83579 15.6642 9.5 15.25 9.5H13.75Z"
        fill="#808191"
      />
      <path
        d="M4.75 13.5C4.33579 13.5 4 13.8358 4 14.25C4 14.6642 4.33579 15 4.75 15H6.25C6.66421 15 7 14.6642 7 14.25C7 13.8358 6.66421 13.5 6.25 13.5H4.75Z"
        fill="#808191"
      />
      <path
        d="M9.25 13.5C8.83579 13.5 8.5 13.8358 8.5 14.25C8.5 14.6642 8.83579 15 9.25 15H10.75C11.1642 15 11.5 14.6642 11.5 14.25C11.5 13.8358 11.1642 13.5 10.75 13.5H9.25Z"
        fill="#808191"
      />
      <path
        d="M13.75 13.5C13.3358 13.5 13 13.8358 13 14.25C13 14.6642 13.3358 15 13.75 15H15.25C15.6642 15 16 14.6642 16 14.25C16 13.8358 15.6642 13.5 15.25 13.5H13.75Z"
        fill="#808191"
      />
      <path
        d="M12 3.5C12 3.08579 12.3358 2.75 12.75 2.75H15.25C15.6642 2.75 16 3.08579 16 3.5C16 3.91421 15.6642 4.25 15.25 4.25H12.75C12.3358 4.25 12 3.91421 12 3.5Z"
        fill="#808191"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 5.28595 0 2.92893 1.46447 1.46447C2.92893 0 5.28595 0 10 0C14.714 0 17.0711 0 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20C5.28595 20 2.92893 20 1.46447 18.5355C0 17.0711 0 14.714 0 10ZM10 18.5C7.60057 18.5 5.9519 18.4968 4.71351 18.3303C3.51828 18.1696 2.93221 17.882 2.52513 17.4749C2.11805 17.0678 1.83038 16.4817 1.66968 15.2865C1.50319 14.0481 1.5 12.3994 1.5 10C1.5 8.83638 1.50075 7.84932 1.52051 7H18.4795C18.4993 7.84932 18.5 8.83638 18.5 10C18.5 12.3994 18.4968 14.0481 18.3303 15.2865C18.1696 16.4817 17.882 17.0678 17.4749 17.4749C17.0678 17.882 16.4817 18.1696 15.2865 18.3303C14.0481 18.4968 12.3994 18.5 10 18.5ZM18.4113 5.5H1.58875C1.61015 5.21999 1.63672 4.95871 1.66968 4.71351C1.83038 3.51828 2.11805 2.93221 2.52513 2.52513C2.93221 2.11805 3.51828 1.83038 4.71351 1.66968C5.9519 1.50319 7.60057 1.5 10 1.5C12.3994 1.5 14.0481 1.50319 15.2865 1.66968C16.4817 1.83038 17.0678 2.11805 17.4749 2.52513C17.882 2.93221 18.1696 3.51828 18.3303 4.71351C18.3633 4.95871 18.3898 5.21999 18.4113 5.5Z"
        fill="#808191"
      />
    </svg>
  );
};

export default NewspaperLiveIcon;
