import React, { useState } from "react";
import { ChannelSearch } from "../../../Video/components";
import {
  DateCalender,
  DateTime,
  TvSourceDropdown,
} from "../../../../components";
import { dateIsValid } from "../../../../const/dateFormat";
import moment from "moment/moment";
import LoadMoreIcon from "../../../../assets/loadMore";
import {
  CategoryOptions,
  Sentiment,
} from "../../../NewspaperReport/components";
import NewspaperSourceDropdown from "../../../NewspaperReport/components/NewspaperSourceDropdown";

const VideoSearch = ({ onChange, loading, params, autoPlay }) => {
  const [state, setState] = useState({
    start: params.start,
    end: params.end,
    categoryName: params.categoryName,
    sentiment: params.sentiment,
    sourceName: params.sourceName,
  });
  const handleCategory = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        ["categoryName"]: value.categoryName,
      };
    });
  };

  const handleSourceName = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleState = (key, date) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: date,
      };
    });
  };
  const handleSubmit = () => {
    onChange("key", state);
  };

  return (
    <div className="d-flex flex-row gap-3 flex-wrap">
      <div style={{ minWidth: "100px" }}>
        <DateTime
          placeholder={"From"}
          date={moment(state.start).format("YYYY-MM-DD, hh:mm A")}
          handleDateWithTime={(e) => {
            handleState("start", e);
          }}
          hasTime={true}
        />
      </div>

      <div style={{ minWidth: "100px" }}>
        <DateTime
          placeholder={"To"}
          date={moment(state.end).format("YYYY-MM-DD, hh:mm A")}
          handleDateWithTime={(e) => {
            handleState("end", e);
          }}
          hasTime={true}
        />
      </div>
      {state ? (
        <div className="d-flex flex-row gap-3">
          <CategoryOptions
            handleCategory={handleCategory}
            seletedCategory={
              autoPlay
                ? { categoryName: params.categoryName }
                : params.categoryName
            }
          />
          <div style={{ minWidth: "100px" }}>
            <TvSourceDropdown
              currentChannel={params.sourceName}
              handleChannel={handleSourceName}
            />
          </div>
          <div style={{ minWidth: "100px" }}>
            <Sentiment
              currentSentiment={params.sentiment}
              handleSentiment={handleState}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <button
        type="button"
        className="btn btn-sm btn-primary px-4 py-2 rounded-4"
        onClick={() => handleSubmit()}
        disabled={loading}
      >
        <div className="px-3 py-2 font-w-bold font-s-md">
          {loading ? <LoadMoreIcon /> : "Search"}
        </div>
      </button>
    </div>
  );
};

export default VideoSearch;
