import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, useAuth } from "../../../../dataService/contexts";

import "./style.scss";
import { parseJwt } from "../../../../utilities";
import OtpAlert from "../../../../components/OtpAlert";

const hasUserCodePermission = (userCodePermission, token) => {
  const decodedJwt = parseJwt(token);

  return userCodePermission && decodedJwt.group && decodedJwt.group
    ? !!decodedJwt.group.includes(userCodePermission)
    : false;
};

const SignIn = () => {
  const [showOtp, setShowOtp] = useState(false);
  const [token, setToken] = useState(null);
  const handleOtp = () => {
    setShowOtp((prevState) => !prevState);
  };
  const { state: authState } = useAuth();
  let navigate = useNavigate();

  const { dispatch: authDispatch } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleEmail = (username) => {
    setErrorMsg("");
    setUsername(username.target.value);
  };

  const handlePassword = (password) => {
    setErrorMsg("");
    setPassword(password.target.value);
  };

  const handleSubmit = () => {
    setErrorMsg("");

    getAuth(
      authDispatch,
      { username: username, password: password },
      (status, data) => {
        if (status === "success" && "token") {
          setShowOtp(true);
          setToken(data.access_token);
        } else {
          setErrorMsg(data);
        }
      }
    );
  };

  return (
    <div className="d-flex flex-column justify-content-evenly gap-5 ">
      <div></div>
      <div
        className=" d-flex flex-column gap-3  justify-content-center p-5 "
        style={{ background: "#ffffff" }}
      >
        {errorMsg && (
          <div className="alert alert-warning font-w-bolder font-s-n rounded-4">
            Wrong username or Password
          </div>
        )}
        <div className="d-flex flex-column gap-2 justify-content-center">
          <div className="text-black-50 font-s-xlg font-w-bold text-center">
            Management Dashboard
          </div>
          <div className="text-black-50 font-s-lg  text-center">
            Sign In to continue
          </div>
        </div>

        <div className=" d-flex flex-column ">
          <div className="form-group">
            <input
              style={{
                height: "unset",
              }}
              className="form-control is-valid p-0 p-4 font-s-n font-w-bolder"
              id="userName"
              type="text"
              placeholder={username}
              value={username}
              onChange={handleEmail}
              required
              autoComplete="off"
            />
            <label htmlFor="userName" className="px-3 font-s-xsm">
              User Name
            </label>
          </div>

          <div className=" form-group text-white">
            <input
              style={{
                height: "unset",
              }}
              className="p-0 p-4 font-s-n font-w-bolder text-white"
              id="password"
              type="password"
              placeholder=""
              value={password}
              onChange={handlePassword}
              required
            />
            <label htmlFor="password" className="px-3 font-s-xsm">
              Password
            </label>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center gap-4">
            {/*<div className="d-flex flex-row align-self-end gap-2">*/}
            {/*  <span>{GetForgotPasswordIcon()}</span>*/}
            {/*  <Link to="" className="font-s-n font-w-fat">*/}
            {/*    forgot password?*/}
            {/*  </Link>*/}
            {/*</div>*/}

            <button
              type="submit"
              className="font-s-lg font-w-bold w-100 btn  btn-lg text-white p-0 py-3 rounded-1"
              style={{ background: "#1bdbe0" }}
              onClick={() => handleSubmit()}
            >
              <div className="py-1">Sign In</div>
            </button>
          </div>
        </div>
        <OtpAlert
          show={showOtp}
          userId={token}
          handleShow={handleOtp}
          handleLogin={handleSubmit}
        />
      </div>
      <div></div>
    </div>
  );
};

export { SignIn };
