import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { KeywordDetails } from "../KeywordsDetails";

import { getKeywordsAlert } from "./keywordsAlert.service";

import { getDateTime_12T2D3M2Y } from "../../../../const/dateFormat";
import "./style.scss";

const getTimeRange = () => {
  const currentTimeDate = moment(new Date()).toISOString();
  const prevTimeDate = moment(
    moment(currentTimeDate).startOf("day")
  ).toISOString();

  return {
    startDate: prevTimeDate,
    endDate: currentTimeDate,
  };
};

const getQueryString = (data) => {
  const dateRange = getTimeRange();
  let queryString = `?keys=${data.keyword}&start=${dateRange.startDate}&end=${dateRange.endDate}`;

  return queryString;
};

const KeywordsAlert = () => {
  const [keywordsAlert, setKeywordsAlert] = useState([]);
  const handleKeywordsAlert = (alerts) => setKeywordsAlert(alerts);

  const [activeKeyword, setActiveKeyword] = useState("");
  const handleActiveKeyword = (keyword) => {
    keyword === activeKeyword
      ? setActiveKeyword("")
      : setActiveKeyword(keyword);
  };

  useEffect(() => {
    const fetchKeywordsAlert = () => {
      getKeywordsAlert((status, data) => {
        if (status === "success") {
          handleKeywordsAlert(data.keywordCounts);
        }
      });
    };

    fetchKeywordsAlert(); //initial

    const timer = setInterval(() => {
      fetchKeywordsAlert();
    }, 5000 * 60);

    return () => clearTimeout(timer);
  }, []);

  return keywordsAlert.length > 0 ? (
    <div className="d-flex flex-column flex-grow-1 gap-4 ">
      <div className="d-flex gap-4 flex-wrap flex-column">
        <div className="d-flex flex-column gap-3">
          <div className=" text-white font-s-lg font-w-bold">
            Top Keywords Alert
          </div>
          <div className="d-flex flex-column gap-1">
            <div className=" text-light font-s-md  font-w-bold">
              Last Updated At
            </div>
            <div className=" text-light font-s-n  font-w-bold">
              {getDateTime_12T2D3M2Y(keywordsAlert.lastUpdatedAt)}
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex gap-4 flex-column rounded-4 shadow my-2 overflow-auto"
        style={{
          maxHeight: "24rem",
        }}
      >
        <table className="table--keyword">
          <thead>
            <tr className="font-s-n text-light font-w-bolder text-uppercase">
              <th className="px-4">Keyword</th>
              <th>Total</th>
              <th>TV</th>
              <th>News</th>
              <th className="px-4">
                <div className="d-flex justify-content-end">Action</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {keywordsAlert.map((alert) => (
              <>
                <tr
                  className={`${
                    activeKeyword === alert.keyword ? "tr--active" : ""
                  }`}
                >
                  <td className="px-4 text-white font-s-md font-w-bold">
                    <div
                      onClick={() => handleActiveKeyword(alert.keyword)}
                      className={`${alert.severity === 1 && "text-success"} 
                      ${alert.severity === 2 && "text-warning"} 
                      ${alert.severity === 3 && "text-danger"} 
                      d-flex justify-content-left text-nowrap`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {alert.keyword}
                    </div>
                  </td>
                  <td className="text-white font-s-n font-w-bold">
                    {alert.total}
                  </td>
                  <td className="text-white font-s-n font-w-bold">
                    {alert.tvTotal}
                  </td>
                  <td className="text-white font-s-n font-w-bold">
                    {alert.newspaperTotal}
                  </td>
                  <td className="text-white font-s-n px-4 font-w-bolder">
                    <div className="d-flex justify-content-end">
                      <Link
                        to={`/news${getQueryString(alert)}#auto-play`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </Link>
                    </div>
                  </td>
                </tr>
                {activeKeyword === alert.keyword && (
                  <tr>
                    <td colSpan={5}>
                      <KeywordDetails
                        keyword={activeKeyword}
                        severity={alert.severity}
                      />
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <></>
  );
};

export { KeywordsAlert };
