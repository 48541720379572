import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNotices, removeNotice } from "./noticeTable.service.js";
import { ROUTE } from "../../../../const";
import { editNotice } from "../EditNotice/editNotice.service";

import { EditNotice } from "..";
import { ConfirmationAlert, UpdatedPagination } from "../../../../components";

import "./style.scss";

const NoticeTable = () => {
  const [notices, setNotice] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);

  //const [SelectedNoticeSeen, setSelectedNoticeSeen] = useState(null);

  const [selectedNoticeId, setSelectedNoticeId] = useState(0);

  const [showAlert, setShowAlert] = useState(false);

  const [selectedDeleteNotice, setSelectedDeleteNotice] = useState(null);

  const initialValue = {
    notice: [],
    total: 0,
    page: 0,
  };

  const [state, setState] = useState({ ...initialValue });

  //params
  const [params, setParams] = useState({
    size: 40,
    page: 0,
  });

  const handleState = (key, value) => {
    setState((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleParams = (key, value) => {
    if (key === "page") {
      setParams((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else {
      setParams((prev) => ({
        ...prev,
        [key]: value,
        page: 0,
      }));
    }
  };

  const fetchNotice = () => {
    getNotices(params, (status, data) => {
      if (status === "success") {
        handleState("notice", data.data);
        handleState("total", data.total);
        handleState("page", data.page);
      }
    });
  };

  const handleOpenAlert = () => setShowAlert(true);

  const handleCloseAlert = () => setShowAlert(false);

  const handleDelete = (id) => {
    const params = {
      id: id,
      title: selectedDeleteNotice.title,
      detail: selectedDeleteNotice.detail,
      keyword: selectedDeleteNotice.keyword,
      deleted: true,
    };
    removeNotice(id, params, (status, data) => {
      if (status === "success") {
        handleCloseAlert();
        removeSelectedNotice();
        setNotice((prev) => prev.filter((obj) => obj.id !== id));
        window.location.reload(true);
      }
    });
  };

  const handleSelectedNotice = (notice) => {
    setSelectedNotice(notice);
  };
  const removeSelectedNotice = () => {
    setSelectedNotice(null);
  };

  useEffect(() => {
    fetchNotice();
  }, [params]);
  return (
    <div className="text-silver">
      <table className="w-100 table--dictionary ">
        <thead>
          <tr
            className="text-light font-s-n font-w-bolder text-uppercase"
            style={{ letterSpacing: "1px" }}
          >
            <th>SL</th>
            <th>Title</th>
            <th>Detail</th>

            <th>
              <div className="d-flex justify-content-end">ACTIONS</div>
            </th>
          </tr>
        </thead>

        <tbody>
          {state.notice.length > 0 &&
            state.notice.map((notice, index) => (
              <tr key={index}>
                <td className="text-light font-s-n font-w-bolder">
                  {`# ${index + 1}`}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {notice.title}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  {notice.detail}
                </td>
                <td className="text-white font-s-n font-w-bolder">
                  <div className="d-flex justify-content-end gap-4">
                    <button
                      className="btn btn-md  bg-warning text-white font-w-bolder px-4 font-s-md"
                      onClick={() => handleSelectedNotice(notice)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-danger btn btn-md text-white px-4 font-w-bolder font-s-md"
                      onClick={() => {
                        handleOpenAlert();
                        setSelectedDeleteNotice(notice);
                        setSelectedNoticeId(notice.id);
                      }}
                    >
                      Delete
                    </button>
                    <Link
                      className="btn btn-primary text-white"
                      to={`/${ROUTE.SEENNOTICELIST}/${notice.id}`}
                    >
                      Seen List
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {selectedNotice ? (
        <EditNotice
          selectedNotice={selectedNotice}
          removeSelectedNotice={removeSelectedNotice}
        />
      ) : (
        <></>
      )}

      <ConfirmationAlert
        show={showAlert}
        handleConfirm={handleDelete}
        id={selectedNoticeId}
        handleShow={handleCloseAlert}
        contentType={"notices"}
      />

      {state.total >= 40 ? (
        <UpdatedPagination
          itemsPerPage={40}
          total={state.total}
          action={handleParams}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default NoticeTable;
