import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

import { addNewCategory } from "./addCategory.service";

const AddCategory = ({ open, handleClose }) => {
  const [category, setCategory] = useState("");
  const [color, setColor] = useState("");
  const [description, setDescription] = useState("");
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleColor = (color) => {
    setColor(color);
  };
  const handleDescription = (descrip) => {
    setDescription(descrip.target.value);
  };

  const onAddCategory = () => {
    addNewCategory(
      { categoryName: category, color: color, description: description },
      (status, data) => {
        if (status === "success") {
          window.location.reload(true);
        }
      }
    );
    handleClose();
  };
  return (
    <div>
      <Modal
        show={open}
        onHide={handleClose}
        className="newspaper-details"
        size="md"
        centered
      >
        <ModalHeader closeButton>
          <div className="text-white">New Category</div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Category</div>
              <div style={{ minWidth: "250px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="text"
                  placeholder="Enter Category Name"
                  value={category}
                  onChange={handleCategory}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Description</div>
              <div style={{ minWidth: "250px" }}>
                <textarea
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  rows={2}
                  placeholder="Enter Description"
                  value={description}
                  onChange={handleDescription}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-3 flex-wrap">
              <div className="text-light font-s-md">Color</div>
              <div style={{ maxWidth: "100px" }}>
                <input
                  className="form-control rounded-4 bg-black__light text-white p-0 py-3 px-4 font-s-md font-w-bolder  border-2 border-primary"
                  type="color"
                  placeholder="Enter Color"
                  value={color}
                  style={{ minHeight: "50px" }}
                  onChange={(e) => handleColor(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary " onClick={onAddCategory}>
            Add Category
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddCategory;
