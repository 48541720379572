import apiService from "../../dataService/apiService";
import { getEnvironment } from "../../const/environment";
import moment from "moment";

export const getMangerMarkedReport = (params, done = () => {}) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${env.port}/topic-management-service/api/v2/${env.type}/report/manager-report?`;

  const _params = {
    date: moment(params.date).format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment(params.endDate).format("YYYY-MM-DD HH:mm:ss"),
    contentType: params.contentType === "" ? null : params.contentType,
    categoryType: params.category === "" ? null : params.category.categoryName,
    sentiment: params.sentiment === "" ? null : params.sentiment,
    sourceName: params.sourceName === "" ? null : params.sourceName,
    size: params.size,
    page: params.page,
  };
  apiService.get(
    url,
    (status, data) => {
      if (status > 199 && status < 300) {
        done("success", data);
      } else {
        done("error");
      }
    },
    {},
    { ..._params }
  );
};

export const downloadManagerMarkedReport = (
  date,
  reportIds,
  done = () => {}
) => {
  const env = getEnvironment();
  const url = `${env.protocol}://${env.baseUrl}:${
    env.port
  }/topic-management-service/api/v2/${
    env.type
  }/report/manager-marked-report/download?
  sourceName=${date.sourceName ? date.sourceName : ""}&categoryType=${
    date.category ? date.category.categoryName : ""
  }&sentiment=${date.sentiment}&ids=${reportIds}&contentType=${
    date.contentType
  }&endDate=${moment(date.endDate).format("YYYY-MM-DD HH:mm:ss")}&date=${moment(
    date.date
  ).format("YYYY-MM-DD HH:mm:ss")}`;

  const config = {
    url,
    method: "GET",
    responseType: "blob",
  };

  apiService.custom(config, (status, data) => {
    if (status > 199 && status < 300) {
      done("success", data);
    } else {
      done("error");
    }
  });
};
