import React, { useState, useEffect } from "react";

import { useUser } from "../../dataService/contexts";
import { addBookmark, removeBookmark } from "./bookmarkButton.service";

import BookmarkOff from "../../assets/BookmarkOff";
import BookmarkOn from "../../assets/BookmarkOn";

const BookmarkButton = ({ news }) => {
  const { state: userState } = useUser();
  const { user } = userState || {};

  const [checked, setChecked] = useState(false);
  const [copyNews, setCopyNews] = useState(null);

  const handleCheckbox = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleClick = () => {
    if (checked) {
      removeBookmark(
        news?.bookmarkInfo?.id || copyNews?.bookmarkInfo?.id,
        (status, data) => {
          if (status === "success") {
            handleCheckbox();
            setCopyNews((prev) => ({
              ...prev,
              bookmarkInfo: null,
            }));
          }
        }
      );
    } else {
      addBookmark(
        {
          contentId: news.contentId,
          contentType: news.type,
        },
        user,
        (status, data) => {
          if (status === "success") {
            setCopyNews((prev) => ({
              ...prev,
              bookmarkInfo: { id: data.id },
            }));
            handleCheckbox();
          }
        }
      );
    }
  };
  useEffect(() => {
    setChecked(!!news.bookmarkInfo);
    setCopyNews(news);
  }, []);

  return (
    <div
      className="pretty p-icon p-toggle p-plain align-self-center"
      onClick={() => handleClick("Cliked")}
    >
      <input
        className=" form-check-input align-self-center"
        type="checkbox"
        checked={checked}
      />
      <div className="state p-on">
        <BookmarkOff />
      </div>
      <div className="state p-off">
        <BookmarkOn />
      </div>
    </div>
  );
};

export default BookmarkButton;
